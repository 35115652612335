import SolarPowerIcon from '@mui/icons-material/SolarPower';
import LabelIcon from '@mui/icons-material/Label';
import * as React from 'react';
import TreeItem from '../TreeItem/TreeItem';
import {DashboardNavigationMenu} from '../../types/DashboardNavigationMenu';
import {getIconColor} from '../../helpers/getIconColor';
import {Handyman} from "@mui/icons-material";
import {MenuItemStatus} from "../../types/MenuItemStatus";
import {useTheme} from "@mui/material";

interface NavigationMenuItemProps {
    item: DashboardNavigationMenu;
    filters?: string[] | null ;
}

const NavigationMenuItem = ({ item, filters }: NavigationMenuItemProps): JSX.Element => {
    const theme = useTheme();
    const colorStationAndSkid = theme.palette.mode === 'light' ? '' : theme.palette.text.primary
    const colorEquipment = theme.palette.mode === 'light' ? '' : theme.palette.text.secondary
    const renderEquipmentList = (equipments: { id: number, name: string, etsStatus: MenuItemStatus }[]) => {
        return (
            <div>
                {equipments.map((equipment) => (
                    <TreeItem
                        key={`e${equipment.id}`}
                        nodeId={`e${equipment.id}`}
                        labelText={equipment.name}
                        title={equipment.etsStatus}
                        labelIcon={Handyman}
                        style={{ marginLeft: '10px' }}
                        color={colorEquipment}
                        iconColor={`${getIconColor(equipment.etsStatus)}`}
                    />
                ))}
            </div>
        );
    };

    const filteredSkids =
        item.skidList.filter(skid => {
            // filters ? filters.includes(skid.name) : false
            for (let i = 0; filters && i < filters.length; i++) {
                if(skid.name.toLowerCase().includes(filters[i].toLowerCase()))
                    return true;
            }
            return false;
        })

    return (<TreeItem key={`p${item.id}`} nodeId={`p${item.id}`} labelText={item.name} title={item.status} labelIcon={SolarPowerIcon} iconColor={getIconColor(item.status)}
                      color={colorStationAndSkid}
                      fontWeight={'bold'}>
        {filters && filters.length > 0 && item.skidList && item.skidList.length > 0 ?
            filteredSkids
                    .map(skid => {
                        return (
                            <TreeItem key={`s${item.id}${skid.id}`} nodeId={`s${skid.id}`} labelText={skid.name}
                                      labelIcon={LabelIcon}
                                      color={colorStationAndSkid}
                                      iconColor={getIconColor(skid.status)}
                            />)
                    }) :
            null}

        {!filters && item.skidList && item.skidList.length > 0?
            item.skidList.map(skid => {
                    return (
                            <TreeItem
                                key={`s${item.id}${skid.id}`}
                                nodeId={`s${skid.id}`}
                                labelText={skid.name}
                                title={skid.status}
                                labelIcon={LabelIcon}
                                style={{ marginLeft: '50px' }}
                                color={colorStationAndSkid}
                                iconColor={getIconColor(skid.status)}
                                // marginLeft={}
                            >
                                {renderEquipmentList(skid.equipmentList)}
                            </TreeItem>
                    )
                }) :
            null }
        {renderEquipmentList(item.equipmentList)}
    </TreeItem>);
}

export default NavigationMenuItem;