import {
    Autocomplete,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useSnackbar} from "notistack";
import {MenuProps} from "../../Utils/PropsStyles";
import Box from "@mui/material/Box";
import {SelectChangeEvent} from "@mui/material/Select";
interface ParamsModalProps {
    open: boolean;
    onCloseModal: any;
    commandDTOList: Array<CommandDTO>;
}
export interface CommandDTO {
    id: number;
    teleObjectName: string;
    userId: number;
    point: number;
    teleObjectSpecType: number;
    etsCode: number;
    brokerId: number;
    powerStationName?: string;
    skidName?: string | null;
    equipmentName?: string;
    commandValues?: any;
}
export const SendingTelecommandUnifilar = ({open, onCloseModal, commandDTOList}: ParamsModalProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [inputValue, setInputValue] = useState("");
    const [commandDTO, setCommandDTO] = useState<CommandDTO | null>(null)
    const cardInfo = commandDTOList != null && commandDTOList.length > 0 ? commandDTOList[0] : null
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [selectedCommand, setSelectedCommand] = useState("");
    const [selectCommandId, setSelectCommandId] = useState(null);

    useEffect(() => {
        setInputValue("")
    }, [onCloseModal]);

    const handleButtonClick = (event:any, data:CommandDTO) => {
        setCommandDTO(data);
    }

    const handleSelectedOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value)
        setInputValue(event.target.value);
    }

    const handleSelectTeleCommandValue = (event: SelectChangeEvent<unknown>) => {
        setSelectedCommand(event.target.value as string);
        let commandValue = commandDTO?.commandValues.find((cmd: any) => cmd.name === event.target.value);
        if (commandValue != null) {
            setInputValue(commandValue.value);
            setSelectCommandId(commandValue.id);
        }
    }

    const handleSend = async () => {
        if (commandDTO != null && inputValue != null) {
            if (!/^\d+$/.test(inputValue)) {
                enqueueSnackbar("Apenas valores numéricos são aceitos!", {variant: "error"});
                return;
            }
            axios.post('/api/send-command', {
                userId: commandDTO.userId,
                teleObjectId: commandDTO.id,
                point: commandDTO.point,
                teleObjectSpecType: commandDTO.teleObjectSpecType,
                etsCode: commandDTO.etsCode,
                data: Number(inputValue),
                brokerId: commandDTO.brokerId,
                commandValueId: selectCommandId

            }).then(
                response => {
                    enqueueSnackbar("Comando enviado com sucesso!", { variant: "success" });
                    setSelectCommandId(null);
                    setSelectedCommand("");
                    onCloseModal()
                }
            ).catch(
                error => {
                    enqueueSnackbar("Erro ao enviar o comando, tente novamente!", {variant: "error"});
                    console.error("Erro ao enviar comando. Tente novamente:", error);
                }
            )
        }
    };
    const printFullHeader = () => {
        if(cardInfo != null){
            if(cardInfo?.skidName != null && cardInfo?.powerStationName != null && cardInfo?.equipmentName != null){
                return true
            }
        }
        return false
    }
    return (
        <Dialog open={open} onClose={onCloseModal}>

            {printFullHeader() ?
                <DialogTitle>{cardInfo?.equipmentName + ' - ' + cardInfo?.skidName + ' - ' + cardInfo?.powerStationName}</DialogTitle>
                :   <DialogTitle>{cardInfo?.teleObjectName}</DialogTitle>
            }
            <DialogContent>
                <Autocomplete
                    id="grouped-demo"
                    value={commandDTO}
                    fullWidth
                    options={commandDTOList}
                    getOptionLabel={(option) => option.teleObjectName}
                    inputValue={commandDTO?.teleObjectName ?? ''}
                    renderInput={(params) => <TextField {...params} label={"Comandos"} variant="standard" />}
                    onChange={(event: any, newValue: any) => handleButtonClick(event,newValue)}
                    sx={{paddingBottom: 3}}
                />
                {
                    commandDTO && commandDTO.teleObjectSpecType === 5 ?
                        <RadioGroup
                            aria-labelledby="chart-type"
                            row
                            name="chart-type"
                            onChange={handleSelectedOption}
                            value={selectedOption}
                        >
                            <FormControlLabel value="1" control={<Radio/>} label="Ligar"/>
                            <FormControlLabel value="0" control={<Radio/>} label="Desligar"/>
                        </RadioGroup>
                        :
                        commandDTO
                        && (commandDTO.teleObjectSpecType === 6 || commandDTO.teleObjectSpecType === 8)
                        && (commandDTO.commandValues && commandDTO.commandValues.length > 0) ?
                            <Box>
                                <InputLabel id="valueCommand" sx={{fontSize: '.8rem'}}>Selecione o Valor</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="valueCommand"
                                    id="valueCommand"
                                    placeholder="Selecione o valor"
                                    value={selectedCommand}
                                    label="Selecione o valor"
                                    title="Selecione o valor"
                                    onChange={(value) => handleSelectTeleCommandValue(value)}
                                    variant={"standard"}
                                    MenuProps={MenuProps}
                                >
                                    {commandDTO.commandValues.map((item: any, index: number) =>
                                        <MenuItem key={index} value={item.name}>{`Nome: ${item.name}, Valor: ${item.value}`}</MenuItem>)}
                                </Select>
                            </Box>
                            :
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Digite o valor"
                                type="text"
                                fullWidth
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseModal} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSend} color="primary" variant="contained" disabled={inputValue === ""}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    )
}