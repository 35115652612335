import {ACard, ADataGrid, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {Grid} from "@mui/material";
import * as React from "react";
import {DateFormat, LocalDateTimeFormatISO, validateDateFormatISO} from "../../../Utils/DateFormatPatternUtils";
import {useCallback, useEffect, useState} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {hideDashboardHeaderMenu} from "../../Dashboard/DashboardMenuReducer";
import {useAppDispatch} from "../../../Config/Hooks";
import {AuditAlarmsQuery} from "./AuditAlarmsReactQuery";
import {ActionConfig} from "../../Dashboard/Alarms/AlarmPage";
import {initColumnsData} from "./Columns";
import {formatISO, isValid} from "date-fns";

export interface AlarmRow {
    id: string;
    selected?: boolean;
    teleObjectId: number;
    severityId: number;
    severityName: string;
    severityColor: string;
    description: string;
    macroRegionName: string;
    regionName: string;
    powerStationId: number;
    powerStationName: string | JSX.Element;
    skidId: number;
    skidName: string | JSX.Element;
    equipmentId: number;
    equipmentName: string;
    clearDate?: Date | string;
    acknowledgementDate?: Date | string;
    dateTime: Date | string;
    dateTimeNote: Date | string;
    acknowledgedUser: string;
    clearedUser: string;
    acknowledgementNote: string;
    descriptionNote: string;
    userNameNote: string;
    group: boolean | any;
    actionsConfig?: Array<ActionConfig>

}

function AuditAlarms(){
    const dispatch = useAppDispatch();
    const [rows, setRows] = React.useState<AlarmRow[]>([]);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumnsData), 0, 50));
    const { data, isLoading, isSuccess } = AuditAlarmsQuery(pagedSearchParams);
    useEffect(() => {
        dispatch(hideDashboardHeaderMenu())
    }, []);
    const updateRows = (data: any) => {
        const filteredData = data?.data?.map((alarm: any) => {
            return {
                teleObjectId: alarm.teleObjectId,
                selected:false,
                id: alarm.id.toString(),
                group: alarm.group,
                severityId: alarm.severityId,
                severityName: alarm.severityName,
                severityColor: alarm.severityColor,
                description: alarm.description,
                macroRegionName: alarm.macroRegionName,
                regionName: alarm.regionName,
                powerStationId: alarm.powerStationId,
                powerStationName: alarm.powerStationName,
                skidId: alarm.skidId,
                skidName: alarm.skidName,
                equipmentId: alarm.equipmentId,
                equipmentName: alarm.equipmentName,
                clearDate: alarm.clearDate ? DateFormat(alarm.clearDate) : "",
                acknowledgementDate: alarm.acknowledgementDate ? DateFormat(alarm.acknowledgementDate) : "",
                dateTime: DateFormat(alarm.dateTime),
                clearedUser: alarm.clearedUser,
                acknowledgedUser: alarm.acknowledgedUser,
                descriptionNote: alarm.descriptionNote,
                dateTimeNote: alarm.dateTimeNote ? DateFormat(alarm.dateTimeNote) : undefined,
                userNameNote: alarm.userNameNote,
                actionsConfig: [
                    {actionName: "grouping", disabled: false},
                    {actionName: "note", disabled: false},
                ],
            };
        }).sort((a:any, b:any) => {
            return new Date(b.dateTimeReq).getTime() - new Date(a.dateTimeReq).getTime();
        });

        setRows(filteredData ?? []);
    };

    useEffect(() => {
        if (isSuccess && data?.totalOfRecords !== undefined) {
            setTotalOfRecords(data?.totalOfRecords)
        }
    }, [data?.data]);

    useEffect(() => {
        updateRows(data);
    }, [data?.data]);

    const formatISOData = (date: string) => {
        const dateTime =  validateDateFormatISO(date);
        if(isValid(new Date(dateTime))){
            return LocalDateTimeFormatISO(dateTime)
        }

        if(isValid(new Date(date))){
            return LocalDateTimeFormatISO(date)
        }
        return "";
    }

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        console.log("newPageParams",newPageParams.filters)
        if(newPageParams.filters[8] && newPageParams.filters[8].value.length > 0){
            newPageParams.filters[8].value = formatISOData(newPageParams.filters[8].value);
        }
        if(newPageParams.filters[9] && newPageParams.filters[9].value.length > 0){
            newPageParams.filters[9].value = formatISOData(newPageParams.filters[9].value);
        }
        if(newPageParams.filters[10] && newPageParams.filters[10].value.length > 0){
            newPageParams.filters[10].value = formatISOData(newPageParams.filters[10].value);
        }
        if(newPageParams.filters[13] && newPageParams.filters[13].value.length > 0){
            newPageParams.filters[13].value = formatISOData(newPageParams.filters[13].value);
        }
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    return (
        <Grid item padding={1} xs={12} style={{marginTop: - 56}}>
            <ACard title="Auditoria de alarmes"
                   styleProps={{
                       titleStyle: {
                           fontWeight: 400,
                           fontSize: '1.5rem',
                           lineHeight: 1.334
                       }
                   }}>
                <ADataGrid
                    hideFilters={false}
                    showFilterOptions={false}
                    hideSelection={true}
                    size={"small"}
                    headerStyle={{
                        paddingTop: 2
                    }}
                    columns={initColumnsData}
                    rows={rows}
                    page={pagedSearchParams.page}
                    loading={isLoading}
                    totalOfRecords={totalOfRecords}
                    onFiltersChange={handleOnFiltersChange}
                    rowsPerPage={pagedSearchParams.size}
                    actionColumnWidth={50}
                    maxHeight={700}
                />

            </ACard>
        </Grid>
    )
}

export default function AuditAlarmsPage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <AuditAlarms/>
        </QueryClientProvider>
    )
}