import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../../Config/Reducers/ReducerUtils";
import {DefaultState} from "../../../../../Config/Types";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {DateFormat} from "../../../../../Utils/DateFormatPatternUtils";

export class AlarmIteration {

    id: number;
    description: string;
    dateTime: string;
    userName: string;
    alarmId: string;

    constructor( id: number, description: string, dateTime: string, userName: string, alarmId: string) {

        this.id = id;
        this.description = description;
        this.dateTime = dateTime;
        this.userName = userName;
        this.alarmId = alarmId;
    }
}

export class NewAlarmIteration {

    description: string;
    user:any;
    alarm: any;
    dateTime: any;

    constructor( description: string, user: any, alarm: any, dateTime:any) {

        this.description = description;
        this.user = user;
        this.alarm = alarm;
        this.dateTime = dateTime;
    }
}

const initialState: DefaultState = {
    loading: false,
    saving: false,

    errorMessage: undefined,
    successMessage: undefined,

    entities: new Array<AlarmIteration>(),
    entity: NewAlarmIteration,

    totalOfPages: 0,
    totalOfRecords: 0,
    totalOfRequestedRecords: 0,
    pageNumber: 0,
    recordsPerPage: 20,
};

const apiUrl = 'api/alarm-notes/';


export const searchEntitiesAlarmIteration = createAsyncThunk<
    any,
    {
        alarmId: Number;
        params: PagedSearchParams | undefined;
    }>(
    'alarms_iteration/alarm/search_entity',
    async ({alarmId, params}) => {

    return axios.get(
            apiUrl + `search/${alarmId}`,
            {
                params: params ? params.toURLSearchParams() : null
            }
        );

    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    'alarms_iteration/create_entity',
    async (entity: NewAlarmIteration, thunkAPI) => {

        const result =  await axios.put(apiUrl, entity);
        thunkAPI.dispatch(searchEntitiesAlarmIteration({alarmId: entity.alarm.id, params:undefined}));
        return result;
        },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, { rejectValue: any }>(
    'alarms_iteration/alarm/fetch_entity',
    async () => {
        return axios.get<AlarmIteration>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const AlarmIterationReducer = createSlice({
    name: 'AlarmIterationReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        resetIteration() {
            return initialState;
        },
        clearIteration: (state) => {
            state.entities = [];

        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(searchEntitiesAlarmIteration), (state, action) => {
                state.loading = false;
                state.saving = false;

                state.successMessage = undefined;
                state.errorMessage = undefined;

                state.entities = action.payload.data.data.map((element: AlarmIteration) => {
                    element.dateTime = DateFormat(element.dateTime)
                    return element;
                });
                state.entity = undefined;

                state.totalOfPages = action.payload.data.totalOfPages;
                state.totalOfRecords = action.payload.data.totalOfRecords;
                state.totalOfRequestedRecords = action.payload.data.totalOfRequestedRecords;
                state.pageNumber = action.payload.data.pageNumber;
                state.recordsPerPage = action.payload.data.recordsPerPage;

            })
            .addMatcher(isPending(searchEntitiesAlarmIteration, getEntity), state => {
                state.loading = true;
                state.successMessage = undefined;
                state.errorMessage = undefined;
            })
            .addMatcher(isRejected(searchEntitiesAlarmIteration), (state:DefaultState, action) => {
                state.loading = false;
                state.successMessage = undefined;
                state.errorMessage = action.error.message;
            })
    },
})

export const { resetIteration, clearIteration } = AlarmIterationReducer.actions;
export const selectAlarmIteration = (state: RootState) => state.alarmIteration;

// Reducer
export default AlarmIterationReducer.reducer;