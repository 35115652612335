import React, {FunctionComponent, useEffect} from "react";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {getTotalizers} from "../../../Shared/Components/AlarmTotalizers/AlarmTotalizersReducer";
import {Container} from "../../../Shared/Components/AlarmTotalizers/AlarmTotalizers.theme";
import {Skeleton, ProgressCard} from "@atiautomacao/ati-ui-library";
import {ACard} from "@atiautomacao/ati-ui-library";
import { Box, Button, Grid, styled, withStyles } from "@mui/material";
import useInterval from "../../../Shared/Hooks/useInterval";
import { TotalizerType } from "../Overview/DashboardOverviewAlarmsPage";
import { useTheme } from "@mui/material";


const TotalizerAlarms: FunctionComponent<{onClickTotalizer:any}> = ({onClickTotalizer}) => {
    const theme = useTheme();
    console.log("theme",theme);
    
    const bootstrapButton = {
        // textTransform: 'none',
        width: 200, 
        border: 'none',
        borderStyle: "none",
        background:  theme.palette.mode === 'light' ? '#FFF' : '#1E1E1E',
        cursor: 'pointer',
    };

    const styledTotalizeCard = {
        '&:hover': {
            boxShadow: '#0062cc',
            transform: 'scale(1.1)',
            transition: "all 0.2s linear",
            // backgroundColor:"#1f477013",
            // color: "rgb(41, 89, 235)"
        }
    };

    const { totalizers, isLoading } = useAppSelector((state: RootState) => state.alarmTotalizers);
    const dispatch = useAppDispatch();

    const [opacityPowerOffice, setOpacityPowerOffice] = React.useState(false);
    const [opacityEquipmentOffice, setOpacityEquipmentOffice] = React.useState(false);
    const [opacityEquipmentAlarm, setOpacityEquipmentAlarm] = React.useState(false);
    const [opacityAlarmCritical, setOpacityAlarmCritical] = React.useState(false);
    const [opacityNotAcknowledged, setOpacityNotAcknowledged] = React.useState(false);
    const [opacityActive, setOpacityActive] = React.useState(false);
    const [valueTotalizerType, setValueTotalizerType] = React.useState<TotalizerType | null>(localStorage.getItem("totalizer_by_overview") as TotalizerType ?? "NONE");
    const userConfig = useAppSelector((state: RootState) => state.userConfigurarion);
    
    useEffect(() => {
        dispatch(getTotalizers());
        const totalizer = localStorage.getItem("totalizer_by_overview") as TotalizerType;
        if(totalizer){
            setValueTotalizerType(totalizer);
            handleOpacity(totalizer);
        }
    }, []);

    const handleOpacity = (type: TotalizerType) => {
        if(type === "POWER_STATION_OFFLINE"){
            setOpacityPowerOffice(!opacityPowerOffice);
        }
        if(type === "EQUIPMENT_OFFLINE"){
            setOpacityEquipmentOffice(!opacityEquipmentOffice);
        }
        if(type === "EQUIPMENTS_ALARMED"){
            setOpacityEquipmentAlarm(!opacityEquipmentAlarm);
        }
        if(type === "ACTIVE"){
            setOpacityActive(!opacityActive);
        }
        if(type === "ACTIVE_CRITICAL"){
            setOpacityAlarmCritical(!opacityAlarmCritical);
        }
        if(type === "NOT_ACKNOWLEDGED"){
            setOpacityNotAcknowledged(!opacityNotAcknowledged);
        } 
        if(type === "NONE"){
            setOpacityPowerOffice(false);
            setOpacityEquipmentOffice(false);
            setOpacityEquipmentAlarm(false);
            setOpacityActive(false);
            setOpacityAlarmCritical(false);
            setOpacityNotAcknowledged(false);
        }
    }

    useInterval(() => {
        dispatch(getTotalizers());
    }, userConfig.alarmUpdateInterval); //5 minutes

    const handleOnclickByType = (totalizeType: TotalizerType) => {
        setValueTotalizerType(totalizeType);
    }

    useEffect(() => {
        if(valueTotalizerType){
            onClickTotalizer(valueTotalizerType);
        }

    }, [valueTotalizerType]);

    if (isLoading) {
        return (
            <Box style={{paddingBottom: 20}}>
                <ACard
                    title={"Totalizadores"}
                >
                    <Container data-testid="loading-skeleton">
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                    </Container>
                </ACard>
            </Box>
        );
    }

    return (
        <Box style={{paddingBottom: 20}}>
            <ACard
                title={"Totalizadores"}
            >
                <Grid container spacing={2} sx={{marginTop: -4}}>
                    <Grid item 
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        xs={6} sm={4} md={4} lg={4} xl={2}>
                        <button style={{...bootstrapButton}}      
                            onClick={() => {
                                opacityPowerOffice ? handleOnclickByType("NONE") : handleOnclickByType("POWER_STATION_OFFLINE") 
                                setOpacityPowerOffice(!opacityPowerOffice)
                            }}
                        >
                            <ProgressCard
                                    value={totalizers.powerStationOff}
                                    label="Usinas Fora do Ar"
                                    background={valueTotalizerType === "POWER_STATION_OFFLINE" && opacityPowerOffice ?  theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey["A700"] :
                                        theme.palette.mode === 'light' ? '#fff' : '#1E1E1E' }
                                    textColor={valueTotalizerType === "POWER_STATION_OFFLINE" && opacityPowerOffice ? '#FFF' : theme.palette.text.primary}
                                    width={200}
                                    boldLabel={true}
                                    showProgressBar={false}
                                    styledPaperProps={{borderColor:  theme.palette.grey["A700"],  ...styledTotalizeCard, 
                                        transform: valueTotalizerType === "POWER_STATION_OFFLINE" && opacityPowerOffice &&  theme.palette.mode === 'dark' ? 'scale(1.2)': 'scale(1)',
                                        transition: "all 0.2s linear",
                                        opacity: valueTotalizerType === "POWER_STATION_OFFLINE" && opacityPowerOffice &&  theme.palette.mode === 'dark' ? 1 : theme.palette.mode === 'dark' ? 0.5 : 1
                                    }}
                            />
                            
                        </button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <button style={{...bootstrapButton}}
                            onClick={() => {
                                opacityEquipmentOffice ? handleOnclickByType("NONE") : handleOnclickByType("EQUIPMENT_OFFLINE")
                                setOpacityEquipmentOffice(!opacityEquipmentOffice)
                                
                            }}
                        >
                            <ProgressCard
                                value={totalizers.equipmentsOff}
                                label="Equipamentos Fora do Ar"
                                background={valueTotalizerType === "EQUIPMENT_OFFLINE" && opacityEquipmentOffice ? 
                                    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey["A700"] :  theme.palette.mode === 'light' ? '#FFF' : '#1E1E1E' }
                                textColor={valueTotalizerType === "EQUIPMENT_OFFLINE" && opacityEquipmentOffice ? '#FFF' : theme.palette.text.primary}
                                width={200}
                                boldLabel={true}
                                showProgressBar={false}
                                styledPaperProps={{borderColor:  theme.palette.grey["A700"], ...styledTotalizeCard,
                                    transform: valueTotalizerType === "EQUIPMENT_OFFLINE" && opacityEquipmentOffice &&  theme.palette.mode === 'dark' ? 'scale(1.2)': 'scale(1)',
                                    transition: "all 0.2s linear",
                                    opacity: valueTotalizerType === "EQUIPMENT_OFFLINE" && opacityEquipmentOffice &&  theme.palette.mode === 'dark' ? 1 : theme.palette.mode === 'dark' ? 0.5 : 1
                                }}
                            />
                        </button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <button style={{...bootstrapButton}}
                            onClick={() => {
                                opacityEquipmentAlarm ? handleOnclickByType("NONE") : handleOnclickByType("EQUIPMENTS_ALARMED") 
                                setOpacityEquipmentAlarm(!opacityEquipmentAlarm)
                            }}
                        >
                            <ProgressCard
                                value={totalizers.equipmentsAlarmed}
                                label="Equipamentos Alarmados"
                                background={valueTotalizerType === "EQUIPMENTS_ALARMED" && opacityEquipmentAlarm ? theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey["A700"] :  theme.palette.mode === 'light' ? '#fff' : '#1E1E1E' }
                                textColor={valueTotalizerType === "EQUIPMENTS_ALARMED" && opacityEquipmentAlarm ?  '#FFF' : theme.palette.text.primary}
                                width={200}
                                boldLabel={true}
                                showProgressBar={false}
                                styledPaperProps={{borderColor:  theme.palette.grey["A700"], ...styledTotalizeCard,
                                    transform: valueTotalizerType === "EQUIPMENTS_ALARMED" && opacityEquipmentAlarm &&  theme.palette.mode === 'dark' ? 'scale(1.2)': 'scale(1)',
                                    transition: "all 0.2s linear",
                                    opacity: valueTotalizerType === "EQUIPMENTS_ALARMED" && opacityEquipmentAlarm &&  theme.palette.mode === 'dark' ? 1 : theme.palette.mode === 'dark' ? 0.5 : 1}}
                            />
                        </button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <button style={{...bootstrapButton}}
                            onClick={() => {
                                opacityActive ? handleOnclickByType("NONE") : handleOnclickByType("ACTIVE") 
                                setOpacityActive(!opacityActive)
                            }}
                        >
                            <ProgressCard
                                value={totalizers.active}
                                label="Ativos"
                                background={valueTotalizerType === "ACTIVE" && opacityActive ? theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey["A700"] :  theme.palette.mode === 'light' ? '#fff' : '#1E1E1E' }
                                textColor={valueTotalizerType === "ACTIVE" && opacityActive ?  '#FFF' : theme.palette.text.primary}
                                width={200}
                                boldLabel={true}
                                showProgressBar={false}
                                styledPaperProps={{borderColor:  theme.palette.grey["A700"], ...styledTotalizeCard,
                                    transform: valueTotalizerType === "ACTIVE" && opacityActive &&  theme.palette.mode === 'dark' ? 'scale(1.2)': 'scale(1)',
                                    transition: "all 0.2s linear",
                                    opacity: valueTotalizerType === "ACTIVE" && opacityActive &&  theme.palette.mode === 'dark' ? 1 : theme.palette.mode === 'dark' ? 0.5 : 1
                                }}
                            />
                        </button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <button style={{...bootstrapButton}}
                            onClick={() => {
                                opacityAlarmCritical ? handleOnclickByType("NONE") : handleOnclickByType("ACTIVE_CRITICAL") 
                                setOpacityAlarmCritical(!opacityAlarmCritical)
                            }}
                        >
                            <ProgressCard
                                value={totalizers.activeCritical}
                                label="Ativos Críticos"
                                background={valueTotalizerType === "ACTIVE_CRITICAL" && opacityAlarmCritical ? theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey["A700"] :  theme.palette.mode === 'light' ? '#fff' : '#1E1E1E' }
                                textColor={valueTotalizerType === "ACTIVE_CRITICAL" && opacityAlarmCritical ?  '#FFF' : theme.palette.text.primary}
                                width={200}
                                boldLabel={true}
                                showProgressBar={false}
                                styledPaperProps={{borderColor:  theme.palette.grey["A700"], ...styledTotalizeCard,
                                    transform: valueTotalizerType === "ACTIVE_CRITICAL" && opacityAlarmCritical &&  theme.palette.mode === 'dark' ? 'scale(1.2)': 'scale(1)',
                                    transition: "all 0.2s linear",
                                    opacity: valueTotalizerType === "ACTIVE_CRITICAL" && opacityAlarmCritical &&  theme.palette.mode === 'dark' ? 1 : theme.palette.mode === 'dark' ? 0.5 : 1
                                }}
                            />
                        </button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <button style={{...bootstrapButton}}
                            onClick={() => {
                                opacityNotAcknowledged ? handleOnclickByType("NONE") : handleOnclickByType("NOT_ACKNOWLEDGED") 
                                setOpacityNotAcknowledged(!opacityNotAcknowledged)
                            }}
                        >
                            <ProgressCard
                                value={totalizers.notAcknowledged}
                                label="Não Reconhecidos"
                                background={valueTotalizerType === "NOT_ACKNOWLEDGED" && opacityNotAcknowledged ? theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey["A700"] :  theme.palette.mode === 'light' ? '#fff' : '#1E1E1E' }
                                textColor={valueTotalizerType === "NOT_ACKNOWLEDGED" && opacityNotAcknowledged ?  '#FFF' : theme.palette.text.primary}
                                width={200}
                                boldLabel={true}
                                showProgressBar={false}
                                styledPaperProps={{borderColor:  theme.palette.grey["A700"], ...styledTotalizeCard,
                                    transform: valueTotalizerType === "NOT_ACKNOWLEDGED" && opacityNotAcknowledged &&  theme.palette.mode === 'dark' ? 'scale(1.2)': 'scale(1)',
                                    transition: "all 0.2s linear",
                                    opacity: valueTotalizerType === "NOT_ACKNOWLEDGED" && opacityNotAcknowledged &&  theme.palette.mode === 'dark' ? 1 : theme.palette.mode === 'dark' ? 0.5 : 1
                                }}
                            />
                        </button>
                      
                    </Grid>
                </Grid>
            </ACard>
        </Box>
    )

}   

export default TotalizerAlarms;
