import React, {FunctionComponent} from 'react';

import { Chart } from "./PerformanceAnalysisReducer";
import {format, isValid, parseISO} from "date-fns";
import {AChartX} from "@atiautomacao/ati-ui-library";
import {useTheme} from "@mui/material";

const GeneratedInjectedChart: FunctionComponent<{dataGenerated:Array<Chart>, dataInjected:Array<Chart>}> = ({dataGenerated, dataInjected}) => {
    const theme = useTheme();
    const style = {
        text: {
            fontSize: 16,
        }
    };

    const valueFormatter = (value:any,seriesItem:any) => {
        return  value + ' ' + seriesItem.unity
    }

    const chartOption: any = {
        yAxis: [{
            name: '',
            min: 0,
            max: Math.max(...dataGenerated.map(item => item.value ? parseInt(item.value.toString()) : item.value)),
            interval: Math.max(...dataGenerated.map(item => item.value ? parseInt(item.value.toString()) : item.value)) / 2,
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
            {
                name: '',
                min: 0,
                max: Math.max(...dataInjected.map(item => item.value ? parseInt(item.value.toString()) : item.value)),
                interval: Math.max(...dataInjected.map(item => item.value ? parseInt(item.value.toString()) : item.value)) / 2,
                axisLabel: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            }
        ],
        xAxis: {
            type: 'category',
            boundaryGap: true,
            name: '',
            nameLocation: 'center',
            nameGap: 20,
            data: dataGenerated.map(item => {
                const date = parseISO(item.dateTime);
                if (isValid(date)) {
                    return format(date, 'dd/MM');
                }
                return item.dateTime;
            }),
            offset: 5,
            axisLabel: {
                show: false,
                inside: false,
                showMinLabel: true,
                showMaxLabel: true,
                align: 'center',
                // interval: "auto",
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        series: [{
            name: 'Gerada',
            type: 'line',
            smooth: true,
            showSymbol: false,
            data: dataGenerated.map(item => item.value ? parseInt(item.value.toString()) : "-"),
            areaStyle: {},
        },
            {
                name: 'Injetada',
                type: 'line',
                smooth: true,
                showSymbol: false,
                data: dataInjected.map(item => item.value ? parseInt(item.value.toString()) : "-"),
                areaStyle: {},
                color: ['rgb(44, 52, 149)'],
            }
        ],
        legend: {
            show: false,
        },
        grid: {
            containLabel: true,
            top: 0,
            bottom: 10,
        },
        animationDuration: 2000,
        color: ['rgb(25, 118, 210)'],
        tooltip: {
            trigger: 'axis',
            textStyle: {
                fontSize: style.text.fontSize
            },
            valueFormatter: (value: any) => `${value} kWh`,
        },
        toolbox: {
            feature: {
              saveAsImage: {
                show: false
              }
            }
        }
    };


    return (
        <>
            { dataGenerated.length > 0 && dataInjected.length > 0 &&
                <AChartX
                    height={40}
                    option={chartOption}
                    theme={theme.palette.mode}
                />
            }

        </>
    )
}

export default GeneratedInjectedChart;