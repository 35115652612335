import {ADataGridColumn} from "@atiautomacao/ati-ui-library";


const commonBreakpoints = {
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true
};

const idBreakpoints = {
    xs: false,
    sm: false,
    md: false,
    lg: true,
    xl: true
};

export const columnsDataGrid: ADataGridColumn[] = [
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'name',
        label: 'Nome',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    }
];

export const columnsCommandHistoryDataGrid: ADataGridColumn[] = [
    {
        name: 'ticket',
        label: 'Ticket',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'dateTimeReq',
        label: 'Enviado',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'powerStationName',
        label: 'Usina',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'equipmentName',
        label: 'Equipamento',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'alarmedName',
        label: 'Telecomando',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'description',
        label: 'Descrição',
        align: 'left',
        minWidth: 10,
        visibility: false,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'value',
        label: 'Valor',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'dateTimeRes',
        label: 'Recebido',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'successRes',
        label: 'Resultado',
        align: 'left',
        minWidth: 10,
        visibility: false,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'userName',
        label: 'Usuário',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
];


export const columnsNotificationGroupItemGrid: ADataGridColumn[] = [
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: 'id',
            condition: 'equals',
            value: '',
            sort: 'none'
        }
    },
    {
        name: 'powerStationName',
        label: 'USINA',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'skidName',
        label: 'SKID',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'equipmentName',
        label: 'EQUIPAMENTO',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'teleObjectName',
        label: 'TELE OBJETO',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'authorityName',
        label: 'AUTORIDADE',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'alarmSeverityName',
        label: 'SEVERIDADE DE ALARME',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
];



export const columnsDataGridSendingTeleCommands: ADataGridColumn[] = [
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'powerStationName',
        label: 'USINA',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'skidName',
        label: 'SKID',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'equipmentName',
        label: 'EQUIPAMENTO',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'teleObjectName',
        label: 'COMANDO',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
];

export const columnsAuditLoginDataGrid  = [
    {
        name: 'timeStamp',
        label: 'Data e Hora',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "timeStamp",
            condition: "starts-at",
            value: '',
            sort: 'none',
            placeholder: "dd/MM/yyyy HH:mm:ss"
        }
    },
    {
        name: 'email',
        label: 'E-mail',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "email",
            condition: "equals",
            value: '',
            sort: 'none',
        }
    },
    {
        name: 'action',
        label: 'Ação',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "action",
            condition: "equals",
            value: '',
            sort: 'none',
        }
    },
    {
        name: 'status',
        label: 'Status',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "status",
            condition: "equals",
            value: '',
            sort: 'none',
        }
    },
    {
        name: 'message',
        label: 'Mensagem',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "message",
            condition: "equals",
            value: '',
            sort: 'none',
        }
    },
    {
        name: 'hostname',
        label: 'Origem',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "hostname",
            condition: "equals",
            value: '',
            sort: 'none',
        }
    }
] as ADataGridColumn[];


