import React, { useEffect, useState } from 'react';
import { Box, Typography, Slider } from '@mui/material';

interface SliderWidthAndHeightProps {
    handleHeightChange: (event:Event, value: number | number[]) => void;
    handleXsChange: (event:Event, value: number | number[]) => void;
    handleSmChange: (event:Event, value: number | number[]) => void;
    handleMdChange: (event:Event, value: number | number[]) => void;
    isXs: boolean;
    isSm: boolean;
    isMd: boolean;
    xsValue:number;
    smValue:number;
    mdValue:number;
    cardHeight:number;
}

const SliderWidthAndHeight: React.FC<SliderWidthAndHeightProps> = ({
    handleHeightChange,
    handleXsChange,
    handleSmChange,
    handleMdChange,
    isMd,
    isSm,
    isXs,
    xsValue,
    smValue,
    mdValue,
    cardHeight
}) => {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {isXs && (
                <>
                    <Typography id="xs-slider" gutterBottom>
                        Largura: {xsValue}
                    </Typography>
                    <Slider
                        aria-labelledby="xs-slider"
                        value={xsValue}
                        onChange={handleXsChange}
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={4}
                        max={12}
                    />
                </>
            )}

            {isSm && (
                <>
                    <Typography id="sm-slider" gutterBottom>
                        Largura: {smValue}
                    </Typography>
                    <Slider
                        aria-labelledby="sm-slider"
                        value={smValue}
                        onChange={handleSmChange}
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={4}
                        max={12}
                    />
                </>
            )}

            {isMd && (
                <>
                    <Typography id="md-slider" gutterBottom>
                        Largura: {mdValue}
                    </Typography>
                    <Slider
                        aria-labelledby="md-slider"
                        value={mdValue}
                        onChange={handleMdChange}
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={4}
                        max={12}
                    />
                </>
            )}
            <Typography id="height-slider" gutterBottom>
                Altura: {cardHeight}px
            </Typography>
            <Slider
                aria-labelledby="height-slider"
                value={cardHeight}
                onChange={handleHeightChange}
                valueLabelDisplay="auto"
                step={10}
                marks
                min={100}
                max={800}
            />
        </Box>
    );
};

export default SliderWidthAndHeight;