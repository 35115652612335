import * as React from 'react';
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileLines} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {SystemRoutes} from "../../../../Utils/RouteUtils";
import {useAppSelector} from "../../../../Config/Hooks";
import {hasPermission} from "../../../Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../../Config/Constants";

const style = {
    linkItem: {
        textDecoration: "none",
        color: "inherit"
    },
};

export default function SGDAuditMenu() {
    const theme = useTheme();
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToAlarms = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_ALARM
    ]);
    const isAuthorizedToPerformanceSummary = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_PERFORMANCE_SUMMARY
    ]);
    const isAuthorizedToPowerPlant = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_POWER_PLANT
    ]);
    const isAuthorizedToEquipment = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_EQUIPMENT
    ]);
    const isAuthorizedToEquipmentClass = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_EQUIPMENT_CLASS
    ]);
    const isAuthorizedToManagementReport = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_MANAGEMENT_REPORT
    ]);
    const isAuthorizedToSkid = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_SKID
    ]);
    const isAuthorizedToTeleObject = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_TELEOBJECTS
    ]);
    const isAuthorizedToAudit = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_AUDIT
    ]);

    return (
        <Box sx={{ width: '100%', maxWidth: 360,
            bgcolor: theme.palette.mode === 'light' ? 'background.paper' : '#1E1E1E',
            height: window.innerHeight - 90,
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            ml: '2%',
            mt: 1}}>
            <nav aria-label="main mailbox folders">
                <List>
                    <Link style={style.linkItem} to={SystemRoutes.AUDIT_LOGIN}>
                        <ListItem disablePadding>
                            <ListItemButton disabled={false}>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Login" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.AUDIT_COMMANDS}>
                        <ListItem disablePadding>
                            <ListItemButton disabled={false}>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Comandos" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link aria-disabled={true} style={style.linkItem} to={SystemRoutes.AUDIT_ALARMS}>
                        <ListItem disablePadding>
                            <ListItemButton disabled={false}>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Alarmes" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                </List>
            </nav>
        </Box>

    );
}