import * as React from "react";
import {AppBar, Box, CssBaseline, Drawer, IconButton, Toolbar} from "@mui/material";
import {MAIN_MENU_WIDTH, MAIN_SUB_MENU} from "../../../../Config/MenuUtil";
import {
    isAnalysisPage, isAuditPage, isCommunicationPage,
    isDashboardPage,
    isHelpPage,
    isIncludesAnalysisHomePage,
    isReportsPage,
    isSettingsPage
} from "../../../../Utils/RouteUtils";
import SGDDashboardMenu from "./SGDDashboardMenu";
import SGDAnalysisMenu from "./SGDAnalysisMenu";
import DrawerHeader from "./DrawerHeader";
import SGDSettingsMenu from "./SGDSettingsMenu";
import SGDReportsMenu from "./SGDReportsMenu";
import SGDHelpMenu from "./SGDHelpMenu";
import {useAppSelector} from "../../../../Config/Hooks";
import SGDAnalysisHomeMenu from "./AnalysisHomeMenu/SGDAnalysisHomeMenu";
import SGDCommunicationMenu from "./SGDCommunicationMenu";
import SGDAuditMenu from "./SGDAuditMenu";

export default function SGDSubMenu() {
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    if(isAuthenticated) return (
        <Drawer
            variant="persistent"
            sx={{
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: MAIN_SUB_MENU },
            }}
            open={openSubMenu}
        >
            <DrawerHeader />
            <Box style={{
                marginLeft: MAIN_MENU_WIDTH ,
                padding: 10,
                height: window.innerHeight,
                width: 306,
                overflowY: 'hidden',
                overflowX: 'hidden',
            }}
            >
                {isDashboardPage() ? <SGDDashboardMenu /> : null}
                {isIncludesAnalysisHomePage() ?<SGDAnalysisHomeMenu />: null}
                {isAnalysisPage() ? <SGDAnalysisMenu /> : null}
                {isCommunicationPage() ? <SGDCommunicationMenu /> : null}
                {isSettingsPage() ? <SGDSettingsMenu /> : null}
                {isReportsPage() ? <SGDReportsMenu /> : null}
                {isAuditPage() ? <SGDAuditMenu /> : null}
                {isHelpPage() ? <SGDHelpMenu /> : null}
            </Box>
        </Drawer>
    );

    return null;
}
