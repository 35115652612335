import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {
    Button, Checkbox,
    Container,
    FormControl,
    FormHelperText, Grid, ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip, useTheme
} from "@mui/material";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";

import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { PowerStationUserProps, RowPowerStationUser } from "./PowerStationUserHomePage";
import { HeaderMenu } from "../HeaderMenu";
import { useAppSelector } from "../../../Config/Hooks";
import { hasPermission } from "../../../Shared/Auth/AuthenticationUtil";
import { AUTHORITIES } from "../../../Config/Constants";
import { MenuProps } from "../../../Utils/PropsStyles";

import { 
    DeletePowerStationUserReactQuery,
    FindAllPowerStationsReactQuery,
    FindAllUserByPowerStationIdReactQuery,
    PowerStationUserFormReactQuery,
    SavePowerStationUserReactQuery
} from "./reactQuery/PowerStationUserReactQuery";

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

const columnsDataGrid: ADataGridColumn[] = [
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'powerStationName',
        label: 'Usina',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'email',
        label: 'Usuário',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    }
];

export interface UserProps {
    id: number;
    email: string;
    selected: boolean
}

export interface PowerStationProps {
    id?: number;
    name: string;
    selected: boolean
}


export const PowerStationUserForm = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const powerStationParams = location.state?.powerStationParams as any;    
    
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsDataGrid), 0, 100));
    const [selectedToDelete, setSelectedToDelete] = useState<number | null>(null);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [rows, setRows] = useState<Array<RowPowerStationUser>>([]);
    const [users, setUsers] = useState<Array<UserProps>>([]);
    const [powerStations, setPowerStations] = useState<Array<PowerStationProps>>([]);
    const mutationSearch = PowerStationUserFormReactQuery();
   
    const account = useAppSelector((state: any) => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_POWER_STATION_USER
    ]);
    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DELETE_POWER_STATION_USER
    ]);

    const mutationSave = SavePowerStationUserReactQuery();
    const mutation = DeletePowerStationUserReactQuery(pagedSearchParams);
    const findAllUsers = FindAllUserByPowerStationIdReactQuery();
    const [checkedUsers, setCheckedUsers] = useState<UserProps[]>([]);
    const [selectedUser, setSelectedUser] = useState<boolean>(false);

    const findAllPowerStations = FindAllPowerStationsReactQuery();
    const [checkedPowerStations, setCheckedPowerStations] = useState<PowerStationProps[]>([]);
    const [selectedPowerStation, setSelectedPowerStation] = useState<boolean>(false);

    const searchTable = (newPageParams = pagedSearchParams) => {     
        if(powerStationParams?.id || selectedPowerStation) {  
            mutationSearch.mutate(newPageParams, {
                onSuccess: (data:any) => {                    
                    setTotalOfRecords(data?.totalOfRecords);
                    if (data?.data) {
                        const rowsStationUserGroup:PowerStationUserProps[] = [];
        
                        let rowsStationUser = data?.data?.map((stationUser: PowerStationUserProps) => {
                            return {
                                id: stationUser.id,
                                powerStationName: stationUser.powerStation.name,
                                email: stationUser.user.email,
                                actionsConfig: [
                                    {actionName: "edit", disabled: !isAuthorizedToSave},
                                    {actionName: "delete", disabled: !isAuthorizedToDelete},
                                ]
                            }
                        });  
                        setRows(rowsStationUser);
                    }
                }
            });
        }
    }

    useEffect(() => {
        
        findAllPowerStations.mutate(0, {
            onSuccess: (data) => {   
                if(data?.data && data?.data.length > 0) {
                    setPowerStations(data?.data);
                    const initializedPowerStations: PowerStationProps[] = data?.data.map((user: {
                        id: number;
                        name: string;
                    }) => ({
                        id: user.id,
                        name: user.name,
                        selected: false
                    }));
                    setCheckedPowerStations(initializedPowerStations);
                }
            }
        })
        setRows([]);
        setTotalOfRecords(0);

        
    },[])

    const handleChangePowerStationCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {

        let updatedPowerStations = checkedPowerStations.map((powerStation, i) => ({
            ...powerStation,
            selected: i === index ? event.target.checked : false // Desmarcar todos os outros
        }));
        setSelectedPowerStation(updatedPowerStations.filter(element => element.selected).length > 0 ?? false);
        setCheckedPowerStations(updatedPowerStations);

        const powerStationId = updatedPowerStations.find((element:any) => element.selected)?.id?.toString() ?? "";
        let newPageParams = updatePageParams(powerStationId);
        setPagedSearchParams(
            newPageParams
        );
        searchTable(newPageParams);

    };

    const updatePageParams = (powerStationId: string) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(extractFiltersFromColumns(columnsDataGrid), 0, 100);
        newPageParams.filters.push({name: 'powerStationId', condition: 'equals', value: powerStationId, sort: 'none'},)
       return newPageParams;
    }

    useEffect(() => {
        
        if(powerStationParams?.id) {
            let newPageParams = updatePageParams(powerStationParams?.id);
            setPagedSearchParams(
                newPageParams
            );
            searchTable(newPageParams);
            findAllUsers.mutate(powerStationParams?.id ?? 0, {
                onSuccess: (data) => {
                    if(data?.data && data?.data.length > 0) {
                        setUsers(data.data);
                        const initializedUsers:UserProps[] = data?.data.map((user: { id: number; email: string; }) => ({
                            id: user.id,
                            email: user.email,
                            selected: false
                        }));
                        setCheckedUsers(initializedUsers);
                    }

                }
            })

            const initializedPowerStations: PowerStationProps[] = [{id: powerStationParams.id, name: powerStationParams.name, selected: true}];
            setCheckedPowerStations(initializedPowerStations);

        } else if(selectedPowerStation) {
            checkedPowerStations.filter(element => element.selected).find(element => {
                findAllUsers.mutate(element.id ?? 0, {
                    onSuccess: (data) => {
                        if(data?.data && data?.data.length > 0) {
                            setUsers(data.data);
                            const initializedUsers: UserProps[] = data?.data.map((user: {
                                id: number;
                                email: string;
                            }) => ({
                                id: user.id,
                                email: user.email,
                                selected: false
                            }));
                            setCheckedUsers(initializedUsers);
                        }
                    }
                })
            })
        }
    },[powerStationParams, selectedPowerStation])

    useEffect(() => {
        const powerStationId = checkedPowerStations.find((element:any) => element.selected)?.id?.toString() ?? "";
        if(powerStationId.length > 0) {
            let newPageParams = updatePageParams(powerStationId);
            setPagedSearchParams(
                newPageParams
            );
            searchTable(newPageParams);
        }

    }, [checkedPowerStations, checkedUsers])

    const handleChangeUsersCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {

        let updatedUsers = checkedUsers.map((user, i) => ({
            ...user,
            selected: i === index ? event.target.checked : false // Desmarcar todos os outros
        }));

        setSelectedUser(updatedUsers.filter(element => element.selected).length > 0 ?? false);
        setCheckedUsers(updatedUsers);
    };

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        if(powerStationParams?.id) {
            newPageParams.filters.push({name: 'powerStationId', condition: 'equals', value: powerStationParams?.id, sort: 'none'},)
        } else {
            checkedPowerStations.filter(element => element.selected).find((element:any) => {
                if(element?.id) {
                    newPageParams.filters.push({name: 'powerStationId', condition: 'equals', value: element.id, sort: 'none'},)
                }
            })   
        }
        setPagedSearchParams(
            newPageParams
        );
        searchTable(newPageParams)
    }, []);

    const handleSavePowerStationUser = () => {        
        const userSave = checkedUsers.find(element => element.selected);
        const powerStationSave = powerStationParams?.id ?? checkedPowerStations.find(element => element.selected)?.id;
       
        if(powerStationSave && userSave) {
            mutationSave.mutate({
                id: null,
                powerStation: {id: powerStationSave},
                user: userSave,
            },{
                onSuccess: (data) => {
                    if(data?.data) {
                        const powerStationId = data.data.powerStation.id
                        let newPageParams = updatePageParams(powerStationId);
                        setPagedSearchParams(
                            newPageParams
                        );
                        searchTable(newPageParams);
                    }
                }
            })
            setRows([]);
            const newPageParams = updatePageParams(powerStationSave)
            setPagedSearchParams(
                newPageParams
            );
            searchTable(newPageParams);
            findAllUsers.mutate(powerStationSave, {
                onSuccess: (data) => {
                    if(data?.data && data?.data.length > 0) {
                        setUsers(data.data);
                        const initializedUsers: UserProps[] = data?.data.map((user: {
                            id: number;
                            email: string;
                        }) => ({
                            id: user.id,
                            email: user.email,
                            selected: false
                        }));
                    }
                }
            })

        }        
    }    

    useEffect(() => {


    }, [])

    const handleDeletePowerStationUser = useCallback((data: any) => {
        setSelectedToDelete(data.id);
        setOpenDialog(true);
    }, []);

    const handleDialogConfirm = async () => {
        mutation.mutate(selectedToDelete, {
            onSuccess: () => {
                if(selectedToDelete) {
                    const powerStationId = checkedPowerStations.find(element => element.selected)?.id?.toString() ?? "";
                    let newPageParams = updatePageParams(powerStationId)
                    setPagedSearchParams(
                        newPageParams
                    );
                    searchTable(newPageParams);
                }
            }
        });
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    const handleSave = () => {
        navigate(SystemRoutes.SETTINGS_POWER_STATION_USER);
    }

    return (
        <>
            <HeaderMenu systemRoutes={SystemRoutes.SETTINGS_POWER_STATION_USER} isOnSave={true} disableSave={!isAuthorizedToSave} handleSave={handleSave}/>
            <Box>
                <ACard>
                    <Container>
                        <Grid container spacing={2}>
                            { powerStationParams || checkedPowerStations.find(item => item.selected)?.selected ? 
                            <Grid item xs={3}>
                            <InputLabel id="object-name">Usina</InputLabel>
                            <TextField
                                error={powerStationParams?.name === ""}
                                fullWidth
                                id="object-name"
                                variant="standard"
                                placeholder={"Nome da Usina"}
                                value={powerStationParams?.name ?? checkedPowerStations.find(item => item.selected)?.name}
                                style={{ width: '100%' }}
                            />
                            {powerStationParams?.name === "" && (
                                <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                            )}
                        </Grid>
                        :
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="select-users-label">Usinas</InputLabel>
                                <Select
                                    labelId="select-users-label"
                                    id="select-users"
                                    multiple
                                    value={checkedPowerStations.filter(item => item.selected).map(item => item.name)}
                                    input={<OutlinedInput label="Usinas" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    variant="standard"
                                    disableUnderline
                                    sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                    MenuProps={MenuProps}
                                >
                                    {powerStations.map((powerStation: any) => (
                                        <MenuItem
                                            key={powerStation.id}
                                            value={powerStation.name || ''}
                                        >
                                            <Checkbox
                                                checked={checkedPowerStations.find(item => item.name === powerStation.name)?.selected || false}
                                                onChange={(e) => handleChangePowerStationCheckbox(e, checkedPowerStations.findIndex(item => item.name === powerStation.name))}
                                            />
                                            <ListItemText primary={powerStation.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        }
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-users-label">Usuário</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="select-users-label"
                                        id="select-users"
                                        multiple
                                        value={checkedUsers.filter(item => item.selected).map(item => item.email)}
                                        input={<OutlinedInput label="Usinas" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        variant="standard"
                                        disableUnderline
                                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                        MenuProps={MenuProps}
                                    >
                                        {users.map((user: any) => (
                                            <MenuItem
                                                key={user.id}
                                                value={user.email || ''}
                                            >
                                                <Checkbox
                                                    checked={checkedUsers.find(item => item.email === user.email)?.selected || false}
                                                    onChange={(e) => handleChangeUsersCheckbox(e, checkedUsers.findIndex(item => item.id === user.id))}
                                                />
                                                <ListItemText primary={user.email} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} style={{display: "flex", justifyContent:"flex-end",  alignItems:"flex-end"}}>
                                <Button
                                    variant="contained"
                                    size={'small'}
                                    disabled={!selectedUser}
                                    endIcon={<AddCircleIcon/>}
                                    onClick={handleSavePowerStationUser}
                                >
                                    Adicionar
                                </Button>
                            </Grid>
                            
                        </Grid>
                    </Container>
                </ACard>
            </Box>

            <Box paddingTop={2}>
                <ACard>
                    <ADataGrid
                        columns={columnsDataGrid}
                        rows={rows}
                        page={pagedSearchParams.page}
                        rowsPerPage={pagedSearchParams.size}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        hideSelection={true}
                        onFiltersChange={handleOnFiltersChange}
                        actions={
                            <>
                                <span></span>
                                <IconButton name="delete" size={"small"} aria-label="Delete" onClick={(value) => handleDeletePowerStationUser(value)}>
                                    <Tooltip title="Excluir">
                                        <Delete />
                                    </Tooltip>
                                </IconButton>
                            </>
                        }
                    />
                </ACard>
                <ConfirmDialog
                    title={'Deletar'}
                    description={'Deseja remover esse acesso?'}
                    open={openDialog}
                    handleConfirm={handleDialogConfirm}
                    handleClose={handleDialogClose}
                />
            </Box>
        </>
    )
}

export default function PowerStationUserPageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <PowerStationUserForm />
        </QueryClientProvider>
    )
}