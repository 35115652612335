import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../Store';
import axios from 'axios';
import qs from "qs";
import {Storage} from "../../Utils/StorageUtils";
import {AUTHENTICATION_URL, CLIENT_SECRET} from "../Constants";
import { loadUserConfiguration } from './ConfigurationReducer';

const AUTH_TOKEN_KEY = 'api-token-key';
const USER_MAIL = 'user-mail';

export class UserEntity {
    username: string | undefined;
    password: string | undefined;
    rememberMe: boolean | undefined;
}

export class Authority {
    id: number | null | undefined;
    name: string | null | undefined;
    displayName: string | null | undefined;
}

export class AccountEntity {
    id: number | null | undefined;
    name: string | null | undefined;
    userName: string | null | undefined;
    authoritySet: Array<Authority> | null | undefined;
    email: string | null;

    constructor() {
        this.id = null;
        this.name = null;
        this.authoritySet = null;
        this.email = null;
    }
}

export interface DefaultAuthState {
    loading: boolean;
    isAuthenticated: boolean;
    loginSuccess: boolean;
    loginError: boolean;
    showModalLogin: boolean;
    account: AccountEntity | undefined;
    errorMessage: string | undefined;
    redirectMessage: string | undefined;
    sessionHasBeenFetched: boolean;
    idToken: string | undefined;
    logoutUrl: string | undefined;
    customer: []
}

const initialState: DefaultAuthState = {
    loading: false,
    isAuthenticated: false,
    loginSuccess: false,
    loginError: false,
    showModalLogin: false,
    account: undefined,
    errorMessage: undefined, // Errors returned from server side
    redirectMessage: undefined,
    sessionHasBeenFetched: false,
    idToken: undefined,
    logoutUrl: undefined,
    customer: []
};

// Actions

export const getSession = () => (dispatch: any) => {
    dispatch(getAccount());
};

export const getAccount = createAsyncThunk(
    'authentication/get_account',
    async () => axios.get('api/account'),
);
export const getCustomer = createAsyncThunk(
    'authentication/get_customer',
    async () => axios.get('api/customer/all'),
)

export const authenticate = createAsyncThunk(
    'authentication/authenticate',
    async (auth: UserEntity) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                'client_id': 'sgd-client',
                'client_secret': CLIENT_SECRET,
                'grant_type': 'password',
                'username': auth.username,
                'password': auth.password
            }),
            url: AUTHENTICATION_URL
        };
        return axios(options);
    },
);

export const login = (user: UserEntity, sendMessage: (message: any) => void, customer: any, hostname: string) => {
    return async function (dispatch: any) {
        const result = await dispatch(authenticate(user));
        const response = result.payload;
        const bearerToken = response?.data['access_token'];
        if (bearerToken) {
            const jwt = bearerToken;
            if (user.rememberMe) {
                Storage.local.set(AUTH_TOKEN_KEY, jwt);
                Storage.session.set(AUTH_TOKEN_KEY, jwt);

                Storage.local.set(USER_MAIL, user.username);
                Storage.session.set(USER_MAIL, user.username);
            } else {
                Storage.local.set(AUTH_TOKEN_KEY, jwt);
                Storage.session.set(AUTH_TOKEN_KEY, jwt);
                Storage.local.set(USER_MAIL, user.username);
                Storage.session.set(USER_MAIL, user.username);
            }
        }
        if (response?.status === 200) {
            await dispatch(loadUserConfiguration());
            dispatch(getSession());
        }
    };
};

export const clearAuthToken = () => {
    if (Storage.local.get(AUTH_TOKEN_KEY)) {
        Storage.local.remove(AUTH_TOKEN_KEY);
    }
    if (Storage.session.get(AUTH_TOKEN_KEY)) {
        Storage.session.remove(AUTH_TOKEN_KEY);
    }
};

export const logout = () => (dispatch: any) => {
    clearAuthToken();
    dispatch(logoutSession());
};

export const clearAuthentication = (messageKey: any) => (dispatch: any) => {
    // clearAuthToken();
    dispatch(authError(messageKey));
    dispatch(clearAuth());
};

// Slices
export const authenticationReducer = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        logoutSession() {
            return {
                ...initialState,
                showModalLogin: true,
            };
        },
        authError(state, action) {
            return {
                ...state,
                showModalLogin: true,
                redirectMessage: action.payload,
            };
        },
        clearAuth(state) {
            return {
                ...state,
                loading: false,
                showModalLogin: true,
                isAuthenticated: false,
            };
        },
    },
    extraReducers(builder) {
        builder

            .addCase(authenticate.fulfilled, (state, action) => ({
                ...state,
                loading: false,
                isAuthenticated: true,
                showModalLogin: false,
                loginSuccess: true,
            }))
            .addCase(getAccount.fulfilled, (state, action: PayloadAction<any>) => {
                const isAuthenticated = !!(action.payload && action.payload.data);
                return {
                    ...state,
                    isAuthenticated,
                    loading: false,
                    sessionHasBeenFetched: true,
                    account: action.payload.data.data,
                };
            })
            .addCase(getCustomer.fulfilled, (state, action: PayloadAction<any>) => {
                const isAuthenticated = !!(action?.payload.data)
                return {
                    ...state,
                    isAuthenticated,
                    loading: false,
                    account: action.payload.data.data,
                    customers: action.payload.data.data,
                };
            })
            .addCase(authenticate.pending, state => {
                state.loading = true;
                state.isAuthenticated = false;
            })
            .addCase(getAccount.pending, state => {
                state.loading = true;
                state.isAuthenticated = false;
            })
            .addCase(authenticate.rejected, (state, action) => ({
                ...initialState,
                isAuthenticated: false,
                errorMessage: action.error.message,
                showModalLogin: true,
                loginError: true,
            }))
            .addCase(getAccount.rejected, (state, action) => ({
                ...state,
                loading: false,
                loginError: true,
                errorMessage: action.error.message,
                isAuthenticated: false,
                sessionHasBeenFetched: true,
                showModalLogin: true,
            }));
    },
});

export const {logoutSession, clearAuth, authError} = authenticationReducer.actions;
export const selectAutentication = (state: RootState) => state.authentication;

// Reducer
export default authenticationReducer.reducer;
