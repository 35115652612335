import {styled} from "@mui/material";


const isSmallScreen = window.innerWidth <= 422;
const isMediumScreen = window.innerWidth <= 495;
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 0),
    marginTop: isSmallScreen ? '41px' : isMediumScreen ? '22px' : '0px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default DrawerHeader;
