import {AppBar, Button, Tooltip, useTheme} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {Link, useNavigate} from "react-router-dom";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {AddCircle, Delete, Edit} from "@mui/icons-material";
import React, {useCallback, useEffect, useState} from "react";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {DeleteAllPowerStationUserReactQuery, PowerStationUserReactQuery} from "./reactQuery/PowerStationUserReactQuery";
import IconButton from "@mui/material/IconButton";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import Box from "@mui/material/Box";
import {QueryClient, QueryClientProvider} from "react-query";
import { HeaderMenu } from "../HeaderMenu";
import { AUTHORITIES } from "../../../Config/Constants";
import { hasPermission } from "../../../Shared/Auth/AuthenticationUtil";
import { useAppSelector } from "../../../Config/Hooks";

const columnsDataGrid: ADataGridColumn[] = [
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'name',
        label: 'Usina',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'adminName',
        label: 'Admin',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    }
];

export type PowerStationUserProps = {
    id?: number;
    powerStation: {
        id: number;
        name: string;
    };
    user: {
        id: number;
        email: string;
    };
};

export type PowerStationProps = {
    id?: number;
    name: string;
    adminName: string;
};

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowPowerStationUser {
    id?: number;
    powerStationId: number;
    powerStationName: string;
    actionsConfig?: Array<ActionConfig>
}
export const PowerStationUserHome = () => {
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsDataGrid), 0, 100));
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [rows, setRows] = useState<Array<RowPowerStationUser>>([]);
    const {data, isSuccess} = PowerStationUserReactQuery(pagedSearchParams);

    const theme = useTheme();

    const navigate = useNavigate();

    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_POWER_STATION_USER
    ]);

    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DELETE_POWER_STATION_USER
    ]);

    const mutation = DeleteAllPowerStationUserReactQuery(pagedSearchParams);

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setTotalOfRecords(data?.totalOfRecords);
            if (data?.data) {

                let rowsStation = data?.data?.map((station: PowerStationProps) => {
                    return {
                        id: station.id,
                        name: station.name,
                        adminName: station.adminName,
                        actionsConfig: [
                            {actionName: "edit", disabled: !isAuthorizedToSave},
                            {actionName: "delete", disabled: !isAuthorizedToDelete},
                        ]
                    }
                });
                
                setRows(rowsStation);
            }
        }
    }, [data?.data]);

    const handleEditPowerStationUser = useCallback((data: any) => {        
        navigate(SystemRoutes.SETTINGS_POWER_STATION_USER_FORM, {state: {powerStationParams: data}});
    }, [navigate]);

    const handleDeletePowerStationUser = useCallback((data: any) => {
        setSelectedToDelete(data.id); // powerStationId
        setOpenDialog(true);
    }, []);

    const handleDialogConfirm = async () => {
        mutation.mutate(selectedToDelete);
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    useEffect(() => {
        if (mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(columnsDataGrid), 0, 50));
        }
    }, [mutation.isSuccess]);

    return (
        <>
            <HeaderMenu systemRoutes={SystemRoutes.SETTINGS_POWER_STATION_USER_FORM} disableSave={!isAuthorizedToSave}/>

            <Box>
                <ACard>
                    <ADataGrid
                        columns={columnsDataGrid}
                        rows={rows}
                        page={pagedSearchParams.page}
                        rowsPerPage={pagedSearchParams.size}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        hideSelection={true}
                        onFiltersChange={handleOnFiltersChange}
                        actions={
                            <>
                                <IconButton name="edit" size={"small"} aria-label="Edit"
                                            onClick={(value) => handleEditPowerStationUser(value)}>
                                    <Tooltip title="Editar">
                                        <Edit/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton name="delete" size={"small"} aria-label="Delete"
                                            onClick={(value) => handleDeletePowerStationUser(value)}>
                                    <Tooltip title="Excluir">
                                        <Delete/>
                                    </Tooltip>
                                </IconButton>
                            </>
                        }
                    />
                </ACard>
                <ConfirmDialog
                    title={'Deletar'}
                    description={'Ao remover esse acesso, todos os dados relacionados a ele também serão removidos. Tem certeza que deseja remover?'}
                    open={openDialog}
                    handleConfirm={handleDialogConfirm}
                    handleClose={handleDialogClose}
                />
            </Box>

        </>
    )

}

export default function PowerStationUserHomePage () {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <PowerStationUserHome/>
        </QueryClientProvider>
    )
}