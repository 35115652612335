import {DashboardNavigationAnalysisMenuState} from "./DashboardNavigationAnalysisMenuState";
import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from "@reduxjs/toolkit";
import axios from "axios";
import {Response} from "../../../../Config/Types";
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {DashboardNavigationAnalysis} from "../DashboardNavigationAnalysis";
import {RootState} from "../../../../Config/Store";

const initialState: DashboardNavigationAnalysisMenuState = {
    isLoading: true,
    errorMessage: null,
    selectedIdsNavigationAnalysisMenu: {
        selectedGenPowerStationIdList: [],
        selectedGenSkidIdList: [],
        selectedGenEquipmentIdList: [],
        selectedToEquipmentIdList: [],
    },
    entities: [],
};


const apiUrl = 'api/analysis/power-stations';

export const getEntitiesNavigationMenuAnalysis = createAsyncThunk<any, { analysisId: string | undefined }>(
    'power-station/navigation-menu-analysis/entity_list',
    async ({analysisId}) => {
        if(analysisId){
            return axios.get<Response<DashboardNavigationAnalysis>>(apiUrl + `?analysisId=${analysisId}`);
        }else {
            return axios.get<Response<DashboardNavigationAnalysis>>(apiUrl);
        }
    },
    {serializeError: serializeAxiosError},
);

const DashboardNavigationAnalysisReducer = createSlice({
    name: 'dashboardNavigationMenuReducer',
    initialState,
    reducers: {
        updateSelectedStates: (state, action) => {
            state.selectedIdsNavigationAnalysisMenu = action.payload;
        },

        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isFulfilled(getEntitiesNavigationMenuAnalysis), (state, action) => {
                const entities = action.payload.data.data.map((item: DashboardNavigationAnalysis) => {
                    // Mapper equipment list to each skid
                    const skidListWithEquipments = item.skidList.map(skid => {
                        return {
                            ...skid,
                            equipmentList: skid.equipmentList.map(equipment => ({
                                ...equipment,
                            })),
                        };
                    });
                    return {
                        ...item,
                        skidList: skidListWithEquipments,
                        equipmentList: item.equipmentList.map(equipment => ({
                            ...equipment,
                        })),
                    };
                });

                return {
                    ...state,
                    isLoading: false,
                    entities: entities,
                };
            })
            .addMatcher(isPending(getEntitiesNavigationMenuAnalysis), state => {
                return {
                    ...state,
                    isLoading: true,
                };
            })
            .addMatcher(isRejected(getEntitiesNavigationMenuAnalysis), state => {
                return {
                    ...state,
                    isLoading: false,
                    entities: [],
                };
            });
    },
});

export const {reset, updateSelectedStates} = DashboardNavigationAnalysisReducer.actions;

export const selectSGDDashboardNavigationMenuAnalysis = (state: RootState) => state.dashboardNavigationMenuAnalysis;
export default DashboardNavigationAnalysisReducer.reducer;
