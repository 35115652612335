export enum SystemRoutes {
    DASHBOARD = "/dashboard",
    DASHBOARD_OVERVIEW = "/dashboard/overview",
    DASHBOARD_OVERVIEW_LAYOUT = "/dashboard/layout",
    DASHBOARD_OVERVIEW_CHARTS = "/dashboard/charts",
    DASHBOARD_OVERVIEW_MAP = "/dashboard/map",
    DASHBOARD_OVERVIEW_ALARMS = "/dashboard/alarms",
    DASHBOARD_OVERVIEW_SYNOPTIC = "/dashboard/synoptic",
    DASHBOARD_OVERVIEW_ACTIVE_POWER = "/dashboard/active-power",

    DASHBOARD_POWER_PLANT = "/dashboard/power-plant",
    DASHBOARD_POWER_PLANT_OVERVIEW = "/dashboard/power-plant/overview",
    DASHBOARD_POWER_PLANT_LAYOUT = "/dashboard/power-plant/plant",
    DASHBOARD_POWER_PLANT_CHARTS = "/dashboard/power-plant/charts",
    DASHBOARD_POWER_PLANT_ALARMS = "/dashboard/power-plant/alarms",
    DASHBOARD_POWER_PLANT_EQUIPMENTS = "/dashboard/power-plant/equipments",
    DASHBOARD_POWER_PLANT_TRACKERS = "/dashboard/power-plant/trackers",
    DASHBOARD_POWER_PLANT_INVERTER = "/dashboard/power-plant/inverter",

    DASHBOARD_POWER_PLANT_SKID = "/dashboard/power-plant/skid",
    DASHBOARD_POWER_PLANT_SKID_OVERVIEW = "/dashboard/power-plant/skid/overview",
    DASHBOARD_POWER_PLANT_SKID_LAYOUT = "/dashboard/power-plant/skid/plant",
    DASHBOARD_POWER_PLANT_SKID_CHARTS = "/dashboard/power-plant/skid/charts",
    DASHBOARD_POWER_PLANT_SKID_ALARMS = "/dashboard/power-plant/skid/alarms",
    DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS = "/dashboard/power-plant/skid/equipments",
    DASHBOARD_POWER_PLANT_EQUIPMENTS_DETAILS = "/dashboard/power-plant/equipments/details",
    DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_DETAILS = "/dashboard/power-plant/skid/equipments/details",
    DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_COMPARE = "/dashboard/power-plant/skid/equipments/compare",
    DASHBOARD_POWER_PLANT_EQUIPMENTS_COMPARE = "/dashboard/power-plant/equipments/compare",

    ANALYSIS = "/analysis/home",
    ANALYSIS_CONFIG = "/analysis/config",

    COMMUNICATION = "/communication",
    COMMUNICATION_SENT_TELECOMMAND = "/communication/sent-telecommand",

    SETTINGS = "/settings",
    SETTINGS_COUNTRY = "/settings/country",
    SETTINGS_MACRO_REGION = "/settings/macro-region",
    SETTINGS_REGION = "/settings/region",
    SETTINGS_REGION_FORM = "/settings/region/form",
    SETTINGS_EQUIPMENT = "/settings/equipment",
    SETTINGS_EQUIPMENT_FORM = "/settings/equipment/form",
    SETTINGS_EQUIPMENT_TYPE = "/settings/equipment-type",
    SETTINGS_EQUIPMENT_TYPE_FORM = "/settings/equipment-type/form",
    SETTINGS_GRAFIC_ELEMENT = "/settings/grafic-element",
    SETTINGS_POWER_STATION_EXCEL = "/settings/power-station-excel",
    SETTINGS_GRAFIC_ELEMENT_FORM = "/settings/grafic-element/form",
    SETTINGS_EQUIPMENT_CLASS = "/settings/equipment-class",
    SETTINGS_EQUIPMENT_CLASS_FORM = "/settings/equipment-class/form",
    SETTINGS_TELE_OBJECT = "/settings/tele-object",
    SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE = "/settings/tele-object-specification-type",
    SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE_FORM = "/settings/tele-object-specification-type/form",
    SETTINGS_TELE_OBJECT_TYPE = "/settings/tele-object-type",
    SETTINGS_TELE_OBJECT_TYPE_FORM = "/settings/tele-object-type/form",
    SETTINGS_MOD_BUS_OBJECTS_TYPES = "/settings/mod-bus-objects-types",
    SETTINGS_MOD_BUS_OBJECTS_TYPES_FORM = "/settings/mod-bus-objects-types/form",
    SETTINGS_MOD_BUS_DATA_TYPES = "/settings/mod-bus-data-types",
    SETTINGS_MOD_BUS_DATA_TYPES_FORM = "/settings/mod-bus-data-types/form",
    SETTINGS_ALARM_SEVERITY = "/settings/alarm-severity",
    SETTINGS_ALARM_SEVERITY_FORM = "/settings/alarm-severity/form",
    SETTINGS_SKID = "/settings/skid",
    SETTINGS_NOTIFICATION_GROUP = "/settings/notification-group",
    SETTINGS_NOTIFICATION_GROUP_FORM = "/settings/notification-group/form",
    SETTINGS_POWER_PLANT = "/settings/power-plant",
    SETTINGS_POWER_PLANT_FORM = "/settings/power-plant/form",
    SETTINGS_PROFILE = "/settings/profiles",
    SETTINGS_PROFILE_FORM = "/settings/profiles/form",
    SETTINGS_USERS = "/settings/users",
    SETTINGS_USERS_FORM = "/settings/users/form",
    SETTINGS_AUDIT = "/settings/audit",
    SETTINGS_GRAPH_ELEMENT = "/settings/graph-element",
    SETTINGS_GRAPH_ELEMENT_FORM = "/settings/graph-element/form",
    SETTINGS_BROKER = "/settings/broker",
    SETTINGS_BROKER_FORM = "/settings/broker/form",
    SETTINGS_POWER_STATION_USER = "/settings/power-station-user",
    SETTINGS_POWER_STATION_USER_FORM = "/settings/power-station-user/form",

    REPORTS = "/reports",
    REPORTS_ALARMS = "/reports/alarms",
    REPORTS_PERFORMANCE_SUMMARY = "/reports/performance-summary",
    REPORTS_POWER_PLANTS = "/reports/power-plants",
    REPORTS_EQUIPMENT = "/reports/equipments",
    REPORTS_EQUIPMENT_CLASS = "/reports/equipmen-classes",
    REPORTS_SKID = "/reports/skids",
    REPORTS_TELEOBJECTS = "/reports/teleobjects",
    REPORTS_AUDIT = "/reports/audit",
    REPORTS_MANAGEMENT_REPORT = "/reports/managementReport",

    AUDIT = "/audit",
    AUDIT_LOGIN = "/audit/login",
    AUDIT_COMMANDS = "/audit/commands",
    AUDIT_ALARMS = "/audit/alarms",

    HELP = "/help",
    LOGOUT = "/logout",


}

// DASHBOARD PAGES UTILS
export const isDashboardPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.DASHBOARD);
};

export const isDashboardOverviewPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_OVERVIEW);
};

export const isDashboardOverviewMapPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_OVERVIEW_MAP);
};

export const isDashboardOverviewLayoutPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_OVERVIEW_LAYOUT);
};

export const isDashboardOverviewAlarmsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_OVERVIEW_ALARMS);
};

export const isDashboardOverviewChartsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_OVERVIEW_CHARTS);
};

export const isDashboardOverviewSynopticPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_OVERVIEW_SYNOPTIC);
};

export const isDashboardOverviewActivePowerPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_OVERVIEW_ACTIVE_POWER);
};

export const isDashboardPowerPlantPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.DASHBOARD_POWER_PLANT);
};

export const isDashboardPowerPlantOverviewPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_OVERVIEW);
};

export const isDashboardPowerPlantLayoutPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_LAYOUT);
};

export const isDashboardPowerPlantAlarmsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_ALARMS);
};

export const isDashboardPowerPlantChartsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_CHARTS);
};

export const isDashboardPowerPlantSKidPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.DASHBOARD_POWER_PLANT_SKID);
};

export const isDashboardPowerPlantSkidOverviewPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_SKID_OVERVIEW);
};

export const isDashboardPowerPlantSkidLayoutPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_SKID_LAYOUT);
};

export const isDashboardPowerPlantSkidAlarmsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_SKID_ALARMS);
};

export const isDashboardPowerPlantSkidChartsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_SKID_CHARTS);
};

export const isDashboardPowerPlantSkidEquipmentPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS);
};

export const isDashboardPowerPlantTrackersPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_TRACKERS);
};

export const isDashboardPowerPlantInvertersPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_INVERTER);
};

export const includesDashboardPowerPlantEquipmentPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS);
};

export const includesDashboardPowerPlantSkidEquipmentPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS);
};

export const isDashboardPowerPlantSkidEquipmentDetailsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_DETAILS);
};

export const isDashboardPowerPlantSkidEquipmentComparePage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_COMPARE);
};

export const isDashboardPowerPlantEquipmentDetailsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS_DETAILS);
};

export const isDashboardPowerPlantEquipmentComparePage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS_COMPARE);
};

// ANALYSIS PAGE UTILS
export const isIncludesAnalysisHomePage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.ANALYSIS);
};

export const isAnalysisPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.ANALYSIS_CONFIG);
};


export const isCommunicationPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.COMMUNICATION);
};
export const isCommunicationSentTelecommandPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.COMMUNICATION_SENT_TELECOMMAND)
};

// SETTINGS PAGE UTILS
export const isSettingsPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.SETTINGS);
};

export const isSettingsOverviewPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS);
};

export const isSettingsCountryPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_COUNTRY);
};

export const includesSettingsCountryPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.SETTINGS_COUNTRY);
};

export const isSettingsMacroRegionPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_MACRO_REGION);
};

export const isSettingsRegionPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_REGION);
};

export const isSettingsRegionFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_REGION_FORM);
};

export const isSettingsEquipmentTypePage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_EQUIPMENT_TYPE);
};

export const isSettingsEquipmentTypeFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_EQUIPMENT_TYPE_FORM);
};

export const isSettingsGraficElementPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_GRAFIC_ELEMENT);
}

export const isSettingsGraficElementFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_GRAFIC_ELEMENT_FORM);
}

export const isSettingsPowerStationExcelPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_POWER_STATION_EXCEL);
}


export const isSettingsEquipmentClassPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_EQUIPMENT_CLASS);
};

export const isSettingsEquipmentClassFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_EQUIPMENT_CLASS_FORM);
}

export const isSettingsEquipmentPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_EQUIPMENT);
};

export const isSettingsEquipmentFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_EQUIPMENT_FORM);
};

export const isSettingsSkidPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_SKID);
};

export const isSettingsNotificationGroupPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_NOTIFICATION_GROUP);
}
export const isSettingsNotificationGroupFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_NOTIFICATION_GROUP_FORM);
}


export const isSettingsPowerPlantPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_POWER_PLANT);
};

export const isSettingsPowerPlantFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_POWER_PLANT_FORM);
};

export const isSettingsProfilesPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_PROFILE);
};

export const includesSettingsProfilesPage = (): boolean => {
    return window.location.pathname.includes(SystemRoutes.SETTINGS_PROFILE);
};

export const isSettingsUsersPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_USERS);
};

export const isSettingsUsersFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_USERS_FORM);
};

export const isSettingsAuditPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_AUDIT);
};

// REPORT PAGE UTILS
export const isReportsPage = (): boolean => {
    return window.location.pathname.includes("reports");
};

export const isReportsAlarmsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.REPORTS_ALARMS);
};

export const isReportsPerformanceSummaryPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.REPORTS_PERFORMANCE_SUMMARY);
};

export const isReportsPowerPlantPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.REPORTS_POWER_PLANTS);
};

export const isReportsEquipmentClassPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.REPORTS_EQUIPMENT_CLASS);
};

export const isReportsEquipmentPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.REPORTS_EQUIPMENT);
};

export const isReportsSkidPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.REPORTS_SKID);
};

export const isReportsTeleobjectPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.REPORTS_TELEOBJECTS);
};
export const isReportsManagementReport = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.REPORTS_MANAGEMENT_REPORT);
};

export const isReportsAuditPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.REPORTS_AUDIT);
};

export const isHelpPage = (): boolean => {
    return window.location.pathname.endsWith("help");
};

export const isGraphElementPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_GRAPH_ELEMENT);
};

export const isGraphElementFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_GRAPH_ELEMENT_FORM);
};

export const isSettingsSeverityAlarmPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_ALARM_SEVERITY);
}

export const isSettingsSeverityAlarmFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_ALARM_SEVERITY_FORM);
}

export const isSettingsBrokerPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_BROKER);
}

export const isSettingsBrokerFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_BROKER_FORM);
}

export const isSettingsPowerStationUserPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_POWER_STATION_USER);
}

export const isSettingsPowerStationUserFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_POWER_STATION_USER_FORM);
}

export const isSettingsTeleObjectPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_TELE_OBJECT);
}

export const isSettingsTeleObjectSpecificationTypePage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE);
}

export const isSettingsTeleObjectSpecificationTypeFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE_FORM);
}

export const isSettingsTeleObjectTypePage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_TELE_OBJECT_TYPE);
}

export const isSettingsTeleObjectTypeFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_TELE_OBJECT_TYPE_FORM);
}

export const isSettingsModBusDataTypePage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES);
}

export const isSettingsModBusDataTypeFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES_FORM);
}

export const isSettingsModBusObjectTypePage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_MOD_BUS_OBJECTS_TYPES);
}

export const isSettingsModBusObjectTypeFormPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.SETTINGS_MOD_BUS_OBJECTS_TYPES_FORM);
}
//AUDIT PAGE UTILS
export const isAuditPage = (): boolean => {
    return window.location.pathname.includes("audit");
};

export const isAuditLoginPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.AUDIT_LOGIN);
}

export const isAuditCommandsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.AUDIT_COMMANDS);
}

export const isAuditAlarmsPage = (): boolean => {
    return window.location.pathname.endsWith(SystemRoutes.AUDIT_ALARMS);
}
