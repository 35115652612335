import {Box, Grid, Paper, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {RootState} from "../../../Config/Store";
import React, { useState } from "react";

import TotalizerAlarm from "./TotalizerAlarm/TotalizerAlarm";
import AlarmPage from "./Alarms/AlarmPage";
import { TotalizerType } from "../Overview/DashboardOverviewAlarmsPage";

export default function DashboardPowerPlantAlarmsPage(){
    const {selectedPowerPlant} = useAppSelector((state: RootState) => state.dashboard);
    const [filter, setFilter] = useState<TotalizerType>(localStorage.getItem("totalizer_by_power_station") as TotalizerType ?? "NONE");

    const onClickTotalizer = (totalizer:TotalizerType) => {
        console.log(totalizer);
        // Saved in localstorage
        localStorage.setItem("totalizer_by_power_station", totalizer);
        setFilter(totalizer)
    }
    return(
        <>
            {selectedPowerPlant &&
            <TotalizerAlarm powerStationId={selectedPowerPlant.id} onClickTotalizer={onClickTotalizer}/>}
            <Paper elevation={3}>
                <Box p={2}>
                    {selectedPowerPlant ? <AlarmPage powerPlantId={selectedPowerPlant.id} filterByTotalizer={filter}/>: null}
                </Box>
            </Paper>
        </>

    );
}
