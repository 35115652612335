import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {ACard, FloatMenuButton, FloatMenuItem} from "@atiautomacao/ati-ui-library";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {RootState} from "../../../../Config/Store";
import {getEntity} from "./MapPowerStationReducer";
import GoogleMapReact, {Maps} from 'google-map-react';
import {LocationOn} from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpand, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import useInterval from "../../../../Shared/Hooks/useInterval";
import Container from "@mui/material/Container";

interface PowerStationData {
    powerStationId: number;
    powerStationName: string;
    latitude: number;
    longitude: number;
    etsStatus: string;
}
interface MapMarkerProps {
    lat: number;
    lng: number;
}

const MapMarker: React.FC<MapMarkerProps> = ({ lat, lng }) => (
    <div
        style={{ fontSize: '40px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'red' }}
    >
        <LocationOn fontSize={"large"}/>
    </div>
);
const MapPowertation: FunctionComponent<{powerStationId: number}> = ({ powerStationId }) => {
    const informationsOfPowerStation = useAppSelector((state: RootState) => state.powerStationMap.infoPowerStation);
    // const status = useAppSelector((state: RootState) => state.dashboardNavigationMenu.entities.);
    const dispatch = useAppDispatch();
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const initialPositionRef = useRef<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
    const userConfig = useAppSelector((state: RootState) => state.userConfigurarion);
    
    useEffect(() => {
        dispatch(getEntity({powerStationId: powerStationId}));
    }, [dispatch, powerStationId]);

    useInterval(() => {
        dispatch(getEntity({powerStationId: powerStationId}));
    }, userConfig.mainUpdateInterval); //5 minutes

    useEffect(() => {
        if (informationsOfPowerStation != null) {
            if (!initialPositionRef.current.lat && !initialPositionRef.current.lng) {
                initialPositionRef.current = {
                    lat: informationsOfPowerStation.latitude,
                    lng: informationsOfPowerStation.longitude,
                };
                setMapCenter(initialPositionRef.current);
            }
        }
    }, [informationsOfPowerStation]);

    const mapOptions = {
        zoom: 18,
    };


    const getMapOptions = (maps: Maps) => {
        return {
            mapTypeId: maps.MapTypeId.SATELLITE,
            mapTypeControlOptions: {
                position: maps.ControlPosition.BOTTOM_CENTER,
            }
        }
    }

    return (
        <ACard
            styleProps={{
                cardStyle: {height: 800, width:'100%'}
            }}
            title="Mapa"
            // headerActions={
            //     <FloatMenuButton
            //         icon={<MoreVertIcon/>}
            //         tooltip={"Floating Menu"}
            //     >
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
            //             text="Expand"
            //             disable={true}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
            //             text="Print"
            //             disable={true}
            //         />
            //     </FloatMenuButton>
            // }
        >
            {
                informationsOfPowerStation != null ?
                    (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12}>
                                <Box style={{ height: '700px', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: 'AIzaSyBIqWy3JkxVNjorFXL1qe20Ylh_0NVQoZc' }}
                                        center={mapCenter}
                                        defaultZoom={mapOptions.zoom}
                                        options={getMapOptions}
                                    >
                                        <MapMarker
                                            lat={Number(informationsOfPowerStation?.latitude) ?? 0}
                                            lng={Number(informationsOfPowerStation?.longitude) ?? 0}
                                        />
                                    </GoogleMapReact>
                                </Box>
                            </Grid>
                        </Grid>
                    )
                    :
                    <Container fixed>
                        <Box sx={{ bgcolor: '#e1e0e0', height: '700px', width: '100%' ,alignItems: 'center',
                            justifyContent: 'center', display:'flex'}}>
                            <div style={{ textAlign: 'center' , fontSize: '23px', color: '#646363'}}>
                                <span>Não foi possível encontrar dados de localização</span>
                            </div>
                        </Box>

                    </Container>
            }
        </ACard>
    );
}

export default MapPowertation;