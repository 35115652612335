import { ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import React, {useCallback, useEffect, useState} from "react";
import {extractFiltersFromColumns} from "../../../../Utils/DataUitils";
import {
    DeleteBrokerReactQuery,
    FindAllBrokerByPowerStationIdReactQuery
} from "../../Broker/BrokerReactQuery";
import {BrokerProps} from "../../../../Shared/Types/Broker";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormHelperText,
    Grid,
    InputLabel,
    TextField,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmDialog from "../../../../Shared/Components/ConfirmDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

const initColumns = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        visibility: true,
        minWidth: 25,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'id', condition: 'equals', value: '', sort: 'asc'}

    },
    {
        name: 'name',
        label: 'nome',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'name', condition: 'contains', value: '', sort: 'asc'}

    },
    {
        name: 'ipAddress',
        label: 'endereço IP',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'ipAddress', condition: 'contains', value: '', sort: ''}

    },
    {
        name: 'port',
        label: 'porta',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'port', condition: 'equals', value: '', sort: ''}

    }
] as ADataGridColumn [];

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowBroker {
    id?: number;
    name?: string;
    ipAddress?: string;
    port?: number;
    txQueue?: string;
    rxQueue?: string;
    notificationQueue?: string;
    gwid?: number;
    actionsConfig?: any
    index?: number | null;
}

interface Props {
    powerStationId: number | null;
    onSaveBroker: any;
}

export function PowerStationGridBrokerPage({powerStationId, onSaveBroker}: Props){
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50));
    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);
    const theme = useTheme();
    const mutation = DeleteBrokerReactQuery(pagedSearchParams);
    const { data, isSuccess, refetch } = FindAllBrokerByPowerStationIdReactQuery(powerStationId, pagedSearchParams);
    const [selectedToDelete, setSelectedToDelete] = useState<RowBroker | null>(null);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [rows, setRows] = useState<Array<RowBroker>>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedBroker, setSelectedBroker] = useState<RowBroker | null>(null)
    const [tempRow, setTempRow] = useState<RowBroker | null>(null);
    const [nextIndex, setNextIndex] = useState(0);

    useEffect(() => {
        if(isSuccess) {
            setTotalOfRecords(data?.totalOfRecords);
            if(data?.data){
                let rowsBroker = data.data.data.map((broker: BrokerProps, index: number) => {
                    return {
                        id: Number(broker.id),
                        name: broker.name,
                        ipAddress: broker.ipAddress,
                        port: broker.port,
                        rxQueue: broker.rxQueue,
                        txQueue: broker.txQueue,
                        notificationQueue: broker.notificationQueue,
                        gwid: broker.gwid,
                        actionsConfig: [
                            {actionName: "edit", disabled: false},
                            {actionName: "delete", disabled: false},
                        ],
                        index: index
                    }
                });
                setRows(rowsBroker);
            }
        }
    }, [data]);

    function handleGwid() {
        let newGwid = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
        setSelectedBroker((prev: any) => ({ ...prev, gwid: newGwid }));
    }

    function handleEditBroker(data: any){
        setIsEditing(true);
        let brokerFound = rows.find(brk => brk.id === data.id);
        if(brokerFound) {
            setSelectedBroker(brokerFound);
        }
        setOpen(true);
    }

    const handleDeleteBroker = useCallback((data: any) => {
        setSelectedToDelete(data);
        setOpenDialog(true);
    },[]);

    const handleDialogConfirm = async ( ) => {
        if(selectedToDelete !== null && selectedToDelete.id !== null) {
            mutation.mutate({id: selectedToDelete.id, pagedSearchParams});
        }
        setRows(rows.filter(row => selectedToDelete?.index !== row.index))
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    useEffect(() => {
        if(mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50))
            refetch()
        }
    }, [mutation.isSuccess]);


    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    function handleAddNew(): void {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedBroker(null);
        setIsEditing(false);
        setTempRow(null);
    };

    useEffect(() => {
        if (!isEditing) {
            setTempRow({
                id: selectedBroker?.id,
                name: selectedBroker?.name,
                ipAddress: selectedBroker?.ipAddress,
                port: selectedBroker?.port,
                txQueue: selectedBroker?.txQueue,
                rxQueue: selectedBroker?.rxQueue,
                notificationQueue: selectedBroker?.notificationQueue,
                gwid: selectedBroker?.gwid,
                actionsConfig: [
                    {actionName: "edit", disabled: false},
                    {actionName: "delete", disabled: false},
                ],
                index: nextIndex,
            });

            setNextIndex(prevIndex => prevIndex + 1);
        }

    }, [selectedBroker]);

    const handleAddItem = (tempRow: any) => {
        if (tempRow) {
            setRows(prevRows => {
                const updatedRows = [...prevRows, tempRow];
                handleSave(updatedRows);
                return updatedRows;
            });
            handleClose();
        }
    }

    const handleEditItem = (data: any) => {
        const newRows = rows.filter(row => row.index !== data.index);
        let newItem = [{
            id: data.id,
            name: data.name,
            ipAddress: data.ipAddress,
            port: data.port,
            rxQueue: data.rxQueue,
            txQueue: data.txQueue,
            notificationQueue: data.notificationQueue,
            gwid: data.gwid,
            actionsConfig: [
                {actionName: "edit", disabled: false},
                {actionName: "delete", disabled: false},
            ],
            index: data.index
        }]

        setRows(() => {
            const updatedRows = [...newRows, ...newItem];
            handleSave(updatedRows);
            return updatedRows;
        });

        setIsEditing(false)
        setOpen(false)
    }

    useEffect(() => {
        const brokerDataList = rows.map((row: RowBroker) => ({
            id: row.id ?? null,
            name: row.name,
            ipAddress: row.ipAddress,
            rxQueue: row.rxQueue,
            txQueue: row.txQueue,
            notificationQueue: row.notificationQueue,
            port: row.port,
            powerStation: {
                id: powerStationId
            },
            gwid: row.gwid
        }));

        onSaveBroker(brokerDataList.length === 0 ? [{
            id: null,
            name: "",
            ipAddress: "",
            port: NaN,
            rxQueue: "",
            txQueue: "",
            notificationQueue: "",
            powerStation: undefined,
            gwid: NaN
        }] : brokerDataList);
    }, [rows]);

    const handleSave = (updatedRows: any) => {

        const brokerDataList = updatedRows.map((row: RowBroker) => ({
            id: row.id ?? null,
            name: row.name,
            ipAddress: row.ipAddress,
            rxQueue: row.rxQueue,
            txQueue: row.txQueue,
            notificationQueue: row.notificationQueue,
            port: row.port,
            powerStation: {
                id: powerStationId
            },
            gwid: row.gwid
        }));

        onSaveBroker(brokerDataList);
    }

    return(
        <>
            <Box style={{paddingTop: 10}}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    {/* Botão adicionado à esquerda acima do grid */}
                    <Button
                        variant="contained"
                        endIcon={<AddCircleIcon/>}
                        color="primary"
                        onClick={() => handleAddNew()} // Função que será chamada ao clicar no botão
                    >
                        Inserir
                    </Button>
                </Box>
                <ADataGrid
                    hideFilters={false}
                    showFilterOptions={false}
                    hideSelection={false}
                    columns={initColumns}
                    rows={rows}
                    page={pagedSearchParams.page}
                    rowsPerPage={pagedSearchParams.size}
                    loading={false}
                    totalOfRecords={totalOfRecords}
                    onFiltersChange={handleOnFiltersChange}
                    actions={
                        <>
                            <IconButton name="edit" size={"small"} aria-label="Edit" disabled={false} onClick={(value) => handleEditBroker(value)}>
                                <Tooltip title="Editar">
                                    <Edit/>
                                </Tooltip>
                            </IconButton>
                            <IconButton name="delete" size={"small"} aria-label="Delete" disabled={false} onClick={(value) => handleDeleteBroker(value)}>
                                <Tooltip title="Excluir">
                                    <Delete />
                                </Tooltip>
                            </IconButton>
                        </>
                    }
                />
            </Box>
            <ConfirmDialog
                title={'Deletar'}
                description={'Deseja deletar esse broker?'}
                open={openDialog}
                handleConfirm={handleDialogConfirm}
                handleClose={handleDialogClose}
            />
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{isEditing ? selectedBroker?.name : "Criar Novo Broker"}</DialogTitle>
                <DialogContent sx={{width: '600px' }}>
                    <Grid container spacing={2} sx={{paddingLeft: 2, marginTop: 2}}>
                        <Grid item xs={12} sm={6}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="type-name">Nome</InputLabel>
                                <TextField
                                    error={selectedBroker?.name === ""}
                                    fullWidth
                                    id="name"
                                    variant="standard"
                                    placeholder={"Nome do broker"}
                                    onChange={(e) => {
                                        setSelectedBroker((prev: any) => ({ ...prev, name: e.target.value }));
                                    }}
                                    value={selectedBroker?.name}
                                />
                                {
                                    selectedBroker?.name === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="ipAddress">Endereço IP</InputLabel>
                                <TextField
                                    fullWidth
                                    error={selectedBroker?.ipAddress === ""}
                                    id="ipAddress"
                                    variant="standard"
                                    placeholder={"Endereço IP"}
                                    onChange={(e) => {
                                        setSelectedBroker((prev: any) => ({ ...prev, ipAddress: e.target.value }));
                                    }}
                                    value={selectedBroker?.ipAddress}
                                />
                                {
                                    selectedBroker?.ipAddress === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="port">Porta</InputLabel>
                                <TextField
                                    fullWidth
                                    id="port"
                                    variant="standard"
                                    placeholder={"Digite a porta"}
                                    type="number"
                                    onChange={(e) => {
                                        setSelectedBroker((prev: any) => ({ ...prev, port: e.target.value }));
                                    }}
                                    value={selectedBroker?.port}
                                />
                                {
                                    isNaN(selectedBroker?.port as number) &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="txQueue">Fila TX</InputLabel>
                                <TextField
                                    fullWidth
                                    error={selectedBroker?.txQueue === ""}
                                    id="txQueue"
                                    variant="standard"
                                    placeholder={"Digite a fila TX"}
                                    onChange={(e) => {
                                        setSelectedBroker((prev: any) => ({ ...prev, txQueue: e.target.value }));
                                    }}
                                    value={selectedBroker?.txQueue}
                                />
                                {
                                    selectedBroker?.txQueue === '' &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="rxQueue">Fila RX</InputLabel>
                                <TextField
                                    fullWidth
                                    error={selectedBroker?.rxQueue === ""}
                                    id="rxQueue"
                                    variant="standard"
                                    placeholder={"Digite a fila RX"}
                                    onChange={(e) => {
                                        setSelectedBroker((prev: any) => ({ ...prev, rxQueue: e.target.value }));
                                    }}
                                    value={selectedBroker?.rxQueue}
                                />
                                {
                                    selectedBroker?.rxQueue === '' &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="notificationQueue">Fila de notificação</InputLabel>
                                <TextField
                                    fullWidth
                                    error={selectedBroker?.notificationQueue === ""}
                                    id="notificationQueue"
                                    variant="standard"
                                    placeholder={"Digite a fila de notificação"}
                                    onChange={(e) => {
                                        setSelectedBroker((prev: any) => ({ ...prev, notificationQueue: e.target.value }));
                                    }}
                                    value={selectedBroker?.notificationQueue}
                                />
                                {
                                    selectedBroker?.notificationQueue === '' &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="gwid">GWID</InputLabel>
                                <TextField
                                    type="number"
                                    sx={{width: "75%"}}
                                    error={selectedBroker?.gwid === null}
                                    id="gwid"
                                    variant="standard"
                                    placeholder={"Digite o GWID"}
                                    onChange={(e) => {
                                        setSelectedBroker((prev: any) => ({ ...prev, gwid: e.target.value }));
                                    }}
                                    value={selectedBroker?.gwid}
                                />
                                <IconButton onClick={handleGwid} sx={{
                                    marginLeft: '10px',
                                    backgroundColor: '#818181',
                                    width: '50px',
                                    borderRadius: '10px'
                                }}>
                                    <FontAwesomeIcon icon={faArrowRight} color='#fff' fontSize={20}/>
                                </IconButton>
                                {
                                    selectedBroker?.gwid === null &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                            color="primary"
                            variant="outlined"
                            size={"small"}
                            endIcon={<CancelOutlined />}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={() => {
                        isEditing ? handleEditItem(selectedBroker) : handleAddItem(tempRow)
                    }}
                            color="primary"
                            variant="contained"
                            size={"small"}
                            endIcon={ isEditing ? <Edit/> : <AddCircleIcon />}
                    >

                        {isEditing ? "Editar" : "Inserir"}</Button>
                </DialogActions>
            </Dialog>
        </>
    );

}