import {Grid} from "@mui/material";
import TotalizerAlarms from "../TotalizerAlarms/TotalizerAlarms";
import AlarmPage from "../Alarms/AlarmPage";
import { useEffect, useState } from "react";

export type TotalizerType =  "NOT_ACKNOWLEDGED" | "POWER_STATION_OFFLINE"  | "EQUIPMENT_OFFLINE" | "EQUIPMENTS_ALARMED" | "ACTIVE" | "ACTIVE_CRITICAL" | "NONE";

export default function DashboardOverviewAlarmsPage() {

        const [filter, setFilter] = useState<TotalizerType>(localStorage.getItem("totalizer_by_overview") as TotalizerType ?? "NONE");

        const onClickTotalizer = (totalizer:TotalizerType) => {
            console.log(totalizer);
            // Saved in localstorage
            localStorage.setItem("totalizer_by_overview", totalizer);
            setFilter(totalizer)
        }
    return (
            <Grid container>
                <Grid item xs={12}>
                    <TotalizerAlarms onClickTotalizer={onClickTotalizer}/>
                    <AlarmPage filterByTotalizer={filter}/>
                </Grid>
            </Grid>
    )
}
