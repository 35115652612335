import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import logo from "../../../Assets/logo.png";
import {
    AppBar,
    Badge,
    Button,
    Divider,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Modal,
    TextField,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {
    ACard,
    ADataGrid,
    ADataGridFilter,
    FloatMenuButton,
    FloatMenuItem,
    PagedSearchParams
} from "@atiautomacao/ati-ui-library";
import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import {DarkModeOutlined, LightModeOutlined, Logout, Menu, Visibility, VisibilityOff} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import {toggleMainMenu, toggleSubMenu} from "../../../Config/Reducers/LayoutReducer";
import Breadcrumb from "./menu/Breadcrumb";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {QueryClient, QueryClientProvider} from "react-query";
import {CommandHistory} from "./types/CommandHistoryReactQuery";
import {useCallback, useEffect, useState} from "react";
import MailIcon from '@mui/icons-material/Mail';
import {columnsCommandHistoryDataGrid} from "../../../Modules/Settings/GraficElement/columnsDataGrid";
import {DateFormat} from "../../../Utils/DateFormatPatternUtils";
import './SGDAppBarStyle.css'
import {hasPermission} from "../../Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";
import {useSnackbar} from "notistack";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";

const style = {
    logo: {
        height: 38,
        width: 'auto'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
};



export interface CommandHistoryRow {
    id: number;
    name?: string;
    dateTime?: string;
    powerStationName?: string;
    value?: number;
    equipmentName?: string;
    userName?: string
}

interface SGDAppBarProps {
    handleThemeMode: () => void;
}


function SGDAppBar({handleThemeModeChange}: any){
    const theme = useTheme();
    const storedTheme = localStorage.getItem("theme") ?? "light";
    const [mode, setMode] = useState<"light" | "dark">(storedTheme as "light" | "dark");
    const { enqueueSnackbar } = useSnackbar();
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const dispatch = useAppDispatch();
    const openMainMenu = useAppSelector((state: any) => state.layout.openMainMenu);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const account = useAppSelector((state) => state.authentication.account);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsCommandHistoryDataGrid), 0, 25));

    const [rows, setRows] = React.useState<CommandHistoryRow[]>([]);
    const [qtdMessages, setQtdMessages] = React.useState(0);
    const isAuthorizedToShowGrid = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_COMMAND_HISTORY
    ]);
    const { data, isSuccess } = CommandHistory(pagedSearchParams, isAuthenticated, isAuthorizedToShowGrid, 10000);


    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);


    const [openModal, setOpenModal] = useState(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState<boolean>(false);

    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [passwordRepeat, setPasswordRepeat] = useState<string>("");

    const handleOpenModal = () => {
        setQtdMessages(0)
        if(isAuthorizedToShowGrid){
            setOpenModal(true);
        }
        else {
            enqueueSnackbar("Você não tem permissão para acessar este recurso!", {variant: 'error'});
        }
    }

    useEffect(() => {
        localStorage.setItem("theme", mode);
    }, [mode]);

    const handleCloseModal = () => {
        setOpenModal(false);
        setQtdMessages(0)
    }
    const handleClosePasswordModal = () => {
        setOpenPasswordModal(false)
        setOldPassword("")
        setNewPassword("")
        setPasswordRepeat("")
        setShowOldPassword(false)
        setShowPasswordRepeat(false)
        setShowNewPassword(false)
    }
    const [isBlinking, setIsBlinking] = useState(false)

    const [themeMode, setThemeMode] = useState('light');

    const updateRows = (data: any) => {
        if (!Array.isArray(data?.data)) {
            setRows([]);
            return;
        }

        const filteredData = data?.data?.map((item: any) => {
            return {
                id: item.id,
                dateTimeReq: item.dateTimeReq != null ? DateFormat(item.dateTimeReq) : "",
                dateTimeRes: item.dateTimeRes != null ? DateFormat(item.dateTimeRes) : "",
                description: item?.description,
                ticket: item?.ticket,
                successRes: item?.successRes ? "Sucesso" : "Falha",
                equipmentName: item?.equipmentName,
                alarmedName: item?.alarmedName,
                powerStationName: item?.powerStationName,
                value: item?.value.toString(),
                userName: item?.userName
            };
        }).sort((a:any, b:any) => {
            return new Date(b.dateTimeReq).getTime() - new Date(a.dateTimeReq).getTime();
        });

        setRows(filteredData);
    };

    useEffect(() => {
        if (isSuccess && data?.totalOfRecords !== undefined) {
            setTotalOfRecords(data?.totalOfRecords)
        }
    }, [data?.data]);

    useEffect(() => {
        if (data && Array.isArray(data.data)) {
            setQtdMessages(Math.min(data?.totalOfRecords, 400));
        } else {
            setQtdMessages(0)
        }

        if (qtdMessages > 0) {
            setIsBlinking(true);
            const timer = setTimeout(() => {
                setIsBlinking(false);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [data?.data]);

    useEffect(() => {
        if(isAuthorizedToShowGrid){
            updateRows(data);
        }
    }, [data?.data]);

    const handleOnRow = (rows:any) => {
    }


    const notificationsLabel = (count: number) => {
        if (count === 0) {
            return 'no notifications';
        }
        if (count > 99) {
            return 'more than 99 notifications';
        }
        return `${count} notifications`;
    }
    const menuToogle = () => {
        if(openMainMenu && !openSubMenu) {
            dispatch(toggleMainMenu());
        }else {
            dispatch(toggleMainMenu());
            dispatch(toggleSubMenu());
        }

    }

    const handleThemeMode = () => {
        if(themeMode === 'light') {
            setThemeMode('dark');
            handleThemeModeChange('dark');
        }else {
            setThemeMode('light');
            handleThemeModeChange('light');
        }
    }
    const handleChangePassword = () => {
        if(oldPassword === "" || newPassword === "" || passwordRepeat === "" ){
            enqueueSnackbar("Existem campos obrigatórios vazios!", {variant: "warning"})
            return;
        }
        if(newPassword !== passwordRepeat){
            enqueueSnackbar("A nova senha e a confirmação da senha devem ser iguais!", {variant: "warning"})
            return;
        }
        axios.put('api/users/change-password',{userId: Number(account?.id), oldPassword: oldPassword, newPassword: newPassword}).then(
            _=> {
                enqueueSnackbar("Senha alterada com sucesso",{variant: 'success'})
                handleClosePasswordModal()
            }
        ).catch(
            error => {
                if(error.response?.data?.status === 400){
                    enqueueSnackbar("Senha atual esta errada",{variant: 'error'})
                }else{
                    enqueueSnackbar("Error ao salvar a nova senha",{variant: 'error'})
                }
            }
        )
    };

    if(isAuthenticated) return (
        <AppBar position="fixed" sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1
        }}>
            <Toolbar style={{paddingLeft: 7}}>
                <IconButton
                    color="error"
                    aria-label="open drawer"
                    edge="start"
                    onClick={menuToogle}
                    sx={{ ml: 2, mr: 2, display: { lg: 'none' }}}
                >
                    <Menu />
                </IconButton>
                <IconButton edge="start" color="error" aria-label="Menu" style={{marginLeft: 0}}>
                    <img alt="logo" style={style.logo} src={logo}/>
                </IconButton>
                <Breadcrumb />
                <Box sx={{ flexGrow: 1}}></Box>

                {/*<IconButton aria-label={notificationsLabel(100)} style={{paddingRight: 25}}>*/}
                {/*    <Badge badgeContent={100} color="secondary">*/}
                {/*        <MailIcon />*/}
                {/*    </Badge>*/}
                {/*</IconButton>*/}

                {/*Ícone de troca de tema, descomentar para continuar a atividade*/}
                <Tooltip title={themeMode === 'light' ? 'Modo Escuro' : 'Modo Claro'}>
                    <IconButton
                        sx={{
                            width: '30px',
                            borderRadius: '20x',
                            height: '30px',
                            '&:hover': {
                                backgroundColor: '#c1c1c1'
                            }
                        }}>
                        <Button onClick={handleThemeMode}>
                            {
                                themeMode === 'light' ? <DarkModeOutlined sx={{color: theme.palette.primary.main}}/> : <LightModeOutlined sx={{color: theme.palette.primary.main}}/>
                            }
                        </Button>
                    </IconButton>
                </Tooltip>
                <IconButton aria-label={notificationsLabel(qtdMessages)}>
                    <Button onClick={handleOpenModal}>
                        <Badge badgeContent={qtdMessages} color="secondary">
                            <MailIcon sx={{color: theme.palette.primary.main}}
                                      className={isBlinking ? 'pulse-effect' : ''}/>
                        </Badge>
                    </Button>
                </IconButton>

                {
                    account ? (<h5>{account.name}</h5>) : ""
                }
                <FloatMenuButton
                    icon={<Avatar sx={{ width: 32, height: 32, border:'solid 2px #FFF', color: '#1656f8' }}>{account?.name ? account.name.charAt(0) : 'A'}</Avatar>}
                    tooltip={"Account Menu"}
                >
                    <FloatMenuItem
                        icon={<FontAwesomeIcon icon={faCalendarDays} fontSize={20} />}
                        text="My Account"
                        disable={true}
                        link="/"
                    />
                    <FloatMenuItem
                        icon={<FontAwesomeIcon icon={faCalendarDays} fontSize={20} />}
                        text="Change Password"
                        disable={false}
                        onclick={() => setOpenPasswordModal(true)}
                    />
                    <FloatMenuItem
                        icon={<FontAwesomeIcon icon={faCalendarDays} fontSize={20} />}
                        text="Settings"
                        disable={true}
                        link="/"
                    />
                    <Divider />
                    <FloatMenuItem
                        icon={<Logout fontSize="small" />}
                        text="Logout"
                        link="/logout"
                    />
                </FloatMenuButton>
            </Toolbar>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <ACard
                    title="Histórico de Telecomandos"
                    styleProps={{
                        cardStyle: {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '65%',
                            height: "55%",
                            padding: '4',
                        }
                    }}
                >
                    <ADataGrid
                        hideFilters={true}
                        showFilterOptions={false}
                        hideSelection={true}
                        size={"small"}
                        columns={columnsCommandHistoryDataGrid}
                        rows={rows}
                        page={pagedSearchParams.page}
                        onRowSelect={handleOnRow}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        onFiltersChange={handleOnFiltersChange}
                        rowsPerPage={pagedSearchParams.size}
                        actionColumnWidth={50}
                        maxHeight={400}
                    />
                </ACard>
            </Modal>
                <Modal
                    open={openPasswordModal}
                    onClose={handleClosePasswordModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Grid sx={{
                        position: 'absolute',
                        top: '40%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '80%', sm: '70%', md: '50%', lg: '30%' },
                        maxWidth: '600px',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 4,
                    }}>
                        <ACard
                            title="Redefinir senha"
                            footerActions={
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                                    <Button variant="contained" color="primary" onClick={handleClosePasswordModal}>
                                        Cancelar
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handleChangePassword}>
                                        Salvar
                                    </Button>
                                </Box>
                            }
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box style={{marginBottom: 20, height: '50%'}}>
                                        <InputLabel id="old-password">Senha atual</InputLabel>
                                        <TextField
                                            error={oldPassword === ""}
                                            fullWidth
                                            id="old-password"
                                            type={showOldPassword ? "text" : "password"}
                                            variant="standard"
                                            placeholder={"Digite sua senha atual"}
                                            onChange={(value) => setOldPassword(value.target.value)}
                                            value={oldPassword}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                                        >
                                                            {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box style={{marginBottom: 20, height: '50%'}}>
                                        <InputLabel id="new-password">Nova senha</InputLabel>
                                        <TextField
                                            error={newPassword === ""}
                                            fullWidth
                                            id="new-password"
                                            type={showNewPassword ? "text" : "password"}
                                            variant="standard"
                                            placeholder={"Digite sua nova senha"}
                                            onChange={(value) => setNewPassword(value.target.value)}
                                            value={newPassword}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                                        >
                                                            {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box style={{marginBottom: 20, height: '50%'}}>
                                        <InputLabel id="password-repeat">Confirme a senha</InputLabel>
                                        <TextField
                                            error={passwordRepeat === ""}
                                            fullWidth
                                            id="password-repeat"
                                            type={showPasswordRepeat ? "text" : "password"}
                                            variant="standard"
                                            placeholder={"Confirme a senha digitada"}
                                            onChange={(value) => setPasswordRepeat(value.target.value)}
                                            value={passwordRepeat}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                                                        >
                                                            {showPasswordRepeat ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {
                                            (passwordRepeat !== "" && newPassword !== "") && (passwordRepeat !== newPassword) &&
                                            <FormHelperText id="my-helper-text" style={{color: 'red'}}>As duas senhas devem ser iguais.</FormHelperText>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </ACard>

                    </Grid>
                </Modal>

        </AppBar>
    );

    return null;
}


export default function SGDAppBarPage({handleThemeMode}: Readonly<SGDAppBarProps>){
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <SGDAppBar handleThemeModeChange={handleThemeMode}/>
        </QueryClientProvider>
    )


}
