import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {ACard} from "@atiautomacao/ati-ui-library";
import {
    Container,
    TextField,
    InputLabel,
    FormHelperText,
    Button,
} from "@mui/material";
import Box from "@mui/material/Box";

import {
    FindEquipmentTypeByIdReactQuery,
    SaveEquipmentTypeReactQuery
} from "./EquipmentTypeReactQuery";
import {EquipmentTypeProps} from "../../../Shared/Types/EquipmentType";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {HeaderMenu} from "../HeaderMenu";

export const EquipmentTypeFormPage = () => {
    const location = useLocation();
    const id = location.state?.id as string;

    const [equipmentTypeName, setEquipmentTypeName] = useState<string>("");

    const mutationSave = SaveEquipmentTypeReactQuery();
    const mutationFindById = FindEquipmentTypeByIdReactQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    let equipmentType = data?.data as EquipmentTypeProps;
                    setEquipmentTypeName(equipmentType.name);
                }
            });
        }
    }, []);

    function handleSave() {
        let newEquipmentType: EquipmentTypeProps = {
            id: id != null ? Number(id) : null,
            name: equipmentTypeName,
        }

        mutationSave.mutate(newEquipmentType, {
            onSuccess: () => {
                navigate(SystemRoutes.SETTINGS_EQUIPMENT_TYPE);
            }
        });
    }

    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_EQUIPMENT_TYPE}/>
            <Box>
                <ACard>
                    <Container style={{marginLeft: -10, display: 'flex', flexDirection: "row", alignItems: 'left', justifyContent: 'left', columnGap: 30}}>
                        <div style={{width: '100%'}}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="type-name">Nome</InputLabel>
                                <TextField
                                    error={equipmentTypeName === ""}
                                    fullWidth
                                    id="type-name"
                                    variant="standard"
                                    placeholder={"Nome do type"}
                                    onChange={(value) => setEquipmentTypeName(value.target.value)}
                                    value={equipmentTypeName}
                                />
                                {
                                    equipmentTypeName === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                        </div>
                    </Container>
                </ACard>
            </Box>
        </>
    )
}

export function EquipmentTypeHomePageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <EquipmentTypeFormPage />
        </QueryClientProvider>
    )
}