import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {ACard, AChartX, Period, PeriodSelector, Skeleton} from "@atiautomacao/ati-ui-library";
import {GeneratedEnergy} from "../../DashboardPowerPlantCharts";
import {format} from "date-fns";
import {ptBR} from "date-fns/locale";
import {roundNextHundred, truncateNumber} from "../../../../../Utils/NumberUtil";
import {isArray} from "lodash";
import {useSnackbar} from "notistack";
import DataNotFound from "../../../../../Shared/Components/DataNotFoundMessage";
import {
    FindGenerationInverterByDailReactQuery,
} from "../InverterReactQuery";
import {useAppSelector} from "../../../../../Config/Hooks";
import {useTheme} from "@mui/material";

export const handleDateTimeFormat = (generatedEnergyData: GeneratedEnergy[], period: Period):Array<any> => {
    try {
        return generatedEnergyData.map((generation) => {
            if (!generation.dateTime) {
                return "Data Ausente";
            }
            if (period.groupBy === "year" && RegExp(/^\d{4}-\d{2}$/).exec(generation.dateTime)) {
                const [year, month] = generation.dateTime.split('-');
                const dateComplete = new Date(parseInt(year), parseInt(month) - 1, 1);
                return format(dateComplete, 'MMM', { locale: ptBR });
            } else if (period.groupBy === "month" || period.groupBy === "none") {
                return format(new Date(generation.dateTime + ' '), 'dd/MM', { locale: ptBR });
            } else {
                return "";
            }
        });
    } catch (error) {
        console.error("Erro ao formatar datas:", error);
        return [];
    }
}

const GenerationEnergyChart: FunctionComponent<{ powerPlantId: number, equipmentId: number}> = ({powerPlantId,equipmentId}) => {
    const theme = useTheme();
    const mutate = FindGenerationInverterByDailReactQuery();
    const [generatedEnergy,setGeneratedEnergy]  = useState<GeneratedEnergy[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    // Define a unidade baseada no groupBy
    const [unity, setUnity] = useState<string>('KWh');

    const generateSeries = useCallback((): ({ data: any; name: string; tooltip: { valueFormatter: (value: number) => string }; type: string } | { unity: string; data: any; name: string; type: string; yAxisIndex: number })[] => {

        const genEnergy = {
            name: 'Energia Gerada',
            type: 'bar',
            itemStyle: {
                color: '#5470C6' // Azul
            },
            tooltip: {
                valueFormatter: (value: number) => `${value} ${unity}`
            },
            yAxisIndex: 0,
            data: generatedEnergy.map((generation: GeneratedEnergy) => generation.generatedEnergy ? truncateNumber(generation.generatedEnergy, 2) : "-")
        };

        const irradiance = {
            name: 'Irradiação',
            type: 'line',
            itemStyle: {
                color: '#F4CA6C' // Amarelo
            },
            tooltip: {
                valueFormatter: (value: number) => `${value} Wh/m²`
            },
            yAxisIndex: 1,
            data: generatedEnergy.map((generation: GeneratedEnergy) => generation.irradiance ? truncateNumber(generation.irradiance, 2) : "-")
        };

        return [genEnergy, irradiance,];
    }, [generatedEnergy]);

    const getMaxYAxisValue = useCallback((): number => {
        const values = generatedEnergy.map(
            (generation: GeneratedEnergy) => generation.generatedEnergy ? truncateNumber(generation.generatedEnergy) : generation.generatedEnergy)

        return values.length > 0 ? Math.max(...values) : 0;
    }, [generatedEnergy]);

    const [period, setPeriod] = useState<Period>(() => {
        const toDateTime = new Date();
        const fromDateTime = new Date();

        return {
            groupBy : "month" ,
            fromDateTime,
            toDateTime
        }
    });

    // Atualiza `groupBy` e `unity` quando o `period` mudar
    useEffect(() => {
        if (period.groupBy === "year") {
            setUnity("MWh");
        } else {
            setUnity("kWh");
        }
    }, [period.groupBy]);

    // @ts-ignore
    const option: AChartXProps['option'] = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        toolbox: {
            right: '15%',
            top: -8,
            itemSize: 15,
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                dataView: { show: false, readOnly: false },
                magicType: { show: false, type: ['line', 'bar'] },
                restore: { show: true },
            }
        },
        dataZoom: [
            {
                type: 'inside',
                throttle: 50
            },
        ],
        xAxis: {
            type: 'category',
            data: handleDateTimeFormat(generatedEnergy, period),
            nameLocation: 'middle',
            nameGap: 20,
        },
        yAxis: [
            {
                name: unity,
                max: truncateNumber(getMaxYAxisValue() + getMaxYAxisValue() * 0.15),
                min: 0,
                interval: truncateNumber(getMaxYAxisValue()/4),
                nameGap: 80,
                nameLocation: 'middle',
                position: "left",
                nameTextStyle: {
                    fontSize: 12,
                },
            },
            {
                name: 'Wh/m²',
                max:truncateNumber(Math.max(
                    ...generatedEnergy.filter(value => value.irradiance && value.irradiance !== null).map(value => value.irradiance),
                    ...generatedEnergy.filter(value => value.expectedIrradiance && value.expectedIrradiance !== null).map(value => truncateNumber(value.expectedIrradiance))
                ) + (Math.max(
                    ...generatedEnergy.filter(value => value.irradiance && value.irradiance !== null).map(value => truncateNumber(value.irradiance)),
                    ...generatedEnergy.filter(value => value.expectedIrradiance && value.expectedIrradiance !== null).map(value => truncateNumber(value.expectedIrradiance))
                ) * 0.15)),
                min: 0,
                interval: truncateNumber(Math.max(
                    ...generatedEnergy.filter(value => value.irradiance && value.irradiance !== null).map(value => value.irradiance),
                    ...generatedEnergy.filter(value => value.expectedIrradiance && value.expectedIrradiance !== null).map(value => value.expectedIrradiance)
                ) / 4),
                nameGap: 60,
                position: "right",
                nameLocation: 'middle',
                nameTextStyle: {
                    fontSize: 12,
                },
            }
        ],
        legend: {
            show: true,
            type: 'scroll',
            orient: 'horizontal', // Altera para horizontal
            bottom: -6, // Coloca a legenda na parte inferior
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: generateSeries().map(el => el.name)
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: '10%',
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        series: generateSeries(),
    }


    useEffect(() => {
        if(equipmentId && powerPlantId){
            const groupBy = period.groupBy === "year" ? "month" : "day";
            mutate.mutate(
                {
                    startDateTime: period.fromDateTime,
                    endDateTime: period.toDateTime,
                    groupBy: groupBy,
                    powerStationId: powerPlantId,
                    equipmentId: equipmentId
                },
                {
                    onSuccess: (data) => {
                        setGeneratedEnergy(data?.data || []);
                    },
                    onError: (error: any) => {
                        enqueueSnackbar(`Erro ao carregar dados de geração de energia: ${error.message}`, { variant: "error" });
                    }
                }
            );
        } else {
            setGeneratedEnergy([]);
        }

    }, [period, equipmentId, powerPlantId]);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    let content;
    if (!mutate.isSuccess) {
        content = <Skeleton animation="wave" height={289} variant="rounded" width="100%" />;
    } else if (isArray(generatedEnergy) && generatedEnergy.length > 0) {
        content = (
            <AChartX
                option={option}
                width={'100%'}
                height={289}
                loading={false}
                theme={theme.palette.mode}
            />
        );
    } else {
        content = <DataNotFound boxStyle={{ height: 289, width: '100%' }} />;
    }

    return (
        <ACard
            key={`chart-power-generation-${openSubMenu}-${period.groupBy}`}
            styleProps={{
                cardStyle: { height: 445 },
                contentStyle: { padding: 0, marginTop: 70 }
            }}
            title={"Energia x Irradiação"}
            headerControls={
                <PeriodSelector
                    styleProps={{ base: { flexDirection: "row", display: "flex", alignItems: "center" } }}
                    mode="hybrid"
                    hideDatetime={false}
                    hideGroup={false}
                    period={period}
                    inputFormat={'dd/MM/yyyy'}
                    periodInterval={{
                        month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
                        year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12}
                    }}
                    groupByOptions={["month", "year"]}
                    onChange={setPeriod}
                />
            }

        >
            {content}
        </ACard>
    );
}

export default GenerationEnergyChart;