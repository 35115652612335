import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {useLocation} from "react-router-dom";
import ScrollTop from './Shared/Components/ScrollTop';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SGDSubMenu from "./Shared/Components/Layout/menu/SGDSubMenu";
import SGDMainMenu from "./Shared/Components/Layout/menu/SGDMainMenu";
import SGDDashboardHeaderMenu from "./Modules/Dashboard/DashboardHeaderMenu/SGDDashboardHeaderMenu";
import SGDDashboardSubHeaderMenu from './Modules/Dashboard/SGDDashboardSubHeaderMenu';
import useAppBackgroundSetup from "./Config/Hooks/AppBackgroundSetupHook";
import useAppLayoutSetup from "./Config/Hooks/AppLayoutSetupHook";
import AppRoutes from "./Config/AppRoutes";
import {useAppDispatch, useAppSelector} from "./Config/Hooks";
import {Storage} from "./Utils/StorageUtils";
import {logout} from "./Config/Reducers/AuthenticationReducer";
import {useSnackbar} from "notistack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {toggleSubMenu} from "./Config/Reducers/LayoutReducer";
import SGDAppBarPage from "./Shared/Components/Layout/SGDAppBarPage";
import {useTheme} from "@mui/material";
import {WebSocketProvider} from "./Modules/Login/hooks/useWebSocket";

export default function App(props: any) {
    const location = useLocation();
    const appBackgroundSetup = useAppBackgroundSetup();
    const appLayoutSetup = useAppLayoutSetup();
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const AUTH_TOKEN_KEY = 'api-token-key';
    const dispatch = useAppDispatch();
    // Layout Settings
    const openMainMenu = useAppSelector((state: any) => state.layout.openMainMenu);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const contentPagePaddingTop = useAppSelector((state: any) => state.layout.contentPagePaddingTop);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const currentRoute = location.pathname;
    const verifyTokenValidity = (token: string) => {
        try {
            const tokenData = JSON.parse(atob(token.split('.')[1]));
            const expiresIn = tokenData.exp * 1000;
            return expiresIn > Date.now();
        } catch (error) {
            return false;
        }
    }

    const getMainBackGroundColor = (currentRoute: string) => {
        if(currentRoute !== '/' && theme.palette.mode === "light"){
            return "#f7f7f7"
        }
        return ''
    }

    useEffect(() => {
        const tokenCheckInterval = setInterval(() => {
            const token = Storage.local.get(AUTH_TOKEN_KEY);
            if (token) {
                const isTokenValid = verifyTokenValidity(token);
                if (!isTokenValid) {
                    enqueueSnackbar("Session expired!", {variant: 'error'});
                    dispatch(logout());
                }
            }
        }, 10000);
        return () => clearInterval(tokenCheckInterval);
    }, [dispatch, enqueueSnackbar]);

    useEffect(() => {
        const token = Storage.local.get(AUTH_TOKEN_KEY);
        if (token) {
            const isTokenValid = verifyTokenValidity(token);
            if (!isTokenValid) {
                enqueueSnackbar("Session expired!", {variant: 'error'});
                dispatch(logout());
            }
        }
    },[dispatch, enqueueSnackbar]);

    useEffect(() => {
        appLayoutSetup();
    },[appLayoutSetup]);

    useEffect( () => {
        appLayoutSetup();
        appBackgroundSetup();
    },[appBackgroundSetup, appLayoutSetup, location]);

    const [open, setOpen] = React.useState(false);

    useEffect( () => {
        setOpen(openSubMenu);
    },[openSubMenu]);

    const menuToogle = () => {
        dispatch(toggleSubMenu());
    }
    const defaultProps = {
        borderColor: 'text.primary',
        borderRadius: 5
    };

    const isSmallScreen = window.innerWidth <= 400;
    const isMedianScreen = window.innerWidth <= 495;

    return (
        <WebSocketProvider>
            <Box id="back-to-top-anchor"
                 sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     backgroundColor: currentRoute !== '/' ? theme.palette.background.default : '',
                     minHeight: currentRoute !== '/' ? '100vh' : '',
                     paddingTop: 10,

                 }}
            >
                <CssBaseline/>

                {/*Menu Settings*/}
                <SGDAppBarPage handleThemeMode={props.handleThemeMode}/>
                <SGDSubMenu/>
                <SGDMainMenu/>
                <SGDDashboardHeaderMenu/>
                <SGDDashboardSubHeaderMenu/>
                {isAuthenticated && openMainMenu &&
                    <Box
                        position="fixed"
                        top={107}
                        left={open ? 380 : 65}
                        zIndex="modal"
                        sx={{
                            transition: "transform 0.2s linear",
                        }}
                        {...defaultProps}
                        style={{alignItems: "center", justifyContent: 'center', width: 35, height: 35}}
                    >
                        <IconButton
                            aria-label="open drawer"
                            title={open ? "Recolher" : "Expandir"}
                            onClick={menuToogle}
                            sx={{
                                backgroundColor: "#051F36",
                                alignItems: "center",
                                justifyContent: 'center',
                                transform: 'scale(0.5)',
                                opacity: 0.7,
                                color: "#FFFFFF",
                                '&:hover': {
                                    opacity: 1,
                                    backgroundColor: "#051F36",
                                    transform: 'scale(0.7)',
                                    transition: "all 0.2s linear"
                                }
                            }}
                        >
                            {open ?
                                <ChevronLeftIcon/>
                                :
                                <ChevronRightIcon/>

                            }
                        </IconButton>
                    </Box>
                }

                <CssBaseline/>

                <Box component="main"
                     style={{
                         backgroundColor: getMainBackGroundColor(currentRoute),
                         padding: 18,
                         paddingTop: isSmallScreen ? 96 : isMedianScreen ? 84 : contentPagePaddingTop,
                         height: '1000px !important',
                     }}
                     sx={{
                         flex: 1,
                         marginLeft: {
                             xs: "initial", md: isAuthenticated ? (
                                 openSubMenu ? (
                                     openMainMenu ? `${400}px` : `${80}px`
                                 ) : (
                                     openMainMenu ? `${80}px` : `${0}` // Set margin to 50 if submenu closed and main menu open
                                 )
                             ) : 'initial'
                         },
                     }}
                >

                    {/*App Routes*/}
                    <AppRoutes/>

                    {/*how to get what route*/}
                    <ScrollTop {...props} >
                        <Fab size="small" aria-label="scroll back to top">
                            <KeyboardArrowUpIcon/>
                        </Fab>
                    </ScrollTop>

                </Box>
            </Box>
        </WebSocketProvider>
    );
}
