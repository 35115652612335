import Box from "@mui/material/Box";
import {
    Button, Checkbox, FormControl,
    Grid, OutlinedInput,
    Paper, Select,
    Typography
} from "@mui/material";
import {TeleCommandMainMenu} from "./TeleCommandMainMenu";
import React, {useCallback, useEffect, useState} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {TeleCommandReactQuery} from "./types/reactQuery/TeleCommandPageReactQuery";
import {ADataGrid, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {extractFiltersFromColumns} from "../../Utils/DataUitils";
import {columnsDataGridSendingTeleCommands} from "../Settings/GraficElement/columnsDataGrid";
import Tooltip from "@mui/material/Tooltip";
import {ArrowForward} from "@mui/icons-material";
import {DashBoardNavigationAnalysisSelectedId} from "../Analysis/types/reducer/DashboardNavigationAnalysisMenuState";
import {CommandDTO, ModalForSendingTelecommand} from "../../Shared/Components/ModalForSendingTelecommand";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {findAllBrokers} from "../../Utils/CommandService"
import {BrokerDTO, ModalIntensiveMonitoring} from "./ModalIntensiveMonitoring";


export interface TeleCommandRow {
    id: number;
    powerStationName: string;
    skidName: string;
    equipmentName: string;
    teleObjectName: string;
    point: number;
    teleObjectSpecType: number;
    etsCode: number;
    teleObjectState: number | null;
    userId: number;
    brokerId: number;
    actionsConfig?: any;
    commandValueList: Array<any>;
}

export interface Broker {
    id: number;
    powerStationName: string;
}

export const TelecommandPage = () => {
    type SelectedRowIDs = {
        powerStationIds: number[];
        skidIds: (number | null)[];
        teleObjectIds: (number | null)[];
        equipmentsIds: number[];
    };
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsDataGridSendingTeleCommands), 0, 100));
    const [totalOfRecords, setTotalOfRecords] = useState(0);

    const [isClear, setIsClear] = useState<boolean>(false);
    const [selectedIdsOfReportTeleObjectMenu, setSelectedIdsOfReportTeleObjectMenu] = useState<DashBoardNavigationAnalysisSelectedId>({
        selectedGenPowerStationIdList: [],
        selectedGenSkidIdList: [],
        selectedGenEquipmentIdList: [],
        selectedToEquipmentIdList: []
    });
    const [rows, setRows] = useState<Array<TeleCommandRow>>([]);

    const [open, setOpen] = useState(false);
    const [openIntensive, setOpenIntensive] = useState(false);
    const [selectedRow, setSelectedRow] = useState<TeleCommandRow | null>(null);
    const [commandDTO, setCommandDTO] = useState<CommandDTO>();
    const [brokerList, setBrokerList] = useState<any[]>([])

    const { data, isSuccess } = TeleCommandReactQuery(selectedIdsOfReportTeleObjectMenu?.selectedToEquipmentIdList, pagedSearchParams);

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        findAllBrokers().then(
            (response:any) => {
                if (response.status === 204) {
                    setBrokerList([])
                } else {
                    const result:any[] = response.data.data.map((broker: any) => {
                        return {
                            id: broker.id,
                            powerStationName: broker.powerStationName,
                            checked: false,
                            userId: broker.userId
                        }
                    })
                    setBrokerList(result)
                }
            }
        ).catch((error:any) => {
            console.error("Error fetching tele commands:", error);
        })
    }, []);

    useEffect(() => {
        if(isSuccess) {
            setTotalOfRecords(data?.totalOfRecords);
            if(data?.data) {
                let rowsTeleCommands = data.data.map((teleCommand: any) => {
                    return {
                        id: teleCommand.id,
                        powerStationName: teleCommand.powerStationName,
                        skidName: teleCommand.skidName,
                        equipmentName: teleCommand.equipmentName,
                        teleObjectName: teleCommand.teleObjectName,
                        point: teleCommand.point,
                        teleObjectSpecType: teleCommand.teleObjectSpecType,
                        etsCode: teleCommand.etsCode,
                        teleObjectState: null,
                        userId: teleCommand.userId,
                        brokerId: teleCommand.brokerId,
                        commandValueList: teleCommand.commandValueList,
                        actionsConfig: [
                            {
                                actionName: 'send',
                                disabled: false
                            }
                        ]
                    }
                });
                setRows(rowsTeleCommands);
            }
        }
    }, [data?.data]);




    const handleOnTelecommandRow = (row: any) => {
        console.log("row: ", row)
    }


    const handleSelectedIdsChange = (selectedIds:any) => {
        setIsClear(true);
        setSelectedIdsOfReportTeleObjectMenu(selectedIds);
    };

    const handleNodeClick = (row: any) => {
        setSelectedRow(row)
        setOpen(true);
    }


    const handleCloseModal = () => {
        setOpen(false);
        setSelectedRow(null);
    };

    const handleCloseIntensiveModal = () => {
        setOpenIntensive(false);
    };

    useEffect(() => {
        if(selectedRow != null){
            setCommandDTO({
                id: selectedRow?.id,
                teleObjectName: selectedRow?.teleObjectName,
                userId: selectedRow?.userId,
                point: selectedRow?.point,
                teleObjectSpecType: selectedRow?.teleObjectSpecType,
                etsCode: selectedRow?.etsCode,
                brokerId: selectedRow?.brokerId,
                commandValues: selectedRow?.commandValueList
            })
        }
    }, [selectedRow]);

    const [brokerNameList, setBrokerNameList] = React.useState<string[]>([]);
    const [brokerListDTO, setBrokerListDTO] = useState<BrokerDTO[]>([])

    useEffect(() => {
        if(openIntensive && brokerList.length > 0){
            setBrokerListDTO(brokerList.filter(item => item.checked === true).map(broker => {
                return {
                    userId: broker.userId,
                    brokerId: broker.id,
                    powerStationName: broker.powerStationName
                }
            }))
        }
    }, [openIntensive]);

    const handleChange = (event: any, index:number) => {
        let updatedChecked = [...brokerList];
        updatedChecked[index].checked = event.target.checked;
        setBrokerList(updatedChecked)
        setBrokerNameList(
            updatedChecked.filter(broker => broker.checked === true).map((item) => item.powerStationName)
        )

    };

    const toOpen = () => {
        return brokerList.filter(broker => broker.checked).length > 0
    }

    return (
            <Paper elevation={1} style={{marginTop: - 56}}>
                <Box p={2} style={{ minHeight: '200vh' }}>
                    <Typography variant="h5" component="h1">Envio de Telecomandos</Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box sx={{ paddingLeft: 1, paddingRight: 1, display: 'flex', flexDirection: 'row', gap: 2,  alignItems: 'flex-end' }}>
                                <FormControl sx={{width: '65%'}}>
                                    <InputLabel sx={{
                                        '&.MuiInputLabel-root': {
                                            top: '-6px',            // Ajusta a posição inicial da label
                                            textAlign: 'left',
                                            width: '100%',
                                        }
                                    }}  id="demo-multiple-checkbox-label">Monitoramento Intensivo</InputLabel>
                                    <Select
                                        sx={{height: '45px'}}
                                        fullWidth
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={brokerNameList}
                                        input={<OutlinedInput label="Ativar monitoramento intensivo" />}
                                        renderValue={(selected) => selected.join(', ')}

                                    >
                                        {brokerList != null && brokerList.length > 0 && brokerList.map((broker, index) => (
                                            <MenuItem key={broker.id} value={broker.id}>
                                                <Checkbox onChange={(e) => handleChange(e,index)} checked={broker.checked} />
                                                <ListItemText primary={broker.powerStationName} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    sx={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        height: '45px',
                                        whiteSpace: 'nowrap',
                                        width: {
                                            xs: '20%',
                                            sm: 'auto',
                                        },
                                        minWidth: 60,
                                        textTransform: 'capitalize'
                                    }}
                                    onClick={() => setOpenIntensive(toOpen)}
                                >
                                    Enviar
                                </Button>
                            </Box>
                            <Box style={{width: '85%', maxWidth: 360, paddingLeft: 2, paddingRight: 2, paddingTop: 4}}>
                                <TeleCommandMainMenu onSelectedIdsChange={handleSelectedIdsChange}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8} lg={9} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Box style={{ width: '100%', height: '100%' }}>
                                <ADataGrid
                                    hideFilters={false}
                                    showFilterOptions={false}
                                    hideSelection={true}
                                    size={"small"}
                                    columns={columnsDataGridSendingTeleCommands}
                                    rows={rows}
                                    page={pagedSearchParams.page}
                                    onFiltersChange={handleOnFiltersChange}
                                    onRowSelect={handleOnTelecommandRow}
                                    loading={false}
                                    totalOfRecords={totalOfRecords}
                                    rowsPerPage={pagedSearchParams.size}
                                    actionColumnWidth={50}
                                    onClearSelectedTempRows={isClear}
                                    maxHeight={600}
                                    disableActionsHeaderIcons={true}
                                    actions={
                                        <>
                                            <Button
                                                name="send"
                                                variant="contained"
                                                onClick={handleNodeClick}
                                            >
                                                <Tooltip title="Enviar">
                                                    <ArrowForward/>
                                                </Tooltip>
                                            </Button>
                                            <></>
                                        </>
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {commandDTO != null && <ModalForSendingTelecommand commandDTO={commandDTO} open={open} onCloseModal={handleCloseModal}/>}
                {brokerListDTO != null && brokerListDTO.length > 0 && <ModalIntensiveMonitoring brokerList={brokerListDTO} open={openIntensive} onCloseModal={handleCloseIntensiveModal}/>}
            </Paper>
    );
}


export default function TeleCommandHomePage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <TelecommandPage />
        </QueryClientProvider>
    )
}



