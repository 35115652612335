import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lightThemeConfig, darkThemeConfig } from './Shared/Theme';
import App from './App';

const RootComponent = () => {
    const storedTheme = localStorage.getItem("theme") as "light" | "dark" || "light";
    const [themeMode, setThemeMode] = useState<'light' | 'dark'>(storedTheme);

    const lightTheme = createTheme(lightThemeConfig);
    const darkTheme = createTheme(darkThemeConfig);

    const handleThemeMode = () => {
        setThemeMode((prevMode) => {
            const newMode = prevMode === 'light' ? 'dark' : 'light';
            localStorage.setItem("theme", newMode);
            return newMode;
        });
    };

    console.log(darkTheme)
    return (
        <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
            <App handleThemeMode={handleThemeMode} />
        </ThemeProvider>
    );
};

export default RootComponent;