import {Box,Tooltip, useTheme} from "@mui/material";
import {deleteEntity, searchEntities} from './ProfilesReducer';
import React, {useCallback, useEffect, useState} from "react";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {searchEntitiesData} from "./AuthorityReducer";
import {clearCountryMessages} from "../Country/CountryReducer";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {Delete, Edit} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { hasPermission } from "../../../Shared/Auth/AuthenticationUtil";
import { AUTHORITIES } from "../../../Config/Constants";
import {HeaderMenu} from "../HeaderMenu";



const columns = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        minWidth: 100,
        visibility: false,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'name',
        label: 'Nome',
        align: 'left',
        minWidth: 100,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
] as ADataGridColumn [];


export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowProfile {
    id: number;
    name: string;
    actionsConfig?: Array<ActionConfig>
}

export default function ProfilesSettingsPage(){
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [authorityGroupId, setAuthorityGroupId] = useState<any>(null);
    const [openDialog, setOpenDialog] = useState(false);

    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSaveProfile = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_PROFILE
    ]);
    const isAuthorizedToDeleteProfile = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DELETE_PROFILE
    ]);
    const isLoading = useAppSelector((state : RootState) => state.profile.loading);
    const errorMessage = useAppSelector((state : RootState) => state.profile.errorMessage);
    const successMessage = useAppSelector((state : RootState) => state.profile.successMessage);
    const entities = useAppSelector((state : RootState) => state.profile.entities);
    const totalOfRecords = useAppSelector((state : RootState) => state.profile.totalOfRecords);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columns), 0, 100));

    const theme = useTheme();

    const [rows, setRows] = useState<Array<RowProfile>>([]);

    useEffect(() => {
        let newRows = entities.map(et => {return {id: et.id, name: et.name, actionsConfig: [
                {actionName: "edit", disabled: !isAuthorizedToSaveProfile},
                {actionName: "delete", disabled: !isAuthorizedToDeleteProfile},
            ]}});
            setRows(newRows)
        }, [entities]);


    const handleEditProfile = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_PROFILE_FORM, {state: {id: data.id}});
    },[navigate]);

    const deleteItemAction = useCallback((data: any) => {
        console.log("data: ", data)
        setAuthorityGroupId(data.id);
        setOpenDialog(true);
    },[]);

    const toggleDialog = () => {
        setOpenDialog(!openDialog);
    }

    const confirmDialog = async () => {
        try {
            await dispatch(deleteEntity(authorityGroupId)).unwrap();

            await dispatch(searchEntities(pagedSearchParams));

            enqueueSnackbar("Perfil deletado com sucesso!", {
                variant: 'success',
            });
        } catch (error) {
            enqueueSnackbar("O Perfil não pode ser deletado porque já está em uso.", {
                variant: 'error',
            });
        } finally {
            toggleDialog();
        }
    };

    const closeDialog = () => {
        //TODO
        // @ts-ignore
        setAuthorityGroupId({});
        toggleDialog();
    }

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams :PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    },[]);

    const handleOnRowSelect = useCallback((rows : any) => {
    },[]);

    useEffect(() => {

        dispatch(searchEntitiesData(pagedSearchParams));
        if (!isLoading) {
            dispatch(searchEntities(pagedSearchParams));

        }
    },[dispatch, pagedSearchParams]);

    // On updateSuccess change
    useEffect(() => {
        if (successMessage) {
            enqueueSnackbar(successMessage, {
                variant: 'success',
            });
            dispatch(clearCountryMessages());
        }
        if(errorMessage) {
            enqueueSnackbar(errorMessage, {
                variant: 'error',
            });
            dispatch(clearCountryMessages());
        }
    }, [errorMessage, successMessage]);

    return (
        <>
            {/*<AppBar position="fixed" style={{top: 64}}>*/}
            {/*    <Toolbar style={{backgroundColor: theme.palette.background.default, boxShadow: '0px 3px 6px #00000029'}}>*/}
            {/*        <div style={{marginLeft: '22%'}}>*/}
            {/*            <Button*/}
            {/*                size={"small"}*/}
            {/*                variant="contained"*/}
            {/*                component={Link}*/}
            {/*                disabled={!isAuthorizedToSaveProfile}*/}
            {/*                to={SystemRoutes.SETTINGS_PROFILE_FORM}*/}
            {/*                endIcon={<AddCircle/>}*/}
            {/*            >*/}
            {/*                Novo*/}
            {/*            </Button>*/}
            {/*        </div>*/}
            {/*    </Toolbar>*/}
            {/*</AppBar>*/}
            <HeaderMenu systemRoutes={SystemRoutes.SETTINGS_PROFILE_FORM} disableSave={!isAuthorizedToSaveProfile}/>
            <Box>
                <ACard>
                    <ADataGrid
                        hideFilters={false}
                        hideSelection={false}
                        columns={columns}
                        rows={rows}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        page={pagedSearchParams.page}
                        rowsPerPage={pagedSearchParams.size}
                        onFiltersChange={handleOnFiltersChange}
                        onRowSelect={handleOnRowSelect}
                        actions={
                            <>
                                <IconButton name="edit" size={"small"} aria-label="Edit"
                                            onClick={(value) => handleEditProfile(value)} disabled={!isAuthorizedToSaveProfile}>
                                    <Tooltip title="Editar">
                                        <Edit/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton name="delete" size={"small"} aria-label="Delete"
                                            onClick={(value) => deleteItemAction(value)} disabled={!isAuthorizedToDeleteProfile}>
                                    <Tooltip title="Excluir">
                                        <Delete/>
                                    </Tooltip>
                                </IconButton>
                            </>
                        }
                    />
                </ACard>
                    <ConfirmDialog
                        title={'Delete confirmation'}
                        description={'Você tem certeza que deseja excluir esse perfil?'}
                        open={openDialog}
                        handleConfirm={confirmDialog}
                        handleClose={closeDialog}
                    />
                </Box>
        </>
    );
}