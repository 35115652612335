import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {ACard} from "@atiautomacao/ati-ui-library";
import {Container, FormHelperText, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import {FindModBusObjectTypeByIdReactQuery, SaveModBusObjectTypeReactQuery} from "./ModBusObjectsTypesReactQuery";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {HeaderMenu} from "../HeaderMenu";

export const ModBusObjectTypeFormPage = () => {
    const location = useLocation();
    const id = location.state?.id as string;
    const [modBusObjectName, setModBusObjectName] = useState<string>("");
    const {data, isSuccess} = SaveModBusObjectTypeReactQuery();

    const mutationSave = SaveModBusObjectTypeReactQuery();
    const mutationFindById = FindModBusObjectTypeByIdReactQuery();
    const navigate = useNavigate();
    function handleSave() {
        if(modBusObjectName === ""){
            return;
        }
        const objectToSave = id ? {id: Number(id), name: modBusObjectName} : {name: modBusObjectName};
        mutationSave.mutate(objectToSave, {
            onSuccess: (data) => {
                navigate(SystemRoutes.SETTINGS_MOD_BUS_OBJECTS_TYPES);
            }
        });
        console.log(modBusObjectName);
    }

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    setModBusObjectName(data?.data.name);
                }
            });
        }
    }, []);

    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_MOD_BUS_OBJECTS_TYPES}/>


            <Box>
                <ACard>
                    <Container style={{marginLeft: -10, display: 'flex', flexDirection: "row", alignItems: 'left', justifyContent: 'left', columnGap: 30}}>

                        <Box style={{marginBottom: 20}}>
                            <InputLabel id="object-name">Nome</InputLabel>
                            <TextField
                                error={modBusObjectName === ""}
                                fullWidth
                                id="object-name"
                                variant="standard"
                                placeholder={"Nome do tipo de objeto"}
                                onChange={(value) => setModBusObjectName(value.target.value)}
                                value={modBusObjectName}
                            />
                            {
                                modBusObjectName === "" &&
                                <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                            }
                        </Box>
                    </Container>
                </ACard>
            </Box>


        </>

    )
}

export default function ModBusObjectsTypesPageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ModBusObjectTypeFormPage />
        </QueryClientProvider>
    )
}
