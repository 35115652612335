import {Box, Tooltip, useTheme} from "@mui/material";
import {
    ACard,
    ADataGrid,
    ADataGridColumn,
    ADataGridFilter,
    PagedSearchParams
} from "@atiautomacao/ati-ui-library";
import IconButton from "@mui/material/IconButton";
import {DeleteEquipmentClassReactQuery, EquipmentClassReactQuery} from "./EquipmentClassReactQuery";
import {QueryClient, QueryClientProvider} from "react-query";
import React, {useCallback, useEffect, useState} from "react";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {Edit, Delete} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import {HeaderMenu} from "../HeaderMenu";
import {useAppSelector} from "../../../Config/Hooks";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";

export const initColumns = [
    {
        name: 'equipmentType',
        label: 'Tipo de Equipamento',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'name',
        label: 'Nome',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'description',
        label: 'Descrição',
        align: 'right',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    }
] as ADataGridColumn[]

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowEquipmentClass {
    equipmentType: string;
    name: string;
    description: string;
    actionsConfig?: Array<ActionConfig>
}

export const EquipmentClassSettingsPage = () => {
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 100));
    const { data, isSuccess } = EquipmentClassReactQuery(pagedSearchParams);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [rows, setRows] = useState<Array<RowEquipmentClass>>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.SAVE_EQUIPMENT_CLASS
    ]);

    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.DELETE_EQUIPMENT_CLASS
    ]);

    const navigate = useNavigate();
    const mutation = DeleteEquipmentClassReactQuery(pagedSearchParams);

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        console.log(newPageParams);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        if(isSuccess){
            setTotalOfRecords(data?.totalOfRecords);
            if(data?.data){
                let rowsEquipment = data.data.map((equipmentClass: any) => {
                    return {
                        id: equipmentClass.id,
                        equipmentType: equipmentClass.equipmentType.name,
                        name: equipmentClass.name,
                        description: equipmentClass.description,
                        actionsConfig: [
                            {actionName: "edit", disabled: !isAuthorizedToSave},
                            {actionName: "delete", disabled: !isAuthorizedToDelete},
                        ]
                    }
                });

                setRows(rowsEquipment);
            }
        }
    }, [data?.data]);

    const handleEditEquipmentClass = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_EQUIPMENT_CLASS_FORM, { state: { id: data.id } });
    },[navigate]);

    const handleDeleteEquipmentClass = useCallback((data: any) => {
        setSelectedToDelete(data.id);
        setOpenDialog(true);
    },[]);

    const handleDialogConfirm = async () => {
        mutation.mutate({ id: selectedToDelete, pagedSearchParams });
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }
    const theme = useTheme();
    return(
        <>
            <HeaderMenu systemRoutes={SystemRoutes.SETTINGS_EQUIPMENT_CLASS_FORM} disableSave={!isAuthorizedToSave}/>

            <ACard>
                <ADataGrid
                    columns={initColumns}
                    rows={rows}
                    page={pagedSearchParams.page}
                    rowsPerPage={pagedSearchParams.size}
                    loading={false}
                    totalOfRecords={totalOfRecords}
                    hideSelection={true}
                    onFiltersChange={handleOnFiltersChange}
                    actions={
                        <>
                            <IconButton name="edit" size={"small"} aria-label="Edit" disabled={!isAuthorizedToSave} onClick={(value) => handleEditEquipmentClass(value)}>
                                <Tooltip title="Editar">
                                    <Edit/>
                                </Tooltip>
                            </IconButton>
                            <IconButton name="delete" size={"small"} aria-label="Delete" disabled={!isAuthorizedToDelete} onClick={(value) => handleDeleteEquipmentClass(value)}>
                                <Tooltip title="Excluir">
                                    <Delete />
                                </Tooltip>
                            </IconButton>
                        </>
                    }
                />
            </ACard>
            <ConfirmDialog
                title={'Deletar'}
                description={'Deseja deletar essa classe de equipamento?'}
                open={openDialog}
                handleConfirm={handleDialogConfirm}
                handleClose={handleDialogClose}
            />
        </>
    );
}

export default function EquipmentClassHomePage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <EquipmentClassSettingsPage />
        </QueryClientProvider>
    )
}
