import React, {CSSProperties, MouseEvent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {RootState} from "../../../Config/Store";
import {getEntities} from "./DashboardOverviewMapPageReducer";
import {getEntitiesWithoutParams, Performance} from "../PerformanceAnalysis/PerformanceAnalysisReducer";
import {Maps} from "google-map-react";
import GoogleMap from "./types/GoogleMap";
import {Grid, Link, Paper, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import {formatData, FormattedData} from "./types/FormattedData";
import {getIconColor} from "../../../Shared/Components/Layout/menu/helpers/getIconColor";
import {LocationOn} from "@mui/icons-material";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import './stylesMap.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MenuItemStatus} from "../../../Shared/Components/Layout/menu/types/MenuItemStatus";
import {usePowerStationNavigationToPlant} from "../../../Shared/Hooks/usePowerStationNavigationToPlant";
import CloseIcon from "@mui/icons-material/Close";
import {usePowerStationNavigationToAlarmsPage} from "../../../Shared/Hooks/usePowerStationNavigationToAlarmsPage";
import useInterval from "../../../Shared/Hooks/useInterval";


export interface MarkerPowerStation extends Performance {
    powerStationId: number;
    powerStationName: string;
    status: MenuItemStatus;
    powerCapacityNominal: number;
    generatedEnergySum: number;
    availability: number;
    performanceRatio: number;
    instantPower: number;
}

export interface MarkerPlace {
    id: number;
    name: string;
    lat: number;
    lng: number;
    status: MenuItemStatus;
    alarmMostRecently: string;
}

const InfoWindow = ({ place, entities, onClose }: { place: MarkerPlace, entities: MarkerPowerStation, onClose: (event: React.MouseEvent) => void  }) => {
    const theme = useTheme();
    const navigationToPowerStationAlarm = usePowerStationNavigationToAlarmsPage()
    const navigationToPlantByPowerStationId = usePowerStationNavigationToPlant();
    const handleNavigationPowerPlant = (event: MouseEvent<HTMLElement>, powerPlantId: number): void => {
        event.preventDefault();
        navigationToPowerStationAlarm(powerPlantId);
    };

    const handleNavigationToPlant = (event: MouseEvent<HTMLElement>, powerPlantId: number): void => {
        event.preventDefault();
        navigationToPlantByPowerStationId(powerPlantId);
    };

    useEffect(() => {
        document.documentElement.style.setProperty("--background-color", theme.palette.background.default);
    }, [theme]);

    const closeButtonStyle: CSSProperties = {
        position: "absolute",
        top: "-6px",
        right: "-6px",
        cursor: "pointer",
        padding: "6px",
        color: theme.palette.mode === 'light' ? 'black' : '#fff'
    };

    const imageStyle: CSSProperties = {
        width: "100%",
        height: "auto",
        objectFit: "cover",
        border: "1px solid black",
    };

    const contentStyle: CSSProperties = {
        backgroundColor: theme.palette.mode === 'light' ? '' : '#1E1E1E',
        textAlign: "left",
        padding: "10px",
    };

    const formattedData: FormattedData = formatData(entities);

    return (
        <div className="containerStyle">
            <img
                src="https://img.olhardigital.com.br/wp-content/uploads/2017/03/20170320124419.jpg"
                alt="Imagem da Usina"
                style={imageStyle}
            />
            <div>
                <CloseIcon
                    style={closeButtonStyle}
                    onClick={onClose}
                />
            </div>
            <div style={contentStyle}>
                <h1>{place.name}</h1>
                <p>Status: {entities.status}</p>
                <p>Último Alarme: {place.alarmMostRecently ? place.alarmMostRecently : "Não possui nenhum"}</p>
                <p>Capacidade Instalada: {formattedData.powerCapacityNominal}</p>
                <p>Potência Instantânea: {formattedData.instantPower}</p>
                <p>Energia Gerada: {formattedData.generatedEnergy}</p>
                <p>Performance Ratio: {formattedData.performanceRatio}</p>
                <p>Disponibilidade: {formattedData.availability}</p>
                <Link href="#" onClick={(event) => handleNavigationPowerPlant(event, place.id)} underline="hover">Tabela
                    de Alarmes</Link>
                <br/>
                <br/>
                <Link href="#" onClick={(event) => handleNavigationToPlant(event, place.id)}
                      underline="hover">Unifilar</Link>
            </div>
        </div>
    );
};

export const Marker = ({place, lat, lng, entities, isOpen, onMarkerClick}: {
    place: MarkerPlace;
    lat: number;
    lng: number;
    entities: MarkerPowerStation;
    isOpen: boolean;
    onMarkerClick: () => void;
}) => {

    const handleMarkerClick = () => {
        onMarkerClick();
    };

    const handleCloseInfoWindow = (event: React.MouseEvent) => {
        event.stopPropagation();
        onMarkerClick();
    };
    const userConfig = useAppSelector((state: RootState) => state.userConfigurarion);



    const markerStyle: CSSProperties = {
        cursor: 'pointer',
        width: "26px",
        height: "40px",
        borderRadius: "50%",
        color: `${getIconColor(place.status)}`,
        filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.7))',
        transition: 'border 0.3s',
    };

    return (
        <>
            <FontAwesomeIcon icon={faLocationDot}
                             onClick={handleMarkerClick}
                             style={markerStyle}
            />
            {isOpen && (
                <InfoWindow place={place} entities={entities} onClose={handleCloseInfoWindow} />
            )}
        </>
    );
};

const DashboardOverViewMapPage: React.FC = () => {
    const [places, setPlaces] = useState<any[]>([]);
    const informationsMapPowerStations = useAppSelector(
        (state: RootState) => state.dashboardMapPage
    );
    const [openMarkerId, setOpenMarkerId] = useState<number | null>(null);
    const entities: Array<Performance> = useAppSelector((state: RootState) => state.performanceAnalysis.entities);
    const userConfig = useAppSelector((state: RootState) => state.userConfigurarion);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getEntities());
        dispatch(getEntitiesWithoutParams());
    }, [dispatch]);

    useInterval(() => {
        dispatch(getEntities());
        dispatch(getEntitiesWithoutParams());
    }, userConfig.mainUpdateInterval); //5 minutes

    useEffect(() => {
        const placesData = informationsMapPowerStations.entities.map((powerStation) => {
            return {
                id: powerStation.powerStationId,
                name: powerStation.powerStationName,
                latitude: powerStation.latitude,
                longitude: powerStation.longitude,
                status: powerStation.etsStatus,
                alarmMostRecently: powerStation.alarmMostRecently,
            };
        });
        setPlaces(placesData);
    }, [informationsMapPowerStations]);


    const mapCenter = {lat: -18.5779679, lng: -45.4514553};

    const mapOptions = {
        zoom: 7,
    };

    const handleMarkerClick = (markerId: number) => {
        setOpenMarkerId(openMarkerId === markerId ? null : markerId);
    };

    const getMapOptions = (maps: Maps) => {
        return {
            mapTypeControlOptions: {
                position: maps.ControlPosition.BOTTOM_CENTER,
            },
            mapTypeId: maps.MapTypeId.ROADMAP,
        };
    };


    return (
        <Paper elevation={3}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{height: '1200px', width: '100%'}}>
                        <GoogleMap
                            defaultZoom={mapOptions.zoom}
                            defaultCenter={mapCenter}
                            options={getMapOptions}
                            bootstrapURLKeys={{
                                key: "AIzaSyBIqWy3JkxVNjorFXL1qe20Ylh_0NVQoZc",
                            }}
                            yesIWantToUseGoogleMapApiInternals

                        >
                            {places.map((marker) => {
                                const entity = entities.find((item) => item.powerStationId === marker.id);
                                if (entity) {
                                    return (
                                        <Marker
                                            key={marker.id}
                                            lat={marker.latitude}
                                            lng={marker.longitude}
                                            place={marker}
                                            entities={entity}
                                            isOpen={openMarkerId === marker.id} // Passa se a janela de informações do marcador está aberta
                                            onMarkerClick={() => handleMarkerClick(marker.id)} // Passa o manipulador para lidar com o clique no marcador
                                        />
                                    );
                                }
                                return null;
                            })}
                        </GoogleMap>
                        <LocationOn/>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DashboardOverViewMapPage;