import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {ACard} from "@atiautomacao/ati-ui-library";
import {FindNotificationGroupReactQuery, FindUsersByIdReactQuery, SaveUsersReactQuery} from "./UsersReactQuery";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {
    Button, Checkbox,
    Container, FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem, Modal, OutlinedInput, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import TelegramIcon from '@mui/icons-material/Telegram';
import ListItemText from "@mui/material/ListItemText";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {getAllEntities, ProfileEntity} from "../Profiles/ProfilesReducer";
import {RootState} from "../../../Config/Store";
import "./index.css";
import {useSnackbar} from "notistack";
import {postToNotificationEmail} from "../../Reports/ManagementReport/utils/postAndDownloadFile";
import {LoadingButton} from "@mui/lab";
import {HeaderMenu} from "../HeaderMenu";

export interface Group {
    authorityGroup?: {
        name?: string;
    };
}

export interface Notification {
    notificationGroup?: {
        name?: string;
    };
}

export const UsersFormPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const id = location.state?.id as string;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const [name, setName] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [telegramNotificationNumber, setTelegramNotificationNumber] = useState<string | null>(null);
    const [whatsappNotificationNumber, setWhatsappNotificationNumber] = useState<string | null>(null);
    const [emailNotification, setEmailNotification] = useState<string | null>(null);
    const [telegramNotificationEnabled, setTelegramNotificationEnabled] = useState<boolean>(false);
    const [whatsappNotificationEnabled, setWhatsappNotificationEnabled] = useState<boolean>(false);
    const [emailNotificationEnabled, setEmailNotificationEnabled] = useState<boolean>(false);
    const [checkedAllProfiles, setCheckedAllProfiles] = useState<boolean>(false);
    const [checkedProfiles, setCheckedProfiles] = useState<{
        name: string;
        checked: boolean;
        data: { id: number; name: string }
    }[]>([]);

    const [checkedAllNotifications, setCheckedAllNotifications] = useState<boolean>(false);
    const [checkedNotifications, setCheckedNotifications] = useState<{
        name: string;
        checked: boolean;
        data: { id: number; name: string }
    }[]>([]);

    const [openModal, setOpenModal] = useState(false);
    const[userSaved, setUserSaved] = useState<any>(null); //state necessary for user coming from backend
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCloseModal = () => setOpenModal(false);


    const mutationSave = SaveUsersReactQuery();
    const mutationFindById = FindUsersByIdReactQuery();
    const {data: dataNotification} = FindNotificationGroupReactQuery();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const entities = useAppSelector((state: RootState) => state.profile.entities);

    const profilesCheckedOfUser = (entities: any, userGroupList = []) => {
        return entities.map((entity: { name: any; id: any; }) => ({
            name: entity.name ?? '',
            checked: userGroupList.some((group: Group) => group?.authorityGroup?.name === entity.name) || false,
            data: { id: Number(entity.id) || 0, name: entity.name ?? '' }
        }));
    };

    const notificationsCheckedOfUser = (notification: any, notificationGroupList = []) => {
        console.log("notification: ", notification)
        if(notification === undefined) enqueueSnackbar("Verifique se existe algum grupo de notificações vinculado à esse usuário!", { variant: "warning" });
        return notification?.map((entity: { name: any; id: any; }) => ({
            name: entity.name ?? '',
            checked: notificationGroupList.some((ntf: Notification) => ntf?.notificationGroup?.name === entity.name) || false,
            data: { id: Number(entity.id) || 0, name: entity.name ?? '' }
        }));
    };

    useEffect(() => {
        if (id) {
            setIsEditing(true);
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    let user = data?.data;
                    setName(user.name);
                    setUserName(user.userName);
                    setEmail(user.email);
                    setPassword(user.password);
                    setTelegramNotificationNumber(user.telegramNotificationNumber);
                    setWhatsappNotificationNumber(user.whatsappNotificationNumber);
                    setEmailNotification(user.emailNotification);
                    setTelegramNotificationEnabled(user.telegramNotificationEnabled);
                    setWhatsappNotificationEnabled(user.whatsappNotificationEnabled);
                    setEmailNotificationEnabled(user.emailNotificationEnabled);

                    setUserSaved(user)

                    const profiles = profilesCheckedOfUser(entities, user.groupList);
                    setCheckedProfiles(profiles);

                    const notifications = notificationsCheckedOfUser(dataNotification?.data, user?.notificationGroupList);
                    setCheckedNotifications(notifications);
                }
            });
        } else {
            setName("");
            setUserName("");
            setEmail("");
            setPassword("");
            setCheckedAllProfiles(false);
            setCheckedAllNotifications(false);
            if (entities.length > 0) {
                const profiles = entities.map((entity: ProfileEntity) => ({
                    name: entity.name ?? '',
                    checked: false,
                    data: {id: Number(entity.id) || 0, name: entity.name ?? ''}
                }));
                setCheckedProfiles(profiles);
            }
            if (dataNotification && dataNotification.data.length > 0) {
                const notifications = dataNotification.data.map((entity: any) => ({
                    name: entity.name ?? '',
                    checked: false,
                    data: {id: Number(entity.id) || 0, name: entity.name ?? ''}
                }));
                setCheckedNotifications(notifications);
            }
        }
    }, [id, entities, dataNotification]);

    useEffect(() => {
        dispatch(getAllEntities());
    }, [isEditing]);

    function handleSave() {
        const groupListChecked = checkedProfiles.map(checked => {
            if (checked.checked) {
                const entity = entities.find(entity => entity.id === checked.data.id);
                return {
                    id: null,
                    authorityGroup: {
                        id: entity.id,
                        name: entity.name,
                        authorityGroupItemList: entity.authorityGroupItemDTOList.map((item: {
                            id: any;
                            authority: { id: any; name: any; displayName: any; domain: any; };
                        }) => ({
                            id: item.id,
                            authorityGroup: null,
                            authority: {
                                id: item?.authority?.id ?? null,
                                name: item?.authority?.name,
                                displayName: item?.authority?.displayName,
                                domain: item?.authority?.domain
                            }
                        }))
                    }
                };
            }
            return null;
        }).filter(item => item !== null);

        const notificationGroupList = checkedNotifications.map(checked => {
            if (checked.checked) {
                const entity = dataNotification.data.find((entity: any) => entity.id === checked.data.id);
                return {
                    id: null,
                    name: entity.name,
                    notificationGroup: entity,
                    enabled: false
                };
            }
            return null;
        }).filter(item => item !== null);

        let objectToSave = {
            id: id ? Number(id) : null,
            name: name,
            userName: userName,
            email: email,
            password: password,
            groupList: groupListChecked,
            notificationGroupList: notificationGroupList,
            telegramNotificationNumber: telegramNotificationNumber,
            whatsappNotificationNumber: whatsappNotificationNumber,
            emailNotification: emailNotification,
            telegramNotificationEnabled: telegramNotificationEnabled,
            whatsappNotificationEnabled: whatsappNotificationEnabled,
            emailNotificationEnabled: emailNotificationEnabled
        };
        if((groupListChecked != null && groupListChecked.length === 0) || (groupListChecked === null)){
            enqueueSnackbar("Selecione pelo menos uma permissão de acesso!", { variant: "warning" });
            return
        }
        mutationSave.mutate(objectToSave, {
            onSuccess: () => {
                navigate(SystemRoutes.SETTINGS_USERS);
            }
        });
    }

    const handleChangeAllProfilesCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedProfiles(checkedProfiles.map(item => {
            return {...item, checked: event.target.checked};
        }));
        setCheckedAllProfiles(event.target.checked);
    };

    const handleChangeAllNotifications = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedNotifications(checkedNotifications.map(item => {
            return {...item, checked: event.target.checked};
        }));
        setCheckedAllNotifications(event.target.checked);
    };

    const handleChangeProfileCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedCheckedProfiles = [...checkedProfiles];
        updatedCheckedProfiles[index].checked = event.target.checked;
        setCheckedProfiles(updatedCheckedProfiles);
        setCheckedAllProfiles(updatedCheckedProfiles.every(item => item.checked));
    };

    const handleChangeNotificationCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedCheckedNotifications = [...checkedNotifications];
        updatedCheckedNotifications[index].checked = event.target.checked;
        setCheckedNotifications(updatedCheckedNotifications);
        setCheckedAllNotifications(updatedCheckedNotifications.every(item => item.checked));
    };


    const handleActivateBot = () => {
        setIsLoading(true); // Ativa o ícone de carregamento
        const data = {
            to: emailNotification,
            message: "<p>Olá,</p>\n" +
                "        <p>Bem-vindo ao nosso sistema de notificações. Para ativar o bot de notificações, siga as instruções abaixo:</p>\n" +
                "\n" +
                "        <!-- Lista ordenada de instruções -->\n" +
                "        <div class=\"instructions\">\n" +
                "            <h2>Instruções de Ativação do Telegram</h2>\n" +
                "            <ol>\n" +
                "                <li>Acesse o bot no Telegram através do link: <a href=\"http://t.me/sgdnotification_bot\">sgdnotification_bot</a></li>\n" +
                "                <li>Clique em \"Start\" para iniciar a conversa com o bot.</li>\n" +
                "                <li>Siga as instruções enviadas pelo bot para concluir a ativação.</li>\n" +
                "            </ol>\n" +
                "        </div>",
            html: true
        };
        postToNotificationEmail('api/notification/mail',
            data,
            () => {
                enqueueSnackbar("Sucesso ao enviar o email de notificação com o link do Bot!", {variant: 'success'})
                setOpenModal(true)
                setIsLoading(false); // Desativa o ícone de carregamento após o sucesso
            },
            (error) => {
                enqueueSnackbar("Talvez o seu e-mail não esteja na nossa base de dados, contate um administrador do sistema!" + error, {variant: 'error'})
                setIsLoading(false); // Desativa o ícone de carregamento após o sucesso
            }
        );
    };



    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_USERS}/>
            <Box>
                <ACard>
                    <Container
                        className="container"
                    >
                        <div style={{width: '80%', display: 'flex', flexDirection: 'column', gap: 20}}>
                            <h4>Dados</h4>
                            <div style={{width: '50%'}}>
                                <div>
                                    <Box style={{marginBottom: 20, height: '50%'}}>
                                        <InputLabel id="user-name">Nome</InputLabel>
                                        <TextField
                                            error={name === ""}
                                            fullWidth
                                            id="user-name"
                                            variant="standard"
                                            placeholder={"Nome do usuário"}
                                            onChange={(value) => setName(value.target.value)}
                                            value={name}
                                        />
                                        {
                                            !isEditing &&
                                            <FormHelperText id="my-helper-text">Campo Obrigatório. Digite sem caracteres
                                                especiais ou acentos</FormHelperText>
                                        }
                                    </Box>
                                </div>
                                <div>
                                    <Box style={{marginBottom: 20, height: '50%'}}>
                                        <InputLabel id="email">Email</InputLabel>
                                        <TextField
                                            error={email === ""}
                                            fullWidth
                                            id="email"
                                            variant="standard"
                                            placeholder={"Email do usuário"}
                                            onChange={(value) => setEmail(value.target.value)}
                                            value={email}
                                        />
                                        {
                                            !isEditing &&
                                            <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                        }
                                    </Box>
                                    <Box style={{marginBottom: 20, height: '50%'}}>
                                        <InputLabel id="password">Senha</InputLabel>
                                        <TextField
                                            error={password === ""}
                                            fullWidth
                                            id="password"
                                            type={showPassword ? "text" : "password"}
                                            variant="standard"
                                            placeholder={"Senha do usuário"}
                                            onChange={(value) => setPassword(value.target.value)}
                                            value={password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {
                                            !isEditing &&
                                            <FormHelperText id="my-helper-text">Campo Obrigatório. A senha precisa ter
                                                letras e
                                                números</FormHelperText>
                                        }
                                        {
                                            isEditing &&
                                            <FormHelperText id="my-helper-text" style={{color: 'red'}}>Caso queira
                                                editar a
                                                senha, lembre-se de alterá-la com letras e números.</FormHelperText>
                                        }
                                    </Box>
                                </div>
                            </div>
                            <Box sx={{width: '100%'}}>
                                <FormControl className="permissoes">
                                    <InputLabel
                                        id="demo-multiple-checkbox-label"
                                    >
                                        Permissões
                                    </InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={checkedProfiles.filter(item => item.checked).map(item => item.name)}
                                        input={<OutlinedInput label="Permissões"/>}
                                        renderValue={(selected) => selected.join(', ')}
                                        variant="standard"
                                        disableUnderline
                                        sx={{
                                            '.MuiOutlinedInput-notchedOutline': {border: 0}
                                        }}
                                    >
                                        <MenuItem value="Todos">
                                            <Checkbox
                                                checked={checkedAllProfiles}
                                                onChange={handleChangeAllProfilesCheckbox}
                                            />
                                            <ListItemText primary="Todos"/>
                                        </MenuItem>
                                        {checkedProfiles?.map((nameObject, index) => (
                                            <MenuItem
                                                key={nameObject.name}
                                                value={nameObject.name}
                                            >
                                                <Checkbox
                                                    checked={nameObject.checked}
                                                    onChange={(e) => handleChangeProfileCheckbox(e, index)}
                                                />
                                                <ListItemText primary={nameObject.name}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl className="permissoes" sx={{marginLeft: 1}}>
                                    <InputLabel
                                        id="demo-multiple-checkbox-label"
                                    >
                                        Notificações
                                    </InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={checkedNotifications?.filter(item => item.checked).map(item => item.name) ?? []}
                                        input={<OutlinedInput label="Notificações"/>}
                                        renderValue={(selected) => selected.join(', ')}
                                        variant="standard"
                                        disableUnderline
                                        sx={{
                                            '.MuiOutlinedInput-notchedOutline': {border: 0}
                                        }}
                                    >
                                        <MenuItem value="Todos">
                                            <Checkbox
                                                checked={checkedAllNotifications}
                                                onChange={handleChangeAllNotifications}
                                            />
                                            <ListItemText primary="Todos"/>
                                        </MenuItem>
                                        {checkedNotifications?.map((nameObject, index) => (
                                            <MenuItem
                                                key={nameObject.name}
                                                value={nameObject.name}
                                            >
                                                <Checkbox
                                                    checked={nameObject.checked}
                                                    onChange={(e) => handleChangeNotificationCheckbox(e, index)}
                                                />
                                                <ListItemText primary={nameObject.name}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <Box marginTop={1} marginRight={5}>
                            <TableContainer component={Paper}>
                                <Table>
                                    {/* Cabeçalho da Tabela */}
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Destino das Notificações</TableCell>
                                            <TableCell align="center">Habilitado</TableCell>
                                            <TableCell align="center">Ações</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    {/* Corpo da Tabela */}
                                    <TableBody>

                                        {/* Linha: Telegram */}
                                        <TableRow>
                                            <TableCell>
                                                <Box marginBottom={3}>
                                                    <InputLabel id="telegram">Telegram</InputLabel>
                                                    <TextField
                                                        id="telegram"
                                                        variant="standard"
                                                        placeholder="Número do Telegram"
                                                        helperText="Formato esperado: +DDI (DDD+Número)"
                                                        fullWidth
                                                        onChange={(e) => setTelegramNotificationNumber(e.target.value)}
                                                        value={telegramNotificationNumber}
                                                    />
                                                </Box>
                                            </TableCell>

                                            <TableCell align="center">
                                                <Checkbox
                                                    checked={telegramNotificationEnabled}
                                                    onChange={() => setTelegramNotificationEnabled(!telegramNotificationEnabled)}
                                                />
                                            </TableCell>

                                            <TableCell align="center">
                                                {userSaved?.telegramNotificationEnabled && userSaved?.telegramNotificationNumber && (
                                                    isLoading ? (
                                                        <LoadingButton
                                                            loading
                                                            loadingPosition="end"
                                                            size="small"
                                                            endIcon={<TelegramIcon />}
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Carregando..
                                                        </LoadingButton>
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            endIcon={<TelegramIcon />}
                                                            onClick={handleActivateBot}
                                                        >
                                                            Ativar
                                                        </Button>
                                                    )
                                                )}
                                            </TableCell>
                                        </TableRow>

                                        {/* Linha: WhatsApp */}
                                        <TableRow>
                                            <TableCell>
                                                <Box marginBottom={3}>
                                                    <InputLabel id="whatsapp">WhatsApp</InputLabel>
                                                    <TextField
                                                        id="whatsapp"
                                                        variant="standard"
                                                        placeholder="Número do WhatsApp"
                                                        helperText="Formato esperado: +DDI (DDD+Número)"
                                                        fullWidth
                                                        onChange={(e) => setWhatsappNotificationNumber(e.target.value)}
                                                        value={whatsappNotificationNumber}
                                                    />
                                                </Box>
                                            </TableCell>

                                            <TableCell align="center">
                                                <Checkbox
                                                    checked={whatsappNotificationEnabled}
                                                    onChange={() => setWhatsappNotificationEnabled(!whatsappNotificationEnabled)}
                                                />
                                            </TableCell>

                                            <TableCell align="center">
                                                {userSaved?.whatsappNotificationEnabled && userSaved?.whatsappNotificationNumber && (
                                                    isLoading ? (
                                                        <LoadingButton
                                                            loading
                                                            loadingPosition="end"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Carregando..
                                                        </LoadingButton>
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            onClick={handleActivateBot}
                                                        >
                                                            Ativar
                                                        </Button>
                                                    )
                                                )}
                                            </TableCell>
                                        </TableRow>

                                        {/* Linha: E-mail */}
                                        <TableRow>
                                            <TableCell>
                                                <InputLabel id="emailNotification">E-mail</InputLabel>
                                                <Box marginBottom={3}>
                                                    <TextField
                                                        id="emailNotification"
                                                        variant="standard"
                                                        placeholder="Digite seu email"
                                                        helperText="Digite seu email de contato"
                                                        fullWidth
                                                        onChange={(e) => setEmailNotification(e.target.value)}
                                                        value={emailNotification}
                                                    />
                                                </Box>
                                            </TableCell>

                                            <TableCell align="center">
                                                <Checkbox
                                                    checked={emailNotificationEnabled}
                                                    onChange={() => setEmailNotificationEnabled(!emailNotificationEnabled)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* Modal */}
                            <Modal
                                open={openModal}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-title"
                                aria-describedby="modal-description"
                            >
                                <Box sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    p: 4,
                                    borderRadius: '8px',
                                    boxShadow: 24
                                }}>
                                    <h2 id="modal-title">Verifique seu e-mail!</h2>
                                    <p id="modal-description">
                                        As instruções para ativação do bot foram enviadas para seu e-mail. Por favor, verifique sua caixa de entrada ou o SPAM.
                                    </p>
                                    <Button onClick={handleCloseModal} variant="contained" color="primary">
                                        Fechar
                                    </Button>
                                </Box>
                            </Modal>
                        </Box>
                    </Container>

                </ACard>
            </Box>
        </>
    )

}

export default function UserHomePageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <UsersFormPage/>
        </QueryClientProvider>
    )
}