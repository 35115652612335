import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../Config/Hooks';
import {getEntities} from './SkidNodesReducer';
import type {RootState} from '../../../../Config/Store';
import {mapElementToNode} from '../../../../Shared/Helpers/mapElementToNode';
import {ACard,APopoverIcon, Flow, Skeleton} from '@atiautomacao/ati-ui-library';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faFileCsv,
    faFileExcel,
    faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import IconButton from '@mui/material/IconButton';
import {useEquipmentNavigation} from '../../../../Shared/Hooks/useEquipmentNavigation';
import {Box, Container} from "@mui/material";
import {Element} from "../../../../Shared/Types/element";
import useInterval from "../../../../Shared/Hooks/useInterval";
import {CommandDTO} from "../../../../Shared/Components/ModalForSendingTelecommand";
import {useSnackbar} from "notistack";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {SendingTelecommandUnifilar} from "../../../../Shared/Components/SendingTelecommandUnifilar";
import {findAllCommands} from "../../../../Utils/CommandService";

interface Props {
    skidId: number;
    powerPlantId: number;
}

const SkidNodes = ({ skidId , powerPlantId}: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { entities, isLoading } = useAppSelector((state: RootState) => state.skidNodesReducer);
    const [elementsSkid, setElementsSkid] = useState<any[]>([])
    const navigateToEquipment = useEquipmentNavigation();
    const [equipmentId, setEquipmentId] = useState<number>(-1);
    const [teleCommandList, setTeleCommandList] = useState<Array<CommandDTO>>([])
    const [open, setOpen] = useState(false)
    const userConfig = useAppSelector((state: RootState) => state.userConfigurarion);
    
    useEffect(() => {
        dispatch(getEntities({skidId, powerPlantId}));
    }, []);

    useInterval(() => {
        dispatch(getEntities({skidId, powerPlantId}));
    }, userConfig.mainUpdateInterval) // 5 minutes

    useEffect(() => {
        if(entities && entities.elements.length > 0) {
            let skidElements = [...entities.elements];
            skidElements.forEach((elt: any) => {
                if(elt.parentId !== null && !skidElements.find((elm: any) => elm.id === elt.parentId)) {
                    skidElements.push({
                        id: elt.parentId,
                        label: elt.parentId.toUpperCase(),
                        edges: [],
                        leftValues: [],
                        rightValues: [],
                        topValues: [],
                        icon: '',
                        isNavigable: true,
                        status: '',
                        type: 'group',
                        idImage: undefined
                    })
                }
            });

            setElementsSkid(skidElements);
        }
    }, [entities]);

    const handleNodeClick = (id: string, isNavigable: boolean): void => {
        const newID = id?.replace(/\D/g, "")
        if (isNavigable) {
            navigateToEquipment(Number(newID));
        }
    };

    const sortNodeChildren = (node: Element): Element => {
        const sortedEdges = node.edges.map(edge => sortNodeChildren(edge));
        sortedEdges.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));
        return { ...node, edges: sortedEdges };
    };

    const traverseAndSortTree = (elements: Element[]): Element[] => {
        return elements.sort((a,b) => a.label.localeCompare(b.label)).map(element => sortNodeChildren(element));
    };

    const handleCloseModal = () => {
        setOpen(false);
        setTeleCommandList([])
        setMenuPosition(null);
        setEquipmentId(-1)
    };

    const [menuPosition, setMenuPosition] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleNodeContextMenu = (event: React.MouseEvent, node: any) => {
        event.preventDefault();
        if(node.id != null){
            if(node.id.startsWith('e')){
                setMenuPosition({
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                });
                const id = node.id.substring(1);
                setEquipmentId(Number(id));
            }
        }else{
            setMenuPosition(null);
            setEquipmentId(-1)
        }

    };

    const handleMenuContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setMenuPosition(null);
        setEquipmentId(-1)

    };
    const toOpen = () => {
        if(teleCommandList.length > 0){
            setOpen(true)
        }else{
            enqueueSnackbar("Comandos não encontrados", {variant: "error"});
            setOpen(false)
            setMenuPosition(null);
            setEquipmentId(-1)
        }

    }
    const handleClose = () => {
        setMenuPosition(null);
        setEquipmentId(-1)
    };

    useEffect(() => {
        if (equipmentId !== -1) {
            findAllCommands(equipmentId).then(
                (response:any) => {
                    if (response.status === 204) {
                        setTeleCommandList([]);
                    } else {
                        setTeleCommandList(response.data.data);
                    }
                }
            ).catch((error:any) => {
                setTeleCommandList([]);
                console.error("Error fetching tele commands:", error);
            })
        }else{
            setTeleCommandList([]);
        }
    }, [equipmentId]);


    let cardContentToRender: JSX.Element;
    if (isLoading) {
        cardContentToRender = (<Skeleton animation="wave" height={500} variant="rectangular" width="100%" />);
    } else if(entities && elementsSkid && elementsSkid.length > 0) {
        const newEntities = [...elementsSkid]
        cardContentToRender = (
            <div style={{height: '800px', width: '100%'}}>
                <Flow nodes={traverseAndSortTree(newEntities).map(element => mapElementToNode(element, entities.images))}
                      onNodeClick={handleNodeClick}
                      isCreating={false}
                      onContextMenu={handleNodeContextMenu}
                />
                <Menu
                    open={menuPosition !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    disablePortal
                    onContextMenu={handleMenuContextMenu}
                    anchorPosition={
                        menuPosition !== null
                            ? {top: menuPosition.mouseY, left: menuPosition.mouseX}
                            : undefined
                    }
                >
                    <MenuItem onClick={toOpen}>Enviar comando</MenuItem>
                </Menu>
            </div>
        );
    } else {
        cardContentToRender = (
            <Container fixed>
                <Box sx={{ bgcolor: '#e1e0e0', height: '500px', width: '800px' ,alignItems: 'center',
                    justifyContent: 'center', display:'flex'}}>
                    <div style={{ textAlign: 'center' , fontSize: '23px', color: '#646363'}}>
                        <span>Nenhum equipamento encontrado</span>
                    </div>
                </Box>

            </Container>
        )
    }

    return (
        <>
            <ACard
                title="Unifilar do Skid"
                // headerActions={
                //     <FloatMenuButton
                //         icon={<MoreVertIcon />}
                //         tooltip={"Floating Menu"}
                //     >
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20} />}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20} />}
                //             text="Expand"
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20} />}
                //             text="Print"
                //         />
                //     </FloatMenuButton>
                // }
                footerActions={
                    <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20} />}>
                        <IconButton disabled={true}>
                            <FontAwesomeIcon icon={faFilePdf} fontSize={20} />
                        </IconButton>
                        <IconButton disabled={true}>
                            <FontAwesomeIcon icon={faFileCsv} fontSize={20} />
                        </IconButton>
                        <IconButton disabled={true}>
                            <FontAwesomeIcon icon={faFileExcel} fontSize={20} />
                        </IconButton>
                    </APopoverIcon>
                }
            >
                {cardContentToRender}
            </ACard>
            {teleCommandList != null && teleCommandList.length > 0 && <SendingTelecommandUnifilar commandDTOList={teleCommandList} open={open} onCloseModal={handleCloseModal}/>}
        </>
    );
};

export default SkidNodes;
