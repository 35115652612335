import * as React from 'react';
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHardDrive} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation} from "react-router-dom";
import {SystemRoutes} from "../../../../Utils/RouteUtils";
import { useAppSelector } from '../../../../Config/Hooks';
import { hasPermission } from '../../../Auth/AuthenticationUtil';
import { AUTHORITIES } from '../../../../Config/Constants';

const style = {
    linkItem: {
        textDecoration: "none",
        color: "inherit"
    },
};

export default function SGDSettingsMenu() {
    const location = useLocation();
    const theme = useTheme();
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToUsers = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_USERS
    ]);
    const isAuthorizedToSearchProfile = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_PROFILE
    ]);
    const isAuthorizedToSearchAlarmSeverity = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_ALARM_SEVERITY
    ]);
    const isAuthorizedToSearchBroker = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_BROKER
    ]);
    const isAuthorizedToSearchEquipment = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_EQUIPMENT
    ]);
    const isAuthorizedToSearchEquipmentClass = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_EQUIPMENT_CLASS
    ]);
    const isAuthorizedToSearchEquipmentType = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_EQUIPMENT_TYPE
    ]);
    const isAuthorizedToSearchGraphElement = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_GRAPH_ELEMENT
    ]);
    const isAuthorizedToSearchGraficElement = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_GRAFIC_ELEMENT
    ]);
    const isAuthorizedToSearchPowerStationExcel = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_POWER_STATION_EXCEL
    ]);
    const isAuthorizedToSearchTeleObject = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_TELE_OBJECT
    ]);
    const isAuthorizedToSearchTeleObjectType = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_TELE_OBJECT_TYPE
    ]);
    const isAuthorizedToSearchTeleObjectSpecificationType = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_TELE_OBJECT_SPECIFICATION_TYPE
    ]);
    const isAuthorizedToSearchModBusDataType = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_MOD_BUS_DATA_TYPES
    ]);
    const isAuthorizedToSearchModBusObjectType = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_MOD_BUS_OBJECTS_TYPES
    ]);
    const isAuthorizedToSearchSkid = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_SKID
    ]);
    const isAuthorizedToSearchPowerPlant = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_POWER_PLANT
    ]);
    const isAuthorizedToSearchMacroRegion = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_MACRO
    ]);
    const isAuthorizedToSearchRegion = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_REGION
    ]);
    const isAuthorizedToSearchCountry = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_COUNTRIES
    ]);
    const isAuthorizedToSearchAudit = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_AUDIT
    ]);
    const isAuthorizedToSearchPowerStationUser = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_POWER_STATION_USER
    ]);


    return (
        <Box sx={{ width: '100%', maxWidth: 360, ml: '2%',
            bgcolor: theme.palette.mode === 'light' ? 'background.paper' : '#1E1E1E',
            height: '100%',
            overflowY: 'auto',
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
        mt: 1}}>
            <nav aria-label="main mailbox folders">
                <List>
                    <Link style={style.linkItem} to={isAuthorizedToSearchAlarmSeverity ? SystemRoutes.SETTINGS_ALARM_SEVERITY : "#"}>
                        <ListItem disablePadding selected={location.pathname.includes("alarm-severity")}>
                            <ListItemButton disabled={!isAuthorizedToSearchAlarmSeverity}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Severidade do Alarme" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={isAuthorizedToSearchBroker ? SystemRoutes.SETTINGS_BROKER : "#" }>
                        <ListItem disablePadding selected={location.pathname.includes("broker")}>
                            <ListItemButton  disabled={!isAuthorizedToSearchBroker}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Broker" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={ isAuthorizedToSearchEquipment ? SystemRoutes.SETTINGS_EQUIPMENT : "#"}>
                        <ListItem disablePadding selected={location.pathname.includes("equipment") && !location.pathname.includes("class") && !location.pathname.includes("type")}>
                            <ListItemButton disabled={!isAuthorizedToSearchEquipment}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Equipamento" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={ isAuthorizedToSearchEquipmentClass ? SystemRoutes.SETTINGS_EQUIPMENT_CLASS : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("equipment-class")}>
                            <ListItemButton disabled={!isAuthorizedToSearchEquipmentClass}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Classe de Equipamento" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={ isAuthorizedToSearchEquipmentType ? SystemRoutes.SETTINGS_EQUIPMENT_TYPE : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("equipment-type")}>
                            <ListItemButton disabled={!isAuthorizedToSearchEquipmentType}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Tipo de Equipamento" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={ isAuthorizedToSearchGraphElement ? SystemRoutes.SETTINGS_GRAPH_ELEMENT : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("graph-element")}>
                            <ListItemButton disabled={!isAuthorizedToSearchGraphElement}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Mapa de Elementos Gráficos" />
                            </ListItemButton>
                        </ListItem>
                    </Link>

                    <Link style={style.linkItem} to={ isAuthorizedToSearchGraficElement ? SystemRoutes.SETTINGS_GRAFIC_ELEMENT : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("grafic_element")}>
                            <ListItemButton disabled={!isAuthorizedToSearchGraficElement}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Elemento Gráfico" />
                            </ListItemButton>
                        </ListItem>
                    </Link>

                    <Link style={style.linkItem} to={ isAuthorizedToSearchPowerStationExcel ? SystemRoutes.SETTINGS_POWER_STATION_EXCEL : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("power-station-excel")}>
                            <ListItemButton disabled={!isAuthorizedToSearchPowerStationExcel}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Usina via Excel" />
                            </ListItemButton>
                        </ListItem>
                    </Link>


                    <Link aria-disabled={true} style={style.linkItem} to={ '#'/*isAuthorizedToSearchTeleObject ? SystemRoutes.SETTINGS_TELE_OBJECT : '#'*/}>
                        <ListItem disablePadding selected={location.pathname.includes("tele-object") && !location.pathname.includes("specification") && !location.pathname.includes("type")}>
                            <ListItemButton disabled={true || !isAuthorizedToSearchTeleObject}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Teleobjeto" />
                            </ListItemButton>
                        </ListItem>
                    </Link>

                          <Link style={style.linkItem} to={ isAuthorizedToSearchTeleObjectSpecificationType ? SystemRoutes.SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("tele-object-specification-type")}>
                            <ListItemButton disabled={!isAuthorizedToSearchTeleObjectSpecificationType}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Especificação de Teleobjeto" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={ isAuthorizedToSearchTeleObjectType ? SystemRoutes.SETTINGS_TELE_OBJECT_TYPE : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("tele-object-type")}>
                            <ListItemButton disabled={!isAuthorizedToSearchTeleObjectType}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Tipo de Teleobjeto" />
                            </ListItemButton>
                        </ListItem>
                    </Link>

                    <Link style={style.linkItem} to={ isAuthorizedToSearchModBusDataType ? SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("mod-bus-data-types")}>
                            <ListItemButton disabled={!isAuthorizedToSearchModBusDataType}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Tipos de Dado" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                          <Link style={style.linkItem} to={ isAuthorizedToSearchModBusObjectType ? SystemRoutes.SETTINGS_MOD_BUS_OBJECTS_TYPES : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("mod-bus-objects-types")}>
                            <ListItemButton disabled={!isAuthorizedToSearchModBusObjectType}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Tipos de Objeto" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link aria-disabled={true} style={style.linkItem} to={ '#'/*isAuthorizedToSearchSkid ? SystemRoutes.SETTINGS_SKID : '#'*/}>
                        <ListItem disablePadding selected={location.pathname.includes("skid")}>
                            <ListItemButton disabled={!isAuthorizedToSearchSkid || true}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Skid" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={ isAuthorizedToSearchPowerPlant ? SystemRoutes.SETTINGS_POWER_PLANT : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("power-plant")}>
                            <ListItemButton disabled={!isAuthorizedToSearchPowerPlant}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Usina" />
                            </ListItemButton>
                        </ListItem>
                    </Link>

                    <Link style={style.linkItem} to={ isAuthorizedToSearchPowerStationUser ? SystemRoutes.SETTINGS_POWER_STATION_USER : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("power-station-user")}>
                            <ListItemButton disabled={!isAuthorizedToSearchPowerStationUser}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Acesso de Usuário a Usina" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_NOTIFICATION_GROUP}>
                        <ListItem disablePadding selected={location.pathname.includes("notification-group")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Notificações" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={ isAuthorizedToSearchCountry ? SystemRoutes.SETTINGS_COUNTRY : '#'}>
                        <ListItem disablePadding selected={location.pathname.includes("country")}>
                            <ListItemButton disabled={!isAuthorizedToSearchCountry}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="País" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link aria-disabled={true} style={style.linkItem} to={ '#'/*isAuthorizedToSearchMacroRegion ? SystemRoutes.SETTINGS_MACRO_REGION : '#'*/}>
                        <ListItem disablePadding selected={location.pathname.includes("macro-region")}>
                            {/*<ListItemButton disabled>*/}
                            <ListItemButton disabled={!isAuthorizedToSearchMacroRegion || true}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Macro Região" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={isAuthorizedToSearchRegion ? SystemRoutes.SETTINGS_REGION : '#'} >
                        <ListItem disablePadding selected={location.pathname.includes("region") && !location.pathname.includes("macro")}>
                            <ListItemButton disabled={!isAuthorizedToSearchRegion}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Região" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={ isAuthorizedToSearchProfile ? SystemRoutes.SETTINGS_PROFILE : "#"}>
                        <ListItem disablePadding selected={location.pathname.includes("profiles")}>
                            <ListItemButton disabled={!isAuthorizedToSearchProfile}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Permissões" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={isAuthorizedToUsers ? SystemRoutes.SETTINGS_USERS : "#"}>
                        <ListItem disablePadding selected={location.pathname.includes("users")}>
                            <ListItemButton disabled={!isAuthorizedToUsers}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link aria-disabled={true} style={style.linkItem} to={ '#'/*isAuthorizedToSearchAudit ? SystemRoutes.SETTINGS_AUDIT : '#'*/}>
                        <ListItem disablePadding selected={location.pathname.includes("audit")}>
                            <ListItemButton disabled={!isAuthorizedToSearchAudit || true}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Auditoria" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                </List>
            </nav>
        </Box>

    );
}
