import {useQuery} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";

const apiUrlLiveConfig = "/api/configuration/liveconfig";


const fetchLiveConfigUrl = async (): Promise<string> => {
    try {
        const response = await axios.get(apiUrlLiveConfig);
        return response.data.data.value; // { baseUrl: https://liveconfig.sgdapp.com:8587 }
    } catch (error) {
        console.error("Erro ao buscar LiveConfig URL:", error);
        throw error;
    }
};

const fetchAuditLogin = async (baseUrl: string, clientName: string | undefined, pagedSearchParams: PagedSearchParams) => {
    if (!baseUrl) throw new Error("A URL da API LiveConfig não foi carregada.");


    const apiUrl = `https://${baseUrl}/api/users/search`;
    const url = new URL(apiUrl);


    const searchParams = new URLSearchParams();

    if (clientName) {
        searchParams.append("clientName", clientName);
    }


    if (pagedSearchParams) {
        const params = pagedSearchParams.toURLSearchParams();
        Array.from(params.entries()).forEach(([key, value]) => {
            searchParams.append(key, value);
        });
    }

    // Anexa os parâmetros à URL final
    url.search = searchParams.toString();


    try {
        const response = await fetch(url.toString(), {
            method: "GET",
            headers: {
                "X-identity": "498f%3o9fj@s$fgj",
                "Content-Type": "application/json",
            },
        });


        if (!response.ok) {
            throw new Error(`Erro ao buscar usuários: ${response.status}`);
        }
        return await response.json();

    } catch (error : any) {
        console.error("Error fetching data:", error);
        throw error;
    }
};


export const AuditLoginQuery = (
    clientName: string,
    pagedSearchParams: PagedSearchParams,
    isAuthenticated: boolean,
    isAuthorizedToShowGrid: boolean
) => {
    // first, search url liveconfig
    const { data: liveConfigUrl } = useQuery({
        queryKey: ["liveConfigUrl"],
        queryFn: fetchLiveConfigUrl,
    });

    // dinamic url by search parameters and clientName
    return useQuery({
        queryKey: ["auditLogin", clientName, pagedSearchParams],
        queryFn: () => {
            if(!liveConfigUrl){
                throw new Error("A URL do LiveConfig não foi carregada.");
            }
            return fetchAuditLogin(liveConfigUrl, clientName, pagedSearchParams)
        },
        enabled: !!clientName && !!liveConfigUrl && isAuthenticated && isAuthorizedToShowGrid,
        refetchInterval: 30000, // Atualiza a cada 10s
    });
};
