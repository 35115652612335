import axios from "axios";
import { serializeAxiosError } from "./ReducerUtils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export interface ConfigurationState {
    mainUpdateInterval: number;
    alarmUpdateInterval: number;
    
    loading: boolean;
    errorMessage: string[] ;
}

const initialState: ConfigurationState = {
    mainUpdateInterval: 60000,
    alarmUpdateInterval: 60000,
    loading: false,
    errorMessage: []
};


export const loadUserConfiguration = () => {
    return async function (dispatch: any) {
        await dispatch(fetchMainUpdateIntervalConfig());
        await dispatch(fetchAlarmUpdateIntervalConfig());
    }    
}
const apiConfigurationUrl = 'api/configuration';
const apiMainUpdateIntervalUrl = `${apiConfigurationUrl}/mainUpdateInterval`;
const apiAlarmUpdateIntervalUrl = `${apiConfigurationUrl}/alarmUpdateInterval`;

// Actions
const fetchMainUpdateIntervalConfig = createAsyncThunk<any>(
    'configuration/fetch_apiMainUpdateIntervalConfigUrl', 
    () => axios.get(apiMainUpdateIntervalUrl)
    .then(result => result.data)
    .catch(serializeAxiosError)
);

const fetchAlarmUpdateIntervalConfig = createAsyncThunk<any>(
    'configuration/fetch_apiAlarmUpdateIntervalConfigUrl', 
    () => axios.get(apiAlarmUpdateIntervalUrl)
    .then(result => result.data)
    .catch(serializeAxiosError)
);

// Reducer  
export const ConfigurationReducer = createSlice({
    name: 'userConfiguration',
    initialState,
    reducers: {
    },
    extraReducers(builder) { 
        // Fetch Main Update Interval
        builder.addCase(fetchMainUpdateIntervalConfig.pending, (state) => {
            state.loading = true;
            state.mainUpdateInterval = 60000;
        });
        builder.addCase(fetchMainUpdateIntervalConfig.fulfilled, (state, action) => {
            state.loading = false;
            if(action.payload.data){
                state.mainUpdateInterval = toMiliseconds(action.payload.data.value);
            }
            else if(action.payload.title){
                state.errorMessage.push(`fetchMainUpdateIntervalConfig: ${action.payload.title}`);
            }
            console.log('fetchMainUpdateIntervalConfig LOADED')
        });
        builder.addCase(fetchMainUpdateIntervalConfig.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage.push(`fetchMainUpdateIntervalConfig: ${action.payload as string}`);
        });
        // Fetch Alarm Update Interval
        builder.addCase(fetchAlarmUpdateIntervalConfig.pending, (state) => {
            state.loading = true;
            state.alarmUpdateInterval = 60000;
        });
        builder.addCase(fetchAlarmUpdateIntervalConfig.fulfilled, (state, action) => {
            state.loading = false;
            if(action.payload.data){
                state.alarmUpdateInterval = toMiliseconds(action.payload.data.value);
            }
            else if(action.payload.title){
                state.errorMessage.push(`fetchAlarmUpdateIntervalConfig: ${action.payload.title}`);
            }
            console.log('fetchAlarmUpdateIntervalConfig LOADED')
        });
        builder.addCase(fetchAlarmUpdateIntervalConfig.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage.push(`fetchAlarmUpdateIntervalConfig: ${action.payload as string}`);
        });
    }
});


function toMiliseconds(value: any): number {
    let miliseconds = 1000 * +(value);
    if(miliseconds < 10000){
        miliseconds = 10000;
    }
    return miliseconds;
}

export default ConfigurationReducer.reducer;
