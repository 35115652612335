import React, {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";

interface WebSocketContextProps {
    sendMessage?: (message: any) => void;
    isConnected?: boolean;
    hostname?: string;
}

const WebSocketContext = createContext<WebSocketContextProps | null>(null);


export const WebSocketProvider = ({ children }: { children: React.ReactNode }) => {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [wsUrl, setWsUrl] = useState<string | null>(null);
    const [hostname, setHostname] = useState<string | undefined>('');

    useEffect(() => {
        const fetchConfig = async () => {
            setHostname(window.location.hostname);
            try {
                const response = await axios.get('api/configuration/liveconfig');
                const result = response?.data;

                const hostValue = result?.data?.value;

                const newWsUrl = `wss://${hostValue}/ws`;
                console.log("Nova WS_URL:", newWsUrl);
                setWsUrl(newWsUrl);
            } catch (error: any) {
                console.error("Erro ao buscar configuração:", error);
            }
        };

        fetchConfig().then(r => console.log("test"));
    }, [isConnected]);


    useEffect(() => {
        if (!wsUrl) return;

        const ws = new WebSocket(wsUrl);

        ws.onopen = () => {
            console.log("WebSocket conectado");
            setIsConnected(true);
        }

        ws.onmessage = (event) => {
            console.log("Mensagem recebida:", event.data);
        };

        ws.onerror = (error) => {
            console.error("WebSocket erro:", error);
        };

        ws.onclose = () => {
            console.log("WebSocket desconectado");
            setIsConnected(false);
        };

        setSocket(ws)

        return () => {
            ws.close();
        };
    }, [wsUrl]);

    const sendMessage = (message: any) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        } else {
            console.error("WebSocket não está conectado!");
        }
    };

    return (
        <WebSocketContext.Provider value={{sendMessage, isConnected, hostname}}>
            {children}
        </WebSocketContext.Provider>
);
};

export const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
        return {
            sendMessage: () => console.error("WebSocketProvider não foi encontrado!"),
            isConnected: false,
        };
    }
    return context;
};