import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {Grid} from "@mui/material";
import * as React from "react";
import {CommandHistoryRow} from "../../../Shared/Components/Layout/SGDAppBarPage";
import {CommandHistory} from "../../../Shared/Components/Layout/types/CommandHistoryReactQuery";
import {DateFormat, LocalDateTimeFormatISO, validateDateFormatISO} from "../../../Utils/DateFormatPatternUtils";
import {useCallback, useEffect, useState} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {hideDashboardHeaderMenu} from "../../Dashboard/DashboardMenuReducer";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {isValid} from "date-fns";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";

const columnsCommandHistoryDataGrid: ADataGridColumn[] = [
    {
        name: 'ticket',
        label: 'Ticket',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'ticket', condition: 'equals', value: '', sort: 'none'}
    },
    {
        name: 'dateTimeReq',
        label: 'Enviado',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "dateTimeReq",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'powerStationName',
        label: 'Usina',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'equipmentName',
        label: 'Equipamento',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'alarmedName',
        label: 'Telecomando',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'description',
        label: 'Descrição',
        align: 'left',
        minWidth: 10,
        visibility: false,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'value',
        label: 'Valor',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'value', condition: 'equals', value: '', sort: "none"}
    },
    {
        name: 'dateTimeRes',
        label: 'Recebido',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "dateTimeRes",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'successRes',
        label: 'Resultado',
        align: 'left',
        minWidth: 10,
        visibility: false,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'userName',
        label: 'Usuário',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
];

function AuditCommands(){
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const [rows, setRows] = React.useState<CommandHistoryRow[]>([]);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsCommandHistoryDataGrid), 0, 50));
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToAccessAudit = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_AUDIT_COMMANDS
    ]);

    const { data, isSuccess } = CommandHistory(pagedSearchParams, isAuthenticated, isAuthorizedToAccessAudit);

    useEffect(() => {
        dispatch(hideDashboardHeaderMenu())
    }, []);

    const updateRows = (data: any) => {
        const filteredData = data?.data?.map((item: any) => {
            return {
                id: item.id,
                dateTimeReq: item.dateTimeReq != null ? DateFormat(item.dateTimeReq) : "",
                dateTimeRes: item.dateTimeRes != null ? DateFormat(item.dateTimeRes) : "",
                description: item?.description,
                ticket: item?.ticket,
                successRes: item?.successRes ? "Sucesso" : "Falha",
                equipmentName: item?.equipmentName,
                alarmedName: item?.alarmedName,
                powerStationName: item?.powerStationName,
                value: item?.value.toString(),
                userName: item?.userName
            };
        }).sort((a:any, b:any) => {
            return new Date(b.dateTimeReq).getTime() - new Date(a.dateTimeReq).getTime();
        });

        setRows(filteredData ?? []);
    };

    useEffect(() => {
        if (isSuccess && data?.totalOfRecords !== undefined) {
            setTotalOfRecords(data?.totalOfRecords)
        }
    }, [data?.data]);

    useEffect(() => {
        updateRows(data);
    }, [data?.data]);

    const formatISOData = (date: string) => {
        const dateTime =  validateDateFormatISO(date);
        if(isValid(new Date(dateTime))){
            return LocalDateTimeFormatISO(dateTime)
        }

        if(isValid(new Date(date))){
            return LocalDateTimeFormatISO(date)
        }
        return "";
    }

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        if(newPageParams.filters[1] && newPageParams.filters[1].value.length > 0){
            newPageParams.filters[1].value = formatISOData(newPageParams.filters[1].value);
        }
        if(newPageParams.filters[7] && newPageParams.filters[7].value.length > 0){
            newPageParams.filters[7].value = formatISOData(newPageParams.filters[7].value);
        }
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        console.log("pagedSearchParams", pagedSearchParams)
    }, [pagedSearchParams]);

    return (
        <Grid item padding={1} xs={12} style={{marginTop: - 56}}>
                    <ACard title="Auditoria de comandos"
                           styleProps={{
                               titleStyle: {
                                   fontWeight: 400,
                                   fontSize: '1.5rem',
                                   lineHeight: 1.334
                               }
                           }}>
                        <ADataGrid
                            hideFilters={false}
                            showFilterOptions={false}
                            hideSelection={true}
                            size={"small"}
                            columns={columnsCommandHistoryDataGrid}
                            rows={rows}
                            page={pagedSearchParams.page}
                            loading={false}
                            totalOfRecords={totalOfRecords}
                            onFiltersChange={handleOnFiltersChange}
                            rowsPerPage={pagedSearchParams.size}
                            actionColumnWidth={50}
                            maxHeight={700}
                        />

                    </ACard>
                </Grid>
    )
}

export default function AuditCommandsPage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <AuditCommands/>
        </QueryClientProvider>
    )
}