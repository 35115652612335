
import {
    Autocomplete,
    Box,
    Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, FormControlLabel,
    Grid,
    InputLabel,
    Paper, Radio, RadioGroup, Select,
    Typography
} from "@mui/material";
import ReportTeleObjectMenu from "./types/ReportTeleObjectMenu";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {extractFiltersFromColumns, lastTime, zeroTime} from "../../../Utils/DataUitils";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {RootState} from "../../../Config/Store";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import {Delete} from "@mui/icons-material";
import {faFileCsv, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {QueryClient, QueryClientProvider} from "react-query";
import {
    DataMockTesteReportPage,
    ModalRowMockTest, TeleMeasurementReport
} from "../../Analysis/types/DashboardNavigationAnalysisRowsOfGrid";
import {
    getEntitiesOfGridWithoutPowerStationAndSkid
} from "../../Analysis/types/reducer/DashboardAnalysGridReducer";
import {subDays, subMonths, subWeeks, subYears} from "date-fns";
import {LocalDateTimeFormatISO} from "../../../Utils/DateFormatPatternUtils";
import {downloadFilePostMapping} from "../../../Utils/DonwloadUtilsForPostMapping";
import {saveConfiguration} from "./types/utils/ApiUtils";
import axios from "axios";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";

import "./index.css";

export const mapPeriodType: Record<string, any> = {
    'SPECIFIC': {name:'specific',ordinal: 0},
    'RECENT': {name:'recent', ordinal: 1}
}

export default function TeleobjectsReportPage() {

    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_REPORT
    ]);
    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DELETE_REPORT
    ]);
    const isAuthorizedToSearchByCsv = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_CSV_EXPORT, AUTHORITIES.EXPORT_REPORT_TELEOBJECTS, AUTHORITIES.CSV_EXPORT_REPORT_TELEOBJECTS
    ]);
    const isAuthorizedToSearchByExcel = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_EXCEL_EXPORT, AUTHORITIES.EXPORT_REPORT_TELEOBJECTS, AUTHORITIES.EXCEL_EXPORT_REPORT_TELEOBJECTS
    ]);
    const handleStartDateTime = (newValue: Date) => {
        const firstDayOfMonth = new Date(newValue.getFullYear(), newValue.getMonth(), 1);
        return zeroTime(firstDayOfMonth);

    };

    const [startDateTime, setStartDateTime] = React.useState<Date>(handleStartDateTime(new Date()));
    const [endDateTime, setEndDateTime] = React.useState<Date>(new Date());
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = useState<Array<ModalRowMockTest>>([]);
    const [selectedRowIDs, setSelectedRowIDs] = useState<SelectedRowIDs>({
        powerStationIds: [],
        skidIds: [],
        teleObjectIds: [],
        equipmentsIds: []
    });
    const [isClear, setIsClear] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const {entities, totalOfRecords} = useAppSelector(
        (state: RootState) => state.dashboardAnalysisGrid
    );
    const [selectedIdsOfReportTeleObjectMenu, setSelectedIdsOfReportTeleObjectMenu] = useState();
    const [isDownloadExcel, setIsDownloadExcel] = useState(false);
    const [isDownloadCsv, setIsDownloadCsv] = useState(false);

    //CRUD
    // Estado para o nome do relatório e as datas de início e fim
    const [reportName, setReportName] = useState('');
    const [reportConfigs, setReportConfigs] = useState<TeleMeasurementReport[]>([]);
    const [selectedReport, setSelectedReport] = useState<TeleMeasurementReport | null>(null);
    const [equipmentIds, setEquipmentIds] = useState<number[]>([]); // Estado para armazenar os IDs
    const [open, setOpen] = useState(false);
    const [columnsState, setColumnsState] = useState<ADataGridColumn[]>([]);
    const options = reportConfigs.map(reportConfig => reportConfig.name);
    const [rowsSelected, setRowsSelected] = useState<Array<ModalRowMockTest>>([])
    const selectedReportId = selectedReport?.id;

    const [currentFilters, setCurrentFilters] = useState<any>({});
    type SelectedRowIDs = {
        powerStationIds: number[];
        skidIds: (number | null)[];
        teleObjectIds: (number | null)[];
        equipmentsIds: number[];
    };

    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsState), 0, 100));


    const getMapPeriodType = (periodType: string | number | undefined) => {
        if (typeof periodType === 'string') {
            return mapPeriodType[periodType.toUpperCase()];
        } else {
            const periodTypeString = Object.keys(mapPeriodType).find((key) => mapPeriodType[key].ordinal === periodType);
            if (periodTypeString) {
                return mapPeriodType[periodTypeString.toString()];
            }
        }
    };


    const [configPeriod, setConfigPeriod] = React.useState(getMapPeriodType(selectedReport?.periodType)?.name ?? "specific");

    const [recentPeriod, setRecentPeriod] = React.useState(selectedReport?.recentPeriod ?? 7);
    const [groupingRecentByPeriod, setGroupingRecentByPeriod] = React.useState(selectedReport?.groupingRecentByPeriod ?? "none");
    const handleChartConfigPeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfigPeriod((event.target as HTMLInputElement).value);
        if((event.target as HTMLInputElement).value === 'recent'){
            setRecentPeriod(7);
            setGroupingRecentByPeriod("day");
            const newStartDateTime = handleStartDateTimeToRecent(7);
            setStartDateTime(newStartDateTime ?? new Date());
            setEndDateTime(new Date());
        }
    };
    const [minutesInterval, setMinutesInterval] = React.useState(1);
    const [isGrouped, setIsGrouped] = React.useState("notGrouped");


    const handleChangeAgrupamento = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsGrouped((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
        const newStartDateTime = handleStartDateTimeToRecent(recentPeriod);
        setStartDateTime(newStartDateTime ?? new Date());
    }, [recentPeriod, groupingRecentByPeriod]);



    const handleStartDateTimeToRecent = (value: number) => {
        let now = new Date();
        let newStartDateTime: Date | null = null;
        if(value === 0){
            return startDateTime;
        }
        if(groupingRecentByPeriod === 'none'){
            newStartDateTime = subDays(now, value);
            newStartDateTime.setHours(0);
            newStartDateTime.setMinutes(0);
            newStartDateTime.setSeconds(0);
        } else if(groupingRecentByPeriod === 'day'){
            newStartDateTime = subDays(now, value -1);
            newStartDateTime.setHours(0);
            newStartDateTime.setMinutes(0);
            newStartDateTime.setSeconds(0);
        } else if(groupingRecentByPeriod === 'week'){
            newStartDateTime = subWeeks(now, value);
            newStartDateTime.setHours(0);
            newStartDateTime.setMinutes(0);
            newStartDateTime.setSeconds(0);
        } else if(groupingRecentByPeriod === 'month'){
            newStartDateTime = subMonths(now, value);
            newStartDateTime.setHours(0);
            newStartDateTime.setMinutes(0);
            newStartDateTime.setSeconds(0);
        } else if(groupingRecentByPeriod === 'year'){
            newStartDateTime = subYears(now, value -1);
            newStartDateTime.setHours(0);
            newStartDateTime.setMinutes(0);
            newStartDateTime.setSeconds(0);
        }
        return newStartDateTime;
    }
    const structureRow = (entitiesValues: Array<DataMockTesteReportPage>, selectedTeleObjectIds: number[]) => {
        let entity: Array<ModalRowMockTest> = [];

        entitiesValues.forEach((array) => {

            const isSelected = array.teleObjectId ? selectedTeleObjectIds.includes(array.teleObjectId) : false;

            entity.push({
                selected: isSelected,
                teleObjectId: array.teleObjectId,
                id: array.id,
                powerStationId: array.powerStationId,
                powerStationName: array.powerStationName,
                skidId: array.skidId,
                skidName: array.skidName,
                equipmentId: array.equipmentId,
                equipmentName: array.equipmentName,
                type: array.type,
                measure: array.measure,
                nameOfColumn: array.nameOfColumn,
                dbColumn: array.dbColumn,
                chartSourceType: array.chartSourceType,
                conversionFormula: array.conversionFormula,
                unity: array.unity,
                unityEquipmentId: array.unityEquipmentId,
                unityConverters: array.unityConverters
            });
        });
        setIsClear(false);
        setRows(entity);
    };


    const handleRecentPeriod = (event: React.ChangeEvent<HTMLInputElement>) =>{
        setRecentPeriod(Number(event.target.value));
        const newStartDateTime = handleStartDateTimeToRecent(Number(event.target.value));
        setStartDateTime(newStartDateTime ?? new Date());
        setEndDateTime(new Date());
    }
    const handleRecentByPeriodChange = (event: SelectChangeEvent) => {        
        setGroupingRecentByPeriod(event.target.value);
        const newStartDateTime = handleStartDateTimeToRecent(Number(event.target.value));
        setStartDateTime(newStartDateTime ?? new Date());
        setEndDateTime(new Date());
    };

    const handleCsvDownload = () => {
        let apiUrl = 'api/report/tele-objects/csv';

        const params = {
            teleObjectsId: selectedRowIDs.teleObjectIds,
            powerStationsId: selectedRowIDs.powerStationIds,
            skidIds: selectedRowIDs.skidIds,
            startDateTime: LocalDateTimeFormatISO(startDateTime),
            endDateTime: LocalDateTimeFormatISO(endDateTime),
            grouped: isGrouped === "isGrouped",
            interval: minutesInterval
        };


        setIsDownloadCsv(true);

        downloadFilePostMapping(apiUrl, params, 'relatorio_telemedidas.csv', () => {
                enqueueSnackbar("Sucesso ao baixar o CSV", {variant: 'success'});
                setIsDownloadCsv(false);
            },
            (error) => {
                const status = error?.response?.status
                if (status === 400) {
                    // @ts-ignore
                    const valueMaxLimit = (error.response?.data?.detail?.match(/\d+/g) || []).join("");
                   
                    enqueueSnackbar(`Período selecionado ultrapassa o limite de ${valueMaxLimit.length > 0 ? + valueMaxLimit : ""}
                        registros.`, {variant: 'error'});

                } else if (status === 404) {
                    enqueueSnackbar("Erro ao baixar o arquivo, tente mudar o Range de datas!", {variant: "error"});
                }
                setIsDownloadCsv(false);
            }
        );

    }

    const handleExcelDownload = () => {

        let apiUrl = 'api/report/tele-objects/excel';

        const params = {
            teleObjectsId: selectedRowIDs.teleObjectIds,
            powerStationsId: selectedRowIDs.powerStationIds,
            skidIds: selectedRowIDs.skidIds,
            startDateTime: LocalDateTimeFormatISO(startDateTime),
            endDateTime: LocalDateTimeFormatISO(endDateTime),
            grouped: isGrouped === "isGrouped",
            interval: minutesInterval,
        };

        setIsDownloadExcel(true);

        downloadFilePostMapping(apiUrl, params, 'relatorio_telemedidas.xlsx', () => {
                enqueueSnackbar("Sucesso ao Baixar o Excel", {variant: 'success'})
                setIsDownloadExcel(false);
            },
            (error) => {
                const status = error?.response?.status
                if (status === 400) {
                    // @ts-ignore
                    const valueMaxLimit = (error.response?.data?.detail?.match(/\d+/g) || []).join("");
                    console.log("valueMaxLimit",valueMaxLimit.length);
                        enqueueSnackbar(`Período selecionado ultrapassa o limite de ${valueMaxLimit.length > 0 ? + valueMaxLimit : ""}
                        registros.`, {variant: 'error'});
                } else if (status === 404) {
                    enqueueSnackbar("Erro ao baixar o arquivo, tente mudar o Range de datas!", {variant: "error"});
                }
                setIsDownloadExcel(false);
            }
        );
    }


    useEffect(() => {
        async function fetchReportIds() {
            try {
                const response = await axios.get('api/report/configs');
                setReportConfigs(response.data.data);
            } catch (error) {
                console.error('Erro ao recuperar os IDs dos relatórios:', error);
            }
        }

        fetchReportIds();
    }, [selectedReport]);


    const deleteReport = async () => {
        if (selectedReportId) {
            try {
                await axios.delete(`api/report/tele-measurement/${selectedReportId}`);

                setReportConfigs(prevConfigs => {
                    const newConfigs = prevConfigs.filter(rc => rc.id !== selectedReportId);
                    return newConfigs;
                });
                setSelectedReport(null);
                setReportName('');
                enqueueSnackbar("Sucesso ao excluir o relatório de telemedidas selecionado!", {variant: "success"});
                handleClose();
            } catch (error) {
                enqueueSnackbar("O Relatório não pode ser deletado. Contacte alguns dos nossos administradores", {
                    variant: 'error',
                });
                console.error('Erro ao excluir o relatório:', error);
            }
        }
    };



    const handleSelectedIdsChange = (selectedIds:any) => {
        setSelectedRowIDs({} as SelectedRowIDs);
        setIsClear(true);
        setSelectedIdsOfReportTeleObjectMenu(selectedIds);
    };


    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        const filtersObject = dataGridFilters.reduce<{ [key: string]: string }>((acc, current) => {
            if (current.name !== 'id') { // Ignorar o filtro "TODOS"
                acc[current.name] = current.value;
            }
            return acc;
        }, {});

        setCurrentFilters(filtersObject);


        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, [columnsState]);

    const isFiltred = () => {
        return columnsState.some((column) => column.filter != null && column.filter?.value !== "")
    }
    const getIDsOnReport = () => {
        if(selectedReport){
            const powerStationIds = new Set()
            const equipmentIDS = new Set()
            const skidIds = new Set()
            const teleObjectIds = new Set()

            selectedReport.teleMeasurementReportConfig.forEach((report:any)=>{
                powerStationIds.add(report.powerStation.id)
                equipmentIDS.add(report.equipment.id)
                teleObjectIds.add(report.teleObject.id)
                if(report.skid){
                    skidIds.add(report.skid.id)
                }
            })
            return {
                powerStationIds: Array.from(powerStationIds) as number[],
                skidIds: Array.from(skidIds) as number[],
                teleObjectIds: Array.from(teleObjectIds) as number[],
                equipmentsIds: Array.from(equipmentIDS) as number[]
            }
        }
        return {
            powerStationIds: [],
            skidIds: [],
            teleObjectIds: [],
            equipmentsIds: []
        }
    }

    const [allRowsSelected, setAllRowsSelected] = useState<any[]>([]);
    const handleOnRowSelect = ((selectedRows: Array<ModalRowMockTest>) => {
        // Arrays temporários para coletar os IDs
        const tempPowerStationIds: number[] = [];
        const tempSkidIds: (number | null)[] = [];
        const tempTeleObjectIds: (number | null)[] = [];
        const tempEquipmentsIds: number[] = [];

        // Adiciona IDs aos arrays temporários se eles ainda não estiverem presentes
        selectedRows.forEach(row => {
            if (!tempPowerStationIds.includes(row.powerStationId)) {
                tempPowerStationIds.push(row.powerStationId);
            }
            if (row.skidId !== null && !tempSkidIds.includes(row.skidId)) {
                tempSkidIds.push(row.skidId);
            }
            if (row.teleObjectId !== null && !tempTeleObjectIds.includes(row.teleObjectId)) {
                tempTeleObjectIds.push(row.teleObjectId);
            }
            if(row.equipmentId !== null && !tempEquipmentsIds.includes(row.equipmentId)) {
                tempEquipmentsIds.push(row.equipmentId);
            }
        });
        setRowsSelected(selectedRows)
        if(selectedRows && selectedRows.length > 0 && !isFiltred()){
            setAllRowsSelected(selectedRows.filter(row => row.selected).map(row => {
                return {
                    powerStation: row.powerStationId,
                    equipment: row.equipmentId,
                    skid: row.skidId ? row.skidId : null,
                    teleObject: row.teleObjectId
                }
            }))
        }else if(selectedRows && selectedRows.length > 0){
            const rowsItens = rows.filter(row => allRowsSelected?.some(item =>
                item.powerStation === row.powerStationId &&
                item.equipment === row.equipmentId &&
                item.skid === (row.skidId ? row.skidId : null) &&
                item.teleObject === row.teleObjectId
            )).map(row => ({
                powerStation: row.powerStationId,
                equipment: row.equipmentId,
                skid: row.skidId ? row.skidId : null,
                teleObject: row.teleObjectId
            }));

            const rowsItensFiltered = allRowsSelected ? allRowsSelected.filter((row:any) => rowsItens.length > 0 ? !rowsItens.some((item:any) => item.teleObject === row.teleObject) : false) : []
            let rowsSelected:any[] = []
            if(selectedRows.length > 0){
                selectedRows.forEach((row) => {
                    if(row.selected){
                        rowsSelected.push({
                            powerStation: row.powerStationId,
                            equipment: row.equipmentId,
                            skid: row.skidId ? row.skidId : null,
                            teleObject: row.teleObjectId
                        })
                    }
                })
            }
            setAllRowsSelected([...rowsSelected, ...rowsItensFiltered])
        }
        if(isFiltred()){
           if(allRowsSelected){
               allRowsSelected.forEach((row) => {
                   if(!selectedRows.some(item => item.teleObjectId === row.teleObjectId)){
                       if(!tempPowerStationIds.some(powerStation => powerStation === row.powerStation)){
                           tempPowerStationIds.push(row.powerStation)
                       }
                       if(row.skid){
                           if(!tempSkidIds.some(skid => skid === row.skid)){
                               tempSkidIds.push(row.skid)
                           }
                       }
                       if(row.equipment){
                           if(!tempEquipmentsIds.some(equipment => equipment === row.equipment)){
                               tempEquipmentsIds.push(row.equipment)
                           }
                       }
                       if(!tempTeleObjectIds.some(teleObject => teleObject === row.teleObject)){
                           tempTeleObjectIds.push(row.teleObject)
                       }
                   }
               })
           }
        }
        if(selectedReport && Object.keys(selectedRowIDs).length === 0){
            setSelectedRowIDs(getIDsOnReport())
        }else if(selectedRows && selectedRows.length > 0){
            setSelectedRowIDs({
                powerStationIds: tempPowerStationIds,
                skidIds: tempSkidIds,
                teleObjectIds: tempTeleObjectIds,
                equipmentsIds: tempEquipmentsIds
            });
        }
    });




    const createTeleMeasurementConfigAssociation = (teleObjectId: any, rows:any) => {
        const row = rowsSelected.find((row: { teleObjectId: any; }) => row.teleObjectId === teleObjectId);

        if (row) {
            return {
                equipmentId: row.equipmentId,
                skidId: row.skidId,
                powerStationId: row.powerStationId,
            };
        }else if(selectedReport){
            return {
                equipmentId: selectedReport?.teleMeasurementReportConfig?.filter((report:any) => report.teleObject.id === teleObjectId)[0].equipment.id,
                skidId: selectedReport?.teleMeasurementReportConfig?.filter((report:any) => report.teleObject.id === teleObjectId)[0].skid.id,
                powerStationId: selectedReport?.teleMeasurementReportConfig?.filter((report:any) => report.teleObject.id === teleObjectId)[0].powerStation.id
            }
        }
        return { equipmentId: null, skidId: null, powerStationId: null };
    }

    const handleSaveReportConfig = async () => {
        let apiUrl = 'api/report/tele-objects/create';

        let newStartDateTime: Date | null = null;
        let newEndDateTime: Date | null = endDateTime;
        if(configPeriod === 'recent'){
            newStartDateTime = handleStartDateTimeToRecent(recentPeriod)
            newEndDateTime = new Date();
        }

        const teleMeasurementReportConfig = selectedRowIDs.teleObjectIds.map(teleObjectId => {
            const { equipmentId, skidId, powerStationId } = createTeleMeasurementConfigAssociation(teleObjectId, rows);
            return {
                powerStation: { id: powerStationId },
                skid: skidId ? { id: skidId } : null,
                equipment: { id: equipmentId },
                teleObject: { id: teleObjectId },
            };
        });
        const requestBody = {
            name: reportName,
            startDateTime: LocalDateTimeFormatISO(newStartDateTime ?? startDateTime),
            endDateTime: LocalDateTimeFormatISO(newEndDateTime),
            powerStationFilterColumn: currentFilters.powerStationName || null,
            skidFilterColumn: currentFilters.skidName || null,
            equipmentFilterColumn: currentFilters.equipmentName || null,
            typeFilterColumn: currentFilters.type || null,
            measurementFilterColumn: currentFilters.measure || null,
            periodType: getMapPeriodType(configPeriod).ordinal,
            recentPeriod,
            groupingRecentByPeriod,
            teleMeasurementReportConfig,
        }

        const response = await saveConfiguration(apiUrl,
            requestBody,
            () => {
                enqueueSnackbar("Sucesso ao salvar o relatório!", {variant: 'success'}
                )
            },
            () => {
                enqueueSnackbar("Erro ao salvar o relatório, contacte um dos nossos administradores!", {variant: 'error'}
                )
            },
        );
        const newReport = {
            ...response,
            name: reportName,
            startDateTime: LocalDateTimeFormatISO(startDateTime),
            endDateTime: LocalDateTimeFormatISO(endDateTime),
            powerStationFilterColumn: currentFilters.powerStationName || null,
            skidFilterColumn: currentFilters.skidName || null,
            equipmentFilterColumn: currentFilters.equipmentName || null,
            typeFilterColumn: currentFilters.type || null,
            measurementFilterColumn: currentFilters.measure || null,
            teleMeasurementReportConfig
        };

        setReportConfigs(prevConfigs => [...prevConfigs, newReport]);
    }

    useEffect(() => {
        if (selectedReport) {
            const { startDateTime, endDateTime } = selectedReport;
            setCurrentFilters({
                powerStationName: selectedReport.powerStationFilterColumn ?? '',
                skidName: selectedReport.skidFilterColumn ?? '',
                equipmentName: selectedReport.equipmentFilterColumn ?? '',
                type: selectedReport.typeFilterColumn ?? '',
                measure: selectedReport.measurementFilterColumn ?? '',
            });

            if(selectedReport.periodType === "RECENT"){
                setConfigPeriod("recent");
                setRecentPeriod(selectedReport.recentPeriod ?? 7);
                setGroupingRecentByPeriod(selectedReport.groupingRecentByPeriod ?? "day")
                const newStartDateTime = selectedReport.recentPeriod  ? handleStartDateTimeToRecent(recentPeriod) : new Date();
                if(newStartDateTime){
                    setStartDateTime(newStartDateTime);
                }else {
                    setStartDateTime(new Date());
                }
                setEndDateTime(new Date());
            } else {
                const startDate = startDateTime ? new Date(startDateTime) : handleStartDateTime(new Date());
                const endDate = endDateTime ? new Date(endDateTime) : new Date();
                setStartDateTime(startDate);
                setEndDateTime(endDate);
                setConfigPeriod('specific');
            }

            const newObject = [
                {
                    name: 'id',
                    label: 'ID',
                    align: 'left',
                    visibility: false,
                    minWidth: 10,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                },
                {
                    name: 'powerStationName',
                    label: 'USINA',
                    align: 'left',
                    minWidth: 10,
                    visibility: true,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                    filter: {
                        name: 'powerStationName',
                        sort: 'none',
                        value: selectedReport.powerStationFilterColumn ?? '',
                        condition: 'contains',
                        placeholder: 'Filter'
                    },

                },
                {
                    name: 'skidName',
                    label: 'SKID',
                    align: 'left',
                    minWidth: 10,
                    visibility: true,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                    filter: {
                        name: 'skidName',
                        sort: 'none',
                        value: selectedReport.skidFilterColumn ?? '',
                        condition: 'contains',
                        placeholder: 'Filter'
                    },
                },
                {
                    name: 'equipmentName',
                    label: 'EQUIPAMENTO',
                    align: 'left',
                    minWidth: 10,
                    visibility: true,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                    filter: {
                        name: 'equipmentName',
                        sort: 'none',
                        value: selectedReport.equipmentFilterColumn ?? '',
                        condition: 'contains',
                        placeholder: 'Filter'
                    },
                },
                {
                    name: 'type',
                    label: 'TIPO',
                    align: 'left',
                    minWidth: 10,
                    visibility: true,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                    filter: {
                        name: 'type',
                        sort: 'none',
                        value: selectedReport.typeFilterColumn ?? '',
                        condition: 'contains',
                        placeholder: 'Filter'
                    },
                },
                {
                    name: 'measure',
                    label: 'MEDIDA',
                    align: 'left',
                    minWidth: 10,
                    visibility: true,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                    filter: {
                        name: 'measure',
                        sort: 'none',
                        value: selectedReport.measurementFilterColumn ?? '',
                        condition: 'contains',
                        placeholder: 'Filter'
                    },
                },
            ] as ADataGridColumn[]
            setColumnsState(newObject)

        } else {
            const newObject = [
                {
                    name: 'id',
                    label: 'ID',
                    align: 'left',
                    visibility: false,
                    minWidth: 10,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                },
                {
                    name: 'powerStationName',
                    label: 'USINA',
                    align: 'left',
                    minWidth: 10,
                    visibility: true,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                    filter: {
                        name: 'powerStationName',
                        sort: 'none',
                        value: '',
                        condition: 'contains',
                        placeholder: 'Filter'
                    },

                },
                {
                    name: 'skidName',
                    label: 'SKID',
                    align: 'left',
                    minWidth: 10,
                    visibility: true,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                    filter: {
                        name: 'skidName',
                        sort: 'none',
                        value: '',
                        condition: 'contains',
                        placeholder: 'Filter'
                    },
                },
                {
                    name: 'equipmentName',
                    label: 'EQUIPAMENTO',
                    align: 'left',
                    minWidth: 10,
                    visibility: true,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                    filter: {
                        name: 'equipmentName',
                        sort: 'none',
                        value: '',
                        condition: 'contains',
                        placeholder: 'Filter'
                    },
                },
                {
                    name: 'type',
                    label: 'TIPO',
                    align: 'left',
                    minWidth: 10,
                    visibility: true,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                    filter: {
                        name: 'type',
                        sort: 'none',
                        value: '',
                        condition: 'contains',
                        placeholder: 'Filter'
                    },
                },
                {
                    name: 'measure',
                    label: 'MEDIDA',
                    align: 'left',
                    minWidth: 10,
                    visibility: true,
                    breakpoints: {
                        xs: true,
                        sm: true,
                        md: true,
                        lg: true,
                        xl: true
                    },
                    filter: {
                        name: 'measure',
                        sort: 'none',
                        value: '',
                        condition: 'contains',
                        placeholder: 'Filter'
                    },
                },
            ] as ADataGridColumn[]
            setColumnsState(newObject)
        }
    }, [selectedReport]);

    useEffect(() => {

        const equipmentIds = new Set();
        selectedReport?.teleMeasurementReportConfig?.forEach((config: { equipment: { id: number; }; }) => {
            equipmentIds.add(config.equipment.id);
        });
        setEquipmentIds(Array.from(equipmentIds) as number[]);
        setAllRowsSelected(selectedReport?.teleMeasurementReportConfig?.map((report:any) => {
            return {
                powerStation: report.powerStation.id,
                equipment: report.equipment.id,
                skid: report.skid ? report.skid.id : null,
                teleObject: report.teleObject.id}
        }))
    }, [selectedReport]);


    useEffect(() => {
        if (entities) {
            const selectedTeleObjectIds = selectedReport?.teleMeasurementReportConfig?.map((config: { teleObject: { id: any; }; }) => config.teleObject.id) || [];
            structureRow(entities, selectedTeleObjectIds);
        } else {
            setRows([])
        }

    }, [entities, selectedReport]);

    useEffect(() => {
        setRows([])
    }, []);

    useEffect(() => {
        if(selectedIdsOfReportTeleObjectMenu) {
            setRows([])
            dispatch(getEntitiesOfGridWithoutPowerStationAndSkid(
                {
                    selectedIds: selectedIdsOfReportTeleObjectMenu,
                    params: pagedSearchParams
                }))
        }
        else {
            setRows([])
        }
    }, [selectedIdsOfReportTeleObjectMenu, pagedSearchParams, dispatch]);

    const entitiesRef = useRef(false);
    useEffect(() => {
        if (!entitiesRef.current) {
            entitiesRef.current = true;
        }
    }, [entities]);
    useEffect(() => {
        if (!entitiesRef.current) {
            entitiesRef.current = true;
        }
    }, [columnsState]);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
        <Grid item padding={1} xs={12} style={{marginTop: - 56}}>
            <ACard title="Relatório de Telemedidas" 
                 styleProps={{
                    titleStyle: {
                        fontWeight: 400,
                        fontSize: '1.5rem',
                        lineHeight: 1.334
                    }
                }}
            >
                    <Box className="filter">
                        {/* Selectbox de Favoritos */}
                        <Box className="filterButtons">
                            <FormControl className="formControl" margin="normal">
                                <Autocomplete
                                    freeSolo
                                    options={options}
                                    value={reportName}
                                    onInputChange={(event, newInputValue) => {
                                        setReportName(newInputValue); // Atualiza reportName diretamente com o valor digitado
                                    }}
                                    onChange={(event, newValue) => {
                                        const selected = reportConfigs.find(report => report.name === newValue) ?? null;
                                        setSelectedReport(selected); // selected pode ser TeleMeasurementReport ou null
                                        if (selected) setReportName(selected.name);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Favorito(s)" variant="standard" />
                                    )}
                                />
                            </FormControl>
                            {/* Botões de ação */}
                            <Box display="flex" height={40} mt={3} gap={2} ml={2}>
                                <Button
                                    sx={{width: "35px", minWidth: "35px", height: "35px"}}
                                    variant="contained"
                                    color="primary"
                                    disabled={!isAuthorizedToSave}
                                    onClick={handleClickOpen}
                                >
                                    <Delete/>
                                </Button>
                                <Dialog open={open} onClose={handleClose}>
                                    <DialogTitle>{"Confirmar exclusão"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Tem certeza de que deseja excluir este relatório? Esta ação não pode ser desfeita.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Cancelar</Button>
                                        <Button onClick={deleteReport} autoFocus>
                                            Excluir
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Button
                                    sx={{width: "35px", minWidth: "35px", height: "35px"}}
                                    variant="contained"
                                    color="primary"
                                    disabled={!isAuthorizedToDelete}
                                    onClick={handleSaveReportConfig}
                                >
                                    <SaveIcon/>
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="gridPage">
                        <Box className="gridFilter">
                            {/* Menu à esquerda */}
                            <Box p={2} style={{width: '100%', maxWidth: 360}}>
                                <ReportTeleObjectMenu
                                    onSelectedIdsChange={handleSelectedIdsChange}
                                    preSelectedEquipmentIds={equipmentIds}
                                    selectedReport={selectedReport}
                                />
                            </Box>
                        </Box>

                        <Box className="tableFilter">
                            {/* Container para os seletores de data e ADataGrid */}
                            {/* Seletores de data */}
                            <Box className="dataFilter">
                                {/*TODO this code comment are use in the future for the next activities*/}
                                {/*<Typography sx={{ color: 'text.secondary' }}>{options?.chartConfig ?*/}
                                {/*    getMapConfigType(options.chartConfig.configType).name : ""}</Typography>*/}

                                <RadioGroup
                                    row
                                    name="period-config"
                                    value={configPeriod}
                                    onChange={handleChartConfigPeriodChange}
                                >
                                    <FormControlLabel value="specific" control={<Radio/>} label="Específico" />
                                    <FormControlLabel value="recent" control={<Radio/>} label="Recente" />
                                </RadioGroup>

                                {/*TODO this code comment are use in the future for the next activities*/}
                                {/*<Button size="large"*/}
                                {/*        variant="contained"*/}
                                {/*        color="primary"*/}
                                {/*        endIcon={<Search/>}*/}
                                {/*>*/}
                                {/*    Filtrar*/}
                                {/*</Button>*/}
                                {configPeriod === 'specific' &&
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            label="Início"
                                            ampm = {false}
                                            inputFormat={isGrouped !== "isGrouped" ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY"}
                                            value={startDateTime}
                                            onChange={(newValue) => {
                                                setStartDateTime(newValue ?? handleStartDateTime(new Date()));
                                            }}
                                            renderInput={(params: any) => <TextField variant={"standard"} sx={{width: '200px'}} {...params} />}
                                        />
                                        <DateTimePicker
                                            label="Fim"
                                            ampm = {false}
                                            inputFormat={isGrouped !== "isGrouped" ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY"}
                                            value={endDateTime}
                                            onChange={(newValue: any) => {
                                                setEndDateTime(newValue ?? lastTime(new Date()));
                                            }}
                                            renderInput={(params: any) => <TextField variant={"standard"} sx={{width: '200px'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                                }
                                {configPeriod === 'recent' &&
                                    <Box style={{display: 'flex', marginBottom: '10px'}}>
                                        <FormControl variant="standard" sx={{minWidth: 120 }}>
                                            <TextField
                                                style={{width: 70}}
                                                type={"number"}
                                                value={recentPeriod}
                                                variant={"standard"}
                                                label="Últimos"
                                                onChange={handleRecentPeriod}
                                            />
                                        </FormControl>
                                        <Box sx={{marginTop: '-2px', marginLeft: -6.3}}>
                                            <InputLabel id="groupedByPeriod" sx={{fontSize: '.8rem'}}>Período</InputLabel>
                                            <Select
                                                labelId="groupedByPeriod"
                                                id="groupedByPeriod"
                                                value={groupingRecentByPeriod}
                                                onChange={handleRecentByPeriodChange}
                                                label="Período"
                                                variant={"standard"}
                                            >
                                                <MenuItem value="day">Dia(s)</MenuItem>
                                                <MenuItem value="week">Semana(s)</MenuItem>
                                                <MenuItem value="month">Mês(es)</MenuItem>
                                                <MenuItem value="year">Ano(s)</MenuItem>
                                            </Select>
                                        </Box>
                                    </Box>
                                }

                                <RadioGroup
                                    row
                                    name="agrupamento-config"
                                    value={isGrouped}
                                    onChange={handleChangeAgrupamento}
                                >
                                    <FormControlLabel value="notGrouped" control={<Radio/>} label="Dado Bruto" />
                                    <FormControlLabel value="isGrouped" control={<Radio/>} label="Agrupado" />
                                </RadioGroup>
                                {
                                    isGrouped === "isGrouped" ?
                                        <Box>
                                            <FormControl variant="standard" sx={{display: "flex", flexDirection: "row" }}>
                                                <TextField
                                                    label="Intervalo(minutos)"
                                                    style={{width: 120}}
                                                    type={"number"}
                                                    value={minutesInterval}
                                                    variant={"standard"}
                                                    onChange={(e) => setMinutesInterval(Number(e.target.value))}
                                                />
                                            </FormControl>
                                        </Box> : ""
                                }
                                <ButtonGroup variant="outlined" aria-label="Basic button group">

                                    {
                                        isDownloadExcel ?
                                            <LoadingButton
                                                sx={{width: "130px", fontSize: 10, height: "40px"}}
                                                loading
                                                loadingPosition="end"
                                                endIcon={<SaveIcon sx={{fontSize: 20}}/>}
                                            >
                                                Carregando..
                                            </LoadingButton>
                                            : <Button
                                                sx={{mr: -0.09, width: "70px", fontSize: 10, height: "40px"}}
                                                onClick={handleExcelDownload}
                                                disabled={isAuthorizedToSearchByExcel ? isDownloadExcel : true}
                                            >
                                                Excel
                                                <FontAwesomeIcon
                                                    icon={faFileExcel}
                                                    fontSize={14}
                                                    style={{marginLeft: '0.5rem'}}/>
                                            </Button>
                                    }
                                    {
                                        isDownloadCsv ?
                                            <LoadingButton
                                                sx={{width: "130px", fontSize: 10, height: "40px"}}
                                                loading
                                                loadingPosition="end"
                                                endIcon={<SaveIcon sx={{fontSize: 20}}/>}
                                            >
                                                Carregando..
                                            </LoadingButton>
                                            : <Button
                                                sx={{ml: 1, width: "70px", fontSize: 10, height: "40px"}}
                                                onClick={handleCsvDownload}
                                                disabled={isAuthorizedToSearchByCsv ? isDownloadCsv : true}
                                            >
                                                CSV
                                                <FontAwesomeIcon
                                                    icon={faFileCsv}
                                                    fontSize={14}
                                                    style={{marginLeft: '0.5rem'}}/>
                                            </Button>
                                    }
                                </ButtonGroup>
                            </Box>

                            { selectedIdsOfReportTeleObjectMenu &&
                                <ADataGrid
                                    size={"small"}
                                    columns={columnsState}
                                    rows={rows}
                                    page={pagedSearchParams.page}
                                    loading={false}
                                    totalOfRecords={totalOfRecords || 0}
                                    rowsPerPage={pagedSearchParams.size}
                                    onFiltersChange={handleOnFiltersChange}
                                    onClearSelectedTempRows={isClear}
                                    onRowSelect={handleOnRowSelect}
                                    actionColumnWidth={80}
                                />

                            }
                        </Box>
                    </Box>
                </ACard>
            </Grid>
        </QueryClientProvider>

    );
}