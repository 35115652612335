import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import { RootState } from '../../../../Config/Store';
import axios from 'axios';
import { serializeAxiosError } from "../../../../Config/Reducers/ReducerUtils";
import {DefaultState, RequestError} from "../../../../Config/Types";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import { TotalizerType } from '../../Overview/DashboardOverviewAlarmsPage';

export class Alarm {

    id: number;
    teleObjectId: number;
    severityId: number;
    severityName: string;
    severityColor: string;
    description: string;
    macroRegionName: string;
    regionName: string
    powerStationId: number;
    powerStationName: string;
    skidId: number;
    skidName: string;
    equipmentId: number;
    equipmentName: string;
    clearDate: number;
    acknowledgementDate: string;
    acknowledgementNote: string;
    dateTime: string;
    userName: string;
    group: string;

    constructor( id: number, teleObjectId: number,severityId: number, severityName: string,  severityColor: string,  description: string,  macroRegionName: string,  regionName: string,
                 powerStationId: number, powerStationName: string, skidId: number, skidName: string, equipmentId: number, equipmentName: string,
                 clearDate: number, acknowledgementDate: string, acknowledgementNote: string, dateTime: string, userName: string, group: string) {

        this.id = id;
        this.teleObjectId = teleObjectId;
        this.severityId = severityId;
        this.severityName = severityName;
        this.severityColor = severityColor;
        this.description = description;
        this.macroRegionName = macroRegionName;
        this.regionName = regionName;
        this.powerStationId = powerStationId;
        this.powerStationName = powerStationName;
        this.skidId = skidId;
        this.skidName = skidName;
        this.equipmentId = equipmentId;
        this.equipmentName = equipmentName;
        this.clearDate = clearDate;
        this.acknowledgementDate = acknowledgementDate;
        this.acknowledgementNote = acknowledgementNote;
        this.dateTime = dateTime;
        this.userName = userName;
        this.group = group;
    }
}

const initialState: DefaultState = {
    loading: false,
    saving: false,

    errorMessage: undefined,
    successMessage: undefined,

    entities: new Array<Alarm>(),
    entity: undefined,

    totalOfPages: 0,
    totalOfRecords: 0,
    totalOfRequestedRecords: 0,
    pageNumber: 0,
    recordsPerPage: 20,
};

const apiUrl = 'api/alarm/search';


export const searchEntities = createAsyncThunk<
    any,
    {
        params: PagedSearchParams | undefined,
        filterByTotalizer: TotalizerType,
    }
>(
    '\'power_plant/alarm/search_entities',
    async ({params,filterByTotalizer}) => {
        return axios.get(
            apiUrl + `/totalizerType=${filterByTotalizer}`,
            {
                params: params ? params.toURLSearchParams() : null
            }
        );

    },
    { serializeError: serializeAxiosError }
);


// Slices
const AlarmPowerPlantReducer = createSlice({
    name: 'AlarmPowerPlantReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                id: 0,
                teleObjectId: 0,
                severityId: 0,
                severityName: "",
                severityColor: "",
                description: "",
                macroRegionName: "",
                regionName: "",
                powerStationId: 0,
                powerStationName: "",
                skidId: 0,
                skidName: "",
                equipmentId: 0,
                equipmentName: "",
                clearDate: "",
                acknowledgementDate: "",
                acknowledgementNote: "",
                dateTime: "",
                userName: "",
                group: false
            };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addMatcher(isFulfilled(searchEntities), (state, action) => {
                state.loading = false;
                state.saving = false;

                state.successMessage = undefined;
                state.errorMessage = undefined;

                state.entities = action.payload.data.data;
                state.entity = undefined;

                state.totalOfPages = action.payload.data.totalOfPages;
                state.totalOfRecords = action.payload.data.totalOfRecords;
                state.totalOfRequestedRecords = action.payload.data.totalOfRequestedRecords;
                state.pageNumber = action.payload.data.pageNumber;
                state.recordsPerPage = action.payload.data.recordsPerPage;

            })
            .addMatcher(isPending(searchEntities), state => {
                state.loading = true;
                state.successMessage = undefined;
                state.errorMessage = undefined;
            })
            .addMatcher(isRejected(searchEntities), (state:DefaultState, action) => {
                state.loading = false;
                state.successMessage = undefined;
                state.errorMessage = action.error.message;

            })
    },
})

export const { reset, clear } = AlarmPowerPlantReducer.actions;
export const selectAlarms = (state: RootState) => state.alarmPowerPlant;

// Reducer
export default AlarmPowerPlantReducer.reducer;