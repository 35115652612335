import {DefaultState} from "../../../../Config/Types";
import {DbColumn, Unity, UnityConverter} from "../DashboardNavigationAnalysisRowsOfGrid";
import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from "@reduxjs/toolkit";
import axios from "axios";
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {DashBoardNavigationAnalysisSelectedId} from "./DashboardNavigationAnalysisMenuState";
import {searchEntities} from "../../../Dashboard/Alarms/AlarmReducer";
import {RootState} from "../../../../Config/Store";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";

export class AnalysisConfig {
    id: number | undefined;
    powerStationId: number;
    powerStationName: string;
    skidId: number | null;
    skidName: string | null;
    teleObjectId: number | null;
    equipmentId: number | null;
    equipmentName: string | null;
    type: string;
    measure: string;
    nameOfColumn: string;
    dbColumn: DbColumn;
    chartSourceType: number;
    conversionFormula: string | null;
    unity: Unity;
    unityEquipmentId: number | null;
    unityConverters: UnityConverter[];

    constructor(
        id: number,
        powerStationId: number,
        powerStationName: string,
        skidId: number | null,
        skidName: string | null,
        teleObjectId: number | null,
        equipmentId: number | null,
        equipmentName: string | null,
        type: string,
        measure: string,
        nameOfColumn: string,
        dbColumn: DbColumn,
        chartSourceType: number,
        conversionFormula: string | null,
        unity: Unity,
        unityEquipmentId: number | null,
        unityConverters: UnityConverter[]
    ) {
        this.id = id;
        this.powerStationId = powerStationId;
        this.powerStationName = powerStationName;
        this.skidId = skidId;
        this.skidName = skidName;
        this.teleObjectId = teleObjectId;
        this.equipmentId = equipmentId;
        this.equipmentName = equipmentName;
        this.type = type;
        this.measure = measure;
        this.nameOfColumn = nameOfColumn;
        this.dbColumn = dbColumn;
        this.chartSourceType = chartSourceType;
        this.conversionFormula = conversionFormula;
        this.unity = unity;
        this.unityEquipmentId = unityEquipmentId;
        this.unityConverters = unityConverters;
    }
}

const initialState: DefaultState = {
    loading: false,
    saving: false,

    errorMessage: undefined,
    successMessage: undefined,

    entities: new Array<AnalysisConfig>(),
    entity: undefined,

    totalOfPages: 0,
    totalOfRecords: 0,
    totalOfRequestedRecords: 0,
    pageNumber: 0,
    recordsPerPage: 20,
};

const apiUrlOfGrid = 'api/analysis/tele-objects-id';
const apiUrlOnlyTeleObjectsId = 'api/report/tele-objects-id';


export const getEntitiesOfGrid = createAsyncThunk<
    any,
    { selectedIds: DashBoardNavigationAnalysisSelectedId, params?: PagedSearchParams },
    {
        rejectValue: any;
    }
>(
    'power-station/navigation-entities-grid-modal/entity_list',
    async ({ selectedIds, params }) => {
        let queryParams = new URLSearchParams();

        if (selectedIds.selectedGenPowerStationIdList && selectedIds.selectedGenPowerStationIdList.length > 0) {
            queryParams.append('genPowerStationIdList', selectedIds.selectedGenPowerStationIdList.join(','));
        }
        if (selectedIds.selectedGenSkidIdList && selectedIds.selectedGenSkidIdList.length > 0) {
            queryParams.append('genSkidIdList', selectedIds.selectedGenSkidIdList.join(','));
        }
        if (selectedIds.selectedGenEquipmentIdList && selectedIds.selectedGenEquipmentIdList.length > 0) {
            queryParams.append('genEquipmentIdList', selectedIds.selectedGenEquipmentIdList.join(','));
        }
        if(selectedIds.selectedToEquipmentIdList && selectedIds.selectedToEquipmentIdList.length > 0) {
            queryParams.append('toEquipmentIdList', selectedIds.selectedToEquipmentIdList.join(','));
        }

        if (params) {
            const extraParams = params.toURLSearchParams();
            extraParams.forEach((value, key) => queryParams.append(key, value));
        }

        const apiUrl = `${apiUrlOfGrid}?${queryParams.toString()}`;

        return await axios.get(apiUrl);
    },
    { serializeError: serializeAxiosError },
);

export const getEntitiesOfGridWithoutPowerStationAndSkid = createAsyncThunk<
    any,
    { selectedIds: DashBoardNavigationAnalysisSelectedId, params?: PagedSearchParams },
    {
        rejectValue: any;
    }
>(
    'power-station/',
    async ({ selectedIds, params }) => {
        let queryParams = new URLSearchParams();

        if (selectedIds.selectedToEquipmentIdList && selectedIds.selectedToEquipmentIdList.length > 0) {
            queryParams.append('equipmentIds', selectedIds.selectedToEquipmentIdList.join(','));
        }
        if (params) {
            const extraParams = params.toURLSearchParams();
            extraParams.forEach((value, key) => queryParams.append(key, value));
        }

        const apiUrl = `${apiUrlOnlyTeleObjectsId}?${queryParams.toString()}`;

        return await axios.get(apiUrl);
    },
    { serializeError: serializeAxiosError },
);

const DashboardAnalysisGridReducer = createSlice({
    name: 'dashboardAnalysisGridReducer',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isFulfilled(getEntitiesOfGridWithoutPowerStationAndSkid), (state, action) => {
                state.loading = false;
                state.saving = false;

                state.successMessage = undefined;
                state.errorMessage = undefined;

                state.entities = action.payload.data.data;
                state.entity = undefined;

                state.totalOfPages = action.payload.data.totalOfPages;
                state.totalOfRecords = action.payload.data.totalOfRecords;
                state.totalOfRequestedRecords = action.payload.data.totalOfRequestedRecords;
                state.pageNumber = action.payload.data.pageNumber;
                state.recordsPerPage = action.payload.data.recordsPerPage;
            })
            .addMatcher(isPending(searchEntities), state => {
                state.loading = true;
                state.successMessage = undefined;
                state.errorMessage = undefined;
            })
            .addMatcher(isFulfilled(getEntitiesOfGrid), (state, action) => {
                state.loading = false;
                state.saving = false;

                state.successMessage = undefined;
                state.errorMessage = undefined;

                state.entities = action.payload.data.data;
                state.entity = undefined;

                state.totalOfPages = action.payload.data.totalOfPages;
                state.totalOfRecords = action.payload.data.totalOfRecords;
                state.totalOfRequestedRecords = action.payload.data.totalOfRequestedRecords;
                state.pageNumber = action.payload.data.pageNumber;
                state.recordsPerPage = action.payload.data.recordsPerPage;
            })
            .addMatcher(isPending(searchEntities), state => {
                state.loading = true;
                state.successMessage = undefined;
                state.errorMessage = undefined;
            })
            .addMatcher(isRejected(searchEntities), (state:DefaultState, action) => {
                state.loading = false;
                state.successMessage = undefined;
                state.errorMessage = action.error.code;

            })
    },
});

export const { reset } = DashboardAnalysisGridReducer.actions

export const selectDashboardAnalysisGrid = (state: RootState) => state.dashboardAnalysisGrid

export default DashboardAnalysisGridReducer.reducer;