import axios, {AxiosRequestConfig} from "axios";

export function extracted(config: AxiosRequestConfig<any>, filename: string, successCallBack: (() => void) | undefined, errorCallback: ((error: any) => void) | undefined, noContentCallBack?: (() => void) | undefined) {
     axios(config).then((response) => {
        if(response.status === 204){
            if(noContentCallBack){
                noContentCallBack()
            }
            return
        }
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        if (successCallBack) {
            successCallBack();
        }

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }).catch(error => {
        if (errorCallback) {
            errorCallback(error);
        }
    });
}

export const downloadFile = (url: string, params: any, filename: string, successCallBack?: () => void, errorCallback?: (error: any) => void, noContentCallback?: () => void) => {
    const config: AxiosRequestConfig = {
        url: url,
        params,
        method: 'GET',
        responseType: 'blob'
    };
    extracted(config, filename, successCallBack, errorCallback, noContentCallback);
}