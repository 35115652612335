import {
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    FormGroup,
    Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {login, logout, UserEntity} from "../../Config/Reducers/AuthenticationReducer";
import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import logoAzul from "../../Assets/logo-azul.png"
import {useSnackbar} from "notistack";
import {useAppDispatch, useAppSelector} from "../../Config/Hooks";
import themeDefaults from "../../Shared/Theme/ThemeDefaults";
import {useWebSocket} from "./hooks/useWebSocket";
import axios from "axios";

export default function LoginPage(){

    const { enqueueSnackbar } = useSnackbar();
    const [entity, setEntity] = useState(new UserEntity());
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
    const account = useAppSelector((state) => state.authentication.account);
    const loginError = useAppSelector(state => state.authentication.loginError);
    const errorMessage = useAppSelector(state => state.authentication.errorMessage);
    const [username, setUsername] = useState("");
    const [customerName, setCustomerName] = React.useState(null);

    const { sendMessage, hostname } = useWebSocket();


    const fetchCustomerName = async (email) => {
        try {
            const response = await axios.get("/api/customer/find-by-email", {
                params: { email: email },
            })
            if(response?.status === 200){
                const customerName = response.data.data ? String(response.data.data) : null;
                setCustomerName(customerName);
            } else {
                setCustomerName(null)
            }
        } catch (error) {
            setCustomerName(null);
        }


    }

    const customers = useAppSelector((state) => state.authentication.customers);

    const handleLogin = () => {
        setCustomerName(null);
        const email = entity.username;

        fetchCustomerName(email).then(r => console.log("Customer resgatado!"));

        let user = new UserEntity();
        user.username = entity.username;
        user.password = entity.password;
        user.rememberMe= entity.rememberMe;
        dispatch(login(user, sendMessage, customers, hostname))
    };

    useEffect(() => {
        if (isAuthenticated && account && account.name !== undefined && account.name.length > 0) {
            const fetchCustomerData = async () => {
                try {
                    const response = await axios.get('api/customer/all');

                    const customerData = response.data?.data || [];
                    const customerName = customerData.length > 0 ? customerData[0].name : "Desconhecido";

                    let user = new UserEntity();
                    user.username = entity.username;

                    sendMessage({
                        email: user.username,
                        hostname: hostname,
                        customerName: customerName,
                        action: "login",
                        message: "Entrou no Sistema",
                        status: "SUCCESS",
                        timeStamp: new Date().toISOString(),
                    });
                } catch (error) {
                    console.error("Erro ao buscar dados do cliente:", error);
                }
            };

            fetchCustomerData().then(r => console.log("Login efetuado com sucesso!"));
        }
    }, [isAuthenticated, account]);

    const handleClose = () => {
        // sair
    };
    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setUsername(value);
        setEntity((oldValue) => {
            return {
                ...oldValue,
                [name]: value
            }
        })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    }

    const handleRememberMe = (event) => {
        let name = event.target.name;
        let value = event.target.checked;
        setEntity((oldValue) => {
            return {
                ...oldValue,
                [name]: value
            }
        })
    }
    useEffect(() => {
        if( loginError) {
            if(errorMessage.toLowerCase().indexOf("timeout") !== -1 ||
                errorMessage.toLowerCase().indexOf("network") !== -1) {
                console.log("Falha de comunicação!", errorMessage);
                enqueueSnackbar("Falha de comunicação!", {variant: 'error'});
            }
            else if(errorMessage.toLowerCase().indexOf("500") !== -1){
                console.log("Falha ao logar. Contate a administrador do sistema.", errorMessage);
                enqueueSnackbar("Falha ao logar. Contate o administrador do sistema.", {variant: 'error'});
            }
            else if(errorMessage.toLowerCase().indexOf("503") !== -1){
                console.log("Serviço de login indisponível.", errorMessage);
                enqueueSnackbar("Serviço de login indisponível.", {variant: 'error'});
            }
            else if(errorMessage.toLowerCase().indexOf("204") !== -1){
                console.log("Usuário não encontrado.", errorMessage);
                enqueueSnackbar("Usuário não encontrado.", {variant: 'error'});
            }
            else if (errorMessage.toLowerCase().indexOf("401") !== -1){
                console.log("Usuário ou senha inválidos.", errorMessage);
                enqueueSnackbar("Usuário ou senha inválidos.", {variant: 'error'});
            }
            else {
                console.log("Erro ao logar!", errorMessage);
                enqueueSnackbar(errorMessage, {variant: 'error'});
            }
            localStorage.removeItem('totalizer_by_overview');
            localStorage.removeItem('totalizer_by_power_station');
            localStorage.removeItem('totalizer_by_skid');
            dispatch(logout());
        }
    },[isAuthenticated,loginError, enqueueSnackbar, errorMessage, dispatch]);


    useEffect(() => {
        if(loginError){
            let msg;
            let status = "ERROR"

            if (errorMessage.toLowerCase().includes("timeout") || errorMessage.toLowerCase().includes("network")) {
                msg = "Falha de comunicação!";
            } else if (errorMessage.toLowerCase().includes("500")) {
                msg = "Erro no servidor. Contate o suporte.";
            } else if (errorMessage.toLowerCase().includes("503")) {
                msg = "Serviço de login indisponível.";
            } else if (errorMessage.toLowerCase().includes("204")) {
                msg = "Usuário não encontrado.";
            } else if (errorMessage.toLowerCase().includes("401")) {
                msg = "Usuário ou senha inválidos.";
            } else {
                msg = errorMessage;
            }
            console.log("Enviando erro via WebSocket:", msg);

            sendMessage({
                email: entity.username,
                hostname: hostname,
                customerName: customerName,
                action: "login",
                message: msg,
                status: status,
                timeStamp: new Date().toISOString(),
            });
            dispatch(logout());
        }
    }, [username, loginError, hostname, username]);

    if (isAuthenticated === true && account && account.name !== undefined && account.name.length > 0) {
        return <Navigate to="/dashboard/overview" />;
    }
    return (
        <Container maxWidth="xs" id="login-form" style={{
            textAlign: "center",
            // backgroundColor: "blue"
        }}>
            <Card
                sx={{marginTop: 3, minWidth: 275, borderRadius: themeDefaults.borderRadius}}>
                <CardContent style={{textAlign: "center"}}>
                    <img alt={"Logo Azul"} src={logoAzul} style={{height: 60, width: "auto"}}/><br/>
                    <Typography variant="p" style={{marginTop: 7}}>
                        Sistema Supervisório de Geração Distribuida</Typography>
                    <Divider/>
                    <br/>
                    <FormGroup>
                        <TextField
                            required
                            id="username"
                            name="username"
                            label="Usuário"
                            placeholder="Digite seu login"
                            variant="standard"
                            // value={entity.username}
                            size={"small"}
                            onChange={handleChange}
                            onKeyUp={handleKeyPress}
                        />
                        <TextField
                            required
                            id="password"
                            name="password"
                            type="password"
                            label="Senha"
                            placeholder="Digite sua senha"
                            variant="standard"
                            // value={entity.password}
                            size={"small"}
                            onChange={handleChange}
                            onKeyUp={handleKeyPress}
                        />
                        <FormControlLabel
                            label="Manter logado"
                            control={<Checkbox checked={entity.rememberMe}

                                               id="rememberMe"
                                               name="rememberMe"
                                               onChange={handleRememberMe}/>}
                        />
                    </FormGroup>
                </CardContent>
                <CardActions style={{textAlign: "right"}}>
                    <Button onClick={handleClose}>Esqueceu a senha?</Button>
                    <Button onClick={handleLogin}>Entrar</Button>
                </CardActions>
            </Card>
            <br/>


        </Container>
    );
}
