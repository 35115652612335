import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";


const apiUrl = "/api/notification-groups";

const fetchNotificationGroups = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrl}/search`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching notification group data:", error);
        return Promise.reject(error);
    }
};

export const NotificationReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchNotificationGroups(pagedSearchParams);
        }
    });
}

const saveNotificationGroup = async (data: any) => {
    try {
        if (data.id) {
            return await axios.put(`${apiUrl}/${data.id}`, data).then((res) => res.data);
        } else {
            return await axios.post(apiUrl, data).then((res) => res.data);
        }
    } catch (error) {
        console.error("Error fetching users data:", error);
        return Promise.reject(error);
    }
};

export const SaveNotificationGroupReactQuery = () => {
    const {enqueueSnackbar} = useSnackbar();

    return useMutation(saveNotificationGroup, {
        onSuccess: () => {
            enqueueSnackbar("Grupo de Notificação salvo com sucesso!", {variant: "success"});
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao ao salvar o grupo de notificação: ${error.response?.data?.detail ?? error.message}`, {variant: "error"});
        }
    });
};

const deleteNotificationGroup = async (id: number) => {
    return await axios.delete(`${apiUrl}/${id}`).then((res) => res.data);
};

export const DeleteNotificationGroupReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: any) => {
            return deleteNotificationGroup(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Grupo de Notificação excluído com sucesso!", {variant: "success"});
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir notificação de grupo: ${error.message}`, {variant: "error"});
        }
    });
};

const fetchNotificationGroupById = async (id: number) => {
    return await axios.get(`${apiUrl}/${id}`).then((res) => res.data);
};

export const FindNotificationGroupByIdReactQuery = () => {
    const {enqueueSnackbar} = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchNotificationGroupById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar uma notificação de grupo: ${error.message}`, {variant: "error"});
        }
    })
};