import {QueryClient, QueryClientProvider} from "react-query";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    FindTeleObjectSpecificationTypeByIdReactQuery,
    SaveTeleObjectSpecificationTypeReactQuery
} from "./TeleObjectSpecificationTypeReactQuery";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {Container, FormHelperText, TextField} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import {ACard} from "@atiautomacao/ati-ui-library";
import Box from "@mui/material/Box";
import {HeaderMenu} from "../HeaderMenu";

export const TeleObjectSpecificationTypeFormPage = () => {


    const location = useLocation();
    const id = location.state?.id as string;
    const [teleObjectName, setTeleObjectName] = useState<string>("");

    const {data, isSuccess} = SaveTeleObjectSpecificationTypeReactQuery();

    const mutationSave = SaveTeleObjectSpecificationTypeReactQuery();
    const mutationFindById = FindTeleObjectSpecificationTypeByIdReactQuery();
    const navigate = useNavigate();

    function handleSave() {
        if(teleObjectName === ""){
            return;
        }
        const objectToSave = id ? {id: Number(id), name: teleObjectName} : {name: teleObjectName};
        mutationSave.mutate(objectToSave, {
            onSuccess: (data) => {
                navigate(SystemRoutes.SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE);
            }
        });
        console.log(teleObjectName);
    }

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    setTeleObjectName(data?.data.name);
                }
            });
        }
    }, []);

    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE}/>
            <Box>
                <ACard>
                    <Container style={{marginLeft: -10, display: 'flex', flexDirection: "row", alignItems: 'left', justifyContent: 'left', columnGap: 30}}>

                        <Box style={{marginBottom: 20}}>
                            <InputLabel id="object-name">Nome</InputLabel>
                            <TextField
                                error={teleObjectName === ""}
                                fullWidth
                                id="object-name"
                                variant="standard"
                                placeholder={"Nome do tipo específico"}
                                onChange={(value) => setTeleObjectName(value.target.value)}
                                value={teleObjectName}
                            />
                            {
                                teleObjectName === "" &&
                                <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                            }
                        </Box>
                    </Container>
                </ACard>
            </Box>

        </>

    )
}



export default function TeleObjectSpecificationTypePageForm(){
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <TeleObjectSpecificationTypeFormPage />
        </QueryClientProvider>
    )
}