import * as React from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {CustomerReactQuery} from "../../Settings/NotificationGroup/reactQuery/CustomerReactQuery";
import {useAppDispatch} from "../../../Config/Hooks";
import {useCallback, useEffect, useRef, useState} from "react";
import {ACard, ADataGrid, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {columnsAuditLoginDataGrid} from "../../Settings/GraficElement/columnsDataGrid";
import {hideDashboardHeaderMenu} from "../../Dashboard/DashboardMenuReducer";
import {AuditLoginQuery} from "./AuditLoginReactQuery";
import {Grid} from "@mui/material";
import {DateFormat} from "../../../Utils/DateFormatPatternUtils";
import {isValid} from "date-fns";
import {useSnackbar} from "notistack";


export interface LoginHistoryRow {
    id: string;
    email: string;
    hostname?: string;
    timeStamp: string;
    action: string;
    status: string;
    message: string
}


function AuditLogin() {
    const dispatch = useAppDispatch();
    const [rows, setRows] = React.useState<LoginHistoryRow[]>([]);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsAuditLoginDataGrid), 0, 50));
    const {data : customers} = CustomerReactQuery();
    const selectedProfileName = customers?.data?.[0]?.name;

    const { enqueueSnackbar } = useSnackbar();


    const { data: users, isSuccess: isSuccessAuditLogin } = AuditLoginQuery(selectedProfileName, pagedSearchParams, true, true);

    useEffect(() => {
        dispatch(hideDashboardHeaderMenu())
    }, []);

    useEffect(() => {
        if (isSuccessAuditLogin && users?.data?.totalOfRecords !== undefined) {
            setTotalOfRecords(users?.data?.totalOfRecords)
        } else {
            setTotalOfRecords(0)
        }
    }, [users?.data]);
    const paramsRef = useRef(false);

    useEffect(() => {
        if(paramsRef.current){
            if(pagedSearchParams.filters[0] && pagedSearchParams.filters[0].value.length > 0){
                pagedSearchParams.filters[0].value = formatInstantData(pagedSearchParams.filters[0].value)
            }

        }else {
            paramsRef.current = true;
        }
    },[pagedSearchParams]);

    const formatInstantData = (date: string) => {

        const dateTime = validateDateFormatISO(date);

        if (isValid(new Date(dateTime))) {
            return instantFormatISO(dateTime);
        }

        if (isValid(new Date(date))) {
            return instantFormatISO(date);
        }

        enqueueSnackbar("Data inválida!", { variant: "error" });
        return "";
    };

    const validateDateFormatISO = (date: string): string => {
        let [day, month, year] = date.split("/");

        // Caso o usuário tenha digitado apenas dia/mês
        if (!year) {
            year = new Date().getFullYear().toString();
        }
        if(!month){
            month = (new Date().getMonth() + 1).toString(); //correction for month
        }

        day = day?.padStart(2, "0");
        month = month?.padStart(2, "0");

        return `${year}-${month}-${day}T00:00:00Z`;
    };

    const instantFormatISO = (date: string) => {
        return new Date(date).toISOString();
    };

    const updateRows = (data: any) => {
        const filteredData = data?.data?.data?.map((item: any) => {
            return {
                id: item.id,
                timeStamp: item.timeStamp != null ? DateFormat(item.timeStamp) : "",
                email: item?.email,
                status: item?.status,
                message: item?.message,
                action: item?.action,
                hostname: item?.hostname,
            };
        }).sort((a:any, b:any) => {
            return new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime();
        });

        setRows(filteredData ?? []);
    };

    useEffect(() => {
        updateRows(users);
    }, [users?.data]);


    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    return (
        <Grid item padding={1} xs={12} style={{marginTop: - 56}}>
            <ACard title="Auditoria de Login"
                   styleProps={{
                       titleStyle: {
                           fontWeight: 400,
                           fontSize: '1.5rem',
                           lineHeight: 1.334
                       }
                   }}>
                <ADataGrid
                    hideFilters={false}
                    showFilterOptions={false}
                    hideSelection={true}
                    size={"small"}
                    columns={columnsAuditLoginDataGrid}
                    rows={rows}
                    page={pagedSearchParams.page}
                    loading={false}
                    totalOfRecords={totalOfRecords}
                    onFiltersChange={handleOnFiltersChange}
                    rowsPerPage={pagedSearchParams.size}
                    actionColumnWidth={50}
                    maxHeight={700}
                />

            </ACard>
        </Grid>
    )
}

export default function AuditLoginPage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <AuditLogin/>
        </QueryClientProvider>
    )
}