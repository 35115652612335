import {Breadcrumbs, Link as MatLink} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {
    includesDashboardPowerPlantEquipmentPage,
    includesDashboardPowerPlantSkidEquipmentPage,
    includesSettingsCountryPage,
    includesSettingsProfilesPage,
    isAnalysisPage,
    isCommunicationPage,
    isDashboardOverviewMapPage,
    isDashboardOverviewPage,
    isDashboardOverviewAlarmsPage,
    isDashboardOverviewSynopticPage,
    isDashboardOverviewActivePowerPage,
    isDashboardPage,
    isDashboardPowerPlantAlarmsPage,
    isDashboardPowerPlantChartsPage,
    isDashboardPowerPlantEquipmentComparePage,
    isDashboardPowerPlantEquipmentDetailsPage,
    isDashboardPowerPlantLayoutPage,
    isDashboardPowerPlantPage,
    isDashboardPowerPlantSkidAlarmsPage,
    isDashboardPowerPlantSkidChartsPage,
    isDashboardPowerPlantSkidEquipmentComparePage,
    isDashboardPowerPlantSkidEquipmentDetailsPage,
    isDashboardPowerPlantSkidLayoutPage,
    isDashboardPowerPlantSKidPage,
    isGraphElementPage,
    isGraphElementFormPage,
    isHelpPage,
    isIncludesAnalysisHomePage,
    isReportsAlarmsPage,
    isReportsAuditPage,
    isReportsEquipmentClassPage,
    isReportsEquipmentPage,
    isReportsManagementReport,
    isReportsPage,
    isReportsPerformanceSummaryPage,
    isReportsPowerPlantPage,
    isReportsSkidPage,
    isReportsTeleobjectPage,
    isSettingsAuditPage,
    isSettingsEquipmentClassFormPage,
    isSettingsEquipmentClassPage,
    isSettingsEquipmentPage,
    isSettingsEquipmentFormPage,
    isSettingsEquipmentTypePage,
    isSettingsEquipmentTypeFormPage,
    isSettingsGraficElementPage,
    isSettingsGraficElementFormPage,
    isSettingsMacroRegionPage,
    isSettingsNotificationGroupFormPage,
    isSettingsNotificationGroupPage,
    isSettingsPage,
    isSettingsPowerPlantPage,
    isSettingsPowerPlantFormPage,
    isSettingsPowerStationExcelPage,
    isSettingsRegionPage,
    isSettingsRegionFormPage,
    isSettingsSkidPage,
    isSettingsUsersPage,
    isSettingsUsersFormPage,
    isSettingsSeverityAlarmPage,
    isSettingsSeverityAlarmFormPage,
    isSettingsBrokerPage,
    isSettingsBrokerFormPage,
    isSettingsTeleObjectPage,
    isSettingsTeleObjectSpecificationTypePage,
    isSettingsTeleObjectSpecificationTypeFormPage,
    isSettingsTeleObjectTypePage,
    isSettingsTeleObjectTypeFormPage,
    isSettingsModBusDataTypePage,
    isSettingsModBusDataTypeFormPage,
    isSettingsModBusObjectTypePage,
    isSettingsModBusObjectTypeFormPage,
    SystemRoutes,
    isDashboardOverviewChartsPage,
    isDashboardPowerPlantInvertersPage,
    isDashboardPowerPlantTrackersPage,
    isSettingsPowerStationUserPage, isAuditPage, isAuditLoginPage, isAuditCommandsPage, isAuditAlarmsPage,
} from "../../../../Utils/RouteUtils";
import {useAppSelector} from "../../../../Config/Hooks";

export default function DashboardPowerPlantChartsPage(){
    const {selectedEquipment, selectedPowerPlant, selectedSkid} = useAppSelector((state) => state.dashboard);
    return(
        <Breadcrumbs aria-label="breadcrumb"
                     sx={{ display:{xs: "none", lg: "block"} }}
                     style={{marginLeft: 20, color: "white", fontWeight: "bolder"}}
                     separator={
                         <FontAwesomeIcon
                             icon="caret-right"
                             style={{marginBottom: 3, marginLeft: 3, marginRight: 3 }}
                         />
                     }

        >

            {/* DASHBOARD */}
            {/*FIRST LEVEL*/}
            {isDashboardPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_OVERVIEW}>DASHBOARD</MatLink> : null}
            {isAnalysisPage() || isIncludesAnalysisHomePage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.ANALYSIS}>ANALYSIS</MatLink> : null}
            {isCommunicationPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.COMMUNICATION}>COMMUNICATION</MatLink> : null}
            {isSettingsPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_ALARM_SEVERITY}>SETTINGS</MatLink> : null}
            {isReportsPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS}>REPORTS</MatLink> : null}
            {isAuditPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.AUDIT}>AUDIT</MatLink> : null}
            {isHelpPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.HELP}>HELP</MatLink> : null}

            {/* SECOND LEVEL */}
            {/* Dashboard pages */}
            {isDashboardOverviewPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_OVERVIEW}>Visão Geral</MatLink>
                : null}

            {isDashboardOverviewChartsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_OVERVIEW_CHARTS}>Gráficos</MatLink>
                : null}

            {isDashboardOverviewAlarmsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_OVERVIEW_ALARMS}>Alarmes</MatLink>
                : null}

            {isDashboardOverviewMapPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_OVERVIEW_MAP}>Mapa</MatLink>
                : null}

            {isDashboardOverviewSynopticPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_OVERVIEW_SYNOPTIC}>Sinóptico</MatLink>
                : null}

            {isDashboardOverviewActivePowerPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_OVERVIEW_ACTIVE_POWER}>Potência Ativa</MatLink>
                : null}

            {isDashboardPowerPlantPage() ?
                // <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_OVERVIEW}>Power Plant</MatLink>
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_OVERVIEW}>{selectedPowerPlant?.name}</MatLink>
                : null}

            {isDashboardPowerPlantLayoutPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_LAYOUT}>Planta</MatLink>
                : null}

            {isDashboardPowerPlantTrackersPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_TRACKERS}>Trackers</MatLink>
                : null}

            {isDashboardPowerPlantInvertersPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_INVERTER}>Inversor</MatLink>
                : null}

            {isDashboardPowerPlantAlarmsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_ALARMS}>Alarmes</MatLink>
                : null}

            {isDashboardPowerPlantChartsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_CHARTS}>Gráficos</MatLink>
                : null}

            {isDashboardPowerPlantSKidPage() ?
                // <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID}>Skid</MatLink>
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_OVERVIEW}>{selectedSkid.name}</MatLink>
                : null}

            {isDashboardPowerPlantSkidLayoutPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_LAYOUT}>Planta</MatLink>
                : null}

            {isDashboardPowerPlantSkidAlarmsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_ALARMS}>Alarmes</MatLink>
                : null}

            {isDashboardPowerPlantSkidChartsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_CHARTS}>Gráficos</MatLink>
                : null}

            {includesDashboardPowerPlantEquipmentPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS_DETAILS}>{selectedEquipment.name}</MatLink>
                : null}

            {includesDashboardPowerPlantSkidEquipmentPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_DETAILS}>{selectedEquipment.name}</MatLink>
                : null}

            {isDashboardPowerPlantEquipmentDetailsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS_DETAILS}>Detalhes</MatLink>
                : null}
            {isDashboardPowerPlantSkidEquipmentDetailsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_DETAILS}>Detalhes</MatLink>
                : null}

            {isDashboardPowerPlantEquipmentComparePage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS_COMPARE}>Comparar</MatLink>
                : null}

            {isDashboardPowerPlantSkidEquipmentComparePage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_COMPARE}>Comparar</MatLink>
                : null}



            {/*Settings pages */}
            {includesSettingsCountryPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_COUNTRY}>Country</MatLink>
                : null}
            {isSettingsMacroRegionPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_MACRO_REGION}>Macro Region</MatLink>
                : null}
            {isSettingsRegionPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_REGION}>Região</MatLink>
                : null}
            {isSettingsRegionFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_REGION_FORM}>Região Formulário</MatLink>
                : null}
            {isSettingsEquipmentTypePage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_EQUIPMENT_TYPE}>Tipo de Equipamento</MatLink>
                : null}
            {isSettingsEquipmentTypeFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_EQUIPMENT_TYPE_FORM}>Tipo de Equipamento Formulário</MatLink>
                : null}
            {isSettingsGraficElementPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_GRAFIC_ELEMENT}>Grafic Element</MatLink>
                : null}
            {isSettingsGraficElementFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_GRAFIC_ELEMENT_FORM}>Grafic Element Formulário</MatLink>
                : null}
            {isSettingsPowerStationExcelPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_POWER_STATION_EXCEL}>Usina via Excel</MatLink>
                : null}
            {isGraphElementPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_GRAPH_ELEMENT}>Unifilar</MatLink>
                : null}
            {isGraphElementFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_GRAPH_ELEMENT_FORM}>Unifilar Formulário</MatLink>
                : null}
            {isSettingsEquipmentClassPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_EQUIPMENT_CLASS}>Classe de Equipamento</MatLink>
                : null}
            {isSettingsEquipmentClassFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_EQUIPMENT_CLASS}>Classe de Equipamento Formulário</MatLink>
                : null}
            {isSettingsEquipmentPage() && (!isSettingsEquipmentTypePage() && !isSettingsEquipmentClassPage())?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_EQUIPMENT}>Equipamento</MatLink>
                : null}

            {isSettingsEquipmentFormPage() && (!isSettingsEquipmentTypePage() && !isSettingsEquipmentClassPage())?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_EQUIPMENT_FORM}>Equipamento Formulário</MatLink>
                : null}

            {isSettingsSkidPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_SKID}>Skid</MatLink>
                : null}
            {isSettingsNotificationGroupPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_NOTIFICATION_GROUP}>Grupo de Notificações</MatLink>
                : null}
            {isSettingsNotificationGroupFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_NOTIFICATION_GROUP_FORM}>Notificações</MatLink>
                : null}
            {isSettingsPowerPlantPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_POWER_PLANT}>Usina</MatLink>
                : null}
            {isSettingsPowerPlantFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_POWER_PLANT}>Usina Formulário</MatLink>
                : null}
            {includesSettingsProfilesPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_PROFILE}>Permissões</MatLink>
                : null}
            {isSettingsUsersPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_USERS}>Usuários</MatLink>
                : null}
            {isSettingsUsersFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_USERS_FORM}>Cadastro de Usuários</MatLink>
                : null}
            {isSettingsAuditPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_AUDIT}>Audit</MatLink>
                : null}

            {isSettingsSeverityAlarmPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_ALARM_SEVERITY}>Severidade do Alarme</MatLink>
                : null}

            {isSettingsSeverityAlarmFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_ALARM_SEVERITY_FORM}>Severidade do Alarme Formulário</MatLink>
                : null}

            {isSettingsBrokerPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_BROKER}>Broker</MatLink>
                : null}

            {isSettingsBrokerFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_BROKER_FORM}>Broker Formulário</MatLink>
                : null}

            {isSettingsTeleObjectPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_BROKER_FORM}>Teleobjeto</MatLink>
                : null}

            {isSettingsTeleObjectSpecificationTypePage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE}>Especificação de Teleobjeto</MatLink>
                : null}

            {isSettingsTeleObjectSpecificationTypeFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE_FORM}>Especificação de Teleobjeto Formulário</MatLink>
                : null}

            {isSettingsTeleObjectTypePage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_TELE_OBJECT_TYPE}>Tipo de Teleobjeto</MatLink>
                : null}

            {isSettingsTeleObjectTypeFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_TELE_OBJECT_TYPE_FORM}>Tipo de Teleobjeto Formulário</MatLink>
                : null}

            {isSettingsModBusDataTypePage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES}>Tipo de Dado</MatLink>
                : null}

            {isSettingsModBusDataTypeFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES_FORM}>Tipo de Dado Formulário</MatLink>
                : null}

            {isSettingsModBusObjectTypePage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_MOD_BUS_OBJECTS_TYPES}>Tipos de Objeto</MatLink>
                : null}

            {isSettingsModBusObjectTypeFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_MOD_BUS_OBJECTS_TYPES_FORM}>Tipos de Objeto Formulário</MatLink>
                : null}

            {isSettingsPowerStationUserPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_POWER_STATION_USER}>Acesso de Usinas</MatLink>
                : null}


            Reports page
            {isReportsAlarmsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_ALARMS}>Alarms</MatLink>
                : null}
            {isReportsPerformanceSummaryPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_PERFORMANCE_SUMMARY}>Performance Summary</MatLink>
                : null}
            {isReportsPowerPlantPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_POWER_PLANTS}>Usina</MatLink>
                : null}
            {isReportsManagementReport() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_MANAGEMENT_REPORT}>Relatório de Gestão</MatLink>
                : null}
            {isReportsEquipmentClassPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_EQUIPMENT_CLASS}>Classes de Equipamento</MatLink>
                : null}
            {isReportsEquipmentPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_EQUIPMENT}>Equipment</MatLink>
                : null}
            {isReportsSkidPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_SKID}>Skid</MatLink>
                : null}
            {isReportsTeleobjectPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_TELEOBJECTS}>Relatório de Telemedidas</MatLink>
                : null}
            {isReportsAuditPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_AUDIT}>Auditoria</MatLink>
                : null}
            {/*Audit pages */}
            {isAuditLoginPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.AUDIT_LOGIN}>Login</MatLink>
                : null}
            {isAuditCommandsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.AUDIT_COMMANDS}>Comandos</MatLink>
                : null}
            {isAuditAlarmsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.AUDIT_ALARMS}>Alarmes</MatLink>
                : null}
        </Breadcrumbs>
    );
}