import {Box, Tooltip, useTheme} from "@mui/material";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import React, {useCallback, useEffect, useState} from "react";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import IconButton from "@mui/material/IconButton";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {useNavigate} from "react-router-dom";
import {DeleteBrokerReactQuery, BrokerSearchReactQuery} from "./BrokerReactQuery";
import {QueryClient, QueryClientProvider} from "react-query";
import {HeaderMenu} from "../HeaderMenu";
import {BrokerProps} from "../../../Shared/Types/Broker";
import {useAppSelector} from "../../../Config/Hooks";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";

const initColumns = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        visibility: true,
        minWidth: 25,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'id', condition: 'equals', value: '', sort: 'asc'}

    },
    {
        name: 'name',
        label: 'nome',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'name', condition: 'contains', value: '', sort: 'asc'}

    },
    {
        name: 'powerStationName',
        label: 'usina',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'powerStationName', condition: 'contains', value: '', sort: ''}

    },
    {
        name: 'ipAddress',
        label: 'endereço IP',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'ipAddress', condition: 'contains', value: '', sort: ''}

    },
    {
        name: 'port',
        label: 'porta',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'port', condition: 'equals', value: '', sort: ''}

    }
] as ADataGridColumn [];

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowBroker {
    id: number;
    name: string;
    powerStationName: string;
    ipAddress: string;
    port: number;
    actionsConfig?: Array<ActionConfig>
}

export function BrokerSettingsPage(){
    const navigate = useNavigate();

    const account = useAppSelector((state: any) => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.SAVE_BROKER
    ]);

    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.DELETE_BROKER
    ]);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50));
    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    const mutation = DeleteBrokerReactQuery(pagedSearchParams);
    const { data, isSuccess,refetch } = BrokerSearchReactQuery(pagedSearchParams);

    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [rows, setRows] = useState<Array<RowBroker>>([]);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if(isSuccess) {
            setTotalOfRecords(data?.totalOfRecords);
            if(data?.data){
                let rowsBroker = data.data.map((broker: BrokerProps) => {
                    return {
                        id: Number(broker.id),
                        name: broker.name,
                        powerStationName: broker.powerStation.name,
                        ipAddress: broker.ipAddress,
                        port: broker.port,
                        actionsConfig: [
                            {actionName: "edit", disabled: !isAuthorizedToSave},
                            {actionName: "delete", disabled: !isAuthorizedToDelete},
                        ]
                    }
                });
                setRows(rowsBroker);
            }
        }
    }, [data?.data]);

    const handleEditBroker = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_BROKER_FORM, { state: { id: data.id } });
    },[navigate]);

    const handleDeleteBroker = useCallback((data: any) => {
        setSelectedToDelete(data.id);
        setOpenDialog(true);
    },[]);

    const handleDialogConfirm = async ( ) => {
        mutation.mutate({ id: selectedToDelete, pagedSearchParams });
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    useEffect(() => {
        if(mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50))
            refetch()
        }
    }, [mutation.isSuccess]);


    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    const theme = useTheme();

    return(
       <>
           <HeaderMenu systemRoutes={SystemRoutes.SETTINGS_BROKER_FORM} disableSave={!isAuthorizedToSave}/>
           <Box>
               <ACard>
                   <ADataGrid
                       hideFilters={false}
                       showFilterOptions={false}
                       hideSelection={false}
                       columns={initColumns}
                       rows={rows}
                       page={pagedSearchParams.page}
                       rowsPerPage={pagedSearchParams.size}
                       loading={false}
                       totalOfRecords={totalOfRecords}
                       onFiltersChange={handleOnFiltersChange}
                       actions={
                           <>
                               <IconButton name="edit" size={"small"} aria-label="Edit" disabled={!isAuthorizedToSave} onClick={(value) => handleEditBroker(value)}>
                                   <Tooltip title="Editar">
                                       <Edit/>
                                   </Tooltip>
                               </IconButton>
                               <IconButton name="delete" size={"small"} aria-label="Delete" disabled={!isAuthorizedToDelete} onClick={(value) => handleDeleteBroker(value)}>
                                   <Tooltip title="Excluir">
                                       <Delete />
                                   </Tooltip>
                               </IconButton>
                           </>
                       }
                   />
               </ACard>
           </Box>
           <ConfirmDialog
               title={'Deletar'}
               description={'Deseja deletar esse broker?'}
               open={openDialog}
               handleConfirm={handleDialogConfirm}
               handleClose={handleDialogClose}
           />
       </>
    );

}

export function BrokerHomePage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <BrokerSettingsPage />
        </QueryClientProvider>
    )
}
