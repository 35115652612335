import {ADataGridColumn} from "@atiautomacao/ati-ui-library";

export const initColumnsData = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'severityName',
        label: 'Severidade',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'description',
        label: 'Descrição',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'macroRegionName',
        label: 'macro',
        align: 'left',
        minWidth: 10,
        visibility: false,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'regionName',
        label: 'região',
        align: 'left',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'powerStationName',
        label: 'Usina',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'skidName',
        label: 'Skid',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'equipmentName',
        label: 'Equipamento',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'dateTime',
        label: 'Início',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "dateTime",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'clearDate',
        label: 'Finalizado',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "clearDate",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'acknowledgementDate',
        label: 'Reconhecido',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "acknowledgementDate",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'acknowledgedUser',
        label: 'Reconhecido por:',
        align: 'left',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'clearedUser',
        label: 'Finalizado por:',
        align: 'left',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'dateTimeNote',
        label: 'Data da nota',
        align: 'left',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "dateTimeNote",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'descriptionNote',
        label: 'Nota',
        align: 'left',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'userNameNote',
        label: 'Feita por:',
        align: 'left',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    }
] as ADataGridColumn[]

