import {QueryClient, QueryClientProvider} from "react-query";
import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {FindGraficElementByIdReactQuery, SaveGraficElementReactQuery} from "./GraficElementReactQuery";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {Container, FormHelperText, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {ACard} from "@atiautomacao/ati-ui-library";
import InputLabel from "@mui/material/InputLabel";
import {HeaderMenu} from "../HeaderMenu";
export const GraficElementFormPage = () => {

    const location = useLocation();
    const id = location.state?.id as string;
    const [graficElementName, setGraficElementName] = useState<string>("");

    const mutationSave = SaveGraficElementReactQuery();
    const mutationFindById = FindGraficElementByIdReactQuery();
    const navigate = useNavigate();
    function handleSave() {
        if(graficElementName === ""){
            return;
        }
        const objectToSave = id ? {id: Number(id), name: graficElementName} : {name: graficElementName};
        mutationSave.mutate(objectToSave, {
            onSuccess: (data) => {
                navigate(SystemRoutes.SETTINGS_GRAFIC_ELEMENT);
            }
        });
        console.log(graficElementName);
    }

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    setGraficElementName(data?.data.name);
                }
            });
        }
    }, []);

    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_GRAFIC_ELEMENT}/>
            <Box>
                <ACard>
                    <Container style={{marginLeft: -10, display: 'flex', flexDirection: "row", alignItems: 'left', justifyContent: 'left', columnGap: 30}}>

                        <Box style={{marginBottom: 20}}>
                            <InputLabel id="object-name">Nome</InputLabel>
                            <TextField
                                error={graficElementName === ""}
                                fullWidth
                                id="object-name"
                                variant="standard"
                                placeholder={"Nome do tipo de objeto"}
                                onChange={(value) => setGraficElementName(value.target.value)}
                                value={graficElementName}
                            />
                            {
                                graficElementName === "" &&
                                <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                            }
                        </Box>
                    </Container>
                </ACard>
            </Box>
        </>
    )
}


export default function GraficElementPageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <GraficElementFormPage />
        </QueryClientProvider>
    )
}