import React, {useEffect, useMemo, useState} from "react";
import {ACard, ACheckboxElement,} from "@atiautomacao/ati-ui-library";
import {useAppSelector} from "../../../Config/Hooks";
import Button from "@mui/material/Button";
import {format} from 'date-fns';
import {RootState} from "../../../Config/Store";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Modal,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Typography
} from "@mui/material";
import {Download} from "@mui/icons-material";
import {LocalDateTimeFormatISO} from "../../../Utils/DateFormatPatternUtils";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import {postAndDownloadFile,downloadFile} from "./utils/postAndDownloadFile";
import {ManagementReportSkidsConcated, SubReportHeader, SubReportHeaderReactQuery} from "./types/ManagementReportBySkidReactQuery";
import {QueryClient, QueryClientProvider} from "react-query";
import {useSnackbar} from "notistack";
import SaveIcon from "@mui/icons-material/Save";
import {LoadingButton} from "@mui/lab";
import SelectAllTransferList, {SubHeader} from "./Shuttle";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";

export interface CustomElement {
    data? : {
        id : number
    } | object
}
type MyElement = CustomElement & ACheckboxElement;


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 450,
        },
    },
};

function ManagementReport(){
    const { enqueueSnackbar } = useSnackbar();
    const { entities } = useAppSelector((state: RootState) => state.dashboardNavigationMenu);
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSearchByPdf = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_PDF_EXPORT, AUTHORITIES.EXPORT_REPORT_MANAGEMENT_REPORT, AUTHORITIES.PDF_EXPORT_REPORT_MANAGEMENT_REPORT
    ]);
    const isAuthorizedToSearchByCsv = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_CSV_EXPORT, AUTHORITIES.EXPORT_REPORT_MANAGEMENT_REPORT, AUTHORITIES.CSV_EXPORT_REPORT_MANAGEMENT_REPORT
    ]);
    const isAuthorizedToSearchByExcel = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_EXCEL_EXPORT, AUTHORITIES.EXPORT_REPORT_MANAGEMENT_REPORT, AUTHORITIES.EXCEL_EXPORT_REPORT_MANAGEMENT_REPORT
    ]);

    const { data } = ManagementReportSkidsConcated();

    const subHeader = SubReportHeaderReactQuery();

    const initListPowerStations = () => {
        const listPowerStations = new Array<ACheckboxElement>();
        entities.forEach((element, index) => {
            listPowerStations.push({label: element.name, checked: index === 0, data: element});
        });
        return listPowerStations;
    }
    const handleStartDateTime = (newValue: Date) => {
        const firstDayOfMonth = new Date(newValue.getFullYear(), newValue.getMonth(), 1);
        firstDayOfMonth.setHours(0, 0, 0, 0);

        return firstDayOfMonth;

    };
    const init:Array<ACheckboxElement> = initListPowerStations();

    const [open, setOpen] = React.useState(false);
        // close modal
        const handleCloseModal = () => {
            setOpen(false);
        }
    const [subHeaderList, setSubHeaderList] = useState<SubReportHeader>();
    const [dataShuttle, setDataShuttle] = useState<SubHeader[]>([]);
    const [checked, setChecked] = useState<Array<ACheckboxElement>>(init);
    const [checkedAll, setCheckedAll] = React.useState(false);
    const [startDateTime, setStartDateTime] = React.useState<Date>(handleStartDateTime(new Date()));
    const [endDateTime, setEndDateTime] = React.useState<Date>(new Date());
    const [groupedByPeriod, setGroupedByPeriod] = React.useState<string>( "day");
    const [typeManagement, setTypeManagement] = React.useState<string>( "powerStation");
    const [selectedSkidList, setSelectedSkidList] = useState<Array<{ id: number, name: string,equipmentList:{id: number; name: string }[] }>>([]);
    const [concatenatedSkids, setConcatenatedSkids] = useState<{ id: number, name: string,equipmentList:{ id: number; name: string }[] }[]>([]);
    const [checkedSkids, setCheckedSkids] = useState<{ name: string; checked: boolean; data: { id: number; name: string,equipmentList:{ id: number; name: string  }[] } }[]>([]);
    const [checkedAllSkid, setCheckedAllSkid] = React.useState(false);

    const [selectedInverterList, setSelectedInverterList] = useState<Array<{ id: number, name: string,checked: boolean,equipmentType: { id: number; name: string } }>>([]);
    const [concatenatedInverters, setConcatenatedInverters] = useState<Array<{ id: number, name: string }>>([]);
    const [checkedInverters, setCheckedInverters] = useState<
        { name: string; checked: boolean; data: { id: number; name: string } }[]>([]);
    const [checkedAllInverter, setCheckedAllInverter] = React.useState(false);

    const [isDownloadCsv, setIsDownloadCsv] = useState(false);
    const [isDownloadPdf, setIsDownloadPdf] = useState(false);
    const [isDownloadExcel, setIsDownloadExcel] = useState(false);
    const getSkidNameWithPowerStationName = (skid: { id: any; name: any; equipmentList: { id: number; name: string }[] }, powerStationList: any[]) => {
        const station = powerStationList.find((powerStation: any) =>
            powerStation.skidList.some((s: any) => s.id === skid.id)
        );
        return station ? `${station.name} - ${skid.name}` : skid.name;
    };

    const memoizedConcatenatedSkids = useMemo(() => {
        return selectedSkidList.map(skid => ({
            id: skid.id,
            name: getSkidNameWithPowerStationName(skid, data?.data || []),
            equipmentList: skid.equipmentList
        }));
    }, [selectedSkidList, data]);


    const concatedSkidsForSelector = (): { data: { id: number; name: string, equipmentList: { id: number; name: string }[] }; name: string; checked: boolean }[] => {
        return concatenatedSkids.map((element) => {
            return {name: element.name, checked: false, data: element};
        });
    }

    useEffect(() => {
        setCheckedSkids(concatedSkidsForSelector());
    }, [concatenatedSkids]);


    useEffect(() => {
        setCheckedAll(checked.every(item => item.checked));
    }, [checked]);

    useEffect(() => {
        setCheckedAllSkid(checkedSkids.every(item => item.checked));
    }, [checkedSkids]);

    useEffect(() => {
        setConcatenatedSkids(memoizedConcatenatedSkids);
    }, [memoizedConcatenatedSkids]);

    useEffect(() => {
        const selectedSkids = checked
            .filter(item => item.checked && item.data && (item.data as any).skidList)
            .flatMap(item => (item.data as any).skidList);
        setSelectedSkidList(selectedSkids);
    }, [checked]);

    const handleChangeAllCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(checked.map(item => {
            return { ...item, checked: event.target.checked };
        }));
        setCheckedAll(event.target.checked);
    };

    const handleChangeElementCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedChecked = [...checked];
        updatedChecked[index].checked = event.target.checked;
        setChecked(updatedChecked);
    };

    const handleChangeAllSkidCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedSkids(checkedSkids.map(item => {
            return { ...item, checked: event.target.checked };
        }));
        setCheckedAllSkid(event.target.checked);
    };

    const handleChangeSkidCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedCheckedSkids = [...checkedSkids];
        updatedCheckedSkids[index].checked = event.target.checked;
        setCheckedSkids(updatedCheckedSkids);
    };

    useEffect(() => {
        const selectedInverters = checkedSkids
            .filter(item => item.checked && item.data && (item.data as any).equipmentList)
            .flatMap(item => (item.data as any).equipmentList);

        setSelectedInverterList(selectedInverters.filter(inverter => inverter.equipmentType.id === 2));
    }, [checkedSkids]);

    // Equipments

    const getInverterNameWithSkidName = (equipment:{id: any; name: string; checked: boolean; equipmentType: { id: number; name: string }}, powerStationList: any[]) => {
        let nameEquipment = '';
        powerStationList.find(powerStation => {
            const skid = powerStation.skidList.find((skid: any) =>
                skid.equipmentList.some((equip: any) => equip.id === equipment.id && equipment.equipmentType?.id === 2)
            );
            if (skid) {
                nameEquipment = `${powerStation.name} - ${skid.name} - ${equipment.name}`;
                return powerStation;
            }
        })
        return nameEquipment;
    }

    const memoizedConcatenatedInverters = useMemo(() => {
        return selectedInverterList.map(equipment => ({
            id: equipment.id,
            name: getInverterNameWithSkidName(equipment, data?.data || []),
        }));
    }, [selectedSkidList,selectedInverterList, data]);

    useEffect(() => {
        setConcatenatedInverters(memoizedConcatenatedInverters);
    }, [memoizedConcatenatedInverters]);

    const concatenatingInverterForSelector = (): { data: { id: number; name: string }; name: string; checked: boolean }[] => {
        return concatenatedInverters.map((element) => {
            return {name: element.name, checked: false, data: element};
        });
    }

    useEffect(() => {
        setCheckedInverters(concatenatingInverterForSelector());
    }, [concatenatedInverters]);

    const handleChangeAllInverterCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedInverters(checkedInverters.map(item => {
            return { ...item, checked: event.target.checked };
        }));
        setCheckedAllInverter(event.target.checked);
    };

    const handleChangeInverterCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedCheckedInverters = [...checkedInverters];
        updatedCheckedInverters[index].checked = event.target.checked;
        setCheckedInverters(updatedCheckedInverters);
    };


    const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroupedByPeriod((event.target as HTMLInputElement).value);
    };

    const handleTypeManagement = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTypeManagement((event.target as HTMLInputElement).value);
    };

    const getFileName = (groupedByPeriod: string, extension: string) => {
        let period;
        switch (groupedByPeriod) {
            case "year":
                period = 'anual';
                break;
            case "month":
                period = 'mensal';
                break;
            case "week":
                period = 'semanal';
                break;
            default:
                period = 'diario';
        }
        return `relatorio_de_gestao_${period}.${extension}`;
    }

    const handlePdfDownload = () => {
        setOpen(true);
    }

    const handlePdfDownloadManagementReportSkid = () => {
        let apiUrl;
        switch (groupedByPeriod) {
            case "year":
                apiUrl = `api/managementReportSkid/pdf/year`
                break;
            case "month":
                apiUrl = `api/managementReportSkid/pdf/month`
                break;
            case "week":
                apiUrl = `api/managementReportSkid/pdf/week`
                break;
            default:
                apiUrl = `api/managementReportSkid/pdf/day`
        }
        const data = {
            skidIdsList: checkedSkids.filter(entity => entity.checked).map(entity => (entity?.data as {
                id: number
            }).id),
            startDateTime: format(new Date(startDateTime), 'yyyy-MM-dd') + 'T00:00:00',
            endDateTime: LocalDateTimeFormatISO(endDateTime)
        }

        setIsDownloadPdf(true);


        postAndDownloadFile(apiUrl, data, getFileName(groupedByPeriod, 'pdf'), () => {
                enqueueSnackbar("Sucesso ao baixar o PDF por skid", {variant: 'success'});
                setIsDownloadPdf(false);
            }, (error) => {
                enqueueSnackbar("Erro ao baixar o PDF por skid" + error, {variant: 'error'});
                setIsDownloadPdf(false);
            },
            () => {
                enqueueSnackbar("Dados não encontrados para esse período", {variant: 'warning'});
                setIsDownloadPdf(false);
            }
        );

    }
    const handlePdfDownloadManagementReportInverter = () => {
        let apiUrl;
        switch (groupedByPeriod) {
            case "year":
                apiUrl = `api/management-report-equipment-inverter/pdf/year`
                break;
            case "month":
                apiUrl = `api/management-report-equipment-inverter/pdf/month`
                break;
            case "week":
                apiUrl = `api/management-report-equipment-inverter/pdf/week`
                break;
            default:
                apiUrl = `api/management-report-equipment-inverter/pdf/day`
        }
        const data = {
            equipmentIdsList: checkedInverters.filter(entity => entity.checked).map(entity => (entity?.data as {
                id: number
            }).id),
            startDateTime: format(new Date(startDateTime), 'yyyy-MM-dd') + 'T00:00:00',
            endDateTime: LocalDateTimeFormatISO(endDateTime)
        }

        setIsDownloadPdf(true);


        postAndDownloadFile(apiUrl, data, getFileName(groupedByPeriod, 'pdf'), () => {
                enqueueSnackbar("Sucesso ao baixar o PDF", {variant: 'success'});
                setIsDownloadPdf(false);
            }, (error) => {
                enqueueSnackbar("Erro ao baixar o PDF " + error, {variant: 'error'});
                setIsDownloadPdf(false);
            },
            () => {
                enqueueSnackbar("Dados não encontrados para esse período", {variant: 'warning'});
                setIsDownloadPdf(false);
            }
        );

    }

    const handleCsvDownloadManagementReportInverter = () => {
        const apiUrl = `api/managementReport/equipment/csv/${groupedByPeriod}`;

        const data = {
            equipmentIdsList: checkedInverters
                .filter(entity => entity.checked)
                .map(entity => (entity?.data as { id: number }).id),
            startDateTime: format(new Date(startDateTime), 'yyyy-MM-dd') + 'T00:00:00',
            endDateTime: LocalDateTimeFormatISO(endDateTime)
        };

        setIsDownloadCsv(true);
        constructedPostAndDownloadFile(apiUrl, data);
    }

    const handleExcelDownloadManagementReportInverter = () => {
        const apiUrl = `api/managementReport/equipment/excel/${groupedByPeriod}`;

        const data = {
            equipmentIdsList: checkedInverters
                .filter(entity => entity.checked)
                .map(entity => (entity?.data as { id: number }).id),
            startDateTime: format(new Date(startDateTime), 'yyyy-MM-dd') + 'T00:00:00',
            endDateTime: LocalDateTimeFormatISO(endDateTime)
        };
        genericExcelDownload(apiUrl, data, groupedByPeriod);
    }



    const handleCsvDownload = () => {
        const apiUrl = `api/managementReport/csv/${groupedByPeriod}`;

        const data = {
            powerStationIdsList: checked
                .filter(entity => entity.checked)
                .map(entity => (entity?.data as { id: number }).id),
            startDateTime: format(new Date(startDateTime), 'yyyy-MM-dd') + 'T00:00:00',
            endDateTime: LocalDateTimeFormatISO(endDateTime)
        };

        setIsDownloadCsv(true);

        constructedPostAndDownloadFile(apiUrl, data);
    }

    const handleCsvDownloadManagementReportSkid = () => {
        const apiUrl = `api/managementReport/skid/csv/${groupedByPeriod}`;

        const data = {
            skidIdsList: checkedSkids
                .filter(entity => entity.checked)
                .map(entity => (entity?.data as { id: number }).id),
            startDateTime: format(new Date(startDateTime), 'yyyy-MM-dd') + 'T00:00:00',
            endDateTime: LocalDateTimeFormatISO(endDateTime)
        };

        setIsDownloadCsv(true);

        constructedPostAndDownloadFile(apiUrl, data);
    }

    function constructedPostAndDownloadFile(apiUrl: string, data:any) {
        postAndDownloadFile(
            apiUrl,
            data,
            getFileName(groupedByPeriod, 'csv'),
            () => {
                enqueueSnackbar("Sucesso ao baixar o CSV", {variant: 'success'});
                setIsDownloadCsv(false);
            },
            (error) => {
                enqueueSnackbar("Erro ao baixar o  CSV " + error, {variant: 'error'});
                setIsDownloadCsv(false);
            },
            () => {
                enqueueSnackbar("Dados não encontrados para esse período", {variant: 'warning'});
                setIsDownloadCsv(false);
            }
        );
    }

    const genericExcelDownload = (apiUrl: string, data: any, groupedByPeriod: string) => {
        setIsDownloadExcel(true);
        postAndDownloadFile(
            apiUrl,
            data,
            getFileName(groupedByPeriod, 'xlsx'),
            () => {
                enqueueSnackbar("Sucesso ao baixar o Excel", {variant: 'success'});
                setIsDownloadExcel(false);
            },
            (error) => {
                enqueueSnackbar("Erro ao baixar o  Excel " + error, {variant: 'error'});
                setIsDownloadExcel(false);
            },
            () => {
                enqueueSnackbar("Dados não encontrados para esse período", {variant: 'warning'});
                setIsDownloadExcel(false);
            }
        );
    }

    const handleExcelDownload = () => {
        const apiUrl = `api/managementReport/excel/${groupedByPeriod}`;

        const data = {
            powerStationIdsList: checked
                .filter(entity => entity.checked)
                .map(entity => (entity?.data as { id: number }).id),
            startDateTime: format(new Date(startDateTime), 'yyyy-MM-dd') + 'T00:00:00',
            endDateTime: LocalDateTimeFormatISO(endDateTime)
        };
        genericExcelDownload(apiUrl, data, groupedByPeriod);
    }

    const handleExcelDownloadManagementReportSkid = () => {
        const apiUrl = `api/managementReport/skid/excel/${groupedByPeriod}`;

        const data = {
            skidIdsList: checkedSkids
                .filter(entity => entity.checked)
                .map(entity => (entity?.data as { id: number }).id),
            startDateTime: format(new Date(startDateTime), 'yyyy-MM-dd') + 'T00:00:00',
            endDateTime: LocalDateTimeFormatISO(endDateTime)
        };
        genericExcelDownload(apiUrl, data, groupedByPeriod);
    }

    function getOnClickByType(type: string) {
        if (type === "excel") {
            if (typeManagement === "powerStation") {
                return handleExcelDownload;
            }
            if (typeManagement === "skid") {
                return handleExcelDownloadManagementReportSkid;
            }
            return handleExcelDownloadManagementReportInverter;
        }

        if (type === "csv") {
            if (typeManagement === "powerStation") {
                return handleCsvDownload;
            }
            if (typeManagement === "skid") {
                return handleCsvDownloadManagementReportSkid;
            }
            return handleCsvDownloadManagementReportInverter;
        }

        if (typeManagement === "powerStation") {
            return handlePdfDownload;
        }
        if (typeManagement === "skid") {
            return handlePdfDownloadManagementReportSkid;
        }
        return handlePdfDownloadManagementReportInverter;
    }

    useEffect(() => {
        if(subHeader?.data) {
            setSubHeaderList(subHeader.data);
        }
    },[subHeader.isSuccess])

    useEffect(() => {
        if(subHeaderList) {
            let newData:SubHeader[] = [];

            newData.push({name: subHeaderList.generatedEnergyName ?? "", prop: "generatedEnergyName", checked: false})
            newData.push({name: subHeaderList.injectedEnergyName ?? "", prop: "injectedEnergyName", checked: false})
            newData.push({name: subHeaderList.expectedInjectedEnergyName ?? "", prop: "expectedInjectedEnergyName", checked: false})
            newData.push({name: subHeaderList.yieldFieldName ?? "", prop: "yieldFieldName", checked: false})
            newData.push({name: subHeaderList.expectedYieldName ?? "", prop: "expectedYieldName", checked: false})
            newData.push({name: subHeaderList.capacityFactorName ?? "", prop: "capacityFactorName", checked: false})
            newData.push({name: subHeaderList.irradianceName ?? "", prop: "irradianceName", checked: false})
            newData.push({name: subHeaderList.expectedIrradianceName ?? "", prop: "expectedIrradianceName", checked: false})
            newData.push({name: subHeaderList.satelitalIrradianceName ?? "", prop: "satelitalIrradianceName", checked: false})
            newData.push({name: subHeaderList.availabilityName ?? "", prop: "availabilityName", checked: false})
            newData.push({name: subHeaderList.totalAvailabilityName ?? "", prop: "totalAvailabilityName", checked: false})
            newData.push({name: subHeaderList.temperatureName ?? "", prop: "temperatureName", checked: false})
            newData.push({name: subHeaderList.performanceRatioName ?? "", prop: "performanceRatioName", checked: false})
            newData.push({name: subHeaderList.expectedPerformanceRatioName ?? "", prop: "expectedPerformanceRatioName", checked: false})
            newData.push({name: subHeaderList.dayEstimatedInjEnergyName ?? "", prop: "dayEstimatedInjEnergyName", checked: false})
            newData.push({name: subHeaderList.weekEstimatedInjEnergyName ?? "", prop: "weekEstimatedInjEnergyName", checked: false})
            newData.push({name: subHeaderList.monthEstimatedInjEnergyName ?? "", prop: "monthEstimatedInjEnergyName", checked: false})
            newData.push({name: subHeaderList.yearEstimatedInjEnergyName ?? "", prop: "yearEstimatedInjEnergyName", checked: false})
            newData.push({name: subHeaderList.generationLossesName ?? "", prop: "generationLossesName", checked: false})
            newData.push({name: subHeaderList.generatedEnergyDeviationName ?? "", prop: "generatedEnergyDeviationName", checked: false})
            newData.push({name: subHeaderList.injectedEnergyDeviationName ?? "", prop: "injectedEnergyDeviationName", checked: false})
            newData.push({name: subHeaderList.estimatedInjEnergyDeviationName ?? "", prop: "estimatedInjEnergyDeviationName", checked: false})
            newData.push({name: subHeaderList.yieldDeviationName ?? "", prop: "yieldDeviationName", checked: false})
            newData.push({name: subHeaderList.irradiationDeviationName ?? "", prop: "irradiationDeviationName", checked: false})
            newData.push({name: subHeaderList.performanceRatioDeviationName ?? "", prop: "performanceRatioDeviationName", checked: false})
            newData.push({name: subHeaderList.performanceRatioRawName ?? "", prop: "performanceRatioRawName", checked: false})
            newData.push({name: subHeaderList.operationAvailabilityName ?? "", prop: "operationAvailabilityName", checked: false})
            newData.push({name: subHeaderList.yieldRateName ?? "", prop: "yieldRateName", checked: false})
            setDataShuttle(newData);
        }
    },[subHeaderList])

    const handleListRight = (value:SubHeader[]) => {

        if(value) {
            let newData:SubReportHeader = {} as SubReportHeader;
            
            value.map(element => {

                if(element.prop === "generatedEnergyName"){
                   newData.generatedEnergyName = element.name;
                }
                if(element.prop === "injectedEnergyName"){
                   newData.injectedEnergyName = element.name;
                }
                if(element.prop === "expectedInjectedEnergyName"){
                   newData.expectedInjectedEnergyName = element.name;
                }
                if(element.prop === "yieldFieldName"){
                   newData.yieldFieldName = element.name;
                }
                if(element.prop === "expectedYieldName"){
                   newData.expectedYieldName = element.name;
                }
                if(element.prop === "capacityFactorName"){
                   newData.capacityFactorName = element.name;
                }
                if(element.prop === "irradianceName"){
                   newData.irradianceName = element.name;
                }
                if(element.prop === "expectedIrradianceName"){
                   newData.expectedIrradianceName = element.name;
                }
                if(element.prop === "satelitalIrradianceName"){
                   newData.satelitalIrradianceName = element.name;
                }
                if(element.prop === "availabilityName"){
                   newData.availabilityName = element.name;
                }
                if(element.prop === "totalAvailabilityName"){
                   newData.totalAvailabilityName = element.name;
                }
                if(element.prop === "temperatureName"){
                   newData.temperatureName = element.name;
                }
                if(element.prop === "performanceRatioName"){
                   newData.performanceRatioName = element.name;
                }
                if(element.prop === "expectedPerformanceRatioName"){
                   newData.expectedPerformanceRatioName = element.name;
                }
                if(element.prop === "dayEstimatedInjEnergyName"){
                    newData.dayEstimatedInjEnergyName = element.name;
                }
                if(element.prop === "weekEstimatedInjEnergyName"){
                   newData.weekEstimatedInjEnergyName = element.name;
                }
                if(element.prop === "monthEstimatedInjEnergyName"){
                   newData.monthEstimatedInjEnergyName = element.name;
                }
                if(element.prop === "yearEstimatedInjEnergyName"){
                   newData.yearEstimatedInjEnergyName = element.name;
                }
                if(element.prop === "generationLossesName"){
                   newData.generationLossesName = element.name;
                }
                if(element.prop === "generatedEnergyDeviationName"){
                   newData.generatedEnergyDeviationName = element.name;
                }
                if(element.prop === "injectedEnergyDeviationName"){
                   newData.injectedEnergyDeviationName = element.name;
                }
                if(element.prop === "estimatedInjEnergyDeviationName"){
                   newData.estimatedInjEnergyDeviationName = element.name;
                }
                if(element.prop === "yieldDeviationName"){
                   newData.yieldDeviationName = element.name;
                }
                if(element.prop === "irradiationDeviationName"){
                   newData.irradiationDeviationName = element.name;
                }
                if(element.prop === "performanceRatioDeviationName"){
                   newData.performanceRatioDeviationName = element.name;
                }
                if(element.prop === "performanceRatioRawName"){
                     newData.performanceRatioRawName = element.name;
                }
                if(element.prop === "operationAvailabilityName"){
                   newData.operationAvailabilityName = element.name;
                }
                if(element.prop === "yieldRateName"){
                   newData.yieldRateName = element.name;
                }
            })
            
            let apiUrl;
            switch (groupedByPeriod) {
                case "year":
                    apiUrl = `api/pdf/download/year`
                    break;
                case "month":
                    apiUrl = `api/pdf/download/month`
                    break;
                case "week":
                    apiUrl = `api/pdf/download/week`
                    break;
                default:
                    apiUrl = `api/pdf/download/day`
            }

            const montedUrl: Array<MyElement> = checked;

            const params = {
                powerStationIds: montedUrl
                    .filter(entity => entity.checked) // filtra apenas objetos com "checked" definido como true
                    .map(entity => (entity?.data as { id: number }).id) // extrai os ids da propriedade "data"
                    .join(","),
                startDateTime: format(new Date(startDateTime), 'yyyy-MM-dd') + 'T00:00:00',
                endDateTime: LocalDateTimeFormatISO(endDateTime),

            };
            const managementSubReportHeader= newData
            setIsDownloadPdf(true);

            downloadFile(apiUrl,
                params,
                'relatoriodegestao.pdf',
                () => {
                    enqueueSnackbar("Sucesso ao baixar o PDF por usina", {variant: 'success'});
                    setIsDownloadPdf(false);
                    handleCloseModal();
                },
                (error) => {
                    enqueueSnackbar("Erro ao baixar o  PDF por usina " + error, {variant: 'error'});
                    setIsDownloadPdf(false);
                },
                () => {
                    enqueueSnackbar("Dados não encontrados para esse período", {variant: 'warning'});
                    setIsDownloadPdf(false);
                    handleCloseModal();
                }
                ,
                managementSubReportHeader
            );
        }
    }

    const contentPagePaddingTop = useAppSelector((state: any) => state.layout.contentPagePaddingTop);
    return (
        <Grid item padding={1} xs={12} style={{marginTop: -56}}>
            <ACard title="Relatório de Gestão">
                <Typography variant="subtitle1" gutterBottom component="div">
                    Relatório de Gestão por:
                </Typography>
                <Box>
                    <RadioGroup
                        sx={{display: "inline", ml: 3}}
                        row
                        name="typeManagement"
                        value={typeManagement}
                        onChange={handleTypeManagement}
                    >
                        <FormControlLabel value="powerStation" control={<Radio/>} label="Usina"/>
                        <FormControlLabel value="skid" control={<Radio/>} label="Skid"/>
                        <FormControlLabel value="inverter" control={<Radio/>} label="Inversor"/>
                    </RadioGroup>
                </Box>
                <Box component="fieldset"
                     sx={{borderColor: 'transparent', padding: '0 0 16px 0', mt: '8px', mb: '16px'}}>
                    <div>
                        <FormControl sx={{m: 1, width: 300}}>
                            <InputLabel
                                id="demo-multiple-checkbox-label"
                            >
                                Usina(s)
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={checked.filter(item => item.checked).map(item => item.label)}
                                input={<OutlinedInput label="Usina(s)"/>}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                <MenuItem value="Todos">
                                    <Checkbox
                                        checked={checkedAll}
                                        onChange={handleChangeAllCheckbox}
                                    />
                                    <ListItemText primary="Todos"/>
                                </MenuItem>
                                {checked.map((nameObject, index) => (
                                    <MenuItem
                                        key={nameObject.label}
                                        value={nameObject.label}
                                        color={"default"}
                                    >
                                        <Checkbox
                                            checked={nameObject.checked}
                                            onChange={(e) => handleChangeElementCheckbox(e, index)}
                                        />
                                        <ListItemText primary={nameObject.label}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{m: 1, width: 300}}>
                            <InputLabel
                                id="demo-multiple-checkbox-label"
                            >
                                Skid(s)
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                disabled={typeManagement === "powerStation"}
                                value={checkedSkids.filter(item => item.checked).map(item => item.name)}
                                input={<OutlinedInput label="Skid(s)"/>}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                <MenuItem value="Todos">
                                    <Checkbox
                                        checked={checkedAllSkid}
                                        onChange={handleChangeAllSkidCheckbox}
                                    />
                                    <ListItemText primary="Todos"/>
                                </MenuItem>
                                {checkedSkids.map((nameObject, index) => (
                                    <MenuItem
                                        key={nameObject.name}
                                        value={nameObject.name}
                                    >
                                        <Checkbox
                                            checked={nameObject.checked}
                                            onChange={(e) => handleChangeSkidCheckbox(e, index)}
                                        />
                                        <ListItemText primary={nameObject.name}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{m: 1, width: 300}}>
                            <InputLabel
                                id="demo-multiple-checkbox-label"
                            >
                                Equipamento(s)
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                disabled={typeManagement === "powerStation" || typeManagement === "skid"}
                                value={checkedInverters.filter(item => item.checked).map(item => item.name)}
                                input={<OutlinedInput label="Equipamento(s)"/>}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value="Todos">
                                    <Checkbox
                                        checked={checkedAllInverter}
                                        onChange={handleChangeAllInverterCheckbox}
                                    />
                                    <ListItemText primary="Todos"/>
                                </MenuItem>
                                {checkedInverters.map((nameObject, index) => (
                                    <MenuItem
                                        key={nameObject.name}
                                        value={nameObject.name}
                                    >
                                        <Checkbox
                                            checked={nameObject.checked}
                                            onChange={(e) => handleChangeInverterCheckbox(e, index)}
                                            title={nameObject.name}
                                        />
                                        <ListItemText primary={nameObject.name}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </div>
                </Box>


                <Typography variant="subtitle1" gutterBottom component="div">
                    Agrupamento:
                </Typography>
                <Box>
                    <RadioGroup
                        sx={{display: "inline", ml: 3}}
                        row
                        name="groupedByPeriod"
                        value={groupedByPeriod}
                        onChange={handlePeriodChange}
                    >
                        <FormControlLabel value="day" control={<Radio/>} label="Diário"/>
                        <FormControlLabel value="week" control={<Radio/>} label="Semanal"/>
                        <FormControlLabel value="month" control={<Radio/>} label="Mensal"/>
                        <FormControlLabel value="year" control={<Radio/>} label="Anual"/>
                    </RadioGroup>
                </Box>

                <Box component="fieldset"
                     sx={{borderColor: 'transparent', padding: '0 0 16px 0', mt: '8px', mb: '16px'}}>
                    <Typography variant="subtitle1" gutterBottom component="div">
                        Período:
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker<Date>
                            label="Início"
                            ampm={false}
                            inputFormat="DD/MM/YYYY"
                            value={startDateTime}
                            onChange={(newValue: Date | null) => {
                                setStartDateTime(newValue ?? handleStartDateTime(new Date()));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DateTimePicker<Date>
                            label="Fim"
                            ampm={false}
                            inputFormat="DD/MM/YYYY"
                            value={endDateTime}
                            onChange={(newValue) => {
                                setEndDateTime(newValue ?? new Date());
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box>
                    {/* Botão de Download do PDF */}
                    {
                        isDownloadPdf ?
                            <LoadingButton
                                loading
                                loadingPosition="end"
                                endIcon={<SaveIcon sx={{fontSize: 20}}/>}
                            >
                                Carregando..
                            </LoadingButton>
                            :
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!isAuthorizedToSearchByPdf}
                                onClick={getOnClickByType("pdf")}
                                startIcon={<Download/>}
                                sx={{mr: 1}}
                            >
                                PDF
                            </Button>
                    }
                    {/* Botão de Download do CSV */}
                    {
                        isDownloadCsv ?
                            <LoadingButton
                                loading
                                loadingPosition="end"
                                endIcon={<SaveIcon sx={{fontSize: 20}}/>}
                            >
                                Carregando..
                            </LoadingButton>
                            :
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!isAuthorizedToSearchByCsv}
                                onClick={getOnClickByType("csv")}
                                startIcon={<Download/>}
                                sx={{mr: 1}}
                            >
                                CSV
                            </Button>
                    }
                    {/* Botão de Download do Excel */}
                    {
                        isDownloadExcel ?
                            <LoadingButton
                                loading
                                loadingPosition="end"
                                endIcon={<SaveIcon sx={{fontSize: 20}}/>}
                            >
                                Carregando..
                            </LoadingButton>
                            :
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!isAuthorizedToSearchByExcel}
                                onClick={getOnClickByType("excel")}
                                startIcon={<Download/>}
                                sx={{mr: 1}}
                            >
                                Excel
                            </Button>
                    }
                </Box>
                <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ 
                    position: 'absolute',
                    top: '55%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: window.innerWidth > 1440 ? '50%' : '85%',
                    height: '85%',
                    bgcolor: 'background.paper',
                    border: '1px solid #666',
                    borderRadius: 5
                }}>
                    <ACard
                        title="Montar relatório em PDF"
                        subTitle="Selecione 14 item para gerar o relatório"
                        styleProps={{
                            cardStyle: {overflow:"auto", padding: 2, height: '100%'}
                        }}
                    >

                        <SelectAllTransferList data={dataShuttle} maxItem={14} handleListRight={handleListRight}/>
                    </ACard>
                </Box>
            </Modal>
               
            </ACard>
        </Grid>
    );
}


export default function ManagementReportPage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ManagementReport />
        </QueryClientProvider>
    );
}