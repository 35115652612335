import {Box, TextField, Tooltip, useTheme} from "@mui/material";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import React, {useCallback, useEffect, useState} from "react";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import IconButton from "@mui/material/IconButton";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {useNavigate} from "react-router-dom";
import {DeleteAlarmSeverityReactQuery, AlarmSeverityReactQuery} from "./AlarmSeverityReactQuery";
import {QueryClient, QueryClientProvider} from "react-query";
import {HeaderMenu} from "../HeaderMenu";
import {useAppSelector} from "../../../Config/Hooks";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";

const initColumns = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'id', condition: 'equals', value: '', sort: ''}
    },
    {
        name: 'name',
        label: 'Nome',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'name', condition: 'contains', value: '', sort: 'asc'}

    },
    {
        name: 'color',
        label: 'Cor',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },

    },
] as ADataGridColumn [];

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowAlarmSeverity {
    id: number;
    name: string;
    color: string;
    actionsConfig?: Array<ActionConfig>
}

export function AlarmSeveritySettingsPage(){
    const navigate = useNavigate();
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.SAVE_ALARM_SEVERITY
    ]);

    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.DELETE_ALARM_SEVERITY
    ]);
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [selectedToDeleteName, setSelectedToDeleteName] = useState<string | null>(null);

    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50));

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        const newDataGrid:Array<ADataGridFilter> = dataGridFilters.map(filter => {
            filter.value = filter.value.replace(/#/g, "");
            return filter;

        })
        let newPageParams: PagedSearchParams = new PagedSearchParams(newDataGrid, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    const mutation = DeleteAlarmSeverityReactQuery(pagedSearchParams);

    const { data, isSuccess } = AlarmSeverityReactQuery(pagedSearchParams);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [rows, setRows] = useState<Array<RowAlarmSeverity>>([]);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if(isSuccess){
            setTotalOfRecords(data?.totalOfRecords);
            if(data?.data){
                let rowsEquipment = data.data.map((alarmSeverity: any) => {
                    return {
                        id: alarmSeverity.id,
                        name: alarmSeverity.name,
                        color: <Box style={{display: "flex", alignItems: "center"}}>

                            <TextField
                                error={alarmSeverity.color === ""}
                                fullWidth
                                id="type-color"
                                variant="standard"
                                type="color"
                                disabled={true}
                                value={`#${alarmSeverity.color}`}
                                style={{width: 24, marginRight: 5}}
                                sx={{
                                    '& ::before': {
                                        content: 'none',
                                    },
                                }}
                            />
                            <p style={{width: "80%"}}>#{alarmSeverity.color}</p>
                        </Box>
                        ,
                        actionsConfig: [
                            {actionName: "edit", disabled: !isAuthorizedToSave},
                            {actionName: "delete", disabled: !isAuthorizedToDelete},
                        ]
                    }
                });
                setRows(rowsEquipment);
            }
        }
    }, [data?.data]);

    const handleEditAlarmSeverity = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_ALARM_SEVERITY_FORM, { state: { id: data.id } });
    },[navigate]);

    const handleDeleteAlarmSeverity = useCallback((data: any) => {
        setSelectedToDelete(data.id);
        setSelectedToDeleteName(data.name);
        setOpenDialog(true);
    },[]);

    const handleDialogConfirm = async () => {
        mutation.mutate({ id: selectedToDelete, pagedSearchParams });
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    useEffect(() => {
        if(mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50))
        }

    }, [mutation.isSuccess]);

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        setSelectedToDelete(null);
        setSelectedToDeleteName(null);
        handleDialogToggle();
    }

    const theme = useTheme();
    return(
       <>
           <HeaderMenu systemRoutes={SystemRoutes.SETTINGS_ALARM_SEVERITY_FORM} disableSave={!isAuthorizedToSave}/>
           <Box>
               <ACard>
                   <ADataGrid
                       hideFilters={false}
                       showFilterOptions={false}
                       hideSelection={false}
                       columns={initColumns}
                       rows={rows}
                       page={pagedSearchParams.page}
                       rowsPerPage={pagedSearchParams.size}
                       loading={false}
                       totalOfRecords={totalOfRecords}
                       onFiltersChange={handleOnFiltersChange}
                       actions={
                           <>
                               <IconButton name="edit" size={"small"} aria-label="Edit" disabled={!isAuthorizedToSave} onClick={(value) => handleEditAlarmSeverity(value)}>
                                   <Tooltip title="Editar">
                                       <Edit/>
                                   </Tooltip>
                               </IconButton>
                               <IconButton name="delete" size={"small"} aria-label="Delete" disabled={!isAuthorizedToDelete} onClick={(value) => handleDeleteAlarmSeverity(value)}>
                                   <Tooltip title="Excluir">
                                       <Delete />
                                   </Tooltip>
                               </IconButton>
                           </>
                       }
                   />
               </ACard>
           </Box>
           <ConfirmDialog
               title={'Deletar'}
               description={`Deseja deletar a severidade ${selectedToDeleteName}?`}
               open={openDialog}
               handleConfirm={handleDialogConfirm}
               handleClose={handleDialogClose}
           />
       </>
    );

}

export function AlarmSeverityHomePage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <AlarmSeveritySettingsPage />
        </QueryClientProvider>
    )
}
