import React, {FunctionComponent, useEffect, useState} from "react";
import {RootState} from "../../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {GenerationEntity, getEntities} from "./GenerationPRChartPlantReducer.reducer";
import {differenceInDays, format, isValid} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faFileCsv
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {isArray} from "lodash";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {useSnackbar} from "notistack";
import {
    Period,
    ACard,
    APopoverIcon,
    AChartX,
    PeriodSelector,
    XAxis,
    YAxis,
    Skeleton
} from "@atiautomacao/ati-ui-library";
import {checkDiffDays} from "../../../../Utils/DataUitils";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";
import {useTheme} from "@mui/material";

const GenerationPRChartPlant: FunctionComponent<{powerPlant: any;}> = ({powerPlant}) => {
    const theme = useTheme();
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const entities: Array<GenerationEntity> = useAppSelector((state: RootState) => state.generationPRChartPlant.entities);
    const { loading } = useAppSelector((state: RootState) => state.generationPRChartPlant);
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [showGraph, setShowGraph] = useState(false);

    const initialPeriod : Period = {
        groupBy : "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const [period, setPeriod] = useState<Period>(initialPeriod);
    const max = () => {
        const maxGeneratedValue = Array.isArray(entities) ? Math.max(...entities.map(item => item.performanceRatioValue) ) : 0
        const maxExpectedValue = Array.isArray(entities) ? Math.max(...entities.map(item => item.expectedPerformanceRatio)) : 0
        return  maxGeneratedValue > maxExpectedValue ? maxGeneratedValue : maxExpectedValue;
    }

    const [performanceRatioData, setPerformanceRatioData] = useState<Array<number>>(Array<number>());
    const [expectedPerformanceRatio, setExpectedPerformanceRatioData] = useState<Array<number>>(Array<number>());
    const [availability, setAvailability] = useState<Array<number>>(Array<number>());
    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<Array<YAxis>>( Array<YAxis>());
    const colors = ['#5470C6', '#EE6666', '#9FE080'];
    const userConfig = useAppSelector((state: RootState) => state.userConfigurarion);
    const valueFormatter = (value:number,unity:string) => {
        return  value + ' ' + unity
    }
    function findGeneratedPRDataByPeriod() {

        let datePeriod: any[] = [];

        if (period.groupBy.valueOf() === 'year') {
            datePeriod = entities
                .map(item => isValid(new Date(item.dateTime)) && format(new Date(item.dateTime +"T00:00:00"), "MMM/yy", {locale: ptBR}))

        } else if (period.groupBy.valueOf() === "week" || period.groupBy.valueOf() === "month" || period.groupBy.valueOf() === "none") {
            datePeriod = entities.map(item =>isValid(new Date(item.dateTime)) &&
                format(new Date(item.dateTime +"T00:00:00"), 'dd/MM')
            );
        }else if (period.groupBy.valueOf() === "general" ) {
            datePeriod = entities.map(item =>isValid(new Date(item.dateTime)) &&
                format(new Date(item.dateTime+"T00:00:00"), 'yyyy',{locale: ptBR})
            );
        }

        const newNameGap = () => {
            if (period.groupBy) {
                switch (period.groupBy) {
                    case "week":
                        return 45;
                    case "month":
                        return 46;
                    case "year":
                        return 50;
                    case "general":
                        return 50;
                    default:
                        return 40;
                }
            }
            return 40;
        };

        setShowGraph(datePeriod.length > 0);
        setXAxis({data: datePeriod, nameGap:30,  nameLocation:'middle'});
        setPerformanceRatioData(Array.isArray(entities) ? entities.map(item => item.performanceRatioValue) : []);
        setExpectedPerformanceRatioData(Array.isArray(entities) ? entities.map(item => item.expectedPerformanceRatio) : []);
        setAvailability(Array.isArray(entities) ? entities.map(item => truncateNumber(item.availability, 2)) : []);
        const maxValue = max();
        const maxInterval = maxValue/4;
        setYAxis([{
            name: '%',
            max: Math.min(100, maxValue),
            min: 0,
            interval: truncateNumber(Math.min(25, maxInterval)),
            nameGap: newNameGap(),
            nameLocation: "middle"
        }])
    }

    useEffect(() => {
        if(period.groupBy){
            if( (period.groupBy.includes('week') || period.groupBy.includes('month')) && (period.toDateTime && period.fromDateTime && differenceInDays(period.toDateTime, period.fromDateTime) > 30)){
                enqueueSnackbar("Escolha um intervalo de datas com até 30 dias", {variant: 'error'})
            }else if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0) {
                dispatch(getEntities({period, powerPlant}));
            }
        }
    },[period, powerPlant, dispatch]);

    useInterval(() => {
        if(period.groupBy){
            if( (period.groupBy.includes('week') || period.groupBy.includes('month')) && (period.toDateTime && period.fromDateTime && differenceInDays(period.toDateTime, period.fromDateTime) > 30)){
                enqueueSnackbar("Escolha um intervalo de datas com até 30 dias", {variant: 'error'})
            }else if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0) {
                dispatch(getEntities({period, powerPlant}));
            }
        }
    }, userConfig.mainUpdateInterval) // 5 minutes

    useEffect(() => {
        if(isArray(entities) && period.groupBy){
            findGeneratedPRDataByPeriod();
        }
    },[entities, period]);


// @ts-ignore
    const option: AChartXProps['option'] = {
        color: colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 40
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        legend: {
            show: true,
            orient: 'horizontal', // Muda para orientação horizontal
            bottom: 10, // Posiciona a legenda na parte inferior do gráfico
            left: 'center', // Centraliza a legenda horizontalmente
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: ['PR Real', 'PR Esperado', 'Disponibilidade']
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: [
            {
                name: 'PR Real',
                data: performanceRatioData,
                type: 'bar',
                unity: '%',
                smooth: true,
                tooltip: {
                    valueFormatter: (value: number) => valueFormatter( value,'%')
                },
            },
            {
                name: 'PR Esperado',
                data: expectedPerformanceRatio,
                type: 'line',
                unity: '%',
                smooth: true,
                tooltip: {
                    valueFormatter: (value: number) => valueFormatter( value, '%')
                },
            },
            {
                name: 'Disponibilidade',
                data: availability,
                type: 'line',
                unity: '%',
                smooth: true,
                tooltip: {
                    valueFormatter: (value: number) => valueFormatter( value, '%')
                },
            }
        ]
    };


    return(
        <>
            <ACard
                key={`chart-power-pr-${openSubMenu}`}
                styleProps={{
                    cardStyle: {height: 445},
                    contentStyle: {padding: 0}
                }}
                title="Performance Ratio (%)"
                headerControls={
                    <>
                        <PeriodSelector
                            styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                            mode="hybrid"
                            hideDatetime={false}
                            disableHourSelector={true}
                            inputFormat={'dd/MM/yyyy'}
                            hideGroup={false}
                            periodInterval={{
                                week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
                                month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
                                year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
                                general: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 7}
                            }}
                            disabled={loading}
                            period={period}
                            onChange={setPeriod}
                            groupByOptions={["week", "month", "year", "general"]}
                        />
                    </>
                }
                // headerActions={
                //     <>
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                //             text="Expand"
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                //             text="Print"
                //         />
                //     </>
                // }
                footerActions={
                    <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                        <IconButton disabled={true}>
                            <FontAwesomeIcon icon={faFileCsv} fontSize={20} />
                        </IconButton>
                    </APopoverIcon>
                }
            >
                {
                    loading ?
                        <Skeleton animation="wave" height={289} variant="rounded" width={"100%"} />
                        :
                        xAxis && yAxis && showGraph ?
                            <AChartX
                                option={option}
                                width={'100%'}
                                height={289}
                                loading={false}
                                theme={theme.palette.mode}
                            />
                            :
                            <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
                }
            </ACard>
        </>
    );
}
export default GenerationPRChartPlant;