import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {ACard} from "@atiautomacao/ati-ui-library";
import {
    Container,
    TextField,
    InputLabel,
    FormHelperText, Select, MenuItem, Checkbox,
} from "@mui/material";
import Box from "@mui/material/Box";

import {
    FindAllEquipmentTypeReactQuery,
    FindAllPowerStationToEquipmentReactQuery,
    FindEquipmentByIdReactQuery,
    SaveEquipmentReactQuery
} from "./EquipmentReactQuery";
import {EquipmentProps} from "../../../Shared/Types/Equipment";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {HeaderMenu} from "../HeaderMenu";
import {EquipmentClassType, EquipmentType} from "../../../Shared/Types/EquipmentClass";
import {
    FindAllEquipmentClassNotPageReactQuery,
} from "../EquipmentClass/EquipmentClassReactQuery";
import {PowerStationProps} from "../../../Shared/Types/PowerStation";
import {MenuProps} from "../../../Utils/PropsStyles";

export const EquipmentFormPage = () => {
    const location = useLocation();
    const id = location.state?.id as string;
    const navigate = useNavigate();

    const mutationSave = SaveEquipmentReactQuery();
    const mutationFindById = FindEquipmentByIdReactQuery();
    const mutationFindAllEquipmentClass = FindAllEquipmentClassNotPageReactQuery();
    const mutationFindAllPowerStation = FindAllPowerStationToEquipmentReactQuery();
    const mutationFindAllEquipmentType = FindAllEquipmentTypeReactQuery();

    const [equipmentName, setEquipmentName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [seriesNumber, setSeriesNumber] = useState<string>("");
    const [equipmentEts, setEquipmentEts] = useState<any>(null);
    const [equipmentAddressUtr, setEquipmentAddressUtr] = useState<string>("");
    const [equipmentClass, setEquipmentClass] = useState<number>(-1);
    const [equipmentType, setEquipmentType] = useState<number>(-1);
    const [equipmentClassList, setEquipmentClassList] = useState<EquipmentClassType[]>(mutationFindAllEquipmentClass.data ?? []);
    const [equipmentTypeList, setEquipmentTypeList] = useState<EquipmentType[]>(mutationFindAllEquipmentType.data ?? []);
    const [powerStation, setPowerStation] = useState<number>(-1);
    const [powerStationList, setPowerStationList] = useState<PowerStationProps[]>([]);
    const [skid, setSkid] = useState<number>(-1);
    const [isHidden, setIsHidden] = useState<boolean>(false);

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    let equipment = data?.data as EquipmentProps;
                    setEquipmentName(equipment.name);
                    setDescription(equipment.description);
                    setSeriesNumber(equipment.seriesNumber);
                    setEquipmentEts(equipment.ets);
                    setEquipmentAddressUtr(equipment.utrIpAddress);
                    if(equipment.equipmentClass.id){
                        setEquipmentClass(equipment.equipmentClass.id);
                    }
                    if(equipment.equipmentType.id){
                        setEquipmentType(equipment.equipmentType.id);
                    }
                    setPowerStation(equipment.powerStation.id);
                    if(equipment.skid?.id){
                        setSkid(equipment.skid.id);
                    }
                    setIsHidden(equipment.isHidden);
                }
            });
        }
    }, []);

    useEffect(() => {
        setEquipmentClassList(mutationFindAllEquipmentClass.data ?? [])
    }, [mutationFindAllEquipmentClass.data]);

    useEffect(() => {
        setEquipmentTypeList(mutationFindAllEquipmentType.data ?? [])
    }, [mutationFindAllEquipmentType.data]);

    useEffect(() => {
        setPowerStationList(mutationFindAllPowerStation.data ?? [])
    }, [mutationFindAllPowerStation.data]);

    function handleSave() {
        const newEquipmentClass = equipmentClassList.find((entity) => entity.id == equipmentClass);
        const newEquipmentType = equipmentTypeList.find((entity) => entity.id == equipmentType);
        const newPoweStation = powerStationList.find((entity) => entity.id == powerStation);
        const newSkid = newPoweStation?.skidList.find((entity) => entity.id == skid);
        if(newEquipmentClass && newPoweStation && newEquipmentType) {
            let newEquipment: EquipmentProps = {
                id: id != null ? Number(id) : null,
                name: equipmentName,
                ets: equipmentEts,
                seriesNumber: seriesNumber,
                description: description,
                utrIpAddress: equipmentAddressUtr,
                equipmentType: newEquipmentType,
                equipmentClass: newEquipmentClass,
                powerStation: newPoweStation,
                skid: newSkid ?? null,
                isSelected: false,
                isHidden: isHidden

            }
            mutationSave.mutate(newEquipment, {
                onSuccess: () => {
                    navigate(SystemRoutes.SETTINGS_EQUIPMENT);
                }
            });
        }
    }

    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_EQUIPMENT}/>
            <Box>
                <ACard>
                    <Container style={{
                        marginLeft: -10,
                        display: 'flex',
                        flexDirection: window.innerWidth <= 900 ? "column": "row",
                        alignItems: 'left',
                        justifyContent: 'left',
                        columnGap: 30
                    }}>
                        <div style={{width: '100%'}}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="type-name">Nome</InputLabel>
                                <TextField
                                    error={equipmentName === ""}
                                    fullWidth
                                    id="type-name"
                                    variant="standard"
                                    placeholder={"Nome do equipamento"}
                                    onChange={(value) => setEquipmentName(value.target.value)}
                                    value={equipmentName}
                                />
                                {
                                    equipmentName === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="Ets">ETS</InputLabel>
                                <TextField
                                    fullWidth
                                    id="Ets"
                                    variant="standard"
                                    placeholder={"Número do ETS"}
                                    type="number"
                                    onChange={(value) => setEquipmentEts(value.target.value)}
                                    value={equipmentEts}
                                />
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="utr-ip-address">IP UTR</InputLabel>
                                <TextField
                                    fullWidth
                                    id="utr-ip-address"
                                    variant="standard"
                                    placeholder={"Endereço IP da UTR"}
                                    onChange={(value) => setEquipmentAddressUtr(value.target.value)}
                                    value={equipmentAddressUtr}
                                />
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="description">Descrição</InputLabel>
                                <TextField
                                    fullWidth
                                    id="description"
                                    variant="filled"
                                    multiline
                                    rows={3}
                                    placeholder={"Descrição do equipamento"}
                                    onChange={(value) => setDescription(value.target.value)}
                                    value={description}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Box>
                            <Box style={{width: 250}}>
                                <InputLabel>Ocultar Equipamento da Árvore</InputLabel>
                                <Checkbox checked={isHidden} onChange={() => setIsHidden(!isHidden)}/>
                            </Box>
                        </div>
                        <div style={{width: '100%'}}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="selectEquipmentClass">Classe de equipamento</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="selectEquipmentClass"
                                    id="selectEquipmentClass"
                                    placeholder={"Selecione a classe"}
                                    value={equipmentClass}
                                    label="Classe"
                                    onChange={(value) => setEquipmentClass(value.target.value as number)}
                                    variant={"standard"}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        equipmentClassList.map((entity: EquipmentClassType) => {
                                            if(entity.id) {
                                                return (
                                                    <MenuItem key={entity.id} value={entity.id}>{entity.name}</MenuItem>
                                                )
                                            }

                                        })
                                    }
                                </Select>
                                { equipmentClass == -1 &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>

                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="selectEquipmentType">Tipo de equipamento</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="selectEquipmentType"
                                    id="selectEquipmentType"
                                    placeholder={"Selecione o tipo de equipamento"}
                                    value={equipmentType}
                                    label="Classe"
                                    onChange={(value) => setEquipmentType(value.target.value as number)}
                                    variant={"standard"}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        equipmentTypeList.map((entity: EquipmentType) => {
                                            if(entity.id) {
                                                return (
                                                    <MenuItem key={entity.id} value={entity.id}>{entity.name}</MenuItem>
                                                )
                                            }

                                        })
                                    }
                                </Select>
                                { equipmentClass == -1 &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>

                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="seriesNumber">Número de série</InputLabel>
                                <TextField
                                    fullWidth
                                    id="seriesNumber"
                                    variant="standard"
                                    placeholder={"Número de série"}
                                    onChange={(value) => setSeriesNumber(value.target.value)}
                                    value={seriesNumber}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="selectPowerStation">Usina</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="selectPowerStation"
                                    id="selectPowerStation"
                                    placeholder={"Selecione a usina"}
                                    value={powerStation}
                                    label="Tipo"
                                    onChange={(value) => setPowerStation(value.target.value as number)}
                                    variant={"standard"}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        powerStationList.map((entity) => {
                                            return (
                                                <MenuItem key={entity.id} value={entity.id}>{entity.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                { powerStation == -1 &&
                                    <FormHelperText id="my-helper-text-power-station">Campo Obrigatório.</FormHelperText>

                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="selectSkid">Skid</InputLabel>
                                <Select
                                    fullWidth
                                    disabled={powerStation < 1}
                                    labelId="selectSkid"
                                    id="selectSkid"
                                    placeholder={"Selecione a skid"}
                                    value={skid}
                                    label="Tipo"
                                    onChange={(value) => setSkid(value.target.value as number)}
                                    variant={"standard"}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        powerStationList?.find(entity => entity.id === powerStation)?.skidList.map((entity) => {
                                            return (
                                                <MenuItem key={entity.id} value={entity.id}>{entity.name}</MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                                { powerStation == -1 &&
                                    <FormHelperText id="my-helper-text-power-station">É necessário selecionar uma usina primeiro.</FormHelperText>
                                }
                            </Box>
                        </div>
                    </Container>
                </ACard>
            </Box>
        </>
    )
}

export function EquipmentHomePageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <EquipmentFormPage/>
        </QueryClientProvider>
    )
}