import {Route, Routes} from "react-router-dom";
import LoginPage from "../Modules/Login/LoginPage";
import {SystemRoutes} from "../Utils/RouteUtils";
import ProtectedRoute from "../Shared/Auth/ProtectedRoute";
import {AUTHORITIES} from "./Constants";
import DashBoardHeaderPage from "../Modules/Dashboard/DashboardHeaderPage";
import DashboardOverviewLayoutPage from "../Modules/Dashboard/Overview/DashboardOverviewLayoutPage";
import DashboardOverviewChartsPage from "../Modules/Dashboard/Overview/DashboardOverviewChartsPage";
import DashboardOverviewMapPage from "../Modules/Dashboard/Overview/DashboardOverviewMapPage";
import DashboardOverviewAlarmsPage from "../Modules/Dashboard/Overview/DashboardOverviewAlarmsPage";
import DashboardPowerPlantLayoutPage from "../Modules/Dashboard/Powerplant/DashboardPowerPlantLayoutPage";
import DashboardPowerPlantChartsPage from "../Modules/Dashboard/Powerplant/DashboardPowerPlantChartsPage";
import DashboardPowerPlantAlarmsPage from "../Modules/Dashboard/Powerplant/DashboardPowerPlantAlarmsPage";
import DashboardPowerPlantSkidLayoutPage from "../Modules/Dashboard/Skid/DashboardPowerPlantSkidLayoutPage";
import DashboardPowerPlantSkidChartsPage from "../Modules/Dashboard/Skid/DashboardPowerPlantSkidChartsPage";
import DashboardPowerPlantSkidAlarmsPage from "../Modules/Dashboard/Skid/DashboardPowerPlantSkidAlarmsPage";
import DashboardPowerPlantSkidEquipmentsOverviewPage
    from "../Modules/Dashboard/Skid/DashboardPowerPlantSkidEquipmentsOverviewPage";
import DashboardPowerPlantSkidEquipmentsDetailPage
    from "../Modules/Dashboard/Skid/DashboardPowerPlantSkidEquipmentsDetailPage";
import DashboardPowerPlantSkidEquipmentsComparePage
    from "../Modules/Dashboard/Skid/DashboardPowerPlantSkidEquipmentsComparePage";
import AnalysisEditPage from "../Modules/Analysis/AnalysisEditPage";
import CountrySavePage from "../Modules/Settings/Country/CountrySavePage";
import CountryPage from "../Modules/Settings/Country/CountryPage";
import MacroRegionSettingsPage from "../Modules/Settings/MacroRegionSettingsPage";
import {EquipmentTypeHomePage} from "../Modules/Settings/EquipmentType/EquipmentTypeHomePage";
import EquipmentClassSettingsPage from "../Modules/Settings/EquipmentClass/EquipmentClassSettingsPage";
import SkidSettingsPage from "../Modules/Settings/SkidSettingsPage";
import ProfilesSettingsPage from "../Modules/Settings/Profiles/ProfilesSettingsPage";
import UsersSettingsPage from "../Modules/Settings/Users/UsersSettingsPage";
import AuditSettingsPage from "../Modules/Settings/AuditSettingsPage";
import SettingsHomePage from "../Modules/Settings/SettingsHomePage";
import AlarmsReportPage from "../Modules/Reports/AlarmsReportPage";
import PerformanceSummaryReportPage from "../Modules/Reports/PerformanceSummaryReportPage";
import PowerPlantsReportPage from "../Modules/Reports/PowerPlantsReportPage";
import EquipmentReportPage from "../Modules/Reports/EquipmentReportPage";
import EquipmentClassesReportPage from "../Modules/Reports/EquipmentClassesReportPage";
import SkidsReportPage from "../Modules/Reports/SkidsReportPage";
import TeleobjectsReportPage from "../Modules/Reports/TeleObjectsReport/TeleobjectsReportPage";
import AuditReportPage from "../Modules/Reports/AuditReportPage";
import ReportsHomePage from "../Modules/Reports/ReportsHomePage";
import HelpPage from "../Modules/Help/HelpPage";
import Logout from "../Modules/Login/Logout";
import PageNotFound from "../Shared/Error/PageNotFound";
import * as React from "react";
import ProfilesSaveSettingsPage from "../Modules/Settings/Profiles/ProfilesSaveSettingsPage";
import TeleObjectSpecificationTypeSettingsPage from "../Modules/Settings/TeleObjectSpecificationType/TeleObjectSpecificationTypeSettingsPage";
import TeleObjectTypeSettingsPage from "../Modules/Settings/TeleObjectType/TeleObjectTypeSettingsPage";
import ModBusObjectsTypesSettingsPage from "../Modules/Settings/ModBusObjectType/ModBusObjectsTypesSettingsPage";
import DashboardOverviewSynopticPage from "../Modules/Dashboard/Overview/DashboardOverviewSynopticPage";
import DashboardOverviewAtivePowerPage from "../Modules/Dashboard/Overview/DashboardOverviewActivePowerPage";
import AnalysisHomePage from "../Modules/Analysis/AnalysisHomePage";
import DashboardPowerPlantSkidTrackersPage from "../Modules/Dashboard/Powerplant/DashboardPowerPlantTrackersPage";
import EquipmentClassFormPage from "../Modules/Settings/EquipmentClass/EquipmentClassFormPage";
import TeleObjectSpecificationTypePageForm
    from "../Modules/Settings/TeleObjectSpecificationType/TeleObjectSpecificationTypeFormPage";
import TeleObjectTypePageForm
    from "../Modules/Settings/TeleObjectType/TeleObjectTypeFormPage";
import ModBusObjectsTypesPageForm from "../Modules/Settings/ModBusObjectType/ModBusObjectsTypesFormPage";
import UserHomePageForm from "../Modules/Settings/Users/UsersFormPage";
import {EquipmentTypeHomePageForm} from "../Modules/Settings/EquipmentType/EquipmentTypeFormPage";
import { AlarmSeverityHomePage } from "../Modules/Settings/AlarmSeverity/AlarmSeverityHomePage";
import {AlarmSeverityHomePageForm} from "../Modules/Settings/AlarmSeverity/AlarmSeverityHomePageForm";
import GraficElementHomePage, {
} from "../Modules/Settings/GraficElement/GraficElementSettingsPage";
import GraficElementPageForm from "../Modules/Settings/GraficElement/GraficElementFormPage";
import {EquipmentHomePage} from "../Modules/Settings/Equipment/EquipmentHomePage";
import {PowerStationHomePage} from "../Modules/Settings/PowerStation/PowerStationHomePage";
import {PowerStationHomePageForm} from "../Modules/Settings/PowerStation/PowerStationFormPage";
import {EquipmentHomePageForm} from "../Modules/Settings/Equipment/EquipmentFormPage";
import {BrokerHomePage} from "../Modules/Settings/Broker/BrokerHomePage"
import {BrokerHomePageForm} from "../Modules/Settings/Broker/BrokerFormPage"
import {RegionHomePage} from "../Modules/Settings/Region/RegionHomePage";
import {RegionHomePageForm} from "../Modules/Settings/Region/RegionFormPage";
import GraphElementSettingsPageHome from "../Modules/Settings/GraphElement/GraphElementSettingsPage";
import GraphElementFormPageHome from "../Modules/Settings/GraphElement/GraphElementFormPage";
import ModBusDataTypeHomePage from "../Modules/Settings/ModBusDataType/ModBusDataTypesSettingsPage";
import ModBusDataTypesPageForm from "../Modules/Settings/ModBusDataType/ModBusDataTypesFormPage";
import ManagementReportPage from "../Modules/Reports/ManagementReport/ManagementReportPage";
import DashboardPowerPlantInverterPage from "../Modules/Dashboard/Powerplant/DashboardPowerPlantInverterPage";
import {PowerStationExcelPage} from "../Modules/Settings/PowerStationExcel/PowerStationExcelPage";
import TeleCommandHomePage from "../Modules/Communication/TelecommandPage";
import CommunicationHome from "../Modules/Communication/CommunicationHome";
import NotificationGroupHomePage, {NotificationGroupPage} from "../Modules/Settings/NotificationGroup/NotificationGroupPage";
import NotificationGroupPageForm, {NotificationGroupFormPage} from "../Modules/Settings/NotificationGroup/NotificationGroupFormPage";
import PowerStationUserHomePage from "../Modules/Settings/PowerStationUser/PowerStationUserHomePage";
import PowerStationUserPageForm from "../Modules/Settings/PowerStationUser/PowerStationUserFormPage";
import AuditCommandsPage from "../Modules/Audit/AuditCommands/AuditCommandsPage";
import AuditAlarmsPage from "../Modules/Audit/AuditAlarms/AuditAlarmsPage";
import AuditLoginPage from "../Modules/Audit/AuditLogin/AuditLoginPage";

export default function AppRoutes(){
    return (
        <Routes>
            <Route path="/" element={
                <LoginPage />
            } />

            {/*   DASHBOARD OVERVIEW ROUTES   */}
            <Route path={SystemRoutes.DASHBOARD} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, 
                    AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_CHART
                ]}>
                    <DashBoardHeaderPage />
                </ProtectedRoute>
            } />


            <Route path={SystemRoutes.DASHBOARD_OVERVIEW} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, 
                    AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_CHART,
                   ]}>
                    <DashboardOverviewChartsPage />
                </ProtectedRoute>
            } />



            <Route path={SystemRoutes.DASHBOARD_OVERVIEW_LAYOUT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD
                ]}>
                    <DashboardOverviewLayoutPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_OVERVIEW_CHARTS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_CHART
                   
                   ]}>
                    <DashboardOverviewChartsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_OVERVIEW_MAP} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_MAP
                ]}>
                    <DashboardOverviewMapPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_OVERVIEW_ALARMS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_ALARM
                   
                   ]}>
                    <DashboardOverviewAlarmsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_OVERVIEW_SYNOPTIC} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_SYNOPTIC
                   
                   ]}>
                    <DashboardOverviewSynopticPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_OVERVIEW_ACTIVE_POWER} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_ACTIVE_POWER
                   
                   ]}>
                    <DashboardOverviewAtivePowerPage />
                </ProtectedRoute>
            } />


            {/*   DASHBOARD POWER PLANT ROUTES   */}

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_OVERVIEW} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_POWER_STATION,
                    AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_CHART
                    
                ]}>
                    <DashboardPowerPlantChartsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_LAYOUT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_POWER_STATION,
                    
                ]}>
                    <DashboardPowerPlantLayoutPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_CHARTS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_POWER_STATION,
                    AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_CHART
                    
                ]}>
                    <DashboardPowerPlantChartsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_ALARMS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN,  AUTHORITIES.VIEW_DASHBOARD,AUTHORITIES.VIEW_DASHBOARD_POWER_STATION,
                    AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_ALARM
                    
                ]}>
                    <DashboardPowerPlantAlarmsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_TRACKERS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD,AUTHORITIES.VIEW_DASHBOARD_POWER_STATION,
                    AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_TRACKER
                    
                ]}>
                    <DashboardPowerPlantSkidTrackersPage/>
                </ProtectedRoute>
            }/>

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_INVERTER} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_POWER_STATION,
                    AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_INVERTER
                    
                ]}>
                    <DashboardPowerPlantInverterPage/>
                </ProtectedRoute>
            }/>


            {/*   DASHBOARD SKID ROUTES   */}

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_OVERVIEW} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_SKID,
                    AUTHORITIES.VIEW_DASHBOARD_SKID_CHART
                ]}>
                    <DashboardPowerPlantSkidChartsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_LAYOUT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_SKID,
                    AUTHORITIES.VIEW_DASHBOARD_SKID_PLANT_LAYOUT
                ]}>
                    <DashboardPowerPlantSkidLayoutPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_CHARTS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_SKID,
                    AUTHORITIES.VIEW_DASHBOARD_SKID_CHART
                ]}>
                    <DashboardPowerPlantSkidChartsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_ALARMS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_SKID,
                    AUTHORITIES.VIEW_DASHBOARD_SKID_ALARM
                ]}>
                    <DashboardPowerPlantSkidAlarmsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD,AUTHORITIES.VIEW_DASHBOARD_POWER_STATION,
                    AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_EQUIPMENT
                ]}>
                    <DashboardPowerPlantSkidEquipmentsOverviewPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS_DETAILS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD,AUTHORITIES.VIEW_DASHBOARD_POWER_STATION,
                    AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_EQUIPMENT_DETAIL
                ]}>
                    <DashboardPowerPlantSkidEquipmentsDetailPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_DETAILS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_SKID,
                    AUTHORITIES.VIEW_DASHBOARD_SKID_EQUIPMENT_DETAIL
                ]}>
                    <DashboardPowerPlantSkidEquipmentsDetailPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS_COMPARE} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD
                ]}>
                    <DashboardPowerPlantSkidEquipmentsComparePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_COMPARE} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD
                ]}>
                    <DashboardPowerPlantSkidEquipmentsComparePage />
                </ProtectedRoute>
            } />


            {/*   DASHBOARD ANALYSIS ROUTES   */}

            <Route path={`${SystemRoutes.ANALYSIS}/`} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN,
                    AUTHORITIES.VIEW_ANALYSIS, AUTHORITIES.SEARCH_ANALYSIS
                ]}>
                    <AnalysisHomePage />
                </ProtectedRoute>
            }/>

            <Route path={`${SystemRoutes.ANALYSIS}/:analysisId/:chartConfigIdList`} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN,
                    AUTHORITIES.VIEW_ANALYSIS, AUTHORITIES.SEARCH_ANALYSIS
                ]}>
                    <AnalysisHomePage />
                </ProtectedRoute>
            }/>

            <Route path={`${SystemRoutes.ANALYSIS}/:analysisId`} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN,
                    AUTHORITIES.VIEW_ANALYSIS, AUTHORITIES.SEARCH_ANALYSIS
                ]}>
                    <AnalysisHomePage />
                </ProtectedRoute>
            }/>

            <Route path={`${SystemRoutes.ANALYSIS_CONFIG}/new`} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN,
                    AUTHORITIES.VIEW_ANALYSIS,
                    AUTHORITIES.SAVE_ANALYSIS
                ]}>
                    <AnalysisEditPage />
                </ProtectedRoute>
            }/>

            <Route path={`${SystemRoutes.ANALYSIS_CONFIG}/:analysisId`} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN,
                    AUTHORITIES.VIEW_ANALYSIS,
                    AUTHORITIES.SAVE_ANALYSIS
                ]}>
                    <AnalysisEditPage />
                </ProtectedRoute>
            }/>


            {/*   DASHBOARD SETTINGS ROUTES   */}


            <Route path={SystemRoutes.SETTINGS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, 
                    AUTHORITIES.SEARCH_SETTINGS
                ]}>
                    <SettingsHomePage />
                </ProtectedRoute>
            } />

            {/* COMMUNICATION ROUTES*/}

            <Route path={SystemRoutes.COMMUNICATION} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_COMMAND_HISTORY
                ]}>
                    <TeleCommandHomePage/>
                </ProtectedRoute>
            }/>

            <Route path={SystemRoutes.COMMUNICATION_SENT_TELECOMMAND} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_COMMAND_HISTORY
                ]}>
                   <TeleCommandHomePage/>
                </ProtectedRoute>
            }/>


            <Route path={`${SystemRoutes.SETTINGS_COUNTRY}/new`} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_COUNTRY
                ]}>
                    <CountrySavePage />
                </ProtectedRoute>
            } />

            <Route path={`${SystemRoutes.SETTINGS_COUNTRY}/:id`} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_COUNTRY
                ]}>
                    <CountrySavePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_COUNTRY} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, 
                    AUTHORITIES.SEARCH_COUNTRIES, AUTHORITIES.VIEW_COUNTRY
                ]}>
                    <CountryPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_MACRO_REGION} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_MACRO
                ]}>
                    <MacroRegionSettingsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_REGION} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_REGION
                ]}>
                    <RegionHomePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_REGION_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_REGION
                ]}>
                    <RegionHomePageForm />
                </ProtectedRoute>
            } />


            <Route path={SystemRoutes.SETTINGS_EQUIPMENT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_EQUIPMENT
                ]}>
                    <EquipmentHomePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_EQUIPMENT_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_EQUIPMENT
                ]}>
                    <EquipmentHomePageForm />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_BROKER} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_BROKER
                ]}>
                    <BrokerHomePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_BROKER_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_BROKER
                ]}>
                    <BrokerHomePageForm />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_EQUIPMENT_TYPE} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_EQUIPMENT_TYPE
                ]}>
                    <EquipmentTypeHomePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_EQUIPMENT_TYPE_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_EQUIPMENT_TYPE
                ]}>
                    <EquipmentTypeHomePageForm />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_EQUIPMENT_CLASS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_EQUIPMENT_CLASS
                ]}>
                    <EquipmentClassSettingsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_EQUIPMENT_CLASS_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_EQUIPMENT_CLASS
                ]}>
                    <EquipmentClassFormPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_TELE_OBJECT_SPECIFICATION_TYPE
                ]}>
                    <TeleObjectSpecificationTypeSettingsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_TELE_OBJECT_SPECIFICATION_TYPE
                ]}>
                    <TeleObjectSpecificationTypePageForm />
                </ProtectedRoute>
            } />


            <Route path={SystemRoutes.SETTINGS_TELE_OBJECT_TYPE} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_TELE_OBJECT_TYPE
                ]}>
                    <TeleObjectTypeSettingsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_TELE_OBJECT_TYPE_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_TELE_OBJECT_TYPE
                ]}>
                    <TeleObjectTypePageForm />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_MOD_BUS_DATA_TYPES
                ]}>
                    <ModBusDataTypeHomePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_MOD_BUS_DATA_TYPES
                ]}>
                    <ModBusDataTypesPageForm />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_MOD_BUS_OBJECTS_TYPES} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_MOD_BUS_OBJECTS_TYPES
                ]}>
                    <ModBusObjectsTypesSettingsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_MOD_BUS_OBJECTS_TYPES_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_MOD_BUS_OBJECTS_TYPES
                ]}>
                    <ModBusObjectsTypesPageForm />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_GRAFIC_ELEMENT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_GRAFIC_ELEMENT
                ]}>
                    <GraficElementHomePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_GRAFIC_ELEMENT_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_GRAFIC_ELEMENT
                ]}>
                    <GraficElementPageForm />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_POWER_STATION_EXCEL} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_POWER_STATION_EXCEL
                ]}>
                    <PowerStationExcelPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_ALARM_SEVERITY} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_ALARM_SEVERITY
                ]}>
                    <AlarmSeverityHomePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_ALARM_SEVERITY_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_ALARM_SEVERITY
                ]}>
                    <AlarmSeverityHomePageForm />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_SKID} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_SKID
                ]}>
                    <SkidSettingsPage />
                </ProtectedRoute>
            } />
            <Route path={SystemRoutes.SETTINGS_NOTIFICATION_GROUP} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_COUNTRY
                ]}>
                    <NotificationGroupHomePage />
                </ProtectedRoute>
            } />
            <Route path={SystemRoutes.SETTINGS_NOTIFICATION_GROUP_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_COUNTRY
                ]}>
                    <NotificationGroupPageForm />
                </ProtectedRoute>
            } />
            <Route path={SystemRoutes.SETTINGS_POWER_PLANT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_POWER_PLANT
                ]}>
                    <PowerStationHomePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_POWER_PLANT_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_COUNTRY
                ]}>
                    <PowerStationHomePageForm />
                </ProtectedRoute>
            } />

            <Route path={`${SystemRoutes.SETTINGS_PROFILE_FORM}`} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_PROFILE
                ]}>
                    <ProfilesSaveSettingsPage />
                </ProtectedRoute>
            } />

            <Route path={`${SystemRoutes.SETTINGS_PROFILE}/new`} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_PROFILE
                ]}>
                    <ProfilesSaveSettingsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_PROFILE} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_PROFILE, AUTHORITIES.VIEW_PROFILE
                ]}>
                    <ProfilesSettingsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_USERS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_USERS, , AUTHORITIES.VIEW_USER
                ]}>
                    <UsersSettingsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_USERS_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_USER
                ]}>
                    <UserHomePageForm />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_AUDIT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_AUDIT
                ]}>
                    <AuditSettingsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_GRAPH_ELEMENT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SEARCH_GRAPH_ELEMENT
                ]}>
                    <GraphElementSettingsPageHome />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_GRAPH_ELEMENT_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_SETTINGS, AUTHORITIES.SAVE_GRAPH_ELEMENT
                ]}>
                    <GraphElementFormPageHome />
                </ProtectedRoute>
            } />


            <Route path={SystemRoutes.SETTINGS_POWER_STATION_USER} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_POWER_STATION_USER, AUTHORITIES.SEARCH_POWER_STATION_USER
                ]}>
                    <PowerStationUserHomePage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.SETTINGS_POWER_STATION_USER_FORM} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_POWER_STATION_USER, AUTHORITIES.DELETE_POWER_STATION_USER
                ]}>
                    <PowerStationUserPageForm />
                </ProtectedRoute>
            } />


            {/*   DASHBOARD REPORT ROUTES   */}

            <Route path={SystemRoutes.REPORTS_ALARMS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT
                ]}>
                    <AlarmsReportPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.REPORTS_PERFORMANCE_SUMMARY} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT
                ]}>
                    <PerformanceSummaryReportPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.REPORTS_POWER_PLANTS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT
                ]}>
                    <PowerPlantsReportPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.REPORTS_EQUIPMENT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT
                ]}>
                    <EquipmentReportPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.REPORTS_EQUIPMENT_CLASS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT
                ]}>
                    <EquipmentClassesReportPage />
                </ProtectedRoute>
            } />
            <Route path={SystemRoutes.REPORTS_MANAGEMENT_REPORT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_MANAGEMENT_REPORT
                ]}>
                    <ManagementReportPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.REPORTS_SKID} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT
                ]}>
                    <SkidsReportPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.REPORTS_TELEOBJECTS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT, AUTHORITIES.EXPORT_REPORT_TELEOBJECTS
                ]}>
                    <TeleobjectsReportPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.REPORTS_AUDIT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.SEARCH_REPORT
                ]}>
                    <AuditReportPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.REPORTS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, 
                    AUTHORITIES.SEARCH_REPORT
                ]}>
                    <TeleobjectsReportPage />
                </ProtectedRoute>
            } />

            {/* AUDIT ROUTES */}

            <Route path={SystemRoutes.AUDIT} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_AUDIT_COMMANDS
                ]}>
                    <AuditCommandsPage />
                </ProtectedRoute>
            } />
            <Route path={SystemRoutes.AUDIT_LOGIN} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN,
                ]}>
                   <AuditLoginPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.AUDIT_COMMANDS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_AUDIT_COMMANDS
                ]}>
                    <AuditCommandsPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.AUDIT_ALARMS} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN,
                ]}>
                    <AuditAlarmsPage />
                </ProtectedRoute>
            } />

            {/*   DASHBOARD OTHER ROUTES   */}

            <Route path={SystemRoutes.HELP} element={
                <ProtectedRoute hasAnyAuthorities={[
                    AUTHORITIES.SYSADMIN
                ]}>
                    <HelpPage />
                </ProtectedRoute>
            } />

            <Route path={SystemRoutes.LOGOUT} element={
                <Logout />
            } />

            <Route path="*" element={<PageNotFound />} />

        </Routes>
    )

}