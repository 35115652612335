import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../Config/Hooks";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {Button, Grid, TextareaAutosize, Typography, useTheme} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {RootState} from "../../../../../Config/Store";
import {extractFiltersFromColumns} from "../../../../../Utils/DataUitils";
import Box from "@mui/material/Box";
import {clearIteration, createEntity, NewAlarmIteration, searchEntitiesAlarmIteration} from "./AlarmIterationReducer";
import {format} from "date-fns";
import {ptBR} from "date-fns/locale";

const columns = [
    {
        name: 'id',
        label: 'Registro',
        align: 'left',
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'description',
        label: 'Descrição',
        align: 'left',
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'dateTime',
        label: 'Data',
        align: 'left',
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'userName',
        label: 'Usuário',
        align: 'left',
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    }
] as ADataGridColumn[];

const style = {
    position: 'absolute' as 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    background: '#FFF',
    bgcolor: 'background.paper',
    border: '1px solid #666',
    // borderRadius: '25px',
}

const styleTypography = {
    textAlign: 'start' as 'start',
    paddingBottom: 5
}

const AlarmNotion: FunctionComponent<{ alarm: any }> = ({alarm}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const totalOfRecords = useAppSelector((state: RootState) => state.alarmIteration.totalOfRecords);
    const entities = useAppSelector((state: RootState) => state.alarmIteration.entities);
    const entityUser = useAppSelector((state: RootState) => state.authentication.account);

    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columns), 0, 10));
    const [text, setText] = useState('');

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        dispatch(clearIteration());
        if (alarm) {
            dispatch(searchEntitiesAlarmIteration({alarmId: alarm.id, params:pagedSearchParams}));
        }
    }, [alarm])

    useEffect(() => {
        dispatch(searchEntitiesAlarmIteration({alarmId: alarm.id, params:pagedSearchParams}));
    }, [pagedSearchParams])

    const handleOnRowSelect = useCallback((row: any) => {
        console.log(`handleOnRowSelect()`, row);
    }, []);

    const saveNotion = () => {
        const newAlarmIteration: NewAlarmIteration = {
            description: text,
            user: {id: entityUser?.id},
            alarm: {id: alarm.id},
            dateTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss",{locale: ptBR})
        }
        dispatch(createEntity(newAlarmIteration));
        setText("");
        dispatch(searchEntitiesAlarmIteration({alarmId: alarm.id, params:pagedSearchParams}));
    }

    return (
        <Box style={style}>
            <ACard title={"Nota: " + alarm.id.toString()}>
                <Grid container spacing={2} style={{justifyContent: 'space-around', alignItems: 'flex-start'}}>
                    <Grid item xs={12} sm={6} md={4} style={{alignItems: 'center', textAlign: 'justify'}}>
                        <Typography sx={{ml: 'auto'}} style={{...styleTypography}}>
                            <strong>Severidade:</strong> {alarm.severityName}
                        </Typography>
                        <Typography sx={{ml: 'auto'}} style={{...styleTypography}}>
                            <strong>Equipamento:</strong> {alarm.equipmentName}
                        </Typography>
                        <Typography sx={{ml: 'auto'}} style={{...styleTypography}}>
                            <strong>Usina:</strong> {alarm.powerStationName}
                        </Typography>
                        {alarm.skidName &&
                            <Typography sx={{ml: 'auto'}} style={{...styleTypography}}>
                                <strong>Skid:</strong> {alarm.skidName}
                            </Typography>
                        }
                        <Typography sx={{ml: 'auto'}} style={{...styleTypography}}>
                            <strong>Descrição:</strong> {alarm.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} style={{background: '#ffffff'}}>
                        <Typography sx={{ml: 'auto'}} style={{...styleTypography}}>
                            <strong>Início:</strong> {alarm.dateTime}
                        </Typography>
                        <Typography sx={{ml: 'auto'}} style={{...styleTypography}}>
                            <strong>Reconhecido:</strong> {alarm.acknowledgementDate}
                        </Typography>
                        <Typography sx={{ml: 'auto'}} style={{...styleTypography}}>
                            <strong>Término:</strong> {alarm.clearDate}
                        </Typography>
                        <Typography sx={{ml: 'auto'}} style={{...styleTypography}}>
                            <strong>Região:</strong> {alarm.regionName}
                        </Typography>
                        <Typography sx={{ml: 'auto'}} style={{...styleTypography}}>
                            <strong>Macro região:</strong> {alarm.macroRegionName}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <TextareaAutosize
                        placeholder="Minímo 15 caracteres…"
                        name="outlined"
                        value={text}
                        contextMenu="character(s)"
                        onChange={(event) => setText(event.target.value)}
                        minRows={2}
                        maxRows={4}
                        style={{
                            width: "70%",
                            height: 100
                        }}
                    />
                    <Grid container style={{ flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <Grid item
                          style={{flex: 1, flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', width: '70%', paddingTop: 15}}>
                        <Button variant="outlined" startIcon={<SaveIcon/>}
                                disabled={text.length < 15} onClick={saveNotion}>Save</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <ADataGrid
                    hideFilters={true}
                    showFilterOptions={false}
                    hideSelection={false}
                    size={"medium"}
                    columns={columns}
                    rows={entities}
                    page={pagedSearchParams.page}
                    loading={false}
                    totalOfRecords={totalOfRecords ? totalOfRecords : 0}
                    rowsPerPage={pagedSearchParams.size}
                    onFiltersChange={handleOnFiltersChange}
                    onRowSelect={handleOnRowSelect}
                />
            </ACard>
        </Box>

    )

}

export default AlarmNotion;