import {
    Box,
    Grid,
    IconButton,
    Stack,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {CommandValue} from "../../../../Shared/Types/PowerStation";
import axios from "axios";

interface Props {
    data: Array<CommandValue>;
    onSaveCommands?: any;
    onDeleteCommand?: any;
    commandIdsDeleted?: any;
}
export const CommandValueTab = ({data, onSaveCommands, onDeleteCommand, commandIdsDeleted}: Props) => {
    const [inputName, setInputName] = useState("");
    const [inputValue, setInputValue] = useState<number | null>(NaN);
    const [commandList, setCommandList] = useState<Array<CommandValue>>(data);
    const [idsDeleted, setIdsDeleted] = useState<Array<number>>(commandIdsDeleted);

    const handleAddItem = () => {
        if (inputName !== "" && inputValue !== null) {
            const newItem: CommandValue = {
                id: null,
                name: inputName,
                value: inputValue
            }

            const newCommandList = [...commandList, newItem];
            setCommandList(newCommandList);
            setInputName("");
            setInputValue(NaN);
        }
    };

    useEffect(() => {
        onDeleteCommand(idsDeleted)
    }, [idsDeleted]);

    useEffect(() => {
        onSaveCommands(commandList);
    }, [commandList]);

    const handleOnDelete = (value:any) => {
        const idDeleted = commandList.filter((_,idx) => idx === value)[0].id
        if(idDeleted != null){
            axios.delete(`/api/command-value/${idDeleted}`,{timeout:30000}).then((res) => res.data);
            setIdsDeleted(prevState => [...prevState, idDeleted])
        }
        setCommandList(commandList.filter((_,idx) => idx != value))
    }

    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={1} alignItems="center" sx={{ pb: 2 }}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                    <TextField
                        label="Name"
                        value={inputName}
                        onChange={(e) => setInputName(e.target.value)}
                        variant="outlined"
                        sx={{
                            width: '60%',
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& input': {
                                    padding: '8px 14px',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '0.875rem',
                                top: '-6px',
                                textAlign: 'left',
                                width: '100%',
                            },
                            '& .MuiInputLabel-shrink': {
                                fontSize: '0.75rem',
                            }
                        }}
                    />
                    <TextField
                        label="Valor"
                        value={inputValue}
                        type="number"
                        onChange={(e) => setInputValue(parseFloat(e.target.value))}
                        variant="outlined"
                        sx={{
                            width: '60%',
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& input': {
                                    padding: '8px 14px',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '0.875rem',
                                top: '-6px',
                                textAlign: 'left',
                                width: '100%',
                            },
                            '& .MuiInputLabel-shrink': {
                                fontSize: '0.75rem',
                            }
                        }}
                    />
                    <IconButton
                        color="primary"
                        onClick={handleAddItem}
                        sx={{ ml: 1, alignSelf: 'center' }} // Alinha o botão verticalmente com o campo de texto
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ maxWidth: '800px' }}>
                <Grid item xs={12}>
                    {
                        commandList.length > 0 &&
                        <TableContainer sx={{ width: '100%' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            sx={{ minWidth: 10, backgroundColor: 'rgba(4,104,190,0.92)', color: 'white', fontSize: '0.875rem', padding: '8px 16px' }}
                                        >
                                            <Stack spacing={0} sx={{ m: 0, p: 0 }}>
                                                <Box>
                                                    {'Nome'}
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ minWidth: 10, backgroundColor: 'rgba(4,104,190,0.92)', color: 'white', fontSize: '0.875rem', padding: '8px 16px' }}
                                        >
                                            <Stack spacing={0} sx={{ m: 0, p: 0 }}>
                                                <Box>
                                                    {'Valor'}
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ minWidth: 10, backgroundColor: 'rgba(4,104,190,0.92)', color: 'white', fontSize: '0.875rem', padding: '2px 6px' }}
                                        >
                                            <Stack spacing={0} sx={{ m: 0, p: 0 }}>
                                                <Box>
                                                    {'Ações'}
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(commandList) && commandList.length > 0 ? commandList.map((column, index) => (
                                        <TableRow key={index.valueOf()}>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    fontSize: '0.875rem',
                                                    padding: '8px 16px',
                                                    height: '40px',
                                                    verticalAlign: 'middle',
                                                }}
                                            >
                                                {column.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    fontSize: '0.875rem',
                                                    padding: '8px 16px',
                                                    height: '40px',
                                                    verticalAlign: 'middle',
                                                }}
                                            >
                                                {column.value}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    fontSize: '0.875rem',
                                                    padding: '2px 6px',
                                                    height: '40px',
                                                    verticalAlign: 'middle',
                                                }}
                                            >
                                                <IconButton edge="end" aria-label="delete" onClick={() => { handleOnDelete(index); }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}