// Dark Theme
import { createTheme, Theme, ThemeOptions } from '@mui/material';
import themeDefaults from "./ThemeDefaults";

export const darkThemeConfig: ThemeOptions = {
  palette: {
    mode: 'dark',
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
          '& .svg-inline--fa fa-house': {
            color: '#fff'
          }
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        // root: {
        //     // Some CSS
        //     borderRadius: '30px',
        //     backgroundColor: 'red',
        //     fontSize: '1rem',
        // },
        root: ({ ownerState }) => ({
          // DEFAULT COLORS CONFIGURATION
          // backgroundColor: '#ffffff',
          // fontSize: '1rem',
          // color: 'red',
          // textTransform: 'uppercase',
          borderRadius: themeDefaults.borderRadius,
          // marginLeft: 5,
          // marginRight: 5,
          '&:hover': {
            backgroundColor: "rgba(255,255,255,0.1)",
            // borderColor: '#0062cc',
            // boxShadow: 'none',
            // color: '#ffffff'
          },
          '&:active': {
            // boxShadow: 'none',
            // backgroundColor: 'blue',
            // borderColor: '#005cbf',
          },
          '&:focus': {
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            // backgroundColor: 'green',
          },

          // APPLY CSS ACCORDING TO COMPONENTE PROPS AND STATES
          ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' &&
              {
                // backgroundColor: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                  backgroundColor: "rgba(144, 202, 249, 0.9)",
                  // borderColor: '#0062cc',
                  // boxShadow: 'none',
                  // color: '#ffffff'
                },
                // color: 'black',
              }),
          ...(ownerState.variant === 'outlined' &&
              {
                // color: '#fff',
                // borderColor: '#fff',
                // '&:hover':{
                //   borderColor: '#fff',
                // }
              })
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          // backgroundColor: "#d2ff00",
          // color: '#c2c2c2'
          // "&.Mui-checked": {
          //   color: "#c2c2c2", // Cor da bolinha quando o radio está selecionado
          // }

        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#c2c2c2", // Cor padrão da Tab quando NÃO está selecionada
          "&.Mui-selected": {
            color: "#fff", // Cor da Tab quando está SELECIONADA
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#fff", // Cor da barrinha de indicação abaixo da Tab selecionada
        },
      },
    },
  }
};
export const DarkTheme: Theme = createTheme(darkThemeConfig);
