import {Box, Paper} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";
import TotalizerAlarmsPlantSkid from "./TotalizerAlarmsPlantSkid/TotalizerAlarmsPlantSkid";
import AlarmPage from "./Alarms/AlarmPage";
import React, { useState } from "react";
import { TotalizerType } from "../Overview/DashboardOverviewAlarmsPage";

export default function DashboardPowerPlantSkidAlarmsPage(){

    const {selectedPowerPlant, selectedSkid} = useAppSelector((state) => state.dashboard);

    const [filter, setFilter] = useState<TotalizerType>(localStorage.getItem("totalizer_by_skid") as TotalizerType ?? "NONE");

    const onClickTotalizer = (totalizer:TotalizerType) => {
        console.log(totalizer);
        // Saved in localstorage
        localStorage.setItem("totalizer_by_skid", totalizer);
        setFilter(totalizer)
    }
    return(
        <>
            {selectedPowerPlant && selectedSkid &&
                <TotalizerAlarmsPlantSkid skidId={selectedSkid.id} onClickTotalizer={onClickTotalizer}/>}
            <Paper elevation={3}>
                <Box p={2}>
                    {selectedSkid ? <AlarmPage skidId={selectedSkid.id} filterByTotalizer={filter}/> : null}
                </Box>
            </Paper>
        </>

    );
}
