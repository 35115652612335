import React, {MouseEvent, useCallback, useEffect, useRef, useState} from "react";

import {
    ACard,
    ADataGrid,
    ADataGridColors,
    ADataGridColumn,
    ADataGridFilter,
    PagedSearchParams,
} from "@atiautomacao/ati-ui-library";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {searchEntities, Alarm, clear, AlarmFinalizeArgs, alarmFinalize, alarmAcknowledge} from "./AlarmReducer";
import IconButton from "@mui/material/IconButton";
import {BorderColor, FactCheck} from "@mui/icons-material";
import {isArray} from "lodash";
import {DateFormat, LocalDateTimeFormatISO, validateDateFormatISO} from "../../../Utils/DateFormatPatternUtils";
import {Dialog, DialogContentText, DialogTitle, Link, DialogContent, Modal, DialogActions, Button, useTheme, Tooltip} from "@mui/material";
import AlarmGroup from "./Components/AlarmGroup";
import {usePowerStationNavigation} from "../../../Shared/Hooks/usePowerStationNavigation";
import {useSkidNavigation} from "../../../Shared/Hooks/useSkidNavigation";
import {clearGroup,} from "./Components/AlarmGroup/AlarmGroupReducer";
import {useSnackbar} from "notistack";
import {alarmSearchGroup, checkGroupingType, handleOnColor} from "../../../Utils/AlarmsService";
import AlarmNotion from "./Components/AlarmNotion";
import {isValid} from "date-fns";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {Storage} from "../../../Utils/StorageUtils";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import useInterval from "../../../Shared/Hooks/useInterval";
import { hasPermission } from "../../../Shared/Auth/AuthenticationUtil";
import { AUTHORITIES } from "../../../Config/Constants";
import { TotalizerType } from "../Overview/DashboardOverviewAlarmsPage";

export const initColumnsData = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'severityName',
        label: 'severidade',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'description',
        label: 'descrição',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'macroRegionName',
        label: 'macro',
        align: 'left',
        minWidth: 10,
        visibility: false,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'regionName',
        label: 'região',
        align: 'left',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'powerStationName',
        label: 'usina',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'skidName',
        label: 'skid',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'equipmentName',
        label: 'equipamento',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'dateTime',
        label: 'início',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "dateTime",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'clearDate',
        label: 'finalizado',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "clearDate",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'acknowledgementDate',
        label: 'reconhecido',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "acknowledgementDate",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'userName',
        label: 'usuário',
        align: 'left',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
] as ADataGridColumn[]

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface AlarmRow {
    id: string;
    selected?: boolean;
    teleObjectId: number;
    severityId: number;
    severityName: string;
    severityColor: string;
    description: string;
    macroRegionName: string;
    regionName: string;
    powerStationId: number;
    powerStationName: string | JSX.Element;
    skidId: number;
    skidName: string | JSX.Element;
    equipmentId: number;
    equipmentName: string;
    clearDate?: Date | string;
    acknowledgementDate?: Date | string;
    dateTime: Date | string;
    userName: string;
    group: boolean | any;
    actionsConfig?: Array<ActionConfig>

}

interface AlarmPageProps {
    filterByTotalizer:TotalizerType
}

export default function AlarmPage({filterByTotalizer}:AlarmPageProps) {

    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToAcknowledge = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DASHBOARD_ALARM_ACKNOWLEDGE
    ]);

    const isAuthorizedToFinalize = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DASHBOARD_ALARM_FINALIZE
    ]);

    const isLoading = useAppSelector((state : RootState) => state.alarmOverview.loading);
    const totalOfRecords = useAppSelector((state : RootState) => state.alarmOverview.totalOfRecords);
    const entities: Array<Alarm> = useAppSelector((state: RootState) => state.alarmOverview.entities);
    const userConfig = useAppSelector((state: RootState) => state.userConfigurarion);

    const navigationToPowerStation = usePowerStationNavigation();
    const navigationToSkid = useSkidNavigation();

    const [rows, setRows] = useState<Array<AlarmRow>>([]);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumnsData), 0, 100));
    const [open, setOpen] = React.useState(false);
    const [notionOpen, setNotionOpen] = React.useState(false);
    const [groupOpen, setGroupOpen] = React.useState(false);
    const [entityToModel, setEntityToModel] = useState<object>();
    const [selectedRows, setSelectedRows] = useState<any>();

    const AUTH_TOKEN_KEY = 'api-token-key';
    const token = Storage.local.get(AUTH_TOKEN_KEY);
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    const username = tokenData.preferred_username;

    const [dialogOpen, setDialogOpen] = useState(false);
    const [acknowledgeDialog, setAcknowledgeDialog] = useState(false);

    const handleOnGroupOpen = useCallback(async (data: any) => {
        if(data && !isArray(data)) {
            setGroupOpen(true);
            let params = new PagedSearchParams(extractFiltersFromColumns(initColumnsData), 0, 100)
            params.filters.push(...checkGroupingType(data))
            try {
                const hasGroup = await alarmSearchGroup(params);
                if (!hasGroup) {
                    enqueueSnackbar("Sem dados agrupados!", {variant: 'warning'})
                } else {
                    setEntityToModel(data)
                    setOpen(true);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    const handleOnNotionOpen = useCallback(async (data: any) => {
        if(data && !isArray(data)) {
            setNotionOpen(true);
            setEntityToModel(data);
            setOpen(true);
        }
    }, []);

    // close modal
    const handleCloseModal = () => {
        dispatch(clearGroup)
        dispatch(searchEntities({params: pagedSearchParams, filterByTotalizer: filterByTotalizer}))
        setOpen(false);
        setGroupOpen(false);
        setNotionOpen(false);
    }

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    const finalizeAlarms = useCallback(async () => {
        handleClose()
        if(isArray(selectedRows)){
            const rowSelected = selectedRows.filter((row) => row.selected)
            const params:AlarmFinalizeArgs = {
                username: username,
                alarmsListId: rowSelected.map((row) => Number(row.data.id))
            }
            const actionResult = await dispatch(alarmFinalize(params))
            if(alarmFinalize.fulfilled.match(actionResult)){
                dispatch(searchEntities({params: pagedSearchParams, filterByTotalizer: filterByTotalizer}))
            }
        }else if(selectedRows){
            const actionResult = await dispatch(alarmFinalize({username: username, alarmsListId: [Number(selectedRows.id)]}))
            if(alarmFinalize.fulfilled.match(actionResult)){
                dispatch(searchEntities({params: pagedSearchParams, filterByTotalizer: filterByTotalizer}))
            }
        }
    },[selectedRows]);


    const alarmAcknowledges = useCallback(async() => {
        handleClose()
        if(isArray(selectedRows)){
            const rowSelected = selectedRows.filter((row) => row.selected)
            const params:AlarmFinalizeArgs = {
                username: username,
                alarmsListId: rowSelected.map((row) => Number(row.data.id))
            }
            const actionResult = await dispatch(alarmAcknowledge(params))
            if(alarmAcknowledge.fulfilled.match(actionResult)){
                dispatch(clear());
                dispatch(searchEntities({params: pagedSearchParams, filterByTotalizer: filterByTotalizer}))
            }
        }else if(selectedRows){
            const actionResult = await dispatch(alarmAcknowledge({username: username, alarmsListId: [Number(selectedRows.id)]}))
            if(alarmAcknowledge.fulfilled.match(actionResult)){
                dispatch(clear());
                dispatch(searchEntities({params: pagedSearchParams, filterByTotalizer: filterByTotalizer}))
            }
        }
    },[selectedRows]);

    useEffect(() => {
        dispatch(clear());
        if (!isLoading) {
            dispatch(searchEntities({params: pagedSearchParams, filterByTotalizer: filterByTotalizer}));
        }
    }, [])


    useEffect(() => {
        dispatch(clear());
        if (!isLoading) {
            dispatch(searchEntities({params: pagedSearchParams, filterByTotalizer: filterByTotalizer}));
        }
    }, [filterByTotalizer])

    useInterval(() => {
        if (!isLoading) {
            dispatch(searchEntities({params: pagedSearchParams, filterByTotalizer: filterByTotalizer}));
        }
    }, userConfig.alarmUpdateInterval); //1 minute

    const formatISOData = (date: string) => {
        const dateTime =  validateDateFormatISO(date);
        if(isValid(new Date(dateTime))){
            return LocalDateTimeFormatISO(dateTime)
        }
        
        if(isValid(new Date(date))){            
            return LocalDateTimeFormatISO(date)
        }
        enqueueSnackbar("Data invalida!", {variant: 'error'})
        return "";
    }

    const paramsRef = useRef(false);
    useEffect(() => {
        if(paramsRef.current){            
            if(pagedSearchParams.filters[8] && pagedSearchParams.filters[8].value.length > 0){
               pagedSearchParams.filters[8].value = formatISOData(pagedSearchParams.filters[8].value);
            }

            if(pagedSearchParams.filters[9] && pagedSearchParams.filters[9].value.length > 0){
               pagedSearchParams.filters[9].value = formatISOData(pagedSearchParams.filters[9].value);
            }

            if(pagedSearchParams.filters[10] && pagedSearchParams.filters[10].value.length > 0){
               pagedSearchParams.filters[10].value = formatISOData(pagedSearchParams.filters[10].value);
            }
            dispatch(searchEntities({params: pagedSearchParams, filterByTotalizer: filterByTotalizer}));
        }else {
            paramsRef.current = true;
        }
    },[pagedSearchParams]);

    const handleNavigationPowerPlant = (event: MouseEvent<HTMLElement>, powerPlantId: number): void => {
        event.preventDefault();
        navigationToPowerStation(powerPlantId);
    }

    const handleNavigationSkid = (event: MouseEvent<HTMLElement>, powerStationId: number, skidId: number): void => {
        event.preventDefault();
        navigationToSkid(powerStationId, skidId);
    }

    const handleClickOpen = (rows:any) => {
        if(isAuthorizedToFinalize){
            setSelectedRows(rows);
            setDialogOpen(true);
        }else {
            enqueueSnackbar("Você não está autorizado à acessar essa funcionalidade", {
                variant: 'warning',
            });
            return null;
        }
    };

    const handleAcknowledgeClick = (rows:any) => {
        if(isAuthorizedToAcknowledge){
            setSelectedRows(rows);
            setAcknowledgeDialog(true)
        } else {
            enqueueSnackbar("Você não está autorizado à acessar essa funcionalidade", {
                variant: 'warning',
            });
            return null;
        }
     
    }

    const handleClose = () => {
        setDialogOpen(false);
        setAcknowledgeDialog(false);
    };

    const structureRow = (entitiesValues: Array<Alarm>) => {
        let entity: Array<AlarmRow> = []
        entitiesValues.forEach( alarm => {
            entity.push({
                teleObjectId: alarm.teleObjectId,
                selected:false,
                id: alarm.id.toString(),
                group: alarm.group,
                severityId: alarm.severityId,
                severityName: alarm.severityName,
                severityColor: alarm.severityColor,
                description: alarm.description,
                macroRegionName: alarm.macroRegionName,
                regionName: alarm.regionName,
                powerStationId: alarm.powerStationId,
                powerStationName: <Link href="#" onClick={(event) => handleNavigationPowerPlant(event, alarm.powerStationId)} underline="hover">{alarm.powerStationName}</Link>,
                skidId: alarm.skidId,
                skidName:<Link href="#" onClick={(event) => handleNavigationSkid(event, alarm.powerStationId, alarm.skidId)} underline="hover">{alarm.skidName}</Link>,
                equipmentId: alarm.equipmentId,
                equipmentName: alarm.equipmentName,
                clearDate: alarm.clearDate ? DateFormat(alarm.clearDate) : "",
                acknowledgementDate: alarm.acknowledgementDate ? DateFormat(alarm.acknowledgementDate) : "",
                dateTime: DateFormat(alarm.dateTime),
                userName: alarm.userName,
                actionsConfig: [
                    {actionName: "grouping", disabled: false},
                    {actionName: "note", disabled: false},
                    {actionName: "acknowledge", disabled: isAuthorizedToAcknowledge ? (alarm.acknowledgementDate != null) : true},
                    {actionName: "finalize", disabled: isAuthorizedToFinalize ? (alarm.clearDate != null) : true}
                ],
            });
        });
        setRows(entity);
    }

    const entitiesRef = useRef(false);
    useEffect(() => {
        if(!entitiesRef.current){
            entitiesRef.current = true;
        }
    },[entities]);

    useEffect(() => {
        if(!entitiesRef.current){
            entitiesRef.current = true;
        }
    },[initColumnsData]);

    useEffect(() => {
        if (isArray(entities) && entities.length > 0) {
            structureRow(entities);
        }else {
            setRows([]);
        }
    }, [entities]);

    const newHandleOnColor = (data: any, columnItem: any): ADataGridColors => {
        return handleOnColor(data,columnItem,theme);
    }

    return (
        <ACard styleProps={{cardStyle: {height: 700}}}>
            <ADataGrid
                hideFilters={false}
                showFilterOptions={false}
                hideSelection={false}
                size={"small"}
                columns={initColumnsData}
                rows={rows}
                page={pagedSearchParams.page}
                loading={false}
                totalOfRecords={totalOfRecords || 0}
                rowsPerPage={pagedSearchParams.size}
                onFiltersChange={handleOnFiltersChange}
                onColorSelect={newHandleOnColor}
                actionColumnWidth={170}
                disableActionsHeaderIcons={false}
                actions={
                    <>
                        <IconButton {...({ disableHeader: true } as any)} name="grouping" size={"small"} aria-label="Fact check" onClick={handleOnGroupOpen}>
                            <Tooltip title="Agrupamento">
                                <FactCheck/>
                            </Tooltip>
                        </IconButton>
                        <IconButton {...({ disableHeader: true } as any)} name="note" size={"small"} aria-label="Border color" onClick={handleOnNotionOpen}>
                            <Tooltip title="Adicionar nota">
                                <BorderColor />
                            </Tooltip>
                        </IconButton>
                        <IconButton name="acknowledge" size={"small"} aria-label="Fact check" onClick={handleAcknowledgeClick} disabled={!isAuthorizedToAcknowledge}>
                            <Tooltip title="Reconhecer alarme">
                                <CheckCircleIcon />
                            </Tooltip>
                        </IconButton>
                        <IconButton name="finalize" size={"small"} aria-label="Border color" onClick={handleClickOpen} disabled={!isAuthorizedToFinalize}>
                            <Tooltip title="Finalizar alarme">
                                <CancelIcon />
                            </Tooltip>
                        </IconButton>
                    </>
                }
            />
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                { groupOpen && !notionOpen ?
                        <AlarmGroup alarm={entityToModel} />
                    :
                        <AlarmNotion alarm={entityToModel} />
                }
            </Modal>
                <Dialog open={dialogOpen || acknowledgeDialog} onClose={handleClose}>
                <DialogTitle>{`Confirmar ${dialogOpen ? 'finalização' : 'reconhecimento'}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza de que deseja {dialogOpen ? 'finalizar' : 'reconhecer'} este alarme?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={dialogOpen ? finalizeAlarms : alarmAcknowledges} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
                </Dialog>
        </ACard>
    )
}