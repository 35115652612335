import {Box, Tooltip, useTheme} from "@mui/material";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import React, {useCallback, useEffect, useState} from "react";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import IconButton from "@mui/material/IconButton";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {useNavigate} from "react-router-dom";
import {DeleteEquipmentReactQuery, EquipmentSearchReactQuery} from "./EquipmentReactQuery";
import {QueryClient, QueryClientProvider} from "react-query";
import {HeaderMenu} from "../HeaderMenu";
import {EquipmentProps} from "../../../Shared/Types/Equipment";
import {useAppSelector} from "../../../Config/Hooks";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";

const initColumns = [
    {
        name: 'name',
        label: 'nome',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'name', condition: 'contains', value: '', sort: 'asc'}

    },
    {
        name: 'equipmentClassName',
        label: 'classe',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'equipmentClassName', condition: 'contains', value: '', sort: ''}

    },
    {
        name: 'powerStationName',
        label: 'usina',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'powerStationName', condition: 'contains', value: '', sort: ''}

    },
    {
        name: 'skidName',
        label: 'skid',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'skidName', condition: 'contains', value: '', sort: ''}

    },
    {
        name: 'etsStatus',
        label: 'status',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'etsStatus', condition: 'contains', value: '', sort: ''}

    },
] as ADataGridColumn [];

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowEquipment {
    id: number;
    name: string;
    actionsConfig?: Array<ActionConfig>
}

export function EquipmentSettingsPage(){
    const navigate = useNavigate();
    const theme = useTheme();
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50));
    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.SAVE_EQUIPMENT
    ]);

    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.DELETE_EQUIPMENT
    ]);
    const mutation = DeleteEquipmentReactQuery(pagedSearchParams);
    const { data, isSuccess } = EquipmentSearchReactQuery(pagedSearchParams);

    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [selectedToDeleteName, setSelectedToDeleteName] = useState<string | null>(null);

    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [rows, setRows] = useState<Array<RowEquipment>>([]);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if(isSuccess) {
            setTotalOfRecords(data?.totalOfRecords);
            if(data?.data){
                let rowsEquipment = data.data.map((equipment: EquipmentProps) => {
                    return {
                        id: equipment.id,
                        name: equipment.name,
                        etsStatus: equipment.etsStatus,
                        equipmentClassName: equipment.equipmentClass.name,
                        powerStationName: equipment.powerStation.name,
                        skidName: equipment.skid?.name ?? "",
                        actionsConfig: [
                            {actionName: "edit", disabled: !isAuthorizedToSave},
                            {actionName: "delete", disabled: !isAuthorizedToDelete},
                        ]
                    }
                });
                setRows(rowsEquipment);
            }
        }
    }, [data?.data]);

    const handleEditEquipmentType = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_EQUIPMENT_FORM, { state: { id: data.id } });
    },[navigate]);

    const handleDeleteEquipmentType = useCallback((data: any) => {
        setSelectedToDelete(data.id);
        setSelectedToDeleteName(data.name);
        setOpenDialog(true);
    },[]);

    const handleDialogConfirm = async () => {
        mutation.mutate({ id: selectedToDelete, pagedSearchParams });
        setSelectedToDelete(null);
        setSelectedToDeleteName(null);
        handleDialogToggle();
        
    }

    useEffect(() => {
        if(mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50))
        }

    }, [mutation.isSuccess]);

    const handleDialogToggle = () => {
        handleOnFiltersChange(extractFiltersFromColumns(initColumns), 0, 50)
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        setSelectedToDelete(null);
        setSelectedToDeleteName(null);
        handleDialogToggle();
    }

    return(
       <>
           <HeaderMenu systemRoutes={SystemRoutes.SETTINGS_EQUIPMENT_FORM} disableSave={!isAuthorizedToSave}/>
           <Box>
               <ACard>
                   <ADataGrid
                       hideFilters={false}
                       showFilterOptions={false}
                       hideSelection={false}
                       columns={initColumns}
                       rows={rows}
                       page={pagedSearchParams.page}
                       rowsPerPage={pagedSearchParams.size}
                       loading={false}
                       totalOfRecords={totalOfRecords}
                       onFiltersChange={handleOnFiltersChange}
                       actions={
                           <>
                               <IconButton name="edit" size={"small"} aria-label="Edit" disabled={!isAuthorizedToSave} onClick={(value) => handleEditEquipmentType(value)}>
                                   <Tooltip title="Editar">
                                       <Edit/>
                                   </Tooltip>
                               </IconButton>
                               <IconButton name="delete" size={"small"} aria-label="Delete" disabled={!isAuthorizedToDelete} onClick={(value) => handleDeleteEquipmentType(value)}>
                                   <Tooltip title="Excluir">
                                       <Delete />
                                   </Tooltip>
                               </IconButton>
                           </>
                       }
                   />
               </ACard>
           </Box>
           <ConfirmDialog
               title={'Deletar'}
               description={`Deseja deletar o equipamento ${selectedToDeleteName}?`}
               open={openDialog}
               handleConfirm={handleDialogConfirm}
               handleClose={handleDialogClose}
           />
       </>
    );

}

export function EquipmentHomePage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <EquipmentSettingsPage />
        </QueryClientProvider>
    )
}
