import axios, {AxiosRequestConfig} from "axios";
import {extracted} from "../../../../Utils/DownloadUtils";
import {GATEWAY_URL_NOTIFICATION} from "../../../../Config/Constants";


// utils/downloadUtils.js
export const handleFileDownload = (response: {
    data: Blob | MediaSource
}, filename: string, successCallBack: (() => void) | undefined, errorCallback: ((error: any) => void) | undefined) => {
    try {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        if (successCallBack) {
            successCallBack();
        }

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    } catch (error) {
        if (errorCallback) {
            errorCallback(error);
        }
    }
};

export const postAndDownloadFile = (url: string, data: any, filename: string, successCallBack?: () => void, errorCallback?: (error: any) => void, noContentCallBack?: () => void) => {
    const config: AxiosRequestConfig = {
        url: url,
        data,
        method: 'POST',
        responseType: 'blob'
    };
    axios({
        ...config,
        timeout: 40000
    }).then((response) => {
        if(response.status === 204){
            if(noContentCallBack){
                noContentCallBack()
            }
            return
        }
        handleFileDownload(response, filename, successCallBack, errorCallback);
    }).catch(error => {
        if (errorCallback) {
            errorCallback(error);
        }
    });
};


export const postToNotificationEmail = (url: string, data: any, successCallBack: (() => void) | undefined, errorCallback: ((error: any) => void) | undefined) => {
    const config: AxiosRequestConfig = {
        baseURL: GATEWAY_URL_NOTIFICATION,
        url: url,
        data,
        method: 'POST',
    };
    axios(config).then(() => {
        if (successCallBack) {
            successCallBack();
        }
    }).catch(error => {
        if (errorCallback) {
            errorCallback(error);
        }
    });
};

export const downloadFile = (url: string, params: any, filename: string, successCallBack?: () => void, errorCallback?: (error: any) => void, noContentCallback?: () => void, data?: any) => {
    const config: AxiosRequestConfig = {
        url: url,
        params,
        data: data,
        method: 'POST',
        responseType: 'blob'
    };

    extracted(config, filename, successCallBack, errorCallback, noContentCallback);
}