import React, {FunctionComponent, useEffect, useState} from 'react';
import {RootState} from "../../../../Config/Store";
import {getEntities, PowerIrradiancia, reset} from './PowerVSIrradianceChartReducer';
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {downloadFile} from "../../../../Utils/DownloadUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExpand, faFileCsv, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {useMediaQuery, useTheme} from "@mui/material";
import {addMinutes, format, parseISO} from 'date-fns';
import {isArray} from "lodash";
import {roundNextHundred, truncateNumber} from "../../../../Utils/NumberUtil";
import {AUTHORITIES, GATEWAY_URL} from "../../../../Config/Constants";
import {
    ACard,
    AChartX,
    FloatMenuItem,
    APopoverIcon,
    Period,
    PeriodSelector,
    Skeleton,
    XAxis,
    YAxis
} from "@atiautomacao/ati-ui-library"
import {checkDiffDays} from "../../../../Utils/DataUitils";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";
import {hasPermission} from "../../../../Shared/Auth/AuthenticationUtil";

interface SeriesOption {
    name: string;
    type: string;
    unity: string;
    yAxisIndex?: number | undefined;
    data: any[];
    date?: any;
    showSymbol?: boolean;
}

const PowerVSIrradianceChart: FunctionComponent<{ powerPlant: any; }> = ({powerPlant}) => {
    const dispatch = useAppDispatch();
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSearchByCsv = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_CSV_EXPORT, AUTHORITIES.EXPORT_POWER_VC_IRRADIANCE, AUTHORITIES.CSV_EXPORT_POWER_VC_IRRADIANCE
    ]);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const PowerIrradianceEntities: Array<PowerIrradiancia> = useAppSelector((state: RootState) => state.powerIrradianceChart.entities);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('xl'));
    const { loading, errorMessage } = useAppSelector((state: RootState) => state.powerIrradianceChart);
    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "day",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<Array<YAxis>>(Array<YAxis>());
    const [series, setSeries] = useState<Array<SeriesOption>>(Array<SeriesOption>());
    const [showGraph, setShowGraph] = useState(false);
    const userConfig = useAppSelector((state: RootState) => state.userConfigurarion);
    
    const isDay = (group:string)=>{
        return group === "day"
    }

    const callback = (params:any) => {
        let formatDate = period && period.groupBy.valueOf() === "week" ? 'dd/MM HH:mm' : 'HH:mm'
        let dateStr = format(new Date(params[0].axisValue), formatDate);

        let result = dateStr + '<br/>';
        params.forEach(function (item: any) {
            result += item.marker + " " + item.seriesName + "&nbsp;&nbsp;&nbsp;&nbsp;" + "<strong>" + item.value[1] + " " + (item.seriesName == "Irradiância" ? 'W/m²' : 'kW') + "</strong>" + "<br/>";
        });

        return result;
    }

    const adjustDataPoints = (dataPoints: any[]): any[] => {
        if (dataPoints.length === 0) {
            return dataPoints;
        }

        // Parseia o primeiro e último dateTime para objetos Date
        const firstDateTime = parseISO(dataPoints[0].dateTime);
        const lastDateTime = parseISO(dataPoints[dataPoints.length - 1].dateTime);

        // Horários limite
        const startTime = new Date(firstDateTime);
        startTime.setHours(4, 0, 0, 0); // 4:00
        const endTime = new Date(lastDateTime);
        endTime.setHours(20, 0, 0, 0); // 20:00

        let newData = [...dataPoints]
        while (firstDateTime > startTime) {
            const newDateTime = addMinutes(firstDateTime, -5);
            newData.unshift({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                irradiance: null,
            });
            firstDateTime.setTime(newDateTime.getTime());
        }

        while (lastDateTime < endTime) {
            const newDateTime = addMinutes(lastDateTime, 5);
            newData.push({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                irradiance: null,
            });
            lastDateTime.setTime(newDateTime.getTime());
        }

        return newData;
    }

    const hoursToMilliseconds = (hours:number): number => {
        return hours * 60 * 60 * 1000
    };

    const minDate = (minDate:string, group:string) : string | undefined => {
        if(!isDay(group)) return undefined;
        const min = new Date(minDate);
        min.setHours(4, 0, 0, 0); // 4:00
        return format(min, 'yyyy-MM-dd HH:mm:ss.S')
    }

    const maxDate = (maxDate:string, group:string) : string | undefined => {
        if(!isDay(group)) return undefined;
        const max = new Date(maxDate);
        max.setHours(20, 0, 0, 0);
        return format(max, 'yyyy-MM-dd HH:mm:ss.S')
    }



    const findPowerDataByPeriod = () => {
        if(period.groupBy){
            const grouping = period.groupBy.valueOf()
            const newData =  isDay(grouping) ? adjustDataPoints(PowerIrradianceEntities) : PowerIrradianceEntities
            let xAxisName: string= 'Dia';
            if (isDay(grouping)) {
                xAxisName = 'Horário';
            }

            setXAxis({
                name: xAxisName,
                type:'time',
                min: minDate(newData[0]?.dateTime, period.groupBy.valueOf()) ,
                max: maxDate(newData[0]?.dateTime, period.groupBy.valueOf()) ,
                nameLocation: 'middle',
                minInterval: isDay(grouping) ? hoursToMilliseconds(3) : hoursToMilliseconds(24),
                axisLabel: {
                    formatter: isDay(grouping) ? '{HH}:{mm}' : '{dd}/{MM}'
                },
                nameGap: 30
            });

            setShowGraph(PowerIrradianceEntities.length > 0);
            const percentage = 0.15;
            const capacity = powerPlant?.powerCapacityNominal ? powerPlant.powerCapacityNominal : 5200.00
            const capacityMaxLeftY = roundNextHundred(percentage * capacity + (capacity));
            const valueMaxIrradiance = newData.reduce((max, obj) => (obj.irradiance > max.irradiance ? obj : max), newData[0]).irradiance;
            const defaultRightY = valueMaxIrradiance > 1200 ? roundNextHundred(valueMaxIrradiance) : 1200;
            setYAxis([
                {
                    name: 'kW',
                    max: capacityMaxLeftY,
                    min: 0,
                    interval: roundNextHundred(capacityMaxLeftY / 4),
                    nameLocation: 'middle',
                    nameGap: 50,
                },
                {
                    name: 'W/m²',
                    max: defaultRightY,
                    min: 0,
                    interval: roundNextHundred(defaultRightY / 4),
                    nameLocation: 'middle',
                    nameGap: 40,
                    position: "right",
                }
            ])
            setSeries([
                    {
                        name: 'Potência AC',
                        data: Array.isArray(newData) ? newData.map((item) => item.activePower != null ? [item.dateTime ? item.dateTime : "", truncateNumber(item.activePower, 2)] : [item.dateTime ? item.dateTime : "", "-"]) : [],
                        type: 'line',
                        unity: 'Kw',
                        showSymbol: false
                    },
                    {
                        name: 'Irradiância',
                        data: Array.isArray(newData) ? newData.map((item) => item.irradiance != null ?  [item.dateTime ? item.dateTime : "", truncateNumber(item.irradiance, 2)]: [item.dateTime ? item.dateTime : "","-"]) : [],
                        type: 'line',
                        unity: 'W/m²',
                        showSymbol: false,
                        yAxisIndex: 1,
                    }
                ]
            );
        }

    }

    useEffect(() => {
        // dispatch(reset())
        if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0){
            dispatch(getEntities({period: period, idPowerPlant: powerPlant.id}));
        }
    }, [period, powerPlant, dispatch]);

    useInterval(() => {
        if (checkDiffDays(period.toDateTime, period.fromDateTime) > 0) {
            dispatch(getEntities({period: period, idPowerPlant: powerPlant.id}));
        }
    }, userConfig.mainUpdateInterval) // 5 minutes

    useEffect(() => {
        if (isArray(PowerIrradianceEntities) && PowerIrradianceEntities.length > 0 && period.groupBy){
            findPowerDataByPeriod();
        }else{
            dispatch(reset())
            setSeries([])
            setXAxis(undefined)
            setYAxis([])
        }
    }, [PowerIrradianceEntities]);

    const handleDownloadCSV = () => {
        const valueTypePeriod = period.groupBy.valueOf() === "hour";

        const params = {
            powerStationId: powerPlant.id,
            startDateTime: format(period.fromDateTime ? period.fromDateTime : new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
            endDateTime: format(period.toDateTime ? period.toDateTime : new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss')
        }
        const apiUrl = GATEWAY_URL+`/api/power-irradiance/csv/${valueTypePeriod}`
        downloadFile(apiUrl, params, 'potencia_vs_irradiancia.csv');
    };

    // @ts-ignore
    const option: AChartXProps['option'] = {
        color: ['rgb(25, 118, 210)', 'rgb(255,198,10)'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    formatter: function (params: any) {
                        const formatDate = period.groupBy.valueOf() === "week" ? "dd/MM HH:mm" : "HH:mm"

                        if (params.axisDimension === 'x') {
                            return format(new Date(params.value), formatDate);
                        }
                        if (params.axisDimension === 'y') {
                            return truncateNumber(params.value,2);
                        }
                    }
                }
            },
            formatter: callback
        },
        toolbox: {
            right: '15%',
            top: -8,
            itemSize: 15,
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
            renderMode: 'richText',
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                magicType: { show: false, type: ['line', 'bar'] },
                restore: { show: true }
            }
        },
        dataZoom: [
            {
                type: 'inside',
                throttle: 50
            },
        ],
        legend: {
            show: true,
            type: 'scroll',
            orient: 'horizontal', // Alterado para orientação horizontal
            bottom: -6, // Posiciona a legenda abaixo do gráfico
            left: 'center', // Centraliza a legenda horizontalmente
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: series.map(el => el.name)
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 40,
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        xAxis: xAxis,
        yAxis: yAxis,
        series: series,
    };

    return (
        <ACard
            key={`chart-power-irradiance-${openSubMenu}`}
            styleProps={{
                contentStyle: {padding: 40, height: 408}
            }}
            title="Potência x Irradiância"
            headerControls={
                <>
                    <PeriodSelector
                        styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                        mode="hybrid"
                        hideDatetime={false}
                        hideGroup={false}
                        inputFormat={period.groupBy === "day" ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                        periodInterval={{
                            day: {date: period.toDateTime, startTime: "00:00:00", endTime: "23:59:59"},
                            week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6}
                        }}
                        period={period}
                        onChange={setPeriod}
                        groupByOptions={["day", "week"]}
                    />
                </>
            }
            footerActions={
                <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                    <IconButton disabled={!isAuthorizedToSearchByCsv}>
                        <FontAwesomeIcon icon={faFileCsv} fontSize={20} onClick={handleDownloadCSV}/>
                    </IconButton>
                </APopoverIcon>
            }
        >
            {
                loading ?
                    <Skeleton animation="wave" height={289} variant="rounded" width={'100%'} />
                    :
                    xAxis && yAxis && series && showGraph ?
                        <AChartX
                            height={350}
                            option={option}
                            style={{marginTop: 8}}
                            theme={theme.palette.mode}
                        />
                        :
                        <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
            }
        </ACard>
    )
}

export default PowerVSIrradianceChart;