import React, {FunctionComponent, useEffect, useState} from "react";
import {
    ACard,
    ACardSplitFooterActions,
    AChartX,
    APopoverIcon,
    Period,
    PeriodSelector,
    Skeleton,
    XAxis,
    YAxis
} from "@atiautomacao/ati-ui-library";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faFileCsv} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {FormControl, FormControlLabel, Radio, RadioGroup, useTheme} from "@mui/material";
import {roundNextHundred, truncateNumber} from "../../../Utils/NumberUtil";
import {addMinutes, differenceInDays, format, parseISO,} from "date-fns";
import {EquipmentEntity} from "./ActivePowerInvertersChartReducer";
import ActivePowerReactQuery from "./ActivePowerReactQuery";
import {checkDiffDays} from "../../../Utils/DataUitils";
import DataNotFound from "../../../Shared/Components/DataNotFoundMessage";
import {useAppSelector} from "../../../Config/Hooks";

const ActivePowerInvertersChart: FunctionComponent<{powerStationData: any, onDataFound: any}> = ({powerStationData, onDataFound}) =>  {
    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "day",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);

    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<Array<YAxis>>(Array<YAxis>);
    const [series, setSeries] = useState<Array<any>>(Array<any>);
    const [radioGroup, setRadioGroup] = React.useState('powerPerUnit');

    const getUnity  = () => {
        return radioGroup.includes("powerPerUnit") ? 'PU' : 'kW';
    }

    const {data,isSuccess, isLoading} = ActivePowerReactQuery(period,getUnity(),powerStationData.id );
    const [equipmentEntity,setEquipmentEntity] = useState<EquipmentEntity[] | undefined>();

    useEffect(() => {
        if(isSuccess) {
            onDataFound(powerStationData.id);
        } else {
            onDataFound(null);
        }
    }, [data, isSuccess, isLoading]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioGroup((event.target as HTMLInputElement).value);
    };

    const valueFormatter = (value:any,unity:string) => {
        return  value + ' ' + unity
    }

    const max = () => {
        let maxValues = 0;
        if(equipmentEntity){
            maxValues = Math.max(...equipmentEntity.map(element => element.activePower));
        }

        return truncateNumber(maxValues + (maxValues * 0.15));
    }

    const adjustDataPoints = (dataPoints: any[]): any[] => {
        if (dataPoints.length === 0) {
            return dataPoints;
        }

        // Parseia o primeiro e último dateTime para objetos Date
        const firstDateTime = parseISO(dataPoints[0].dateTime);
        const lastDateTime = parseISO(dataPoints[dataPoints.length - 1].dateTime);
        const powerStationName = dataPoints[0].powerStationName
        const skidName = dataPoints[0].skidName
        const equipmentId = dataPoints[0].equipmentId
        const equipmentName = dataPoints[0].equipmentName

        // Horários limite
        const startTime = new Date(firstDateTime);
        startTime.setHours(4, 0, 0, 0); // 4:00
        const endTime = new Date(lastDateTime);
        endTime.setHours(20, 0, 0, 0); // 20:00

        let newData = [...dataPoints]
        while (firstDateTime > startTime) {
            const newDateTime = addMinutes(firstDateTime, -5);
            newData.unshift({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                reactivePower: null,
                powerPerUnit: null,
                equipmentId: equipmentId,
                equipmentName: equipmentName,
                value: null,
                powerStationName: powerStationName,
                skidName: skidName,
            });
            firstDateTime.setTime(newDateTime.getTime());
        }

        // Adiciona pontos de dados após 21:00, se necessário
        while (lastDateTime < endTime) {
            const newDateTime = addMinutes(lastDateTime, 5);
            newData.push({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                reactivePower: null,
                powerPerUnit: null,
                equipmentId: equipmentId,
                equipmentName: equipmentName,
                value: null,
                powerStationName: powerStationName,
                skidName: skidName,
            });
            lastDateTime.setTime(newDateTime.getTime());
        }

        return newData;
    }

    const handleAxisY = () => {
        setYAxis([])
        if(radioGroup.includes("powerPerUnit")){
            const defaultRightY = 1200;
            setYAxis([
                {
                    name: 'PU',
                    max: 1.5,
                    min: 0,
                    interval: truncateNumber(1.5 / 4, 2),
                    nameLocation: 'middle',
                    nameGap: 50,
                },
                {
                    name: "W/m²",
                    type: 'value',
                    max: defaultRightY, // Ou algum valor máximo que você deseja definir
                    position: 'right',
                    interval: roundNextHundred(defaultRightY / 4),
                    nameLocation: 'middle',
                    nameGap: 60
                }
            ])
        } else {
            const defaultRightY = 1200;
            const maxAxisY = max();
            setYAxis([
                {
                    name: 'kW',
                    max: maxAxisY,
                    min: 0,
                    interval: truncateNumber(maxAxisY / 4, 2),
                    nameLocation: 'middle',
                    nameGap: 50,
                },
                {
                    name: "W/m²",
                    type: 'value',
                    max: defaultRightY, // Ou algum valor máximo que você deseja definir
                    position: 'right',
                    interval: roundNextHundred(defaultRightY / 4),
                    nameLocation: 'middle',
                    nameGap: 60
                }
            ]);
        }
    }

    const handleSeriesData = (data:any[]) => {
        if(radioGroup.includes("powerPerUnit")){
            return data.map(element => element.powerPerUnit != null ? truncateNumber(element.powerPerUnit, 2) : '-');
        }
        return data.map(element => element.activePower != null ? truncateNumber(element.activePower, 2) : '-');
    }

    function setNewDatePeriod(newDate: any) {
        if (newDate.length > 0) {
            return newDate.map((item: any) => format(new Date(item.dateTime), "HH:mm"))
        } else {
            return [];
        }

    }
    console.log(series);
    console.log(yAxis);
    console.log(xAxis);
    const findPowerActiveDataByPeriod = () => {
        handleAxisY();
        const equipmentEntitySort = equipmentEntity ? [...equipmentEntity].sort((a, b) => {
            return a.equipmentName.localeCompare(b.equipmentName);
        }) : [];
        let newDate = adjustDataPoints(equipmentEntitySort)
        let datePeriod: any[];
        let xAxisName = 'Horário';

        datePeriod = Array.isArray(newDate) ? Array.from(new Set(setNewDatePeriod(newDate))
        ) : [];

        setXAxis({name: xAxisName, data: datePeriod, min: '04:00', max:'20:00', nameGap: 20,  nameLocation: 'center'});


        const filteredData = data?.data.reduce((acc: any[], current) => {
            const x = acc.find(item => item.dateTime === current.dateTime);
            if (!x) {
                return acc.concat([current]); // Se não encontrar duplicata, adiciona ao acumulador
            } else {
                return acc; // Caso contrário, retorna o acumulador sem mudança
            }
        }, []);



        let newArray = filteredData ? adjustDataPoints(filteredData) : [];



        let dataSeries: Array<any> = [];
        const equipmentIds = Array.from(new Set(
            equipmentEntitySort.map(
                item => item.equipmentId
            )
        ));
        equipmentIds.forEach(element => {
            let filter = equipmentEntitySort.filter(data => data.equipmentId === element);
            let newData = adjustDataPoints(filter)
            dataSeries.push(
                {
                    name: filter[0].equipmentName + " - " + filter[0].skidName,
                    data: handleSeriesData(newData),
                    type: 'line',
                    unity: getUnity(),
                    smooth: true,
                    tooltip: {
                        valueFormatter: (value: any) => valueFormatter(value, getUnity()),
                        show: true
                    },
                })
        })
        dataSeries.push(
            {
                name: 'Irradiância',
                type: 'line',
                unity: 'W/m²',
                itemStyle: {
                    normal: {
                        color: '#FEC60F',
                        lineStyle: {
                            width: 1
                        },
                        smooth: true
                    }
                },
                yAxisIndex: 1,
                data: newArray.map(element => element.irradiance != null ? truncateNumber(element.irradiance, 2) : '-'),
                tooltip: {
                    valueFormatter: (value: any) => valueFormatter(value, 'W/m²'),
                    show: true
                },
                smooth: true,  // Adiciona a suavização à linha
                areaStyle: {
                    color: 'rgba(254, 198, 15, 0.2)'
                }
            }
        )
        setSeries(dataSeries)
    }
    useEffect(() => {
           if( period.toDateTime && period.fromDateTime && differenceInDays(period.toDateTime, period.fromDateTime) <= 6 && checkDiffDays(period.toDateTime, period.fromDateTime) > 0){
            if(isSuccess && data){
                setEquipmentEntity(data.data)
            }else{
                setEquipmentEntity([])
            }

        }
    }, [period, radioGroup, data]);


    useEffect(() => {
        if(equipmentEntity && equipmentEntity.length > 0) {
            findPowerActiveDataByPeriod()
        }else {
            setSeries([])
        }
    }, [equipmentEntity, period]);

    const theme = useTheme();
    // @ts-ignore
    const option: AChartXProps['option'] = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            show: true,
            orient: 'horizontal',
            type:'scroll',
            bottom: 5,
            left: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: series.map(sr => sr.name)
        },
        toolbox: {
            right: '16%',
            top: -7,
            itemSize: 15,
            feature: {
                dataView: { show: false, readOnly: false },
                dataZoom: {
                    yAxisIndex: 'none'
                },
                restore: { show: true },
                magicType: { show: false, type: ['line', 'bar'] },
            }
        },
        dataZoom: [
            {
                type: 'inside',
                throttle: 50
            },
        ],
        grid: {
            containLabel: true,
            top: 10,
            bottom: 40
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        xAxis: xAxis,
        yAxis: yAxis,
        series: series
    };

    console.log(option)
    let contentToRender: JSX.Element;
    if(isLoading) {
        contentToRender = <Skeleton animation="wave" height={250} variant="rounded" width={"100%"}/>;
    } else if(series && series.length > 0 && xAxis && yAxis.length > 0) {
        contentToRender = <AChartX
                                key={`chart-overview-active-power-${openSubMenu}`}
                                height={250}
                                option={option}
                                theme={theme.palette.mode}
                            />
    } else {
        contentToRender = <DataNotFound/>;
    }

    return (
        <ACard
            title={powerStationData.name ? powerStationData.name : "Nome indefinido"}
            headerControlsPosition={"header"}
            headerControls={
                <PeriodSelector
                    styleProps={{
                        base: {flexDirection: "row", display: "flex", alignItems: "center", color: '#fff'},
                    }}
                    mode="hybrid"
                    hideDatetime={false}
                    hideGroup={false}
                    periodInterval={{
                        day: {date: period.toDateTime, startTime: "00:00:00", endTime: "23:59:59"},
                    }}

                    period={period}
                    onChange={setPeriod}
                    groupByOptions={["day"]}
                />
            }
            // headerActions={
            //     <>
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
            //             text="Expand"
            //             disable={true}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
            //             text="Print"
            //             disable={true}
            //         />
            //     </>
            // }
            footerActions={
                <ACardSplitFooterActions
                    leftActions={
                        <FormControl>
                            <RadioGroup
                                style={{marginLeft: 15}}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="controlled-radio-buttons-group"
                                value={radioGroup}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="powerPerUnit" control={<Radio/>} label="PU"/>
                                <FormControlLabel value="activePower" control={<Radio/>} label="kW"/>
                            </RadioGroup>
                        </FormControl>
                    }
                    rightActions={
                        <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                            <IconButton disabled={true}>
                                <FontAwesomeIcon icon={faFileCsv} fontSize={20}/>
                            </IconButton>
                        </APopoverIcon>
                    }/>

            }
            styleProps={{
                contentStyle: {height: 250, paddingLeft: 0, paddingRight: 0}
            }}
        >
            {contentToRender}
        </ACard>
    );
}
export default ActivePowerInvertersChart;

