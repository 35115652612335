import React, {FunctionComponent, useEffect} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator, faExpand, faGear, faHourglassStart, faPowerOff, faPrint} from "@fortawesome/free-solid-svg-icons";
import {ElectricBolt, SolarPower, WbSunny} from '@mui/icons-material/';
import {getEntity} from "./TotalizerPlantSkidReducer";
import {RootState} from "../../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {Container} from "../../../../Shared/Components/AlarmTotalizers/AlarmTotalizers.theme";
import {ACard, FloatMenuButton, FloatMenuItem, Skeleton, Totalizer} from "@atiautomacao/ati-ui-library";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Box, Grid} from "@mui/material";
import useInterval from "../../../../Shared/Hooks/useInterval";

const TotalizerPlantSkid: FunctionComponent<{skidId: any}> = ({skidId}) => {

    const totalizers = useAppSelector((state: RootState) => state.totalizerPlantSkid.totalizers);
    const isLoading = useAppSelector((state : RootState) => state.totalizerPlantSkid.isLoading);
    const dispatch = useAppDispatch();
    const userConfig = useAppSelector((state: RootState) => state.userConfigurarion);
    
    useEffect(() => {
        dispatch(getEntity({skidId: skidId}));
    }, []);

    useInterval(() => {
        dispatch(getEntity({skidId: skidId}));
    }, userConfig.mainUpdateInterval) // 5 minutes

    const formatNumber = (value: number, decimals: number, unit: string): string => {
        if(value == null){
            return "N/A";
        }
        if(value < 0){
            return "Parâmetro Inválido"
        }
        return value.toFixed(decimals) + " " + unit;
    };


    const formatPercentage = (value: number): string => {
        if(value == null){
            return "N/A"
        }
        return (value * 100).toFixed(2) + "%";
    };


    const formattedGeneratedEnergy = formatNumber(totalizers?.generatedEnergy, 2, "MWh");
    const formattedPerformanceRatio = formatPercentage(totalizers?.performanceRatio);
    const formattedAvailability = formatPercentage(totalizers?.availability);
    const formattedActivePower = formatNumber(totalizers?.activePower, 2, "kW");
    const formattedCapacityFactor = formatPercentage(totalizers?.capacityFactor);
    const formattedIrradiance = formatNumber((totalizers?.irradiance / 1000), 2,  'kWh/m²');
    const formattedIrradiation = formatNumber((totalizers?.irradiation !== undefined ? totalizers?.irradiation : 0), 2,  'W/m²');

    return (
        <>
            {
                !totalizers ?
                    <Box style={{paddingBottom: 10}}>
                        <ACard
                            title={"Performance"}
                            // headerActions={
                            //     <FloatMenuButton
                            //         icon={<MoreVertIcon/>}
                            //         tooltip={"Floating Menu"}
                            //     >
                            //         <FloatMenuItem
                            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                            //             text="Settings"
                            //             disable={true}
                            //             link={"/"}
                            //         />
                            //         <FloatMenuItem
                            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                            //             text="Expand"
                            //             disable={true}
                            //         />
                            //         <FloatMenuItem
                            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                            //             text="Print"
                            //             disable={true}
                            //         />
                            //     </FloatMenuButton>
                            // }
                        >
                            <Container data-testid="loading-skeleton">
                                <Skeleton animation="wave" height={100} variant="rectangular" width={240} />
                                <Skeleton animation="wave" height={100} variant="rectangular" width={240} />
                                <Skeleton animation="wave" height={100} variant="rectangular" width={240} />
                                <Skeleton animation="wave" height={100} variant="rectangular" width={240} />
                                <Skeleton animation="wave" height={100} variant="rectangular" width={240} />
                                <Skeleton animation="wave" height={100} variant="rectangular" width={240} />
                            </Container>
                        </ACard>
                    </Box>
                    :
                    <Box>
                        <ACard
                            title={"Performance do Skid"}
                            // headerActions={
                            //     <FloatMenuButton
                            //         icon={<MoreVertIcon/>}
                            //         tooltip={"Floating Menu"}
                            //     >
                            //         <FloatMenuItem
                            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                            //             text="Settings"
                            //             disable={true}
                            //             link={"/"}
                            //         />
                            //         <FloatMenuItem
                            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                            //             text="Expand"
                            //             disable={true}
                            //         />
                            //         <FloatMenuItem
                            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                            //             text="Print"
                            //             disable={true}
                            //         />
                            //     </FloatMenuButton>
                            // }
                        >
                            <Grid container spacing={2}>
                                {/*TODO do later, with the appropriate bussines ruler*/}
                                {/*<div>*/}
                                {/*    <Totalizer*/}
                                {/*        width={220}*/}
                                {/*        value={formattedIrradiation}*/}
                                {/*        label="Irradiância"*/}
                                {/*        cardIcon={<WbSunny style={{fontSize: 38}}/>}*/}
                                {/*        background="#556B2F"*/}
                                {/*        textColor="#ffffff"*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                    <Totalizer
                                        width={215}
                                        value={formattedActivePower}
                                        label="Potência Ativa"
                                        cardIcon={<SolarPower style={{fontSize: 38}}/>}
                                        background="#0288d1"
                                        textColor="#ffffff"
                                    />
                                </Grid>
                                <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                    <Totalizer
                                        width={220}
                                        value={formattedIrradiance}
                                        label="Irradiação"
                                        cardIcon={<WbSunny style={{fontSize: 38}}/>}
                                        background="#7fae2e"
                                        textColor="#ffffff"
                                    />
                                </Grid>
                                <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                    <Totalizer
                                        width={215}
                                        value={formattedGeneratedEnergy}
                                        label="Energia gerada"
                                        cardIcon={<ElectricBolt style={{fontSize: 38}}/>}
                                        background="#00bcd4"
                                        textColor="#ffffff"
                                    />
                                </Grid>
                                <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                    <Totalizer
                                        width={215}
                                        value={formattedCapacityFactor}
                                        label="Fator de Capacidade"
                                        cardIcon={<FontAwesomeIcon icon={faHourglassStart} style={{fontSize: 38}}/>}
                                        background="#6B8897"
                                        textColor="#ffffff"
                                    />
                                </Grid>
                                <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                    <Totalizer
                                        width={215}
                                        value={formattedPerformanceRatio}
                                        label="Performance Ratio"
                                        cardIcon={<FontAwesomeIcon icon={faCalculator} style={{fontSize:38}}/>}
                                        background="#ffc107"
                                        textColor="#ffffff"
                                    />
                                </Grid>
                                <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                    <Totalizer
                                        width={215}
                                        value={formattedAvailability}
                                        label="Disponibilidade"
                                        cardIcon={<FontAwesomeIcon icon={faPowerOff} style={{fontSize: 38}}/>}
                                        background="#616161"
                                        textColor="#ffffff"
                                    />
                                </Grid>
                            </Grid>
                        </ACard>
                    </Box>
            }
        </>
    )

}

export default TotalizerPlantSkid;