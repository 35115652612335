import {Tooltip, useTheme} from "@mui/material";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import React, {useCallback, useEffect, useState} from "react";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {DeleteModBusDataTypeReactQuery, ModBusDataTypeReactQuery} from "./ModBusDataTypesReactQuery";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {Delete, Edit} from "@mui/icons-material";
import {QueryClient, QueryClientProvider} from "react-query";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import Box from "@mui/material/Box";
import {HeaderMenu} from "../HeaderMenu";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";
import {useAppSelector} from "../../../Config/Hooks";


export const initColumns = [
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'name',
        label: 'Nome',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
] as ADataGridColumn[]

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowModBusDataType {
    id?: number;
    name: string;
    actionsConfig?: Array<ActionConfig>
}

export const ModBusDataTypesSettingsPage = () => {
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.SAVE_MOD_BUS_DATA_TYPES
    ]);

    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_SETTINGS, AUTHORITIES.DELETE_MOD_BUS_DATA_TYPES
    ]);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 100));
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const {data, isSuccess} = ModBusDataTypeReactQuery(pagedSearchParams);


    const [openDialog, setOpenDialog] = useState(false);
    const [rows, setRows] = useState<Array<RowModBusDataType>>([]);

    const navigate = useNavigate();
    const mutation = DeleteModBusDataTypeReactQuery(pagedSearchParams);

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);


    useEffect(() => {
        if (isSuccess) {
            setTotalOfRecords(data?.totalOfRecords);
            if (data?.data) {
                let rowsObjectType = data.data.map((modBusDataType: any) => {
                    return {
                        id: modBusDataType.id,
                        name: modBusDataType.name,
                        actionsConfig: [
                            {actionName: "edit", disabled: !isAuthorizedToSave},
                            {actionName: "delete", disabled: !isAuthorizedToDelete},
                        ]
                    }
                });

                setRows(rowsObjectType);
            }
        }
    }, [data?.data]);

    const handleEditEquipmentClass = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES_FORM, {state: {id: data.id}});
    }, [navigate]);

    const handleDeleteObjectType = useCallback((data: any) => {
        setSelectedToDelete(data.id);
        setOpenDialog(true);
    }, []);

    const handleDialogConfirm = async () => {
        mutation.mutate({id: selectedToDelete, pagedSearchParams});
        setSelectedToDelete(null);
        handleDialogToggle();
        navigate(SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES);
    }

    useEffect(() => {
        if (mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50))
        }

    }, [mutation.isSuccess]);

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    const theme = useTheme();
    
    return (
        <>

            <HeaderMenu systemRoutes={SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES_FORM} disableSave={!isAuthorizedToSave} />
            <Box>
                <ACard>
                    <ADataGrid
                        columns={initColumns}
                        rows={rows}
                        page={pagedSearchParams.page}
                        rowsPerPage={pagedSearchParams.size}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        hideSelection={true}
                        onFiltersChange={handleOnFiltersChange}
                        actions={
                            <>
                                <IconButton name="edit" size={"small"} aria-label="Edit"
                                            disabled={!isAuthorizedToSave} onClick={(value) => handleEditEquipmentClass(value)}>
                                    <Tooltip title="Editar">
                                        <Edit/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton name="delete" size={"small"} aria-label="Delete"
                                            disabled={!isAuthorizedToDelete} onClick={(value) => handleDeleteObjectType(value)}>
                                    <Tooltip title="Excluir">
                                        <Delete/>
                                    </Tooltip>
                                </IconButton>
                            </>
                        }
                    />
                </ACard>
                <ConfirmDialog
                    title={'Deletar'}
                    description={'Deseja deletar esse tipo de objeto?'}
                    open={openDialog}
                    handleConfirm={handleDialogConfirm}
                    handleClose={handleDialogClose}
                />
            </Box>

        </>
    );
}

export default function ModBusDataTypeHomePage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ModBusDataTypesSettingsPage/>
        </QueryClientProvider>
    )
}
