import {useQuery} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";

const apiUrl = "api/command-history/grid";

const fetchCommandHistory = async (pagedSearchParams: PagedSearchParams) => {
    try {
        const response = await axios
            .get(apiUrl, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data);

        return response ?? 0;
    } catch (error : any) {
        console.error("Error fetching data:", error);
        return 0;
    }
};

export const CommandHistory = (pagedSearchParams: PagedSearchParams, isAuthenticated:boolean, isAuthorizedToShowGrid: boolean, interval?: number) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => fetchCommandHistory(pagedSearchParams),
        refetchInterval: interval ?? false,
        enabled: isAuthenticated && isAuthorizedToShowGrid, // Apenas ativa a query se ambas as condições forem verdadeiras
    });
};