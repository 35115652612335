import {useQuery} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";

const apiUrl = "api/alarm/audit/search";

const searchAlarms = async (pagedSearchParams: PagedSearchParams) => {
    try {
        const response = await axios
            .get(apiUrl, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data);

        return response ?? 0;
    } catch (error : any) {
        console.error("Error fetching data:", error);
        return 0;
    }
};

export const AuditAlarmsQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => searchAlarms(pagedSearchParams),
    });
};