import countryReducer from '../../Modules/Settings/Country/CountryReducer';
import countryMenuReducer from '../../Modules/Settings/Country/CountryMenuReducer';
import authorityReducer from '../../Modules/Settings/Profiles/AuthorityReducer';
import profilesReducer from '../../Modules/Settings/Profiles/ProfilesReducer';
import profilesMenuReducer from '../../Modules/Settings/Profiles/ProfilesMenuReducer';
import authenticationReducer from './AuthenticationReducer';
import layoutReducer from './LayoutReducer';
import dashboardReducer from '../../Modules/Dashboard/DashboardReducer';
import dashboardMenuReducer from '../../Modules/Dashboard/DashboardMenuReducer';
import generationPRChartReducerReducer
    from "../../Modules/Dashboard/GenerationPRChart/GenerationPRChartReducer.reducer";
import dashboardNavigationMenuReducer from '../../Shared/Components/Layout/menu/SGDDashboardMenuReducer';
// import {combineReducers} from "redux";
import {combineReducers} from '@reduxjs/toolkit'
import generationEnergyChartReducerReducer
    from "../../Modules/Dashboard/GenerationEnergyChart/GenerationEnergyChartReducer.reducer";
import dashboardPowerPlantChartsReducer
    from "../../Modules/Dashboard/Powerplant/GenerationChart/DashboardPowerPlantChartsReducer";
import powerVSIrradianciaChartReducer
    from "../../Modules/Dashboard/Powerplant/PowerVSIrradianceChart/PowerVSIrradianceChartReducer";
import powerPUForInvertersReducer
    from '../../Modules/Dashboard/Powerplant/PowerPUForInvertersChart/PowerPUForInvertersReducer';
import PerformanceAnalysisReducer from '../../Modules/Dashboard/PerformanceAnalysis/PerformanceAnalysisReducer';
import totalizerPlantReducer from "../../Modules/Dashboard/Powerplant/TotalizerPlant/TotalizerPlantReducer";
import mapPowerStationReducer from "../../Modules/Dashboard/Powerplant/MapPowerStation/MapPowerStationReducer";
import CapacityFactorVSPowerReducer
    from "../../Modules/Dashboard/Powerplant/CapacityFactorVSPowerChart/CapacityFactorVSPowerReducer";
import TotalizerAlarmsReducerReducer from "../../Modules/Dashboard/TotalizerAlarms/TotalizerAlarmsReducer";
import GenerationYieldComparisonReducerReducer
    from "../../Modules/Dashboard/GenerationYieldComparisonChart/GenerationYieldComparisonReducer.reducer";
import generationPRComparisonChartReducerReducer
    from "../../Modules/Dashboard/GenerationPRChartComparison/GenerationPRComparisonChartReducer.reducer";
import WeatherStationPlantReducer
    from '../../Modules/Dashboard/Powerplant/WeatherStationPlant/WeatherStationPlantReducer';
import generationPRChartPlantReducerReducer
    from "../../Modules/Dashboard/Powerplant/GenerationPRChartPlant/GenerationPRChartPlantReducer.reducer";
import totalizerPlantSkidReducer from "../../Modules/Dashboard/Skid/TotalizerPlantSkid/TotalizerPlantSkidReducer";
import TransformerTemperatureReducer
    from "../../Modules/Dashboard/Skid/TransformerTemperatureChart/TransformerTemperatureReducer";
import totalizerAlarmsPlantSkidReducer
    from "../../Modules/Dashboard/Skid/TotalizerAlarmsPlantSkid/TotalizerAlarmsPlantSkidReducer";
import generationEnergySkidChartReducer
    from "../../Modules/Dashboard/Skid/GenerationEnergySkidChart/GenerationEnergySkidChartReducer";
import SkidComparisonChartReducer
    from "../../Modules/Dashboard/Powerplant/SkidComparisonChart/SkidComparisonChartReducer";
import generationYieldChartReducer from "../../Modules/Dashboard/GenerationYieldChart/GenerationYieldChartReducer";
import alarmReducer from "../../Modules/Dashboard/Alarms/AlarmReducer";
import alarmGroupReducer from "../../Modules/Dashboard/Alarms/Components/AlarmGroup/AlarmGroupReducer";
import alarmPowerPlantReducer from "../../Modules/Dashboard/Powerplant/Alarms/AlarmPowerPlantReducer";
import alarmSkidReducer from "../../Modules/Dashboard/Skid/Alarms/AlarmSkidReducer";
import TotalizerPowerStationAlarmReducer
    from "../../Modules/Dashboard/Powerplant/TotalizerAlarm/TotalizerPowerStationAlarmReducer";
import alarmTotalizersReducer from '../../Shared/Components/AlarmTotalizers/AlarmTotalizersReducer';
import groupingByPowerPlantReducer
    from "../../Modules/Dashboard/Powerplant/Alarms/Components/AlarmGroup/GroupingByPowerPlantReducer";
import groupingBySkidReducer from "../../Modules/Dashboard/Skid/Alarms/Components/AlarmGroup/GroupingBySkidReducer";
import environmentalDataStationPlantReducer
    from "../../Modules/Dashboard/Powerplant/EnvironmentalData/EnvironmentalDataStationPlantReducer";
import EquipmentDetailsSkidReducer from "../../Modules/Dashboard/Skid/EquipmentDetailsSkid/EquipmentDetailsSkidReducer";
import activePowerInvertersSkidChartReducer
    from "../../Modules/Dashboard/Skid/ActivePowerInverterSkidChart/ActivePowerInvertersSkidChartReducer";
import powerStationElementsReducer
    from "../../Modules/Dashboard/Powerplant/PowerStationElements/PowerStationElementsReducer";
import alarmIterationReducer from "../../Modules/Dashboard/Alarms/Components/AlarmNotion/AlarmIterationReducer";
import skidNodesReducer from "../../Modules/Dashboard/Skid/SkidNodes/SkidNodesReducer";
import DashboardOverviewMapPageReducer from "../../Modules/Dashboard/Overview/DashboardOverviewMapPageReducer";
import UTRDataPlantReducer from "../../Modules/Dashboard/Powerplant/UTRPlant/UTRDataPlantReducer";
import synopticChartReducer from "../../Modules/Dashboard/Synoptic/SynopticChartReducer";
import GenerationRateChartReducer
    from "../../Modules/Dashboard/Powerplant/GenerationRateChart/GenerationRateChartReducer";
import GenerationRateOfInvertersReducer
    from "../../Modules/Dashboard/Skid/GenerationRateOfInvertersChart/types/GenerationRateOfInvertersReducer";
import ActivePowerInvertersChartReducer from "../../Modules/Dashboard/ActivePower/ActivePowerInvertersChartReducer"
import DashboardNavigationAnalysisReducer
    from "../../Modules/Analysis/types/reducer/DashboardNavigationAnalysisReducer";
import PowerPUForInvertersSkidReducer
    from "../../Modules/Dashboard/Skid/PowerPUForInvertersChart/PowerPUForInvertersReducer";
import DashboardAnalysisGridReducer from "../../Modules/Analysis/types/reducer/DashboardAnalysGridReducer"
import TrackersReducer from "../../Modules/Dashboard/Powerplant/Trackers/TrackersReducer";
import  ConfigurationReducer from './ConfigurationReducer';

const rootReducer = combineReducers({
    layout: layoutReducer,
    authentication: authenticationReducer,
    dashboard: dashboardReducer,
    dashboardMenu: dashboardMenuReducer,
    country: countryReducer,
    countryMenu: countryMenuReducer,
    profile: profilesReducer,
    authority: authorityReducer,
    profilesMenu: profilesMenuReducer,
    generationEnergyChart: generationEnergyChartReducerReducer,
    powerIrradianceChart: powerVSIrradianciaChartReducer,
    dashboardPowerPlantCharts: dashboardPowerPlantChartsReducer,
    powerPUForInvertersChart: powerPUForInvertersReducer,
    performanceAnalysis: PerformanceAnalysisReducer,
    totalizerPlant: totalizerPlantReducer,
    powerStationMap: mapPowerStationReducer,
    dashboardMapPage: DashboardOverviewMapPageReducer,
    capacityFactorChart: CapacityFactorVSPowerReducer,
    totalizerAlarms: TotalizerAlarmsReducerReducer,
    totalizerPowerPlantAlarms: TotalizerPowerStationAlarmReducer,
    generationPRChart: generationPRChartReducerReducer,
    generationYieldComparisonChart: GenerationYieldComparisonReducerReducer,
    generationPRComparisonChart: generationPRComparisonChartReducerReducer,
    weatherStationPlant: WeatherStationPlantReducer,
    generationPRChartPlant: generationPRChartPlantReducerReducer,
    totalizerPlantSkid: totalizerPlantSkidReducer,
    transformerTemperatureChart: TransformerTemperatureReducer,
    totalizerAlarmsPlantSkid: totalizerAlarmsPlantSkidReducer,
    generationEnergySkid: generationEnergySkidChartReducer,
    skidComparisonChart: SkidComparisonChartReducer,
    generationRateBySkid: GenerationRateChartReducer,
    generationYieldChart: generationYieldChartReducer,
    alarmOverview: alarmReducer,
    alarmGroupOverview: alarmGroupReducer,
    dashboardNavigationMenu: dashboardNavigationMenuReducer,
    alarmPowerPlant: alarmPowerPlantReducer,
    alarmSkid: alarmSkidReducer,
    alarmTotalizers: alarmTotalizersReducer,
    groupingByPowerPlant: groupingByPowerPlantReducer,
    groupingBySkid: groupingBySkidReducer,
    environmentalDataStationPlant: environmentalDataStationPlantReducer,
    equipmentDetailsSkid: EquipmentDetailsSkidReducer,
    activePowerInvertersSkid: activePowerInvertersSkidChartReducer,
    powerStationElements: powerStationElementsReducer,
    alarmIteration: alarmIterationReducer,
    skidNodesReducer: skidNodesReducer,
    UTRDataPlant: UTRDataPlantReducer,
    generationActivePowerInvertersSkid: activePowerInvertersSkidChartReducer,
    synopticChart: synopticChartReducer,
    generationRateByInverter: GenerationRateOfInvertersReducer,
    activePowerInvertersChart: ActivePowerInvertersChartReducer,
    dashboardNavigationMenuAnalysis: DashboardNavigationAnalysisReducer,
    powerPUForInvertersSkid: PowerPUForInvertersSkidReducer,
    dashboardAnalysisGrid: DashboardAnalysisGridReducer,
    trackers: TrackersReducer,
    userConfigurarion: ConfigurationReducer
});

export default rootReducer;
