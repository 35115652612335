import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel, InputLabel, MenuItem, Radio,
    RadioGroup, Select,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useSnackbar} from "notistack";
import {SelectChangeEvent} from "@mui/material/Select";
import Box from "@mui/material/Box";
import {MenuProps} from "../../Utils/PropsStyles";
interface ParamsModalProps {
    open: boolean;
    onCloseModal: any;
    commandDTO: CommandDTO;
}
export interface CommandDTO {
    id: number;
    teleObjectName: string;
    userId: number;
    point: number;
    teleObjectSpecType: number;
    etsCode: number;
    brokerId: number;
    powerStationName?: string;
    skidName?: string | null;
    equipmentName?: string;
    commandValues?: any;
}
export const ModalForSendingTelecommand = ({open, onCloseModal, commandDTO}: ParamsModalProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [inputValue, setInputValue] = useState("");
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [selectedCommand, setSelectedCommand] = useState("");
    const [selectCommandId, setSelectCommandId] = useState(null);

    useEffect(() => {
        setInputValue("")
    }, [onCloseModal]);

    const handleSelectedOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value)
        setInputValue(event.target.value);
    }

    const handleSelectTeleCommandValue = (event: SelectChangeEvent<unknown>) => {
        setSelectedCommand(event.target.value as string);
        let commandValue = commandDTO?.commandValues.find((cmd: any) => cmd.name === event.target.value);
        if (commandValue != null) {
            setInputValue(commandValue.value);
            setSelectCommandId(commandValue.id);
        }
    }

    const handleSend = async () => {
        if (commandDTO && inputValue != null) {
            if (!/^\d+$/.test(inputValue)) {
                enqueueSnackbar("Apenas valores numéricos são aceitos!", {variant: "error"});
                return;
            }
            try {
                const response = await axios.post('/api/send-command', {
                    userId: commandDTO.userId,
                    teleObjectId: commandDTO.id,
                    point: commandDTO.point,
                    teleObjectSpecType: commandDTO.teleObjectSpecType,
                    etsCode: commandDTO.etsCode,
                    data: Number(inputValue),
                    brokerId: commandDTO.brokerId,
                    commandValueId: selectCommandId
                });
                enqueueSnackbar("Valor enviado com sucesso!", { variant: "success" });
                setSelectCommandId(null);
                setSelectedCommand("");
                onCloseModal()
            } catch (error) {
                enqueueSnackbar("Erro ao enviar o valor do Telecomando, tente novamente!", {variant: "error"});
                console.error("Erro ao enviar comando. Tente novamente:", error);
            }
        }
    };
    const printFullHeader = () => {
        return commandDTO?.skidName != null && commandDTO?.powerStationName != null && commandDTO?.equipmentName != null
    }
    return (
        <Dialog open={open} onClose={onCloseModal}>

            {printFullHeader() ?
                <DialogTitle>{commandDTO?.equipmentName + ' - ' + commandDTO?.skidName + ' - ' + commandDTO?.powerStationName}</DialogTitle>
            :   <DialogTitle>{commandDTO?.teleObjectName}</DialogTitle>
            }
            <DialogContent>
                {printFullHeader() && <DialogContentText>{commandDTO?.teleObjectName}</DialogContentText>}
                {
                    commandDTO && commandDTO.teleObjectSpecType === 5 ?
                        <RadioGroup
                            aria-labelledby="chart-type"
                            row
                            name="chart-type"
                            onChange={handleSelectedOption}
                            value={selectedOption}
                        >
                            <FormControlLabel value="1" control={<Radio/>} label="Ligar"/>
                            <FormControlLabel value="0" control={<Radio/>} label="Desligar"/>
                        </RadioGroup>
                            :
                        commandDTO
                        && (commandDTO.teleObjectSpecType === 6 || commandDTO.teleObjectSpecType === 8)
                        && (commandDTO.commandValues && commandDTO.commandValues.length > 0) ?
                            <Box>
                                <InputLabel id="valueCommand" sx={{fontSize: '.8rem'}}>Selecione o Valor</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="valueCommand"
                                    id="valueCommand"
                                    placeholder="Selecione o valor"
                                    value={selectedCommand}
                                    label="Selecione o valor"
                                    title="Selecione o valor"
                                    onChange={(value) => handleSelectTeleCommandValue(value)}
                                    variant={"standard"}
                                    MenuProps={MenuProps}
                                >
                                    {commandDTO.commandValues.map((item: any, index: number) =>
                                        <MenuItem key={index} value={item.name}>{`Nome: ${item.name}, Valor: ${item.value}`}</MenuItem>)}
                                </Select>
                            </Box>
                            :
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Digite o valor"
                            type="text"
                            fullWidth
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseModal} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSend} color="primary" variant="contained" disabled={inputValue === ""}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    )
}