import React, {FunctionComponent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {AChartX, Period, PeriodSelectorGroupProps, Skeleton, XAxis, YAxis} from "@atiautomacao/ati-ui-library";
import {GenerationRateSkid, getEntities, reset} from "./GenerationRateChartReducer";
import {RootState} from "../../../../Config/Store";
import {format} from "date-fns";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {checkDiffDays} from "../../../../Utils/DataUitils";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";

import {Button, useTheme} from "@mui/material";
import ColorInformation, {NewHtmlTooltip} from "../../../../Utils/ColorInformation";


interface GenerationRateChartProps {
    period?: Period;
    periodIntervalGroup? : PeriodSelectorGroupProps;
    powerPlant: any;
}

const GenerationRateChart: FunctionComponent< GenerationRateChartProps > = (props: GenerationRateChartProps) => {
    const dispatch = useAppDispatch();
    const generationRateSkid: Array<GenerationRateSkid> = useAppSelector((state: RootState) => state.generationRateBySkid.entities);
    const { loading } = useAppSelector((state: RootState) => state.generationRateBySkid);
    const theme = useTheme();
    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const userConfig = useAppSelector((state: RootState) => state.userConfigurarion);
    
    const [period, setPeriod] = useState<Period>(props.period ? props.period : initialPeriod);
    const [series, setSeries] = useState<Array<any>>([]);
    let [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<Array<YAxis>>(new Array<YAxis>);

    useEffect(() => {
        if(props.period){
            setPeriod(props.period);
        }
        setSeries([])
    }, [props.period]);

    useEffect(() => {
        setSeries([])
    }, [props.powerPlant]);

    const formatCaption = (fromDateTime: Date, toDateTime: Date): string => {
        return period.groupBy && (period.groupBy.valueOf() === "none" || period.groupBy.valueOf() === "week" || period.groupBy.valueOf() === "month")
            ? format(fromDateTime, 'dd/MM') + ' a ' + format(toDateTime, 'dd/MM')
            : format(fromDateTime, 'HH:mm') + ' a ' + format(toDateTime, 'HH:mm');
    };

    const dateByPeriod = (): string => {
        let fromDateTime = new Date();
        let toDateTime = new Date();
        let captionData: string;

        if (period.fromDateTime) {
            fromDateTime = period.fromDateTime;
        }
        if (period.toDateTime) {
            toDateTime = period.toDateTime;
        }

        captionData = formatCaption(fromDateTime, toDateTime);
        return captionData;
    };

    const maxGenerationRate = Math.max(...generationRateSkid.map(item => item.generationRate ?? 0), 0);


    const yAxisMax = maxGenerationRate > 100 ? 120 : 100;


    const newNameGap = () => {
        if (period.groupBy) {
            switch (period.groupBy) {
                case "day":
                    return 75;
                case "week":
                    return 70;
                case "month":
                    return 70;
                default:
                    return 65;
            }
        }
        return 40;
    };

    useEffect(() => {
        setSeries([{
                name: 'Taxa de Geração',
                data: Array.isArray(generationRateSkid)
                    ? generationRateSkid.map(item => {
                        let truncatedRateData;
                        truncatedRateData = item.generationRate;
                        return item.generationRate !== undefined && item.generationRate !== null
                            ? truncateNumber(truncatedRateData, 2)
                            : '-';
                    })
                    : [],
                type: 'bar',
                unity: '%',
                date: datePeriodTime,
                itemStyle: {
                    color: function (params: any) {
                        const value = params.data;
                        if (value < 60) {
                            return '#E56C6C';
                        } else if (value >= 60 && value <= 80) {
                            return '#F9D37F';
                        } else {
                            return '#91CC75';
                        }
                    }
                }
            }]
        )
        setXAxis({
            type: 'category',
            data: Array.isArray(generationRateSkid)
                ? generationRateSkid.map(item => {
                    if (item.skidName !== undefined && item.skidName !== null) {
                        return item.skidName;
                    } else {
                        return "";
                    }
                })
                : []
        })

        setYAxis([{
            name: "%",
            min: 0,
            max: yAxisMax,
            interval: truncateNumber(yAxisMax/ 5),
            nameGap: newNameGap(),
            nameLocation: "middle",
            axisLabel: {
                formatter: function (value: any) {
                    return value.toFixed(2);
                },
            },
        }])
    }, [generationRateSkid]);

    const datePeriodTime: any = dateByPeriod();

    const chartOption : any = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
            valueFormatter: (value: any) => `${value} %`,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        legend: {
            type: 'scroll',
            show: true,
            // orient: 'horizontal', // Alterado para orientação horizontal
            // bottom: 2, // Posiciona a legenda na parte inferior
            // left: 'center', // Centraliza a legenda horizontalmente
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1,
                color: "gray" // Mantém a cor do item
            },
            data: ['Taxa de Geração']
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 40
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        series: series
    };


    useEffect(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0){
            dispatch(reset)
        dispatch(getEntities({period: period, idPowerPlant: props.powerPlant}));
        }
    }, [period, dispatch, props.powerPlant]);


    useInterval(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0){
            dispatch(getEntities({period: period, idPowerPlant: props.powerPlant}));
        }
    }, userConfig.mainUpdateInterval) // 5 minutes


    const [content, setContent] = useState<JSX.Element>();
    useEffect(() => {
        console.log("generationRateSkid: ", generationRateSkid)
        if(loading){
            setContent(<Skeleton animation="wave" height={289} variant="rounded" width="100%" />)
        } else if(generationRateSkid && generationRateSkid.length > 0){
            console.log("entrou aki")
            setContent(<AChartX height={350} width="100%" option={chartOption} theme={theme.palette.mode}/>)
        }else{
            setContent(<DataNotFound boxStyle={{ height: 350, width: '100%' }} />)
        }
    }, [generationRateSkid, series, period, loading]);

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: '-19%', right: 25 }}>
                <NewHtmlTooltip
                    title={
                        <ColorInformation />
                    }
                >
                    <Button>HTML</Button>
                </NewHtmlTooltip>
            </div>
            {content}

        </div>
    );
}

export default GenerationRateChart;